import React from 'react'
import { TextType } from '../../types/textType'
import Text from './Text'
import { textDefaultConfig } from './textMeta'

const TextPreview = (config: TextType) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : textDefaultConfig
  return <Text {...defaultData} />
}

export default TextPreview
