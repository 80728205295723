import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'

export const buttonsBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type ButtonsBlock = InferType<typeof buttonsBlockSchema>
