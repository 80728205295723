import React from 'react'
import HeroCarousel2 from './HeroCarousel2'
import { heroCarousel2DefaultConfig } from './heroCarousel2Meta'

const HeroCarousel2Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroCarousel2DefaultConfig

  return <HeroCarousel2 {...defaultData} />
}

export default HeroCarousel2Preview
