import { string } from 'yup'

export const cssTextDecorationStyleSchema = string().oneOf([
  'inherit',
  'solid',
  'double',
  'dotted',
  'dashed',
  'wavy',
])
