import { assocJMESPath, normalizeJMESPath } from '@sceneio/tools'
import { flatten, unflatten } from 'flat'
import { search } from 'jmespath'

export function flattenUpdate(
  object: Record<string, any> = {},
  data: Record<string, any>,
  path: string,
) {
  const searchPath = normalizeJMESPath(path || '@')

  return assocJMESPath(
    path,
    unflatten({
      ...flatten(search(object, searchPath) || {}),
      ...flatten(data),
    }),
    object,
  )
}
