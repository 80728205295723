import React from 'react'
import { Container } from '../../../../components/Container/Container'
import Text from '../../../../components/Text/Text'
import resolveComponentId from '../../../../tools/helpers/resolveComponentId'
import { HeaderVariant1Block } from '@sceneio/schemas/lib/types'

export const TopBanner = ({
  config,
  blockId,
  editor,
}: {
  config: HeaderVariant1Block['components']['topBanner']
  blockId?: string
  editor: HeaderVariant1Block['editor']
}) => {
  return (
    <Container
      {...config}
      className="sc-header__top-banner"
      editor={{
        ...editor?.component,
        blockId,
        id: 'topBanner',
      }}
      name="componentArray"
    >
      {config?.items?.map(({ id: contentId, ...restTextProps }, index) => (
        <Text
          key={contentId || index}
          cid={resolveComponentId(blockId, `topBanner-text-${index}`)}
          {...restTextProps}
          editor={{
            ...editor?.component,
            blockId,
            id: `topBanner.items[${index}]`,
          }}
        />
      ))}
    </Container>
  )
}
