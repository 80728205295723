import { Icon } from '@sceneio/ui-icons'
import { BreakpointType } from '../../../atoms/BreakpointDropdown/BreakpointDropdown'
import { RHFColorPicker } from '../../../atoms/ColorPicker/ColorPicker'
import { RHFMediaInput } from '../../../atoms/MediaInput/MediaInput'
import { RHFSwitch } from '../../../atoms/Switch/Switch'
import { useFormContext } from '../../../form/context/FormContext'

type BreakpointBackgroundFieldsPropType = {
  valuesPath: string
  breakpoint: BreakpointType | 'default'
  onMediaAddClick?: ({
    fieldName,
  }: {
    fieldName: string
    sourceType: 'IMAGE' | 'VIDEO'
  }) => void
}

const RemoveBackgroundButton = ({
  onClick,
  show,
}: {
  onClick: () => void
  show?: boolean
}) => {
  if (!show) return null
  return (
    <button
      type="button"
      className="tw-w-6 tw-h-8 tw-flex tw-justify-end tw-items-center tw-pr-2"
      onClick={onClick}
    >
      <Icon provider="phosphor" icon="Minus" />
    </button>
  )
}

export const BreakpointBackgroundFields = ({
  breakpoint,
  valuesPath,
  onMediaAddClick,
}: BreakpointBackgroundFieldsPropType) => {
  const { values, unregister, setValue, forceBreakpoint } = useFormContext({
    name: valuesPath,
  })
  const isClearable = false

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {values[`${valuesPath}.responsiveConfig.${breakpoint}.overlay`] && (
        <div className="tw-flex tw-gap-1">
          <div className="tw-flex-1 tw-min-w-0">
            <RHFColorPicker
              name={`${valuesPath}.responsiveConfig.${breakpoint}.overlay`}
              isClearable={isClearable}
            />
          </div>
          <RemoveBackgroundButton
            show={
              !forceBreakpoint || (forceBreakpoint && breakpoint !== 'default')
            }
            onClick={() =>
              unregister([
                `${valuesPath}.responsiveConfig.${breakpoint}.overlay`,
              ])
            }
          />
        </div>
      )}
      {values[`${valuesPath}.responsiveConfig.${breakpoint}.image.src`] && (
        <div className="tw-flex tw-gap-1">
          <div className="tw-flex tw-flex-col tw-flex-1 tw-min-w-0 tw-bg-form-field-bg tw-rounded">
            <RHFMediaInput
              name={`${valuesPath}.responsiveConfig.${breakpoint}.image.src`}
              className="tw-col-span-2"
              onMediaAddClick={onMediaAddClick}
              isClearable={isClearable}
            />
            {breakpoint === 'default' && (
              <div className="tw-grid tw-grid-cols-2">
                <RHFSwitch
                  label="Cover"
                  name={`${valuesPath}.fit`}
                  value={values[`${valuesPath}.fit`] === 'cover'}
                  onChange={(val) => {
                    if (val) {
                      setValue(`${valuesPath}.fit`, 'cover')
                    } else {
                      setValue(`${valuesPath}.fit`, 'contain')
                    }
                  }}
                />
                <RHFSwitch label="Parallax" name={`${valuesPath}.parallax`} />
              </div>
            )}
          </div>
          <RemoveBackgroundButton
            show={
              !forceBreakpoint || (forceBreakpoint && breakpoint !== 'default')
            }
            onClick={() =>
              unregister([`${valuesPath}.responsiveConfig.${breakpoint}.image`])
            }
          />
        </div>
      )}
      {values[`${valuesPath}.responsiveConfig.${breakpoint}.color`] && (
        <div className="tw-flex tw-gap-1">
          <div className="tw-flex-1 tw-min-w-0">
            <RHFColorPicker
              name={`${valuesPath}.responsiveConfig.${breakpoint}.color`}
              isClearable={isClearable}
            />
          </div>
          <RemoveBackgroundButton
            show={
              !forceBreakpoint || (forceBreakpoint && breakpoint !== 'default')
            }
            onClick={() =>
              unregister([`${valuesPath}.responsiveConfig.${breakpoint}.color`])
            }
          />
        </div>
      )}
    </div>
  )
}
