import Spinner from 'react-bootstrap/Spinner'
import styles from '../../styles/loadingPage.scss?inline'

function LoadingPage() {
  return (
    <>
      <style>{styles}</style>
      <div className="w-100 vh-100 d-flex flex-column align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
        <p className="mt-3">Loading...</p>
      </div>
    </>
  )
}

export default LoadingPage
