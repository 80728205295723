import { object, string, InferType, mixed, boolean } from 'yup'
import { backgroundSchema } from '../components/common/backgroundSchema'

export const contentSchema = object({
  data: object({
    type: string().oneOf(['verticalLayout']).required(),
    config: object({
      background: backgroundSchema.optional(),
      animation: string().oneOf(['fade-in', 'fade-in-bottom']),
    }).noUnknown(),
  })
    .noUnknown()
    .required(),
  // preferences: object({
  //   userHasInteracted: boolean().optional(),
  // }).noUnknown(),
})

export type Content = InferType<typeof contentSchema>
