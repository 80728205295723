import { useEffect, useRef } from 'react'
import { useFormContext } from '../form/context/FormContext'

export const ScrollToViewHelper = ({ type }: { type: string }) => {
  const divRef = useRef<HTMLDivElement>(null)
  const { focusedMolecule } = useFormContext()

  const scrollToElement = () => {
    const { current } = divRef
    if (current !== null) {
      setTimeout(() => {
        current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 1)
    }
  }

  useEffect(() => {
    if (focusedMolecule === type) {
      scrollToElement()
    }
  }, [focusedMolecule])

  return <div ref={divRef}></div>
}
