// due to specificity on blockContainer css styles (bootstrap breakpoints) we need to clone xs breakpoints to sm breakpoints (we support only xs overrides)
export const cloneXsBreakpointsToSmBreakpoints = (
  cssVariables?: Record<string, any>,
) => {
  let outputCssVariables = cssVariables || {}
  let existingSmBreakpoints = {}

  outputCssVariables = Object.entries(outputCssVariables).reduce(
    (acc, [cssVar, value]) => {
      const isSmBreakpoint = cssVar.includes('-sm')
      const isXsBreakpoint = cssVar.includes('-xs')

      if (isSmBreakpoint) {
        existingSmBreakpoints = {
          ...existingSmBreakpoints,
          [cssVar]: value,
        }
        return acc
      }

      return {
        ...acc,
        [cssVar]: value,
        ...(isXsBreakpoint && {
          [cssVar.replace('-xs', '-sm')]: value,
        }),
      }
    },
    {},
  )

  return {
    ...outputCssVariables,
    // override cloned sm breakpoints with existing origin sm breakpoints if exist
    ...existingSmBreakpoints,
  }
}
