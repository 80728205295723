import React from 'react'
import FAQ3 from './FAQ3'
import { fAQ3DefaultConfig } from './fAQ3Meta'

const FAQ3Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : fAQ3DefaultConfig

  return <FAQ3 {...defaultData} />
}

export default FAQ3Preview
