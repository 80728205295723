import { updateReusableContentBlock } from '../features/content/contentSlice'
import { UpdateReusableContentBlockQueryVarsType } from '../features/content/contentSliceTypes'
import { StoreType } from '../store'
import { LocalHistoryCommand } from './LocalHistoryCommand'

export class UpdateReusableBlockCommand extends LocalHistoryCommand {
  thunkData: UpdateReusableContentBlockQueryVarsType
  constructor(thunkData: UpdateReusableContentBlockQueryVarsType) {
    super()
    this.thunkData = thunkData
    this.type = 'UpdateReusableBlockCommand'
  }

  execute() {
    return updateReusableContentBlock(this.thunkData)
  }

  preExecute(getState: StoreType['getState']) {
    const state = getState()

    if (state.content.status !== 'succeeded') {
      return
    }

    const block = state.content.entity.contentBlocks.find(
      ({ id }) => this.thunkData.queryVariables.blockId === id,
    )

    // when the draft has not been created yet
    if (block && !block.reusableContentBlockDraft) {
      this.pastState = {
        blockId: block.id,
        blockCid: block.cid,
        configValue: block.config,
        name: block.name,
        configPath: '',
        customConfigValue: block.customConfig,
        referencesToReplace: block.references,
      }
    }

    // when the draft has already been created
    if (block && block.reusableContentBlockDraft) {
      this.pastState = {
        blockId: block.id,
        blockCid: block.cid,
        configValue: block.reusableContentBlockDraft.config,
        name: block.name,
        configPath: '',
        customConfigValue: block.reusableContentBlockDraft.customConfig,
        referencesToReplace: block.reusableContentBlockDraft.references,
      }
    }
  }

  undo() {
    return updateReusableContentBlock({
      queryVariables: {
        ...this.pastState,
      },

      thunkOptions: {
        shouldReplaceValue: true,
      },
    })
  }
}
