import React from 'react'
import { heroWithForm4DefaultConfig } from './heroWithForm4Meta'
import HeroWithForm4 from './HeroWithForm4'

const HeroWithForm4Preview = (config: any) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroWithForm4DefaultConfig

  return <HeroWithForm4 {...defaultData} />
}

export default HeroWithForm4Preview
