import type { RefObject } from 'react'
import { useRef, useState, useEffect, useCallback } from 'react'
import { stringify } from '@sceneio/tools/lib/stringify'

export const DEFAULT_IGNORE_CLASS = 'ignore-onclickoutside'

function canUsePassiveEvents(): boolean {
  if (
    typeof window === 'undefined' ||
    typeof window.addEventListener !== 'function'
  )
    return false

  let passive = false
  const options = Object.defineProperty({}, 'passive', {
    get() {
      passive = true
    },
  })
  const noop = () => null

  window.addEventListener('test', noop, options)
  window.removeEventListener('test', noop, options)

  return passive
}

interface Callback<T extends Event = Event> {
  (event: T): void
}
type El = HTMLElement
type Refs = RefObject<El>[]

export interface Options {
  refs?: Refs
  useCapture?: boolean
  disabled?: boolean
  eventTypes?: string[]
  excludeScrollbar?: boolean
  ignoreClass?: string | string[]
  detectIFrame?: boolean
}
interface Return {
  (element: El | null): void
}

const checkClass = (el: HTMLElement, cl: string): boolean => {
  // SVG <use/> elements do not technically reside in the rendered DOM, so
  // they do not have classList directly, but they offer a link to their
  // corresponding element, which can have classList. This extra check is for
  // that case.

  // if (el.correspondingElement) {
  //   return el.correspondingElement?.classList?.contains(cl)
  // }
  return el.classList?.contains(cl)
}

const hasIgnoreClass = (e: any, ignoreClass: string | string[]): boolean => {
  let el =
    (e.composed && e.composedPath && e.composedPath().shift()) || e.target

  while (el || el?.host) {
    if (Array.isArray(ignoreClass)) {
      if (ignoreClass.some((c) => checkClass(el, c))) return true
    } else if (checkClass(el, ignoreClass)) {
      return true
    }
    el = el.parentNode || el.host
  }

  return false
}

const clickedOnScrollbar = (e: MouseEvent): boolean =>
  document.documentElement.clientWidth <= e.clientX ||
  document.documentElement.clientHeight <= e.clientY

type EventListenerOptions = { passive: boolean } | boolean

const getEventOptions = (type: string): EventListenerOptions => {
  if (type === 'click') {
    return true
  }
  return type.includes('touch') && canUsePassiveEvents()
    ? { passive: true }
    : false
}

export const useOnClickOutside = (
  callback: Callback,
  {
    refs: refsOpt,
    disabled,
    eventTypes = ['mousedown', 'touchstart'],
    excludeScrollbar,
    ignoreClass = DEFAULT_IGNORE_CLASS,
    detectIFrame = true,
  }: Options = {},
): Return => {
  const [refsState, setRefsState] = useState<Refs>([])
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  const ref: Return = useCallback(
    (el) => setRefsState((prevState) => [...prevState, { current: el }]),
    [],
  )

  useEffect(
    () => {
      if (!refsOpt?.length && !refsState.length) return

      const getEls = () => {
        const els: El[] = []
        ;(refsOpt || refsState).forEach(
          ({ current }) => current && els.push(current),
        )
        return els
      }

      const handler = (e: any) => {
        if (
          !hasIgnoreClass(e, ignoreClass) &&
          !(excludeScrollbar && clickedOnScrollbar(e)) &&
          getEls().every((el) => !el.contains(e.target))
        )
          callbackRef.current(e)
      }

      const blurHandler = (e: FocusEvent) =>
        // On firefox the iframe becomes document.activeElement in the next event loop
        setTimeout(() => {
          const { activeElement } = document

          if (
            activeElement?.tagName === 'IFRAME' &&
            !hasIgnoreClass(activeElement, ignoreClass) &&
            !getEls().includes(activeElement as HTMLIFrameElement)
          )
            callbackRef.current(e)
        }, 0)

      const removeEventListener = () => {
        eventTypes.forEach((type) =>
          // @ts-ignore
          document.body.removeEventListener(
            type,
            handler,
            getEventOptions(type),
          ),
        )

        if (detectIFrame) window.removeEventListener('blur', blurHandler)
      }

      if (disabled) {
        removeEventListener()
        return
      }

      eventTypes.forEach((type) =>
        document.body.addEventListener(type, handler, getEventOptions(type)),
      )

      if (detectIFrame) window.addEventListener('blur', blurHandler)

      // eslint-disable-next-line consistent-return
      return () => removeEventListener()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      refsState,
      ignoreClass,
      excludeScrollbar,
      disabled,
      detectIFrame,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      stringify(eventTypes),
    ],
  )

  return ref
}
