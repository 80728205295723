import { Lazy, Schema, reach } from 'yup'

export type ValidateSchemaOptions = {
  // For nested schemas, path will retrieve an inner schema based on the provided path.
  path?: string
}

export function validateSchema(
  data: any,
  validationSchema: Schema | Lazy<any>,
  options?: ValidateSchemaOptions,
) {
  const { path } = options || {}

  const schema = path
    ? (reach(validationSchema, path) as Schema)
    : validationSchema

  let output = {
    isValid: false,
    error: null,
  }
  try {
    const validation = schema.validateSync(data, {
      strict: true,
    })
    output = {
      ...output,
      isValid: Boolean(validation),
    }
  } catch (error: any) {
    // FOR DEBUGGING TESTS
    // console.warn(`[validateSchema]: ${error}`)
    output = {
      isValid: false,
      error,
    }
  }

  return output
}
