import * as Switch from '@radix-ui/react-switch'
import clsx from 'clsx'

type SwitchBodyPropType = {
  inputClassName?: string
  required?: boolean
  disabled?: boolean
  value?: boolean
  onChange?: (arg0: boolean) => void
}

export function SwitchBody({
  disabled,
  required,
  value,
  onChange,
  inputClassName,
}: SwitchBodyPropType) {
  return (
    <Switch.Root
      tabIndex={1}
      disabled={disabled}
      required={required}
      checked={value}
      onCheckedChange={onChange}
      className={clsx(
        'tw-rounded-[20px] tw-relative tw-w-[28px] tw-h-[16px] tw-bg-primary data-[state=unchecked]:tw-bg-gray-200 focus-visible:tw-outline-none',
        { 'tw-opacity-40': disabled },
        inputClassName,
      )}
    >
      <Switch.Thumb className="tw-block tw-rounded-full tw-transition tw-transform tw-translate-x-[2px] tw-w-[12px] tw-h-[12px] tw-bg-white data-[state=checked]:tw-translate-x-[14px]" />
    </Switch.Root>
  )
}
