import { EditorState } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'

export function OnChangePlugin({
  onChange,
}: {
  onChange: (editorState: EditorState) => void
}) {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState, dirtyLeaves }) => {
      // Prevent all updates not initiated by the editor itself
      if (dirtyLeaves.size === 0) {
        return
      }

      onChange(editorState)
    })
  }, [editor, onChange])
  return null
}
