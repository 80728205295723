import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { FeatureGrid3Block } from '@sceneio/schemas/lib/types'
import FeatureGrid2, { featureGrid2Plugins } from '../FeatureGrid2/FeatureGrid2'
import { featureGrid3DefaultConfig } from './featureGrid3Meta'

function FeatureGrid3(props: FeatureGrid3Block = featureGrid3DefaultConfig) {
  return <FeatureGrid2 {...props} />
}

export const featureGrid3Plugins: PluginsType = featureGrid2Plugins

export default FeatureGrid3
