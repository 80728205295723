import React from 'react'
import CTABanner4 from './CTABanner4'
import { ctaBanner4DefaultConfig } from './CTABanner4Meta'

const CTABanner4Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : ctaBanner4DefaultConfig

  return <CTABanner4 {...defaultData} />
}

export default CTABanner4Preview
