import React from 'react'
import Block from '../../../components/Block/Block'
import clsx from 'clsx'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { Container } from '../../../components/Container/Container'
import AccordionItem from '../../../components/AccordionItem/AccordionItem'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Text from '../../../components/Text/Text'
import { FAQ1Block } from '@sceneio/schemas/lib/types'
import { buttonSampleFactory, fAQ1DefaultConfig } from './fAQ1Meta'
import Button from '../../../components/Button/Button'
import Accordion from '../../../components/Accordion/Accordion'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function FAQ1({
  id,
  components: {
    block,
    topHeading,
    topText,
    accordion,
    bottomHeading,
    bottomText,
    buttons,
  },
  editor,
}: FAQ1Block = fAQ1DefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const fAQClass = clsx('sc-faq-1', className)

  return (
    <Block className={fAQClass} id={id} editor={editor} {...restBlockProps}>
      <Text
        {...topHeading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'topHeading',
        }}
        cid={resolveComponentId(id, 'topHeading')}
      />
      <Text
        {...topText}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'topText',
        }}
        cid={resolveComponentId(id, 'topText')}
      />
      <Accordion
        {...accordion}
        editor={{ ...editor?.component, blockId: id, id: 'accordion' }}
      >
        {accordion?.items?.map(
          (
            {
              id: accordionId,
              className: accordionClassname,
              ...restAccordionProps
            },
            index,
          ) => (
            <AccordionItem
              editor={{
                ...editor?.component,
                blockId: id,
                id: `accordion.items[${index}]`,
              }}
              className={clsx('sc-faq-1__accordion-item', accordionClassname)}
              key={accordionId}
              cid={resolveComponentId(id, `accordion-item-${index}`)}
              {...restAccordionProps}
            />
          ),
        )}
      </Accordion>
      <Text
        {...bottomHeading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'bottomHeading',
        }}
        cid={resolveComponentId(id, 'bottomHeading')}
      />
      <Text
        {...bottomText}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'bottomText',
        }}
        cid={resolveComponentId(id, 'bottomText')}
      />
      <Container
        {...buttons}
        className="bui-flex-wrap bui-w-100"
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'buttons',
        }}
        name="componentArray"
      >
        {buttons?.items?.map(
          (
            { id: buttonId, className: buttonClassname, ...restButtonProps },
            index,
          ) => (
            <Button
              editor={{
                ...editor?.component,
                blockId: id,
                id: `buttons.items[${index}]`,
                wysiwyg: {
                  type: 'ITEMS',
                  ...editorItemsControlsFactory({
                    itemsArr: buttons.items || [],
                    itemsId: `buttons.items`,
                    itemFactory: buttonSampleFactory,
                    index: index,
                  }),
                },
              }}
              className={clsx('sc-faq-1__button', buttonClassname)}
              key={buttonId}
              cid={resolveComponentId(id, `button-${index}`)}
              {...restButtonProps}
            />
          ),
        )}
      </Container>
    </Block>
  )
}

export const fAQ1Plugins: PluginsType = {
  js: ['accordion', 'animation'],
  css: [],
}

export default FAQ1
