import type { SVGProps } from 'react'
const SvgMobileX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 49 48"
    {...props}
  >
    <g clipPath="url(#mobile-x_svg__a)">
      <rect
        width={21}
        height={34.125}
        x={12.5}
        y={3}
        stroke="#252523"
        strokeWidth={2.625}
        rx={2.625}
      />
      <path
        stroke="#252523"
        strokeLinecap="round"
        strokeWidth={2.625}
        d="M19.063 8.25h7.875"
      />
      <circle
        cx={34.5}
        cy={36.125}
        r={11}
        fill="#FDEBEC"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        stroke="#FF445A"
        strokeLinecap="round"
        strokeWidth={2}
        d="m38.5 33.125-7 7M31.5 33.125l7 7"
      />
    </g>
    <defs>
      <clipPath id="mobile-x_svg__a">
        <path fill="#fff" d="M.5 0h48v48H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgMobileX
