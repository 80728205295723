import {
  $createParagraphNode,
  $getSelection,
  $INTERNAL_isPointSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { mergeRegister } from '@lexical/utils'
import * as Toolbar from '@radix-ui/react-toolbar'
import { Icon } from '@sceneio/ui-icons'
import { computePosition } from '@floating-ui/dom'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { $setBlocksType } from '@lexical/selection'
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text'
import { blockTypeToBlockName } from './FloatingTextFormatToolbarPlugin'
import * as Select from '@radix-ui/react-select'
import { TagSelectItem } from './TagSelectItem'
import { TOGGLE_LINK_COMMAND } from '@lexical/link'

export function TextFormatFloatingToolbar({
  blockType,
  editor,
  anchorElem,
  isLink,
  isBold,
  isItalic,
  isStrikethrough,
  isUnderline,
  canUndo,
  canRedo,
  enableHistory,
  enableTag,
  enableLink,
  enableList,
  enableTable,
  enableAI,
  enableFormat,
}: {
  blockType: keyof typeof blockTypeToBlockName
  editor: LexicalEditor
  anchorElem: HTMLElement
  isBold: boolean
  isItalic: boolean
  isLink: boolean
  isStrikethrough: boolean
  isUnderline: boolean
  canUndo: boolean
  canRedo: boolean
  enableAI?: boolean
  enableHistory?: boolean
  enableTag?: boolean
  enableLink?: boolean
  enableList?: boolean
  enableTable?: boolean
  enableFormat?: boolean
}): JSX.Element {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX
        const y = e.clientY
        const elementUnderMouse = document.elementFromPoint(x, y)

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none'
        }
      }
    }
  }
  function mouseUpListener() {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto'
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener)
      document.addEventListener('mouseup', mouseUpListener)

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener)
        document.removeEventListener('mouseup', mouseUpListener)
      }
    }
    return
  }, [popupCharStylesEditorRef])

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection()

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    const nativeSelection = window.getSelection()

    if (popupCharStylesEditorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      computePosition(
        nativeSelection.getRangeAt(0),
        popupCharStylesEditorElem,
        {
          placement: 'top',
        },
      )
        .then((pos) => {
          popupCharStylesEditorElem.style.opacity = '1'
          popupCharStylesEditorElem.style.transform = `translate(${pos.x}px, ${
            pos.y - 10
          }px)`
        })
        .catch(() => {
          popupCharStylesEditorElem.style.opacity = '0'
          popupCharStylesEditorElem.style.transform = `translate(-10000px, -10000px)`
        })
    }
  }, [editor, anchorElem, isLink])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, updateTextFormatFloatingToolbar])

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($INTERNAL_isPointSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if ($INTERNAL_isPointSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const handleFormatChange = (value: typeof blockType) => {
    if (value === 'paragraph') {
      formatParagraph()
    }

    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)) {
      formatHeading(value as HeadingTagType)
    }

    if (value === 'bullet') {
      formatBulletList()
    }

    if (value === 'number') {
      formatNumberedList()
    }
  }

  return (
    <Toolbar.Root
      ref={popupCharStylesEditorRef}
      className="wysiwyg-toolbar-root"
      aria-label="Formatting options"
      data-no-drag
    >
      {editor.isEditable() && (
        <>
          {enableHistory && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Text history"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="back"
                  aria-label="Back"
                  disabled={!canUndo}
                  onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined)
                  }}
                >
                  <Icon size={24} provider="phosphor" icon="ArrowArcLeft" />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="forward"
                  aria-label="Forward"
                  disabled={!canRedo}
                  onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined)
                  }}
                >
                  <Icon size={24} provider="phosphor" icon="ArrowArcRight" />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
          {enableTag && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Select.Root
                onOpenChange={(open) => {
                  if (open) {
                    // fix radix select disabling pointer events on body, preventing moving in infinite canvas
                    setTimeout(() => {
                      document.body.style.pointerEvents = 'auto'
                    }, 1)
                  }
                }}
                defaultValue={blockType}
                onValueChange={handleFormatChange}
              >
                <Select.Trigger className="wysiwyg-tag-select-trigger">
                  <Select.Value placeholder="Select format" />
                  <Select.Icon className="wysiwyg-tag-select-icon">
                    <Icon provider="phosphor" icon="CaretUp" />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Content
                  position="popper"
                  className="wysiwyg-tag-select-content"
                >
                  <Select.Viewport className="wysiwyg-tag-select-viewport">
                    <Select.Group>
                      <TagSelectItem value="paragraph">
                        <Icon
                          size={24}
                          provider="phosphor"
                          icon="TextAlignJustify"
                        />
                      </TagSelectItem>
                      <TagSelectItem value="h1">
                        <Icon size={24} provider="phosphor" icon="TextHOne" />
                      </TagSelectItem>
                      <TagSelectItem value="h2">
                        <Icon size={24} provider="phosphor" icon="TextHTwo" />
                      </TagSelectItem>
                      <TagSelectItem value="h3">
                        <Icon size={24} provider="phosphor" icon="TextHThree" />
                      </TagSelectItem>
                      <TagSelectItem value="h4">
                        <Icon size={24} provider="phosphor" icon="TextHFour" />
                      </TagSelectItem>
                      <TagSelectItem value="h5">
                        <Icon size={24} provider="phosphor" icon="TextHFive" />
                      </TagSelectItem>
                      <TagSelectItem value="h6">
                        <Icon size={24} provider="phosphor" icon="TextHSix" />
                      </TagSelectItem>
                      {enableList && (
                        <>
                          <TagSelectItem value="bullet">
                            <Icon
                              size={24}
                              provider="phosphor"
                              icon="ListBullets"
                            />
                          </TagSelectItem>
                          <TagSelectItem value="number">
                            <Icon
                              size={24}
                              provider="phosphor"
                              icon="ListNumbers"
                            />
                          </TagSelectItem>
                        </>
                      )}
                    </Select.Group>
                  </Select.Viewport>
                </Select.Content>
              </Select.Root>
            </>
          )}
          {enableFormat && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Text formatting"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="bold"
                  aria-label="Bold"
                  data-state={isBold ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                  }}
                >
                  <Icon size={24} provider="phosphor" icon="TextB" />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="italic"
                  aria-label="Italic"
                  data-state={isItalic ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
                  }}
                >
                  <Icon size={24} provider="phosphor" icon="TextItalic" />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="underline"
                  aria-label="Underline"
                  data-state={isUnderline ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
                  }}
                >
                  <Icon size={24} provider="phosphor" icon="TextUnderline" />
                </Toolbar.ToggleItem>
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="strikethrough"
                  aria-label="Strike through"
                  data-state={isStrikethrough ? 'on' : 'off'}
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
                  }}
                >
                  <Icon
                    size={24}
                    provider="phosphor"
                    icon="TextStrikethrough"
                  />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
          {enableLink && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Text links"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value="text-link"
                  aria-label="Text Link"
                  data-state={isLink ? 'on' : 'off'}
                  onClick={insertLink}
                >
                  <Icon size={24} provider="phosphor" icon="LinkSimple" />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
          {enableAI && (
            <>
              <Toolbar.Separator className="wysiwyg-toolbar-separator" />
              <Toolbar.ToggleGroup
                className="wysiwyg-toolbar-toggle-group"
                type="multiple"
                aria-label="Muse AI"
              >
                <Toolbar.ToggleItem
                  className="wysiwyg-toolbar-toggle-item"
                  value=""
                  aria-label="Muse AI"
                  data-state={'off'}
                  onClick={() =>
                    document
                      .querySelector('.react-chatbot-kit-chat-container')
                      ?.classList.add('expanded')
                  }
                >
                  <Icon
                    size={24}
                    style={{ padding: '0.15rem' }}
                    provider="scene"
                    icon="sparkle"
                  />
                </Toolbar.ToggleItem>
              </Toolbar.ToggleGroup>
            </>
          )}
        </>
      )}
    </Toolbar.Root>
  )
}
