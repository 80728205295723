import { flatten, unflatten } from 'flat'

/**
    Creates a new object with the own properties of the first object merged with the own properties of the second object. If a key exists in both objects:

    - and both values are objects, the two values will be recursively merged
    - otherwise the value from the second object will be used
    - values contain nested array and preserveArrays option is set to true, the two values will be recursively merged
    - values contain nested array and preserveArrays option is set to false, the two values will be merged but array from the second object will be preserved
 */

type Obj = Record<string, unknown>

export function mergeDeepRight<
  Obj1 extends Obj = Obj,
  Obj2 extends Obj = Obj,
  OutputType = Obj,
>(
  obj1: Obj1,
  obj2: Obj2,
  options?: {
    preserveArrays?: boolean
  },
) {
  const { preserveArrays = false } = options || {}

  const flattenObj1 = flatten<Obj, Obj>(obj1, { safe: preserveArrays })
  const flattenObj2 = flatten<Obj, Obj>(obj2, { safe: preserveArrays })

  return unflatten({
    ...flattenObj1,
    ...flattenObj2,
  }) as OutputType
}
