import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#252523"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 9h5v5M14 7H9V2"
    />
  </svg>
)
export default SvgCollapse
