import type { SVGProps } from 'react'
const SvgBlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#101828"
      d="M3 2.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5"
    />
    <path
      fill="#101828"
      fillRule="evenodd"
      d="M3.5 4a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5zM4 5v6h8V5z"
      clipRule="evenodd"
    />
    <path fill="#101828" d="M3.5 13a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z" />
  </svg>
)
export default SvgBlockIcon
