import React from 'react'
import { SliderVariant1Component } from '@sceneio/schemas/lib/types'
import clsx from 'clsx'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Button from '../../Button/Button'
import { Container } from '../../Container/Container'
import Pagination from '../components/Pagination'
import { getActiveSlideIndexInEditor } from '../helpers'
import SliderContainer from '../SliderContainer'

function SliderVariant1({
  components: { slides, controlsContainer },
  itemsPerView,
  loop,
  autoplay,
  className,
  editor,
  preview,
  show,
  style,
  hasFloatingNavigation = true,
  ...props
}: SliderVariant1Component) {
  if (!show || !slides.items.length) {
    return null
  }
  const { navigation, pagination } = controlsContainer?.components || {}

  return (
    <SliderContainer
      {...props}
      itemsPerView={itemsPerView}
      activeSlideIndex={getActiveSlideIndexInEditor({
        editor,
        slidesLength: slides.items.length,
      })}
      loop={loop}
      autoplay={autoplay}
      editor={editor}
      preview={preview}
      className={clsx(
        'sc-slider-variant-1',
        { 'sc-slider-variant-1--floating-nav': hasFloatingNavigation },
        className,
      )}
    >
      <div className="sc-slider__wrapper swiper-wrapper">
        {slides.items.map((slide, idx) => (
          <div key={idx} className="sc-slider__slide swiper-slide">
            {slide}
          </div>
        ))}
      </div>

      <Container
        name="component"
        {...controlsContainer}
        editor={{
          ...editor,
          id: `${editor?.id}.controlsContainer`,
        }}
        className="sc-slider__controls-container"
      >
        <Pagination
          {...pagination}
          editor={{
            ...editor,
            id: `${editor?.id}.controlsContainer.pagination`,
          }}
          itemsPerView={itemsPerView}
          slidesLength={slides.items.length}
          activeSlideIndex={getActiveSlideIndexInEditor({
            editor,
            slidesLength: slides.items.length,
          })}
          loop={loop}
          preview={preview}
          autoplay={autoplay?.enable}
        />
        <Container
          className="sc-slider__navigation"
          {...navigation}
          editor={{
            ...editor,
            id: `${editor?.id}.controlsContainer.navigation`,
          }}
          name="group"
        >
          <div className="sc-slider__navigation-btn sc-slider__button--prev">
            <Button
              {...navigation?.components?.buttonPrev}
              editor={{
                ...editor,
                id: `${editor?.id}.controlsContainer.navigation.buttonPrev`,
                wysiwyg: {
                  type: 'RAW',
                },
              }}
            />
          </div>
          <div className="sc-slider__navigation-btn sc-slider__button--next">
            <Button
              {...navigation?.components?.buttonNext}
              editor={{
                ...editor,
                id: `${editor?.id}.controlsContainer.navigation.buttonNext`,
                wysiwyg: {
                  type: 'RAW',
                },
              }}
            />
          </div>
        </Container>
      </Container>
    </SliderContainer>
  )
}

export const plugins: PluginsType = {
  js: ['slider'],
  css: ['slider'],
}
export default SliderVariant1
