import React from 'react'
import clsx from 'clsx'
import { generatePositionClasses } from './blockHelpers'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import { BreakpointEnum } from '../Image/constants/breakpoints'
import { blockDefaults } from './blockMeta'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { BlockContainer } from '../BlockContainer/BlockContainer'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { BlockComponent } from '@sceneio/schemas/lib/types'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'

const BLOCK_DEFAULTS = blockDefaults.config

export function Block({
  id,
  children,
  minHeight,
  padding,
  margin,
  boxShadow,
  blockContainer,
  className,
  horizontalAlignment,
  border,
  background,
  as: BlockComponent = BLOCK_DEFAULTS.as,
  dataAttributes = BLOCK_DEFAULTS.dataAttributes,
  verticalAlignment,
  anchorId,
  style = {},
  show = BLOCK_DEFAULTS.show,
  editor,
  position = BLOCK_DEFAULTS.position,
  beforeContainerEl,
}: BlockComponent) {
  if (!show || !BlockComponent) {
    return null
  }

  const { fit, parallax, responsiveConfig } = background || {}

  const blockClass = clsx(
    'sc-block',
    {
      ...generatePositionClasses({ position }),
    },
    className,
  )
  return (
    <BlockComponent
      className={blockClass}
      style={{
        ...style,
        ...getBackgroundCssVariables({ background, prefix: 'block' }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'block',
        }),
        ...getRootLevelCssVariables({
          cssVariables: {
            margin,
            padding,
            minHeight,
            border,
            verticalAlignment,
            horizontalAlignment,
          },
          prefix: 'block',
        }),
      }}
      data-block-id={id}
      data-component-id={'block'}
      id={anchorId}
      {...(editor?.component.onClick && {
        onMouseDown: editor?.component.onClick,
      })}
      {...dataAttributes}
    >
      {responsiveConfig && Object.keys(responsiveConfig).length && (
        <>
          <div className="sc-block__bg-overlay" />
          {Object.entries(responsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    key={bp}
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-block__bg-image', {
                      'sc-block__bg-image--parallax': parallax,
                      'sc-block__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-block__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(responsiveConfig) as BreakpointEnum[]
                        ).filter((bp) => responsiveConfig[bp]),
                        elementClassName: 'sc-block__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
      {/* beforeContainerEl is used in a Header block with sticky banner component, which should not inherit the settings of BlockContainer*/}
      {beforeContainerEl && beforeContainerEl}
      <BlockContainer
        as="div"
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'blockContainer',
        }}
        {...blockContainer}
      >
        {children}
      </BlockContainer>
    </BlockComponent>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default Block
