import { InferType } from 'yup'
import {
  sliderVariant1ComponentSchema,
  sliderVariant1ComponentsSchema,
} from './sliderVariant1ComponentSchema'

export const sliderVariant2ComponentsSchema = sliderVariant1ComponentsSchema
export const sliderVariant2ComponentSchema = sliderVariant1ComponentSchema

export type SliderVariant2Component = InferType<
  typeof sliderVariant1ComponentSchema
>
