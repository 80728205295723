import React from 'react'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import type { GridComponent } from '@sceneio/schemas/lib/types'

function Grid({
  cssType = 'grid',
  columns,
  margin,
  padding,
  boxShadow,
  border,
  gap,
  horizontalAlignment,
  verticalAlignment,
  items = [],
  className,
  columnClassName,
  fillRow,
  show = true,
  editor,
  style,
}: GridComponent) {
  if (isEmpty(items) || !show) {
    return null
  }

  const gridClass = clsx(
    'sc-grid',
    className,
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    {
      'sc-grid--flex': cssType === 'flex',
    },
  )
  const columnClass = clsx(
    'sc-grid__column',
    {
      'bui-flex-grow-1': cssType === 'flex' && fillRow,
    },
    columnClassName,
  )

  return (
    <div
      data-component-id={editor?.id}
      className={gridClass}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            margin,
            padding,
            border,
            gap,
            horizontalAlignment,
            verticalAlignment,
            columns,
          },
          prefix: 'grid',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'grid',
        }),
      }}
    >
      <div className="sc-grid__row">
        {items.map((item, index) => (
          <div key={index} className={columnClass}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default Grid
