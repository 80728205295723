import {
  RHFRange,
  RHFSelect,
  RHFSwitch,
  RHFToggleGroup,
} from '@sceneio/ui-forms'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

type PlacementType = 'top' | 'bottom'

export const blockLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  fieldNames: [...(breakpoint === 'default' ? 'swapOrder' : [])],
  children: (
    <>
      {breakpoint === 'default' && (
        <div className="tw-flex tw-flex-col tw-gap-1">
          <RHFSwitch name="swapOrder" label="Swap order" />
          <RHFRange
            name="columnRatio"
            min={1}
            max={11}
            step={1}
            label="Split ratio"
          />
        </div>
      )}
    </>
  ),
  placement: 'bottom' as PlacementType,
})

export const buttonContainerLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  placement: 'top',
  fieldNames: [`flow.data.--flow-${breakpoint}`],
  children: (
    <RHFToggleGroup
      name={`flow.data.--flow-${breakpoint}`}
      options={[
        { value: 'row', label: 'Horizontal' },
        { value: 'column', label: 'Vertical' },
      ]}
    />
  ),
})
