import { InferType, string } from 'yup'

export const cssFlexAlignmentSchema = string().oneOf([
  'center',
  'flex-start',
  'flex-end',
  'stretch',
])

export type CssFlexAlignment = InferType<typeof cssFlexAlignmentSchema>
