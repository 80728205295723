import clsx from 'clsx'
import React from 'react'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { generateDisabledContainerClasses } from './helpers/generateDisabledContainerClasses'
import { generateResponsiveContainerStyles } from './helpers/generateResponsiveContainerStyles'
import { ROOT_CONTAINER_MAX_WIDTH_DEFAULTS } from '../../config/container'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import { BreakpointEnum } from '../Image/constants/breakpoints'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { BlockContainerComponent } from '@sceneio/schemas/lib/types'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { cloneXsBreakpointsToSmBreakpoints } from './helpers/cloneXsBreakpointsToSmBreakpoints'

export function BlockContainer({
  className,
  children,
  as: Component = 'div',
  padding,
  margin,
  border,
  boxShadow,
  horizontalAlignment,
  verticalAlignment,
  background,
  responsiveConfig,
  rootContainerMaxWidth = ROOT_CONTAINER_MAX_WIDTH_DEFAULTS,
  show = true,
  style = {},
  direction = 'vertical',
  editor,
}: BlockContainerComponent) {
  if (!show) {
    return null
  }

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  return (
    <Component
      data-component-id={editor?.id}
      className={clsx(
        'sc-block-container',
        className,
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        {
          ...generateDisabledContainerClasses({
            containerResponsiveConfig: responsiveConfig,
          }),

          [`bui-d-flex sc-block-container--${direction}`]: direction,
        },
      )}
      style={cloneXsBreakpointsToSmBreakpoints({
        ...style,
        ...getBackgroundCssVariables({ background, prefix: 'block-container' }),
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            border,
            horizontalAlignment,
            verticalAlignment,
          },
          prefix: 'block-container',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          prefix: 'block-container',
          shadowType: 'box',
        }),
        ...generateResponsiveContainerStyles({
          containerResponsiveConfig: responsiveConfig,
          rootContainerMaxWidth,
        }),
      })}
    >
      {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
        <>
          <div className="sc-block-container__bg-overlay" />
          {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-container__bg-image', {
                      'sc-container__bg-image--parallax': parallax,
                      'sc-container__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-block-container__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(bgResponsiveConfig) as BreakpointEnum[]
                        ).filter((bp) => bgResponsiveConfig[bp]),
                        elementClassName: 'sc-block-container__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
      {children}
    </Component>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}
