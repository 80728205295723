import React from 'react'
import type { SVGProps } from 'react'

const SvgThreads = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.69 2.282c1.223.578 2.281 1.582 2.711 3.097a.5.5 0 1 1-.962.273c-.336-1.183-1.16-1.986-2.176-2.466a5.95 5.95 0 0 0-3.237-.493C5.146 2.947 3.742 4.66 3.502 7.4c-.12 1.362.117 2.884.821 4.047.688 1.135 1.833 1.953 3.639 1.953 1.596 0 2.613-.452 3.193-1.083.577-.627.797-1.513.633-2.55-.062-.388-.334-.759-.807-1.07a3 3 0 0 0-.2-.122 9 9 0 0 1-.046.382c-.12.852-.53 1.477-1.09 1.87a2.82 2.82 0 0 1-1.83.481c-.612-.047-1.22-.284-1.644-.72-.44-.452-.647-1.09-.462-1.823.194-.768.779-1.244 1.45-1.492.666-.245 1.462-.289 2.223-.183q.19.026.38.065a2.1 2.1 0 0 0-.273-.771c-.299-.474-.748-.748-1.24-.786-.49-.037-1.095.157-1.702.763a.5.5 0 1 1-.707-.707c.77-.77 1.649-1.117 2.485-1.053s1.557.532 2.01 1.249l-.42.265.42-.265c.293.463.43 1.03.47 1.626.257.11.502.238.726.386.615.405 1.124.99 1.244 1.749l-.493.078.493-.078c.2 1.26-.053 2.48-.884 3.383-.828.9-2.154 1.406-3.93 1.406-2.174 0-3.634-1.017-4.493-2.435-.842-1.39-1.094-3.14-.962-4.652.263-3.008 1.871-5.271 4.386-5.61l.067.495-.067-.496c1.187-.16 2.584.006 3.798.58m-.882 5.91a5 5 0 0 0-.563-.112c-.645-.09-1.268-.043-1.74.131-.465.172-.737.444-.826.799l-.407-.103.407.103c-.099.39.008.674.21.881.219.226.58.388 1.002.42.417.032.844-.067 1.179-.302.325-.228.592-.603.675-1.191q.046-.324.063-.626"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgThreads
