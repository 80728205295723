import { SliderComponent } from '@sceneio/schemas/lib/types'

export const getSliderCssVariables = ({
  responsiveConfig,
  selectedSlideIndex,
  easing,
}: {
  responsiveConfig: SliderComponent['itemsPerView']['responsiveConfig']
  selectedSlideIndex: number
  easing: SliderComponent['easing']
}) => {
  if (!responsiveConfig) {
    return {}
  }
  if (!responsiveConfig || !Object.entries(responsiveConfig)?.length) return {}

  const cssVars: Record<string, any> = {}
  Object.entries(responsiveConfig).forEach(([key, value]) => {
    const breakpoint = key
    const slidesPerView = value
    if (selectedSlideIndex === 0) {
      cssVars[`--slider-editor-translate-x-${breakpoint}`] = `translateX(0)`
    }
    if (slidesPerView === 1 && selectedSlideIndex > 0) {
      cssVars[`--slider-editor-translate-x-${breakpoint}`] = `translateX(
            ${(100 / slidesPerView) * selectedSlideIndex * -1}%
          )`
    }

    if (
      slidesPerView > 1 &&
      selectedSlideIndex > 0 &&
      selectedSlideIndex >= slidesPerView
    ) {
      cssVars[`--slider-editor-translate-x-${breakpoint}`] = `translateX(
           ${
             (100 / slidesPerView) *
             (selectedSlideIndex - (slidesPerView - 1)) *
             -1
           }%
          )`
    }
    cssVars[`--slider-slide-width-${breakpoint}`] = `${100 / slidesPerView}%`
    cssVars['--slider-easing-function'] = easing
  })

  return cssVars
}
