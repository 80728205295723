import { useFormContext } from '../../form/context/FormContext'
import { ResponsiveSpacingSettings } from './ResponsiveSpacingSettings'
import { DefaultSpacingSettings } from './DefaultSpacingSettings'
import { ScrollToViewHelper } from '../../helpers/ScrollToViewHelper'

export type RHFSpacingSettingsPropType = {
  type: 'padding' | 'margin'
  title?: string
  basePath?: string
  className?: string
}

export const RHFSpacingSettings = ({
  type,
  basePath,
  title = 'Spacing',
  className,
}: RHFSpacingSettingsPropType) => {
  const { forceBreakpoint, excludedSettings } = useFormContext({
    name: basePath,
  })

  if (excludedSettings.includes(type)) {
    return null
  }

  if (forceBreakpoint) {
    return (
      <ResponsiveSpacingSettings
        basePath={basePath}
        type={type}
        title={title}
        className={className}
      />
    )
  }
  return (
    <>
      <ScrollToViewHelper type={type} />
      <DefaultSpacingSettings
        basePath={basePath}
        type={type}
        title={title}
        className={className}
      />
    </>
  )
}
