import { StateType } from '../../atoms/StateTabs/StateTabs'
import { useFormContext } from '../../form/context/FormContext'
import { ScrollToViewHelper } from '../../helpers/ScrollToViewHelper'
import { DefaultBorderSettings } from './DefaultBorderSettings'
import { ResponsiveBorderSettings } from './ResponsiveBorderSettings'

export type RHFBorderSettingsPropType = {
  basePath?: string
  className?: string
  stateTabs?: {
    inheritedState?: StateType
    allowedInheritedStates?: StateType[]
    allowHover?: boolean
    allowActive?: boolean
    activeStateLabel?: string
  }
}

export const RHFBorderSettings = ({
  basePath,
  className,
  stateTabs: {
    inheritedState,
    allowHover = false,
    allowActive = false,
    activeStateLabel = 'Active',
  } = {},
}: RHFBorderSettingsPropType) => {
  const valuesPath = `${basePath ? basePath + '.' : ''}data`
  const { forceBreakpoint, excludedSettings } = useFormContext({
    snippetsType: 'STROKE',
    metaPath: valuesPath,
    name: basePath,
  })

  if (excludedSettings.includes('border')) {
    return null
  }

  if (forceBreakpoint) {
    return (
      <ResponsiveBorderSettings
        basePath={basePath}
        className={className}
        stateTabs={{
          inheritedState,
          allowHover,
          allowActive,
          activeStateLabel,
        }}
      />
    )
  }

  return (
    <>
      <ScrollToViewHelper type="stroke" />
      <DefaultBorderSettings
        basePath={basePath}
        className={className}
        stateTabs={{
          inheritedState,
          allowHover,
          allowActive,
          activeStateLabel,
        }}
      />
    </>
  )
}
