import { createHeadlessEditor } from '@lexical/headless'
import { $generateHtmlFromNodes } from '@lexical/html'
import wysiwygConfig from '@sceneio/config-wysiwyg'

export function lexicalToHtml(serialisedEditorState: string) {
  const jsdom = require('jsdom')
  const { JSDOM } = jsdom

  const dom = new JSDOM()
  global.window = dom.window
  global.document = dom.window.document
  global.DocumentFragment = dom.window.DocumentFragment

  const editor = createHeadlessEditor({ ...wysiwygConfig })
  const parseEditorState = editor.parseEditorState(serialisedEditorState)

  editor.setEditorState(parseEditorState)
  const editorState = editor.getEditorState()

  let html = ''

  editorState.read(() => {
    html = $generateHtmlFromNodes(editor)
  })

  return html
}
