import { InferType } from 'yup'
import {
  FooterVariant1ColumnConfig,
  footerVariant1BlockSchema,
} from './footerVariant1BlockSchema'

export const footerVariant3BlockSchema = footerVariant1BlockSchema

export type FooterVariant3ColumnConfig = FooterVariant1ColumnConfig
export type FooterVariant3Block = InferType<typeof footerVariant3BlockSchema>
