export type ReferenceDefinitionProps = {
  entity: string
  field: string
  value: string
  pathTo: string
  resolveMethod?: 'REPLACE' | 'MERGE'
}

export function buildReferenceDefinition(
  input: ReferenceDefinitionProps,
): string {
  const {
    entity,
    field,
    value,
    pathTo,
    resolveMethod = 'REPLACE',
  } = input || {}

  if (!entity) throw Error('Property `entity` is not defined')
  if (!field) throw Error('Property `field` is not defined')
  if (!value) throw Error('Property `value` is not defined')
  if (!pathTo) throw Error('Property `pathTo` is not defined')
  return `${String(
    entity,
  ).toLowerCase()}:${field}:${value}:${pathTo}:${resolveMethod}`
}
