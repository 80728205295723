import React from 'react'
import FeatureGrid from './FeatureGrid'
import { featureGridDefaultConfig } from './featureGridMeta'

const FeatureGridPreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : featureGridDefaultConfig

  return <FeatureGrid {...defaultData} />
}

export default FeatureGridPreview
