import { InferType, array, mixed, object } from 'yup'
import { buttonComponentSchema } from './buttonComponentSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { commonSliderSchema } from './sliderComponentSchema'
import { sliderPaginantionComponentSchema } from './sliderPaginationComponentSchema'

export const sliderVariant3ComponentsSchema = object({
  slides: containerComponentSchema
    .shape({
      items: array().of(mixed<any>()).required(),
    })
    .noUnknown()
    .required(),
  buttonPrev: buttonComponentSchema.required(),
  buttonNext: buttonComponentSchema.required(),
  pagination: sliderPaginantionComponentSchema.required(),
})
  .noUnknown()
  .required()

export const sliderVariant3ComponentSchema = commonSliderSchema
  .shape({
    components: sliderVariant3ComponentsSchema,
  })
  .noUnknown()

export type SliderVariant3Component = InferType<
  typeof sliderVariant3ComponentSchema
>
