export function hpMaskFormField(str: string = '') {
  let substrings = []

  // str division to chunks with random 1-3 length
  while (str.length > 0) {
    let length = Math.floor(Math.random() * 3) + 1
    substrings.push(str.substring(0, length))
    str = str.substring(length)
  }

  // join them with dash
  let result = substrings.join('-')

  return result
}
