import React from 'react'
import type { SVGProps } from 'react'
const SvgX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.667 2.628a.5.5 0 0 1 .037.706L9.22 7.206 13 12.608a.567.567 0 0 1-.464.892h-2.17a.57.57 0 0 1-.464-.241L7.22 9.427l-3.516 3.908a.5.5 0 0 1-.744-.67l3.672-4.079-3.636-5.194A.567.567 0 0 1 3.46 2.5h2.17c.186 0 .359.09.465.242L8.63 6.365l3.33-3.7a.5.5 0 0 1 .705-.037m-4.988 5.71 2.913 4.162h1.113l-6.3-9H4.292zm4.503 4.844"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgX
