import { assocJMESPath } from '@sceneio/tools'
import { BlockComponentMeta, BlockMeta } from './blockCommonTypes'

function getComponentDefaultConfigs<T extends { components?: any }>({
  subPath,
  componentMeta,
  data = {},
}: {
  subPath?: string
  componentMeta: BlockComponentMeta<T>
  data?: Object
}) {
  let outputData = { ...data } as T
  Object.values<any>(componentMeta).forEach(
    ({ path, defaultConfig, components }) => {
      const assocPath = subPath ? `${subPath}.${path}` : path

      if (defaultConfig) {
        outputData = assocJMESPath(assocPath, defaultConfig, outputData)
      }

      if (components) {
        outputData = getComponentDefaultConfigs({
          subPath: path,
          componentMeta: components,
          data: outputData as Object,
        })
      }
    },
  )

  return outputData as T
}

export function blockDefaultConfigFactory<
  T extends { components?: any; config?: any },
>({
  componentMeta,
  blockMeta,
  version,
}: {
  componentMeta: BlockComponentMeta<T>
  blockMeta: BlockMeta<T>
  version: number
}): {
  components: T['components']
  config: T['config'] | {}
  version: number
} {
  const { components } = getComponentDefaultConfigs<T>({ componentMeta })

  return {
    components,
    config: {
      ...(blockMeta.defaultConfig || {}),
    },
    version,
  }
}
