export function resolveZoomBoundaries(zoom: number) {
  let newZoom = zoom
  if (newZoom <= 5) {
    newZoom = 5
  }

  if (newZoom >= 200) {
    newZoom = 200
  }

  return newZoom
}
