import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './../../store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { DashboardSliceType } from './dashboardSliceTypes'

export * from './dashboardSliceSelectors'

// ---------------
// Initial State
// ---------------
export const initialState: DashboardSliceType = {
  dashboardModal: {
    show: false,
  },
} as DashboardSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Reducer
// ---------------

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardModal: (
      state,
      action: PayloadAction<DashboardSliceType['dashboardModal']>,
    ) => {
      state.dashboardModal = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', () => {
      return initialState
    })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDashboardDispatch: () => AppDispatch = useDispatch
export const useDashboardSelector: TypedUseSelectorHook<RootState> = useSelector

export const { setDashboardModal } = dashboardSlice.actions

export default dashboardSlice.reducer
