import { clsx } from 'clsx'
export const SaveButton = ({
  onClick,
  className,
}: {
  onClick: () => void
  className?: string
}) => {
  return (
    <button
      type="button"
      className={clsx(
        'tw-text-primary tw-font-semibold tw-bg-secondary tw-p-2 tw-rounded-lg tw-text-xs tw-w-full',
        className,
      )}
      onClick={onClick}
    >
      Save changes
    </button>
  )
}
