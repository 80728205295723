import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Image from '../../../components/Image/Image'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import { heroWithForm1DefaultConfig } from './heroWithForm1Meta'
import { HeroWithForm1Block } from '@sceneio/schemas/lib/types'
import Form from '../../../components/Form/Form'

function HeroWithForm1({
  id,
  components: {
    block,
    logo,
    subheading,
    text,
    heading,
    media,
    author,
    form,
    mediaContainer,
    contentContainer,
  },
  editor,
  preview,
}: HeroWithForm1Block = heroWithForm1DefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block

  const mediaColumns = columnRatio!
  const contentColumns = 12 - mediaColumns

  const productCardClass = clsx(['sc-split-hero', className])

  const mediaColumnClasses = clsx({
    [`bui-col-md-${mediaColumns}`]: mediaColumns,
    'bui-order-md-last': swapOrder,
  })

  const contentColumnClasses = clsx(`sc-split-hero__content-column`, {
    [`bui-col-md-${contentColumns}`]: contentColumns,
    [`bui-col`]: contentColumns === 0,
  })

  return (
    <Block
      className={productCardClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={mediaColumnClasses}>
          <Container
            {...mediaContainer}
            name="group"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'mediaContainer',
            }}
            flow={{ data: { '--flow-default': 'column' } }}
            className="bui-h-100"
          >
            {media && (
              <Media
                {...media}
                editor={{
                  ...editor?.component,
                  blockId: id,
                  id: 'media',
                }}
                components={{
                  ...media.components,
                  image: {
                    ...('image' in media.components && {
                      ...media.components.image,
                      isAnimated: true,
                    }),
                  },
                  caption: {
                    show: false,
                  },
                }}
                className="sc-split-hero__media"
                cid={resolveComponentId(id, 'media')}
                preview={preview}
              />
            )}
          </Container>
        </div>
        <div className={contentColumnClasses}>
          <Container
            {...contentContainer}
            name="group"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'contentContainer',
            }}
            flow={{ data: { '--flow-default': 'column' } }}
            className="bui-h-100"
          >
            <Image
              {...logo}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'logo',
              }}
              isAnimated={true}
            />
            <Text
              {...subheading}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'subheading',
              }}
              cid={resolveComponentId(id, 'subheading')}
            />
            <Text
              {...heading}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'heading',
              }}
              cid={resolveComponentId(id, 'heading')}
            />
            <Text
              {...text}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'text',
              }}
              cid={resolveComponentId(id, 'text')}
            />
            <Text
              {...author}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'author',
              }}
              cid={resolveComponentId(id, 'author')}
            />
            <Form
              {...form}
              editor={{ ...editor?.component, blockId: id, id: 'form' }}
              preview={preview}
              cid={resolveComponentId(id, 'form')}
            />
          </Container>
        </div>
      </div>
    </Block>
  )
}

export const heroWithForm1Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default HeroWithForm1
