import { buildReferenceDefinition } from './helpers/buildReferenceDefinition'
import {
  EntityType,
  referenceEntityFactory,
} from './helpers/referenceEntityFactory'
import { transformReferencesFromArrayToObject } from './helpers/transformReferencesFromArrayToObject'
import { transformReferencesFromObjectToArray } from './helpers/transformReferencesFromObjectToArray'
import { ReferenceResolveMethod, ReferencesType } from './referencesTypes'

export type AppendReferenceType = {
  references: ReferencesType
  data: {
    path: string
    entity: {
      id: string
      type: EntityType
      path: string
    }
    resolveMethod?: ReferenceResolveMethod
  }
}

export function appendReference({
  references = [],
  data,
}: AppendReferenceType): ReferencesType {
  const referencesObject = transformReferencesFromArrayToObject(references)
  const { path, entity, resolveMethod } = data
  const updatedReferencesObject = {
    ...referencesObject,
    [path]: buildReferenceDefinition(
      referenceEntityFactory({
        value: entity.id,
        entity: entity.type,
        pathTo: entity.path,
        resolveMethod,
      }),
    ),
  }

  return transformReferencesFromObjectToArray(updatedReferencesObject)
}

export type AppendResourceReferenceType = {
  references: ReferencesType
  data: {
    path: string
    id: string
    resolveMethod?: ReferenceResolveMethod
  }
}

export function appendMediaResourceReference({
  references,
  data,
}: AppendResourceReferenceType): ReferencesType {
  const { path, id } = data
  return appendReference({
    references,
    data: {
      path,
      entity: {
        id,
        type: 'asset',
        path: 'presentationUrl',
      },
    },
  })
}

export function appendDocumentResourceReference({
  references,
  data,
}: AppendResourceReferenceType): ReferencesType {
  const { path, id } = data
  return appendReference({
    references,
    data: {
      path,
      entity: {
        id,
        type: 'asset',
        path: 'presentationUrl',
      },
    },
  })
}

export function appendSnippetReference({
  references,
  data,
}: AppendResourceReferenceType): ReferencesType {
  const { path, id, resolveMethod } = data
  return appendReference({
    references,
    data: {
      path,
      entity: {
        id,
        type: 'snippet',
        path: 'data.value',
      },
      resolveMethod,
    },
  })
}

export function appendExternalMediaResourceReference({
  references,
  data,
}: AppendResourceReferenceType): ReferencesType {
  const { path, id } = data
  return appendReference({
    references,
    data: {
      path,
      entity: {
        id,
        type: 'resource',
        path: 'data.url',
      },
    },
  })
}

export function appendInternalLinkResourceReference({
  references,
  data,
}: AppendResourceReferenceType): ReferencesType {
  const { path, id } = data
  return appendReference({
    references,
    data: {
      path,
      entity: {
        id,
        type: 'path',
        path: 'value',
      },
    },
  })
}
