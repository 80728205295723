import React from 'react'
import SplitHero2 from './SplitHero2'
import { splitHero2DefaultConfig } from './splitHero2Meta'

const SplitHero2Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : splitHero2DefaultConfig

  return <SplitHero2 {...defaultData} />
}

export default SplitHero2Preview
