export type PreprocessValueType = {
  value?: string | number
  unitsAsValue?: string[]
}

export function preprocessValueWithUnit({
  value,
  unitsAsValue,
}: PreprocessValueType) {
  if (typeof value === 'undefined' || value === null) {
    return ''
  }

  if (typeof value === 'number') {
    return value
  }

  if (unitsAsValue?.includes(value)) {
    return value
  }

  const parsedValue = value.match(/^[+-]?\d+(\.\d+)?/g)?.[0]

  if (!parsedValue || Number.isNaN(parsedValue)) {
    return ''
  }

  return parseFloat(parsedValue)
}
