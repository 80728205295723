import {
  BlockComponent,
  HeroCarousel2Block,
  MediaComponent,
} from '@sceneio/schemas/lib/types'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { randomString } from '@sceneio/tools'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import {
  DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES,
  DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
} from '../../../components/Button/constants/globalButtonCssVariables'
import {
  blockLayoutAdditionalFormFieldRenderer,
  buttonContainerLayoutAdditionalFormFieldRenderer,
} from '../SplitHero/form/additionalFormFieldRenderers'
import { sliderVariant3Defaults } from '../../../components/Slider/SliderVariant3/sliderVariant3Meta'
import { imageDefaults } from '../../../components/Image/imageMeta'

const MEDIA_DEFAULTS = mediaDefaults.config
const IMAGE_DEFAULTS = imageDefaults.config
const SLIDER_DEFAULTS = sliderVariant3Defaults.config

export const logoImageSampleFactory = () => ({
  ...IMAGE_DEFAULTS,
  responsiveConfig: {
    default: {
      alt: 'This is image alt',
      src: 'https://assets.scene.io/global/placeholders/scene-logo-placeholder.svg',
      width: 120,
    },
  },
  id: randomString(8),
})

export const buttonSampleFactory = (data = {}) => ({
  id: randomString(8),
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const mediaSampleFactory = (): MediaComponent & { id: string } => ({
  id: randomString(8),
  ...MEDIA_DEFAULTS,
  cover: true,
  components: {
    ...MEDIA_DEFAULTS.components,
    image: {
      ...MEDIA_DEFAULTS.components.image,
      meta: {
        dataType: 'image-component',
      },
      responsiveConfig: {
        default: {
          src: 'https://assets.scene.io/global/placeholders/media-720x900.png',
        },
      },
      transformationOptions: {
        cover: '-x100p',
      },
    },
  },
})

export const heroCarousel2ComponentMeta: BlockComponentMeta<HeroCarousel2Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        minHeight: {
          data: {
            '--min-height-default': '100vh',
          },
        },
        swapOrder: false,
        columnRatio: 6,
        meta: {
          dataType: 'block-component',
        },
      } as BlockComponent & {
        swapOrder?: boolean
        columnRatio: number
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: blockLayoutAdditionalFormFieldRenderer,
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
          },
        },
      },
      type: 'blockContainer',
    },
    slider: {
      path: 'components.slider',
      name: 'Slider',
      type: 'sliderVariant3',
      components: {
        slides: {
          type: 'container',
          name: 'Slides',
          path: 'components.slides',
          configEditor: {
            disable: true,
          },
          disableToggleShow: true,
          persistentSelectionId: 'slider',
          components: {
            items: {
              isArrayComponent: true,
              path: 'items',
              name: 'Slide',
              type: 'media',
              disableToggleShow: true,
              sampleFactory: mediaSampleFactory,
              components: {
                credits: {
                  path: 'components.credits',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.media.components.credits',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                  disableEditing: true,
                  name: 'Credits',
                  type: 'text',
                },
              },
            },
          },
        },
        buttonPrev: {
          path: 'components.buttonPrev',
          name: 'Button prev',
          type: 'button',
        },
        buttonNext: {
          path: 'components.buttonNext',
          name: 'Button next',
          type: 'button',
        },
        pagination: {
          type: 'sliderPagination',
          name: 'Pagination',
          path: 'components.pagination',
        },
      },
      defaultConfig: {
        ...SLIDER_DEFAULTS,
        components: {
          ...SLIDER_DEFAULTS.components,
          slides: {
            ...SLIDER_DEFAULTS.components.slides,
            items: [
              mediaSampleFactory(),
              mediaSampleFactory(),
              mediaSampleFactory(),
            ],
          },
        },
      },
      configEditor: {
        formProps: {
          excludedSettings: ['itemsPerView', 'gap'],
        },
      },
    },
    contentContainer: {
      path: 'components.contentContainer',
      name: 'Content container',
      type: 'container',
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        padding: {
          data: {
            '--padding-default-left': '4rem',
            '--padding-default-right': '4rem',
            '--padding-default-top': '2rem',
            '--padding-default-bottom': '2rem',
            '--padding-xs-left': '2rem',
            '--padding-xs-right': '2rem',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        gap: {
          data: {
            [`--row-gap-default`]: '1.5rem',
          },
        },
      },
      disableToggleShow: true,
      configEditor: {
        formProps: {
          name: 'group',
          registerValues: containerComponentFormRegisterValuesFactory('group'),
        },
      },
    },
    heading: {
      path: 'components.heading',
      name: 'Heading',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.heading',
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'],
        children: textToLexical('Medium length hero headline goes here', 'h1'),
        as: 'h1',
        show: true,
      },
      type: 'text',
    },
    text: {
      path: 'components.text',
      name: 'Text',
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.text',
          editorOptions: {
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'text-component',
        },
        font: {
          meta: {
            dataType: 'font-style',
          },
        },
        children: textToLexical(
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.',
        ),
        as: 'p',
        show: true,
      },
      type: 'text',
    },
    logoImages: {
      path: 'components.logoImages',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Logo',
          type: 'image',
          disableToggleShow: true,
          sampleFactory: logoImageSampleFactory,
        },
      },
      defaultConfig: {
        meta: {
          dataType: 'grid-component',
        },
        items: [
          logoImageSampleFactory(),
          logoImageSampleFactory(),
          logoImageSampleFactory(),
          logoImageSampleFactory(),
        ],
        show: true,
        gap: {
          data: {
            [`--row-gap-default`]: '2rem',
            [`--column-gap-default`]: '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        columns: {
          data: {
            '--columns-default': 4,
            '--columns-md': 2,
            '--columns-xs': 2,
          },
        },
      },
      name: 'Logos',
      type: 'grid',
    },
    buttons: {
      path: 'components.buttons',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Button',
          type: 'button',
          disableToggleShow: true,
          sampleFactory: () =>
            buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
        },
      },
      inlineEditors: [
        {
          editorType: 'buttonEditor',
          editorPath: 'components.buttons.items',
          editorOptions: {
            isItemArray: true,
            sampleFactory: () =>
              buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        items: [
          buttonSampleFactory({
            children: textToLexical('CTA Button', 'array-item'),
            ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
          }),
          buttonSampleFactory({ ...DEFAULT_OUTLINE_BUTTON_CSS_VARIABLES }),
        ],
        show: true,
        padding: {
          data: {
            '--padding-default-top': '1rem',
          },
        },
        gap: {
          data: {
            [`--row-gap-default`]: '1rem',
            [`--column-gap-default`]: '1rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
      },
      name: 'Buttons',
      type: 'container',
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: buttonContainerLayoutAdditionalFormFieldRenderer,
          },
        },
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
      },
    },
  }

export const heroCarousel2BlockMeta: BlockMeta<HeroCarousel2Block> = {}

export const heroCarousel2DefaultConfig =
  blockDefaultConfigFactory<HeroCarousel2Block>({
    componentMeta: heroCarousel2ComponentMeta,
    blockMeta: heroCarousel2BlockMeta,
    version: 1,
  })
