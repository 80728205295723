import { RHFSwitch } from '@sceneio/ui-forms'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

type PlacementType = 'top' | 'bottom'

export const blockLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  fieldNames: [...(breakpoint === 'default' ? 'swapOrder' : [])],
  children: (
    <>
      {breakpoint === 'default' && (
        <RHFSwitch
          name="enableLightbox"
          label="Open images in lightbox"
          defaultValue={true}
        />
      )}
    </>
  ),
  placement: 'bottom' as PlacementType,
})
