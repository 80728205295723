import React from 'react'
import GalleryCarousel from './GalleryCarousel'
import { galleryCarouselDefaultConfig } from './galleryCarouselMeta'

export function GalleryCarouselPreview(config: any) {
  const defaultData =
    Object.keys(config).length !== 0 ? config : galleryCarouselDefaultConfig

  return <GalleryCarousel {...defaultData} />
}
