import type { SVGProps } from 'react'
const SvgHorizontalGap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 3h2v11H2M14 3h-2v11h2M8 5v7"
    />
  </svg>
)
export default SvgHorizontalGap
