import React from 'react'
import { heroWithForm2DefaultConfig } from './heroWithForm2Meta'
import HeroWithForm2 from './HeroWithForm2'

const HeroWithForm2Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroWithForm2DefaultConfig

  return <HeroWithForm2 {...defaultData} />
}

export default HeroWithForm2Preview
