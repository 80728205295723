import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { CTABanner2Block } from '@sceneio/schemas/lib/types'
import Banner from '../../Hero/Banner/Banner'
import { ctaBanner2DefaultConfig } from './CTABanner2Meta'

function CTABanner2(props: CTABanner2Block = ctaBanner2DefaultConfig) {
  return <Banner {...props} />
}

export const ctaBanner2Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default CTABanner2
