import React from 'react'
import { featureGrid2DefaultConfig } from './featureGrid2Meta'
import FeatureGrid2 from './FeatureGrid2'

const FeatureGrid2Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : featureGrid2DefaultConfig

  return <FeatureGrid2 {...defaultData} />
}

export default FeatureGrid2Preview
