import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { splitHero2DefaultConfig } from './splitHero2Meta'
import { SplitHero2Block } from '@sceneio/schemas/lib/types'
import SplitHero from '../SplitHero/SplitHero'

function SplitHero2(props: SplitHero2Block = splitHero2DefaultConfig) {
  return <SplitHero {...props} />
}

export const splitHero2Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default SplitHero2
