import { InferType, object } from 'yup'
import { cssLengthUnitSchema } from './common/cssLengthUnitSchema'

export const thicknessStyleSchema = object({
  data: object({
    '--thickness-default': cssLengthUnitSchema,
    '--thickness-xs': cssLengthUnitSchema,
    '--thickness-md': cssLengthUnitSchema,
    '--thickness-lg': cssLengthUnitSchema,
  }).noUnknown(),
}).noUnknown()

export type ThicknessStyle = InferType<typeof thicknessStyleSchema>
