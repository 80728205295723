import { mixed } from 'yup'

const FONT_WEIGHT_OPTIONS = [
  'inherit',
  'normal',
  'lighter',
  'bold',
  'bolder',
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
] as const

export const cssFontWeightSchema = mixed<(typeof FONT_WEIGHT_OPTIONS)[number]>()
