import { forwardRef } from 'react'
import * as Select from '@radix-ui/react-select'
import { Icon } from '@sceneio/ui-icons'

export const TagSelectItem = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; value: string }
>(({ children, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className="wysiwyg-tag-select-item"
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="wysiwyg-tag-select-item-indicator">
        <Icon provider="phosphor" icon="Check" />
      </Select.ItemIndicator>
    </Select.Item>
  )
})
