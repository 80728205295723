import { ComponentMeta } from '../componentCommonTypes'
import { componentDefaultConfigFactory } from '../helpers'

export const blockContainerComponentMeta: ComponentMeta<'blockContainer'> = {
  defaultConfig: {
    responsiveConfig: {
      default: {
        maxWidth: 'screen',
      },
    },
    padding: {
      data: {
        '--padding-default-bottom': '4rem',
        '--padding-default-top': '4rem',
        '--padding-default-left': '4rem',
        '--padding-default-right': '4rem',
        '--padding-xs-left': '2rem',
        '--padding-xs-right': '2rem',
      },
    },
  },
}

export const blockContainerDefaults =
  componentDefaultConfigFactory<'blockContainer'>({
    componentMeta: blockContainerComponentMeta,
  })
