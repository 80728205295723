import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { DividerComponent } from '@sceneio/schemas/lib/types'

function Divider({
  thickness,
  padding,
  margin,
  color,
  style,
  className,
  isAnimated = false,
  show = true,
  editor,
}: DividerComponent) {
  if (!show) {
    return null
  }

  const dividerClass = clsx(
    'sc-divider',
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )
  return (
    <div
      data-component-id={editor?.id}
      data-plugin-animation={isAnimated ? true : null}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            color,
            thickness,
          },
          prefix: 'divider',
        }),
      }}
      className={dividerClass}
    >
      <hr className="sc-divider__line" />
    </div>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default Divider
