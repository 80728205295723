import type { SVGProps } from 'react'
const SvgCaretCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      fillRule="evenodd"
      d="M8.354 6.354a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 5.293l2.646-2.647a.5.5 0 0 1 .708.708zM11.354 13.354a.5.5 0 0 1-.708 0L8 10.707l-2.646 2.647a.5.5 0 0 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCaretCollapse
