import React from 'react'
import { featureGrid3DefaultConfig } from './featureGrid3Meta'
import FeatureGrid3 from './FeatureGrid3'

const FeatureGrid3Preview = (config: any) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : featureGrid3DefaultConfig

  return <FeatureGrid3 {...defaultData} />
}

export default FeatureGrid3Preview
