import * as Tooltip from '@radix-ui/react-tooltip'
import clsx from 'clsx'

export const TooltipTrigger = ({
  children,
  tooltip,
  side = 'top',
  contentClassName,
}: {
  children: JSX.Element
  tooltip: string | JSX.Element
  side?: 'left' | 'right' | 'top' | 'bottom'
  contentClassName?: string
}) => {
  if (!tooltip) {
    return children
  }

  const className = clsx(
    'tw-text-white tw-select-none tw-rounded tw-bg-[rgba(37,37,35,0.92)] tw-px-2 tw-py-1 tw-text-xs tw-animate-fadeIn tw-z-[9999]',
    contentClassName,
  )

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={250}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Content side={side} className={className} sideOffset={5}>
          {tooltip}
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
