/* eslint-disable no-bitwise */
export default function hashString(text: string): string {
  let hash = 0
  let chr

  for (let i = 0; i < text.length; i += 1) {
    chr = text.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  return String(Math.abs(hash))
}
