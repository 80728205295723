import * as React from 'react'
import type { SVGProps } from 'react'
const SvgImageSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.16667 16.75H11.777C10.9453 16.1371 10.2174 15.3525 9.61065 14.5755C9.0279 13.8293 8.12735 13.3656 7.20951 13.2727C6.29601 13.1803 5.41598 13.4572 4.81817 14.1235C4.403 14.5863 4.00238 15.0176 3.67128 15.3441C4.18263 16.1869 5.10889 16.75 6.16667 16.75ZM18 13.8333C18 15.7647 16.6859 17.3892 14.903 17.8614C14.8556 17.8795 14.8057 17.8919 14.7539 17.898C14.4578 17.9647 14.1497 18 13.8333 18H6.16667C3.86548 18 2 16.1345 2 13.8333V6.16667C2 3.86548 3.86548 2 6.16667 2H13.8333C16.1345 2 18 3.86548 18 6.16667V13.8333ZM10.5958 13.8062C11.7585 15.295 13.1479 16.4844 14.616 16.6438C15.8467 16.3018 16.75 15.1731 16.75 13.8333V13.3719C16.3215 13.0109 15.4755 12.4166 14.6803 11.8774C13.9108 11.3555 12.9035 11.3582 12.1345 11.8837C11.4133 12.3765 10.6382 12.9212 10.1436 13.309C10.3053 13.4622 10.4567 13.628 10.5958 13.8062ZM15.3819 10.8429C15.8321 11.1481 16.3219 11.4866 16.75 11.7975V6.16667C16.75 4.55584 15.4442 3.25 13.8333 3.25H6.16667C4.55584 3.25 3.25 4.55584 3.25 6.16667V13.8333C3.25 13.8827 3.25123 13.9319 3.25366 13.9807C3.44914 13.773 3.66282 13.5395 3.88777 13.2888C4.79792 12.2744 6.09814 11.9039 7.33535 12.029C7.93796 12.09 8.53764 12.2691 9.08753 12.5569C9.59703 12.1232 10.5865 11.4275 11.4292 10.8517C12.6208 10.0374 14.1861 10.0319 15.3819 10.8429ZM6.99902 7.99805C7.55077 7.99805 7.99805 7.55077 7.99805 6.99902C7.99805 6.44728 7.55077 6 6.99902 6C6.44728 6 6 6.44728 6 6.99902C6 7.55077 6.44728 7.99805 6.99902 7.99805Z"
      fill="#252523"
    />
  </svg>
)
export default SvgImageSquare
