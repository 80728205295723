export function normalizeJMESPath(path: string) {
  if (typeof path !== 'string') throw Error('Property `path` is not defined')
  if (!path) {
    // return same object if trying to get root path equal -> ''
    return '@'
  }
  return (
    path
      //remove leading dot from start
      .replace(/^(\.+)/g, '')
      // wrap object keys containing dash keys into correct notation
      .replaceAll(/((?!\/--)[^.]*-(?!-)[^.]*)/g, '"$1"')
      // change flatten array notation .0 -> [0]
      .replaceAll(/\.(\d+)/g, '[$1]')
  )
}
