import { RHFToggleGroup } from '@sceneio/ui-forms'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

export const menuContainerLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  placement: 'top',
  fieldNames: [`flow.data.--flow-${breakpoint}`, `displayTarget.${breakpoint}`],
  children: (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <RHFToggleGroup
        name={`flow.data.--flow-${breakpoint}`}
        options={[
          { value: 'row', label: 'Horizontal' },
          {
            value: 'column',
            label: 'Vertical',
            disabled: breakpoint === 'default',
            tooltip:
              breakpoint === 'default'
                ? 'This option is available only for mobile and tablet breakpoints.'
                : '',
          },
        ]}
      />
    </div>
  ),
})

export const menuContainerDisplayAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  placement: 'top',
  fieldNames: [`flow.data.--flow-${breakpoint}`, `displayTarget.${breakpoint}`],
  children: (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <RHFToggleGroup
        name={
          breakpoint === 'default'
            ? 'displayTarget.lg'
            : `displayTarget.${breakpoint}`
        }
        options={[
          { value: 'desktop', label: 'Navbar' },
          {
            value: 'mobile',
            label: 'Mobile overlay',
            disabled: breakpoint === 'default',
            tooltip:
              breakpoint === 'default'
                ? 'This option is available only for mobile and tablet breakpoints.'
                : '',
          },
        ]}
      />
    </div>
  ),
})
