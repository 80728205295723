import { RHFSelect } from '@sceneio/ui-forms'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

type PlacementType = 'top' | 'bottom'

export const blockContainerLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  fieldNames: [],
  children: (
    <>
      {breakpoint === 'default' && (
        <div className="tw-flex tw-flex-col tw-gap-1">
          <RHFSelect
            name="direction"
            label="Direction"
            placeholder="vertical"
            options={[
              { label: 'horizontal', value: 'horizontal' },
              {
                label: 'vertical',
                value: 'vertical',
              },
            ]}
          />
        </div>
      )}
    </>
  ),
  placement: 'bottom' as PlacementType,
})
