import React from 'react'
import CTABanner3 from './CTABanner3'
import { ctaBanner3DefaultConfig } from './CTABanner3Meta'

const CTABanner3Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : ctaBanner3DefaultConfig

  return <CTABanner3 {...defaultData} />
}

export default CTABanner3Preview
