import { ComponentConfigType, ComponentMeta } from './componentCommonTypes'

export function componentDefaultConfigFactory<
  T extends keyof ComponentConfigType,
>({
  componentMeta,
}: {
  componentMeta: ComponentMeta<T>
}): {
  config: ComponentConfigType[T]
} {
  return {
    config: componentMeta.defaultConfig,
  }
}
