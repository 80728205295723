import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlusSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5669 10.4419C13.6842 10.3247 13.75 10.1658 13.75 10C13.75 9.83424 13.6842 9.67527 13.5669 9.55806C13.4497 9.44085 13.2908 9.375 13.125 9.375H10.625V6.875C10.625 6.70924 10.5592 6.55027 10.4419 6.43306C10.3247 6.31585 10.1658 6.25 10 6.25C9.83424 6.25 9.67527 6.31585 9.55806 6.43306C9.44085 6.55027 9.375 6.70924 9.375 6.875V9.375H6.875C6.70924 9.375 6.55027 9.44085 6.43306 9.55806C6.31585 9.67527 6.25 9.83424 6.25 10C6.25 10.1658 6.31585 10.3247 6.43306 10.4419C6.55027 10.5592 6.70924 10.625 6.875 10.625H9.375V13.125C9.375 13.2908 9.44085 13.4497 9.55806 13.5669C9.67527 13.6842 9.83424 13.75 10 13.75C10.1658 13.75 10.3247 13.6842 10.4419 13.5669C10.5592 13.4497 10.625 13.2908 10.625 13.125V10.625H13.125C13.2908 10.625 13.4497 10.5592 13.5669 10.4419Z"
      fill="#252523"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.375C2.00124 5.21506 2.46258 4.10298 3.28278 3.28278C4.10298 2.46258 5.21506 2.00124 6.375 2H13.625C14.7849 2.00124 15.897 2.46258 16.7172 3.28278C17.2974 3.86294 17.698 4.58913 17.8832 5.375C17.9599 5.70016 17.9996 6.03553 18 6.375L18 13.625C17.9988 14.7849 17.5374 15.897 16.7172 16.7172C15.897 17.5374 14.7849 17.9988 13.625 18H6.375C6.03553 17.9996 5.70016 17.9599 5.375 17.8832C4.58913 17.698 3.86294 17.2974 3.28278 16.7172C2.46258 15.897 2.00124 14.7849 2 13.625V6.375ZM15.8347 4.16529C16.4208 4.75134 16.75 5.5462 16.75 6.375L16.75 13.625C16.75 14.4538 16.4208 15.2487 15.8347 15.8347C15.2487 16.4208 14.4538 16.75 13.625 16.75H6.375C5.5462 16.75 4.75134 16.4208 4.16529 15.8347C3.57924 15.2487 3.25 14.4538 3.25 13.625V6.375C3.25 5.5462 3.57924 4.75134 4.16529 4.16529C4.75134 3.57924 5.5462 3.25 6.375 3.25H13.625C14.4538 3.25 15.2487 3.57924 15.8347 4.16529Z"
      fill="#252523"
    />
  </svg>
)
export default SvgPlusSquare
