import flatten from 'flat'

export function getRootLevelCssVariables({
  cssVariables,
  prefix,
}: {
  cssVariables: Record<string, any>
  prefix?: string
}) {
  const flattenCssVariables = flatten(cssVariables) as Record<
    string,
    string | number
  >

  return Object.keys(flattenCssVariables).reduce((acc, path) => {
    // remove undefined or empty css values
    if (flattenCssVariables[path]) {
      const lastPathKey = path.substring(path.lastIndexOf('.') + 1)
      const isCssVar = lastPathKey.startsWith('--')

      let cssVariableValue = flattenCssVariables[path]
      if (cssVariableValue.toString().endsWith('vh')) {
        // calculate with defined viewport height variable
        const val = cssVariableValue.toString().replace('vh', '')
        const updatedVal = `calc(var(--vh, 1vh) * ${val})`
        cssVariableValue = updatedVal
      }

      if (isCssVar) {
        const lastPathKeyWithPrefix = prefix
          ? lastPathKey.replace(/--/, `--${prefix}-`)
          : lastPathKey
        return {
          ...acc,
          [lastPathKeyWithPrefix]: cssVariableValue,
        }
      }

      return acc
    }

    return acc
  }, {})
}
