import { InferType, object, string } from 'yup'

export const overlayStyleSchema = object({
  responsiveConfig: object({
    default: string(),
    xs: string(),
    md: string(),
    lg: string(),
  })
    .noUnknown()
    .required(),
}).noUnknown()

export type OverlayStyle = InferType<typeof overlayStyleSchema>
