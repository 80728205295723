import React from 'react'
import { heroWithForm3DefaultConfig } from './heroWithForm3Meta'
import HeroWithForm3 from './HeroWithForm3'

const HeroWithForm3Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroWithForm3DefaultConfig

  return <HeroWithForm3 {...defaultData} />
}

export default HeroWithForm3Preview
