import { fetchFonts } from '../features/assets/assetsSlice'
import { fetchContent } from '../features/content/contentSlice'
import { fetchSnippets } from '../features/snippets/snippetsSlice'
import { ThunkApiType } from './asyncThunkFetcher'

export type RejectQueryType =
  | 'CONTENT'
  | 'CONTENT_MODULES'
  | 'SNIPPETS'
  | 'FONTSTYLES'

export type ThunkRejectType = {
  code: string
  message: string
  thunkAPI: ThunkApiType<never>
  rejectQueries: RejectQueryType[]
}

const REJECT_CALLBACKS_FACTORY = ({
  thunkAPI,
  rejectQueries,
}: Pick<ThunkRejectType, 'rejectQueries' | 'thunkAPI'>) => {
  if (rejectQueries) {
    return rejectQueries.reduce<(() => void)[]>((acc, query) => {
      if (query === 'CONTENT') {
        acc.push(() =>
          thunkAPI.dispatch(
            fetchContent({
              queryVariables: {
                contentId: thunkAPI.getState().editor.content.contentId,
                pathId: thunkAPI.getState().editor.content.pathId,
              },
            }),
          ),
        )
      }

      if (query === 'SNIPPETS') {
        acc.push(() => thunkAPI.dispatch(fetchSnippets({ queryVariables: {} })))
      }

      if (query === 'FONTSTYLES') {
        acc.push(() => thunkAPI.dispatch(fetchFonts({ queryVariables: {} })))
      }
      return acc
    }, [])
  }
}

export function thunkReject(data: ThunkRejectType) {
  const { code, message, thunkAPI, rejectQueries } = data

  const rejectCallbacks = REJECT_CALLBACKS_FACTORY({ thunkAPI, rejectQueries })

  if (rejectCallbacks) {
    rejectCallbacks.forEach((rejectCallback) => rejectCallback())
  }

  return thunkAPI.rejectWithValue({
    code,
    message,
  })
}
