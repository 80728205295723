import React from 'react'
import { SliderVariant2Component } from '@sceneio/schemas/lib/types'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'

import SliderVariant1 from '../SliderVariant1/SliderVariant1'

function SliderVariant2({ ...props }: SliderVariant2Component) {
  return <SliderVariant1 {...props} hasFloatingNavigation={false} />
}

export const plugins: PluginsType = {
  js: ['slider'],
  css: ['slider'],
}
export default SliderVariant2
