import type { SVGProps } from 'react'
const SvgMaxWidth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      d="M2 3a.5.5 0 0 0-1 0v10a.5.5 0 0 0 1 0zM15 3a.5.5 0 0 0-1 0v10a.5.5 0 0 0 1 0zM10.223 4.584a.5.5 0 0 1 .693.139l2 3a.5.5 0 0 1 0 .554l-2 3a.5.5 0 1 1-.832-.554L11.566 8.5H4.434l1.482 2.223a.5.5 0 1 1-.832.554l-2-3a.5.5 0 0 1 0-.554l2-3a.5.5 0 1 1 .832.554L4.434 7.5h7.132l-1.482-2.223a.5.5 0 0 1 .139-.693"
    />
  </svg>
)
export default SvgMaxWidth
