import type { SVGProps } from 'react'
const SvgWebflow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9708 4L10.6089 12.6337H6.51179L8.33723 9.05553H8.25536C6.74935 11.0349 4.50237 12.338 1.30078 12.6337V9.10502C1.30078 9.10502 3.34891 8.98257 4.55296 7.70083H1.30078V4.00006H4.95587V7.04388L5.03791 7.04359L6.5315 4.00006H9.29579V7.0246L9.37781 7.02445L10.9275 4H14.9708Z"
      fill="#252523"
    />
  </svg>
)
export default SvgWebflow
