import { IconComponent } from '@sceneio/schemas/lib/types'

export const preprocessIconConfig = ({
  responsiveConfig,
}: {
  responsiveConfig: IconComponent['responsiveConfig']
}) => {
  const result = {} as typeof responsiveConfig

  // transformation where every breakpoint (default, xs, md,..) has a defined default, hover and active state
  for (const breakpoint in responsiveConfig) {
    const bp = breakpoint as keyof typeof responsiveConfig
    const defaultState =
      responsiveConfig[bp]?.default || responsiveConfig['default'].default
    const hoverState =
      responsiveConfig[bp]?.hover ||
      responsiveConfig['default']?.hover ||
      defaultState
    const activeState =
      responsiveConfig[bp]?.active ||
      responsiveConfig['default']?.active ||
      defaultState

    result[bp] = {
      default: defaultState,
      hover: hoverState,
      active: activeState,
    }
  }

  return result
}
