import React, { useEffect } from 'react'
import { ToastContainer, ToastOptions, toast } from 'react-toastify'
import styles from '../node_modules/react-toastify/dist/ReactToastify.css?inline'

type EventCallbackType = (message?: string, data?: any) => void

type EventCallbacksType = {
  onError?: EventCallbackType
  onSuccess?: EventCallbackType
  onWarning?: EventCallbackType
  onInfo?: EventCallbackType
}
type NotificationContainerProps = EventCallbacksType & {
  options?: ToastOptions
}

const noop: EventCallbackType = () => {}

let onErrorFn = noop
let onSuccessFn = noop
let onWarningFn = noop
let onInfoFn = noop

function setCmsNotificationCallbacks(callbacks: EventCallbacksType) {
  const { onError, onSuccess, onWarning, onInfo } = callbacks || {}

  if (onError) onErrorFn = onError
  if (onSuccess) onSuccessFn = onSuccess
  if (onWarning) onWarningFn = onWarning
  if (onInfo) onInfoFn = onInfo
}

const NotificationContainer = ({
  onError,
  onSuccess,
  onWarning,
  onInfo,
  options,
}: NotificationContainerProps) => {
  useEffect(() => {
    setCmsNotificationCallbacks({
      onError,
      onSuccess,
      onWarning,
      onInfo,
    })
  }, [])
  return (
    <>
      <style>{styles}</style>
      <ToastContainer {...options} />
    </>
  )
}

type NotificationProps = {
  content: string | React.ReactNode
  options?: ToastOptions<{}>
  log?: {
    message: string
    data?: any
  }
}

function successNotification({ content, options, log }: NotificationProps) {
  if (log) {
    onSuccessFn(log.message)
  }
  toast.success(content, options)
}

function errorNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.error(log.message, log.data)
    onErrorFn(log.message)
  }
  toast.error(content, options)
}

function warningNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.warn(log.message, log.data)
    onWarningFn(log.message)
  }
  toast.warning(content, options)
}

function infoNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.info(log.message, log.data)
    onInfoFn(log.message)
  }

  toast.info(content, options)
}

export {
  NotificationContainer,
  infoNotification,
  warningNotification,
  errorNotification,
  successNotification,
}
