import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChatCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99982 1.875C8.59706 1.87469 7.21812 2.23757 5.99721 2.9283C4.7763 3.61904 3.75501 4.6141 3.03277 5.81664C2.31052 7.01918 1.91192 8.38822 1.87575 9.79052C1.83958 11.1928 2.16709 12.5806 2.82638 13.8188L1.93966 16.4789C1.86621 16.6992 1.85556 16.9355 1.90888 17.1615C1.96221 17.3874 2.07741 17.5941 2.24158 17.7582C2.40575 17.9224 2.61239 18.0376 2.83836 18.0909C3.06432 18.1443 3.30067 18.1336 3.52091 18.0602L6.18107 17.1734C7.27073 17.753 8.47811 18.0767 9.71156 18.12C10.945 18.1633 12.1721 17.925 13.2997 17.4232C14.4273 16.9215 15.4258 16.1694 16.2194 15.2241C17.0129 14.2789 17.5807 13.1652 17.8796 11.9678C18.1785 10.7703 18.2007 9.52047 17.9445 8.31315C17.6882 7.10584 17.1603 5.97276 16.4008 4.99993C15.6413 4.02711 14.6701 3.24009 13.561 2.69864C12.4519 2.15718 11.234 1.87551 9.99982 1.875ZM9.99982 16.875C8.79121 16.8758 7.6038 16.5575 6.55763 15.9523C6.48104 15.9079 6.39587 15.8803 6.30779 15.8713C6.21971 15.8622 6.13071 15.872 6.0467 15.9L3.12482 16.875L4.09904 13.9531C4.12712 13.8692 4.13705 13.7802 4.12816 13.6921C4.11927 13.604 4.09177 13.5188 4.04748 13.4422C3.28964 12.132 2.98537 10.6083 3.18187 9.10747C3.37837 7.60667 4.06466 6.21267 5.13426 5.14171C6.20387 4.07076 7.597 3.38271 9.09755 3.18431C10.5981 2.98592 12.1222 3.28826 13.4334 4.04444C14.7445 4.80062 15.7695 5.96837 16.3493 7.36652C16.9291 8.76468 17.0313 10.3151 16.64 11.7773C16.2487 13.2394 15.3858 14.5316 14.1852 15.4533C12.9846 16.375 11.5134 16.8748 9.99982 16.875Z"
      fill="#252523"
    />
    <path
      d="M6.64697 8.5H13.4097"
      stroke="#252523"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.64697 11.5H11.4775"
      stroke="#252523"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
)
export default SvgChatCircle
