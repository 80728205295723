import { InferType, object } from 'yup'
import { cssDimensionSchema } from './common/cssDimensionSchema'

export const minHeightStyleSchema = object({
  data: object({
    '--min-height-default': cssDimensionSchema,
    '--min-height-xs': cssDimensionSchema,
    '--min-height-md': cssDimensionSchema,
    '--min-height-lg': cssDimensionSchema,
  }).noUnknown(),
}).noUnknown()

export type MinHeightStyle = InferType<typeof minHeightStyleSchema>
