import clsx from 'clsx'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { GenericSettingsPropType } from './GenericSettings'

export const DefaultGenericSettings = ({
  className,
  title = 'Layout',
  formFieldRenderer = () => null,
  additionalFormFieldRenderer = () => null,
}: GenericSettingsPropType) => {
  return (
    <SegmentWrapper className={clsx('sc-generic-settings', className)}>
      <HeaderSegment heading={title} />
      <div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          {formFieldRenderer({ breakpoint: 'default' })?.children}
          <div
            className={clsx({
              'tw-order-first':
                additionalFormFieldRenderer({ breakpoint: 'default' })
                  ?.placement === 'top',
            })}
          >
            {additionalFormFieldRenderer({ breakpoint: 'default' })?.children}
          </div>
        </div>
      </div>
    </SegmentWrapper>
  )
}
