import { ReferenceResolveMethod } from '../referencesTypes'

export type EntityType =
  | 'project'
  | 'content'
  | 'theme'
  | 'path'
  | 'resource'
  | 'asset'
  | 'snippet'

export type ReferenceEntityFactoryProps = {
  value: any
  entity: EntityType
  pathTo: string
  resolveMethod?: ReferenceResolveMethod
}

export function referenceEntityFactory({
  value,
  entity,
  pathTo,
  resolveMethod = 'REPLACE',
}: ReferenceEntityFactoryProps) {
  if (!value) throw Error('Property `value` is not defined')
  if (!pathTo) throw Error('Property `pathTo` is not defined')
  const referenceEntityMap = {
    resource: {
      entity: 'resource',
      field: 'id',
      value,
      pathTo,
      resolveMethod,
    },
    project: {
      entity: 'project',
      field: 'id',
      value,
      pathTo,
      resolveMethod,
    },
    content: {
      entity: 'content',
      field: 'id',
      value,
      pathTo,
      resolveMethod,
    },
    path: {
      entity: 'path',
      field: 'id',
      value,
      pathTo,
      resolveMethod,
    },
    asset: {
      entity: 'asset',
      field: 'id',
      value,
      pathTo,
      resolveMethod,
    },
    snippet: {
      entity: 'snippet',
      field: 'id',
      value,
      pathTo,
      resolveMethod,
    },
  }
  if (!referenceEntityMap[entity])
    throw Error('Property `entity` not exists in referenceEntityMap')

  return referenceEntityMap[entity]
}
