import React, { useContext } from 'react'
import type { NativeLinkElementType } from './nativeLinkType'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { AppContext } from '@sceneio/components-app-context'

export default function NativeLink({
  href,
  type,
  children,
  className,
  target,
  dataAttributes = {},
  show = true,
}: NativeLinkElementType) {
  const app = useContext(AppContext)
  const isEditor = app !== 'WEBSITE'

  if (!show) {
    return null
  }

  if (href && !isEditor) {
    const resolvedTarget = type === 'anchor' ? '_self' : target
    const resolvedHref = type === 'anchor' ? `#${href}` : href

    return (
      <a
        href={resolvedHref}
        target={resolvedTarget}
        className={className}
        {...dataAttributes}
      >
        {children}
      </a>
    )
  }

  return <>{children}</>
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}
