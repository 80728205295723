import {
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { getSelectedNode } from '../../utils/getSelectedNode'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  $createLinkNode,
  $isAutoLinkNode,
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
} from '@lexical/link'
import * as React from 'react'
import { computePosition } from '@floating-ui/dom'
import * as Toolbar from '@radix-ui/react-toolbar'
import * as Switch from '@radix-ui/react-switch'
import { Icon } from '@sceneio/ui-icons'
import clsx from 'clsx'
import { sanitizeUrl, validateUrl } from './helpers'
import useOnClickOutside from 'react-cool-onclickoutside'

export function FloatingLinkEditor({
  editor,
  isLink,
  setIsLink,
  anchorElem,
}: {
  editor: LexicalEditor
  isLink: boolean
  setIsLink: Dispatch<boolean>
  anchorElem: HTMLElement
}) {
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)
  const [isInvalidUrl, setIsInvalidUrl] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const [linkUrl, setLinkUrl] = useState('')
  const [editedLinkUrl, setEditedLinkUrl] = useState('https://')
  const [editedLinkTarget, setEditedLinkTarget] = useState('_self')
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null)

  const updateFloatingLink = useCallback(() => {
    const selection = $getSelection()
    const activeElement = document.activeElement

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const linkParent = $findMatchingParent(node, $isLinkNode)

      if (linkParent) {
        setLinkUrl(linkParent.getURL())
        const linkParentTarget = linkParent.getTarget()
        if (linkParentTarget) {
          setEditedLinkTarget(linkParentTarget)
        }
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
      } else {
        setLinkUrl('')
      }
      if (isLinkEditMode) {
        setEditedLinkUrl(linkUrl)
      }
    }

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    const nativeSelection = window.getSelection()

    if (popupCharStylesEditorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      computePosition(
        nativeSelection.getRangeAt(0),
        popupCharStylesEditorElem,
        {
          placement: 'bottom',
        },
      )
        .then((pos) => {
          popupCharStylesEditorElem.style.opacity = '1'
          popupCharStylesEditorElem.style.transform = `translate(${pos.x}px, ${
            pos.y + 10
          }px)`
          setLastSelection(selection)
        })
        .catch(() => {
          popupCharStylesEditorElem.style.opacity = '0'
          popupCharStylesEditorElem.style.transform = `translate(-10000px, -10000px)`
        })
    } else if (!activeElement || activeElement.className !== 'link-input') {
      if (rootElement !== null) {
        popupCharStylesEditorElem.style.opacity = '0'
        popupCharStylesEditorElem.style.transform = `translate(-10000px, -10000px)`
      }
      setLastSelection(null)
      setIsLinkEditMode(false)
      setLinkUrl('')
    }
  }, [editor, anchorElem, isLink, isLinkEditMode, linkUrl])

  useOnClickOutside(
    () => {
      setIsLink(false)
    },
    { refs: [popupCharStylesEditorRef] },
  )

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateFloatingLink()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateFloatingLink()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateFloatingLink()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false)
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH,
      ),
    )
  }, [editor, updateFloatingLink, isLink])

  useEffect(() => {
    if (isLinkEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isLinkEditMode, isLink])

  const monitorInputInteraction = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleLinkSubmission()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      setIsLinkEditMode(false)
    }
  }

  const handleLinkSubmission = () => {
    const isValid = validateUrl(editedLinkUrl)
    if (!isValid) {
      setIsInvalidUrl(true)
      return
    }
    if (lastSelection !== null) {
      if (linkUrl !== '') {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl))
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const parent = getSelectedNode(selection).getParent()
            if ($isAutoLinkNode(parent)) {
              const linkNode = $createLinkNode(parent.getURL(), {
                rel: parent.__rel,
                target: parent.__target,
                title: parent.__title,
              })
              parent.replace(linkNode, true)
            } else if ($isLinkNode(parent)) {
              const linkNode = $createLinkNode(parent.getURL(), {
                rel: parent.__rel,
                target: parent.__target,
                title: parent.__title,
              })
              parent.replace(linkNode, true)
            }
          }
        })
      }
      setEditedLinkUrl('https://')
      setIsLinkEditMode(false)
    }
  }

  // handle target change - without submit button
  const handleNewTabChange = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const parent = getSelectedNode(selection).getParent()
        if ($isLinkNode(parent)) {
          const linkNode = $createLinkNode(editedLinkUrl, {
            target: editedLinkTarget === '_blank' ? '_self' : '_blank',
            // rel: editedLinkTarget === '_blank' ? '' : 'noopener noreferrer',
          })
          parent.replace(linkNode, true)
        }
      }
    })
  }

  if (!isLink) {
    return null
  }

  return (
    <Toolbar.Root
      ref={popupCharStylesEditorRef}
      className="wysiwyg-link-toolbar-root"
      aria-label="Formatting options"
    >
      {isLinkEditMode ? (
        <div data-no-drag>
          <div className="d-flex align-items-center mb-2">
            <input
              ref={inputRef}
              className={clsx('wysiwyg-link-toolbar-input', {
                'wysiwyg-link-toolbar-input--invalid': isInvalidUrl,
              })}
              value={editedLinkUrl}
              onChange={(event) => {
                if (isInvalidUrl) {
                  setIsInvalidUrl(false)
                }
                setEditedLinkUrl(event.target.value)
              }}
              onKeyDown={(event) => {
                monitorInputInteraction(event)
              }}
            />
            <div className="d-flex align-items-center ms-2 ">
              <Toolbar.Button
                onClick={handleLinkSubmission}
                className="wysiwyg-toolbar-toggle-item"
              >
                <Icon size={24} provider="phosphor" icon="Check" />
              </Toolbar.Button>
              <Toolbar.Button
                onClick={() => {
                  setIsLinkEditMode(false)
                }}
                className="wysiwyg-toolbar-toggle-item"
              >
                <Icon size={24} provider="phosphor" icon="X" />
              </Toolbar.Button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <label
              className="me-3"
              htmlFor="wysiwyg-floating-toolbar-link-target"
            >
              Open in new tab
            </label>
            <Switch.Root
              id="wysiwyg-floating-toolbar-link-target"
              tabIndex={1}
              checked={editedLinkTarget === '_blank'}
              onCheckedChange={handleNewTabChange}
              className={clsx('wysiwyg-floating-toolbar-target-switch-root', {
                'wysiwyg-floating-toolbar-target-switch-root--active':
                  editedLinkTarget === '_blank',
              })}
            >
              <Switch.Thumb
                className={clsx(
                  'wysiwyg-floating-toolbar-target-switch-thumb',
                  {
                    'wysiwyg-floating-toolbar-target-switch-thumb--active':
                      editedLinkTarget === '_blank',
                  },
                )}
              />
            </Switch.Root>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center" data-no-drag>
          <div className="me-3 d-flex align-items-center">
            <a
              href={sanitizeUrl(linkUrl)}
              target="_blank"
              rel="noopener noreferrer"
              className="wysiwyg-link-toolbar-link"
            >
              {linkUrl}
            </a>
          </div>
          <div className="d-flex align-items-center">
            <Toolbar.Button
              onClick={() => {
                setEditedLinkUrl(linkUrl)
                setIsLinkEditMode(true)
              }}
              className="wysiwyg-toolbar-toggle-item"
            >
              <Icon size={24} provider="phosphor" icon="NotePencil" />
            </Toolbar.Button>
            <Toolbar.Button
              onClick={() => {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
              }}
              className="wysiwyg-toolbar-toggle-item"
            >
              <Icon size={24} provider="phosphor" icon="Trash" />
            </Toolbar.Button>
          </div>
        </div>
      )}
    </Toolbar.Root>
  )
}
