import type { SVGProps } from 'react'
const SvgLineHeight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#252523"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.503 2.5h10.994M2.503 13.5h10.994M4.941 11.25l.9-1.912m5.218 1.912-.9-1.912m-4.318 0L8 4.75l2.16 4.588m-4.32 0h4.32"
    />
  </svg>
)
export default SvgLineHeight
