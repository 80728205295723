import React from 'react'
import Text from './../../Text/Text'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import clsx from 'clsx'
import { MediaComponent } from '@sceneio/schemas/lib/types'

type WithLabelsProps = {
  children: React.ReactNode
  credits?: MediaComponent['components']['credits']
  caption?: MediaComponent['components']['caption']
  cid?: string
}

export function MediaWithLabels({
  children,
  credits = {},
  caption = {},
  cid,
}: WithLabelsProps) {
  return (
    <figure
      className={clsx('sc-media__figure bui-w-100 bui-h-100', {
        'bui-m-0': !caption.show,
      })}
    >
      <div
        className={clsx('bui-position-relative', {
          'bui-h-100 bui-w-100': !caption.show,
        })}
      >
        <div className="sc-media__overlay" />
        {children}
        {credits.show && (
          <div className="sc-media__credits-wrapper">
            <Text {...credits} cid={resolveComponentId(cid, 'credits')} />
          </div>
        )}
      </div>
      {caption.show && (
        <figcaption>
          <Text {...caption} cid={resolveComponentId(cid, 'caption')} />
        </figcaption>
      )}
    </figure>
  )
}
