import { transformReferencesFromArrayToObject } from './helpers/transformReferencesFromArrayToObject'
import { transformReferencesFromObjectToArray } from './helpers/transformReferencesFromObjectToArray'
import { ReferenceItemType, ReferencesType } from './referencesTypes'

export type DeleteReferenceType = {
  references: ReferencesType
  path: string
}

export function deleteReference({
  references = [],
  path,
}: DeleteReferenceType): ReferencesType {
  const referencesObject = transformReferencesFromArrayToObject(references)
  let result = {}

  for (let key in referencesObject) {
    if (key !== path) {
      result[key] = referencesObject[key]
    }
  }

  return transformReferencesFromObjectToArray(result)
}
