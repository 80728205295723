import React from 'react'
import FeatureGrid5 from './FeatureGrid5'
import { featureGrid5DefaultConfig } from './featureGrid5Meta'

const FeatureGrid5Preview = (config: any) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : featureGrid5DefaultConfig

  return <FeatureGrid5 {...defaultData} />
}

export default FeatureGrid5Preview
