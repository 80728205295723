import { object, string, InferType, mixed, bool, array, number } from 'yup'
import { voidFuncionSchema } from './voidFuncionSchema'

export const enableEditorSchema = object({
  editorType: string().oneOf([
    'inlineText',
    'buttonEditor',
    'inlineGrid',
    'inlineNavLink',
  ]),
  editorOptions: object({
    isItemArray: bool(),
    sample: object().optional(),
    include: array().of(string()),
    exclude: array().of(string()),
    disableWriting: bool(),
    sampleFactory: mixed<(data?: any) => any>(),
    showDeleteButton: bool(),
    editorIndex: number(),
    isNestedArrayItem: bool(),
  })
    .noUnknown()
    .optional(),
  values: object().optional(),
  onBlur: voidFuncionSchema,
  onFocusIn: voidFuncionSchema,
  onTempStorage: mixed<(data: Object) => void>(),
  onAddItem: voidFuncionSchema,
  onDeleteItem: voidFuncionSchema,
})
  .noUnknown()
  .optional()

export type EnableEditor = InferType<typeof enableEditorSchema>
