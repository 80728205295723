import { Icon } from '@sceneio/ui-icons'
import { RHFNumericInput } from '../../../atoms/Input/NumericInput/NumericInput'
import { BreakpointType } from '../../../atoms/BreakpointDropdown/BreakpointDropdown'

type BreakpointSpacingFieldsPropType = {
  type: 'padding' | 'margin'
  valuesPath: string
  breakpoint: BreakpointType | 'default'
}

export const BreakpointSpacingFields = ({
  breakpoint,
  type,
  valuesPath,
}: BreakpointSpacingFieldsPropType) => {
  const isClearable = false
  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-1 tw-flex-1">
      <RHFNumericInput
        label={<Icon provider="scene" icon="sides-t" size={'1rem'} />}
        name={`${valuesPath}.--${type}-${breakpoint}-top`}
        units={['px', 'rem', 'em', '%']}
        defaultUnit={'rem'}
        isClearable={isClearable}
        allowNegativeValue={type === 'margin'}
      />
      <RHFNumericInput
        label={<Icon provider="scene" icon="sides-r" size={'1rem'} />}
        name={`${valuesPath}.--${type}-${breakpoint}-right`}
        units={['px', 'rem', 'em', '%']}
        defaultUnit={'rem'}
        isClearable={isClearable}
        allowNegativeValue={type === 'margin'}
      />
      <RHFNumericInput
        label={<Icon provider="scene" icon="sides-b" size={'1rem'} />}
        name={`${valuesPath}.--${type}-${breakpoint}-bottom`}
        units={['px', 'rem', 'em', '%']}
        defaultUnit={'rem'}
        isClearable={isClearable}
        allowNegativeValue={type === 'margin'}
      />
      <RHFNumericInput
        label={<Icon provider="scene" icon="sides-l" size={'1rem'} />}
        name={`${valuesPath}.--${type}-${breakpoint}-left`}
        units={['px', 'rem', 'em', '%']}
        isClearable={isClearable}
        defaultUnit={'rem'}
        allowNegativeValue={type === 'margin'}
      />
    </div>
  )
}
