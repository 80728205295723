import { FlowStyle } from '@sceneio/schemas/lib/styles/flowStyleSchema'
import {
  AVAILABLE_BREAKPOINTS,
  BreakpointType,
} from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

export function getContainerFlowClasses({
  flow,
  className = 'sc-container',
}: {
  flow?: FlowStyle
  className?: string
}) {
  if (!flow || !Object.keys(flow?.data).length) return

  const availableBreakpoints: (BreakpointType | 'default')[] = [
    ...AVAILABLE_BREAKPOINTS,
    'default',
  ]
  const classes = availableBreakpoints.map((bp) => {
    return `${className}--flow-${bp}-${
      flow.data[`--flow-${bp}`] || flow.data[`--flow-default`]
    }`
  })

  return classes
}
