import { UnitType } from '../NumericInput'

export function getUnit(value?: string | number, units?: UnitType[]) {
  const isValueUnitless = units?.includes('-')

  if (!value || typeof value === 'number') {
    return isValueUnitless ? '-' : undefined
  }

  if (units?.includes(value as UnitType)) {
    return value
  }

  return value.replace(/\s/, '').replace(/[+-]?\d+(\.\d+)?/g, '')
}
