import * as Form from '@radix-ui/react-form'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { ClearButton } from '../../ClearButton/ClearButton'
import { debounce } from '@sceneio/tools/lib/debounce'
import { useFormContext } from '../../../form/context/FormContext'

export type RHFTextAreaInputPropType = {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  maxLength?: number
  className?: string
  inputClassName?: string
  style?: CSSProperties
  disabled?: boolean
  hidden?: boolean
  defaultValue?: string
  pattern?: RegExp
  rows?: number
  cols?: number
  isClearable?: boolean
}

export function RHFTextAreaInput({
  name,
  label,
  required = false,
  disabled = false,
  rows,
  cols,
  maxLength,
  hidden,
  className,
  inputClassName,
  placeholder,
  isClearable = false,
}: RHFTextAreaInputPropType) {
  const { setValue, placeholders, values, error, forceBreakpoint } =
    useFormContext({ name })
  const [internalValue, setInternalValue] = useState(values[name] || '')
  const containerClasses = clsx(
    'sc-rhf-textarea-input tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  useEffect(() => {
    setInternalValue(values[name] || '')
  }, [values[name]])

  const handleOnChange = () => {
    setValue(name, internalValue)
  }

  return (
    <Form.Field name={name} className={containerClasses}>
      <Form.Label
        className={clsx(
          'tw-flex tw-flex-col tw-bg-form-field-bg tw-rounded tw-py-2 tw-pl-2 tw-pr-1 tw-gap-3 tw-relative hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && <span className="tw-text-label-color">{label}</span>}
        <Form.Control asChild>
          <textarea
            tabIndex={1}
            disabled={disabled}
            maxLength={maxLength}
            rows={rows}
            cols={cols}
            required={required}
            placeholder={placeholder ?? placeholders[name]}
            value={internalValue}
            name={name}
            className={clsx(
              'tw-bg-transparent focus:tw-outline-none',
              inputClassName,
            )}
            hidden={hidden}
            onKeyDown={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => {
              const inputValue = e.target.value
              setInternalValue(inputValue)
            }}
            onBlur={handleOnChange}
            onKeyDownCapture={(e) => {
              if (e.key === 'Enter' || e.key === 'Tab') {
                handleOnChange()
              }
            }}
            onFocus={(e) => e.target.select()}
          />
        </Form.Control>
        {isClearable && values[name] && <ClearButton name={name} />}
      </Form.Label>
      {error && <ErrorMessage message={error?.message} />}
    </Form.Field>
  )
}
