import * as Slider from '@radix-ui/react-slider'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

type RangeBodyPropType = {
  inputClassName?: string
  disabled?: boolean
  inputValue?: number
  onChange?: (arg0: number) => void
  min?: number
  max?: number
  step?: number
  label?: string
  showValue?: boolean
}

export function RangeBody({
  disabled,
  inputValue = 0,
  onChange,
  inputClassName,
  min = 0,
  max = 100,
  step,
  label,
  showValue = false,
}: RangeBodyPropType) {
  const [tempValue, setTempValue] = useState(inputValue)

  useEffect(() => {
    setTempValue(inputValue)
  }, [inputValue])

  return (
    <Slider.Root
      tabIndex={1}
      className={clsx(
        'tw-relative tw-flex-1 tw-min-w-[100px] tw-flex tw-items-center tw-select-none tw-touch-none tw-w-[100%] tw-h-5 focus-visible:tw-outline-none',
        { 'tw-opacity-40': disabled },
        inputClassName,
      )}
      disabled={disabled}
      defaultValue={[tempValue]}
      max={max}
      step={step}
      min={min}
      onValueChange={([val]) => {
        setTempValue(val)
      }}
      onValueCommit={([val]) => {
        if (onChange) {
          onChange(val)
        }
      }}
    >
      <Slider.Track className="tw-bg-gray-200 tw-relative tw-grow tw-rounded-full tw-h-[3px]">
        <Slider.Range className="tw-bg-primary tw-absolute tw-rounded-full tw-h-full" />
      </Slider.Track>
      <Slider.Thumb
        aria-label={label}
        className="tw-block tw-w-3 tw-h-3 tw-bg-white tw-rounded-[10px] hover:tw-bg-white focus:tw-outline-none tw-shadow-[0_0_0_2px] tw-shadow-gray-200"
      >
        {showValue && (
          <div className="">
            <span className="tw-text-[10px] tw-text-label-color tw-absolute  tw-top-0 tw-left-[50%] tw-transform tw-translate-x-[-50%] tw-translate-y-[-100%]">
              {tempValue}
            </span>
          </div>
        )}
      </Slider.Thumb>
    </Slider.Root>
  )
}
