import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Image from '../../../components/Image/Image'
import Button from '../../../components/Button/Button'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import { buttonSampleFactory, splitHeroDefaultConfig } from './splitHeroMeta'
import { SplitHeroBlock } from '@sceneio/schemas/lib/types'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function SplitHero({
  id,
  components: {
    block,
    logo,
    subheading,
    text,
    heading,
    media,
    author,
    buttons,
    mediaContainer,
    contentContainer,
  },
  editor,
  preview,
}: SplitHeroBlock = splitHeroDefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block

  const mediaColumns = columnRatio!
  const contentColumns = 12 - mediaColumns

  const productCardClass = clsx(['sc-split-hero', className])

  const mediaColumnClasses = clsx({
    [`bui-col-md-${mediaColumns}`]: mediaColumns,
    'bui-order-md-last': swapOrder,
  })

  const contentColumnClasses = clsx(`sc-split-hero__content-column`, {
    [`bui-col-md-${contentColumns}`]: contentColumns,
    [`bui-col`]: contentColumns === 0,
  })

  const showButtons = Boolean(buttons?.items?.length) && buttons?.show

  // TODO possible different alignments on other breakpoints
  const isCoverMedia =
    mediaContainer?.verticalAlignment?.data?.[
      '--vertical-alignment-default'
    ] === 'stretch'

  return (
    <Block
      className={productCardClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={mediaColumnClasses}>
          <Container
            {...mediaContainer}
            name="group"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'mediaContainer',
            }}
            flow={{ data: { '--flow-default': 'column' } }}
            className="bui-h-100"
          >
            {media && (
              <Media
                {...media}
                editor={{
                  ...editor?.component,
                  blockId: id,
                  id: 'media',
                }}
                components={{
                  ...media.components,
                  image: {
                    ...('image' in media.components && {
                      ...media.components.image,
                      isAnimated: true,
                    }),
                  },
                  caption: {
                    show: false,
                  },
                }}
                className="sc-split-hero__media"
                cid={resolveComponentId(id, 'media')}
                preview={preview}
              />
            )}
          </Container>
        </div>
        <div className={contentColumnClasses}>
          <Container
            {...contentContainer}
            name="group"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'contentContainer',
            }}
            flow={{ data: { '--flow-default': 'column' } }}
            className="bui-h-100"
          >
            <Image
              {...logo}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'logo',
              }}
              isAnimated={true}
            />
            <Text
              {...subheading}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'subheading',
              }}
              cid={resolveComponentId(id, 'subheading')}
            />
            <Text
              {...heading}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'heading',
              }}
              cid={resolveComponentId(id, 'heading')}
            />
            <Text
              {...text}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'text',
              }}
              cid={resolveComponentId(id, 'text')}
            />
            <Text
              {...author}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'author',
              }}
              cid={resolveComponentId(id, 'author')}
            />
            <Container
              className="bui-flex-wrap bui-w-100"
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'buttons',
              }}
              {...buttons}
              name="componentArray"
              show={showButtons}
            >
              {buttons?.items?.map(
                (
                  {
                    id: buttonId,
                    childrenWrapProps = {},
                    className: buttonClassname,
                    ...restButtonProps
                  },
                  index,
                ) => (
                  <Button
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: `buttons.items[${index}]`,
                      wysiwyg: {
                        type: 'ITEMS',
                        ...editorItemsControlsFactory({
                          itemsArr: buttons.items || [],
                          itemsId: `buttons.items`,
                          itemFactory: buttonSampleFactory,
                          index: index,
                        }),
                      },
                    }}
                    className={clsx('sc-split-hero__button', buttonClassname)}
                    childrenWrapProps={{
                      ...childrenWrapProps,
                    }}
                    key={buttonId}
                    cid={resolveComponentId(id, `button-${index}`)}
                    {...restButtonProps}
                  />
                ),
              )}
            </Container>
          </Container>
        </div>
      </div>
    </Block>
  )
}

export const splitHeroPlugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default SplitHero
