import { mixed } from 'yup'
import type { ReactPortal, ReactElement } from 'react'

type ReactText = string | number
type ReactChild = ReactElement | ReactText

type ReactFragment = Array<ReactNode>
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean

//@ts-ignore
export const reactNodeSchema = mixed<ReactNode | undefined>()
