import type { SVGProps } from 'react'
const SvgReusableBlockMake = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.543 10.457a1 1 0 0 1 0-1.414l3.75-3.75a1 1 0 0 1 1.414 0l3.75 3.75a1 1 0 0 1 0 1.414l-3.75 3.75a1 1 0 0 1-1.414 0zm2.121-.707L16 12.086l2.336-2.336L16 7.414zm-8.371 5.543a1 1 0 0 0 0 1.414l3.75 3.75a1 1 0 0 0 1.414 0l3.75-3.75a1 1 0 0 0 0-1.414l-3.75-3.75a1 1 0 0 0-1.414 0zm4.457 3.043L7.414 16l2.336-2.336L12.086 16zm11.793-6.793-3.75 3.75a1 1 0 0 0 0 1.414l3.75 3.75a1 1 0 0 0 1.414 0l3.75-3.75a1 1 0 0 0 0-1.414l-3.75-3.75a1 1 0 0 0-1.414 0m.707 6.793L19.914 16l2.336-2.336L24.586 16zm-10.707 3.207a1 1 0 0 0 0 1.414l3.75 3.75a1 1 0 0 0 1.414 0l3.75-3.75a1 1 0 0 0 0-1.414l-3.75-3.75a1 1 0 0 0-1.414 0zM16 24.586l-2.336-2.336L16 19.914l2.336 2.336z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgReusableBlockMake
