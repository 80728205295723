import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { InputFieldComponent } from '@sceneio/schemas/lib/types'
import { stringify } from '@sceneio/tools'

function TextArea({
  id,
  config: {
    name,
    maskedName,
    placeholder,
    required,
    pattern,
    minLength,
    maxLength,
    defaultValue,
    rows,
    cols,
  } = {},
  honeypot,
}: InputFieldComponent) {
  if (honeypot) {
    return (
      //https://dev.to/felipperegazio/how-to-create-a-simple-honeypot-to-protect-your-web-forms-from-spammers--25n8
      <textarea
        className="oh-no-ho-ne-y"
        autoComplete={'off'}
        name={name}
        placeholder={placeholder}
      />
    )
  }

  return (
    <textarea
      id={id}
      name={maskedName}
      autoComplete={'off'}
      // chrome ignores autocomplete https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-3920283
      role="presentation"
      data-plugin-form-field={stringify({
        name: maskedName,
        fieldType: 'input',
        pattern,
        required,
        minLength,
        maxLength,
        defaultValue,
      })}
      required={required}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      className={clsx('sc-input-field__input')}
    />
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default TextArea
