import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { ctaBanner4DefaultConfig } from './CTABanner4Meta'
import { CTABanner4Block } from '@sceneio/schemas/lib/types'
import Form from '../../../components/Form/Form'

function CTABanner4({
  id,
  components: { block, tagline, heading, text, form, bottomText },
  editor,
  preview,
}: CTABanner4Block = ctaBanner4DefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block

  const leftColumns = columnRatio!
  const rightColumns = 12 - leftColumns

  const ctaBanner4Class = clsx(['sc-cta-banner-4', className])

  const leftColumnClasses = clsx('sc-cta-banner-4__left-column', {
    [`bui-col-md-${leftColumns}`]: leftColumns,
    'bui-order-md-last': swapOrder,
  })

  const rightColumnClasses = clsx('sc-cta-banner-4__right-column', {
    [`bui-col-md-${rightColumns}`]: rightColumns,
    [`bui-col`]: rightColumns === 0,
  })

  return (
    <Block
      className={ctaBanner4Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={leftColumnClasses}>
          <Text
            {...tagline}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'tagline',
            }}
            cid={resolveComponentId(id, 'tagline')}
          />
          <Text
            {...heading}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'heading',
            }}
            cid={resolveComponentId(id, 'heading')}
          />
          <Text
            {...text}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'text',
            }}
            cid={resolveComponentId(id, 'text')}
          />
        </div>
        <div className={rightColumnClasses}>
          <Form
            {...form}
            editor={{ ...editor?.component, blockId: id, id: 'form' }}
            preview={preview}
            cid={resolveComponentId(id, 'form')}
          />
          <Text
            {...bottomText}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'bottomText',
            }}
            cid={resolveComponentId(id, 'bottomText')}
          />
        </div>
      </div>
    </Block>
  )
}

export const ctaBanner4Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default CTABanner4
