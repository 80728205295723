import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { FeatureGrid4Block } from '@sceneio/schemas/lib/types'
import FeatureGrid, { featureGridPlugins } from '../FeatureGrid/FeatureGrid'
import { featureGrid4DefaultConfig } from './featureGrid4Meta'

function FeatureGrid4(props: FeatureGrid4Block = featureGrid4DefaultConfig) {
  return <FeatureGrid {...props} />
}

export const featureGrid4Plugins: PluginsType = featureGridPlugins

export default FeatureGrid4
