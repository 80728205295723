import React, { CSSProperties } from 'react'
import * as Form from '@radix-ui/react-form'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { useFormContext } from '../../form/context/FormContext'
import clsx from 'clsx'
import { TooltipTrigger } from '../../../../../../content/shared/ui'

export type RHFToggleGroupPropType = {
  name: string
  options: {
    value: string
    label: string
    disabled?: boolean
    tooltip?: string
  }[]
  onMouseEnter?: (value: string) => void
  onMouseLeave?: (value: string) => void
  hidden?: boolean
  className?: string
  style?: CSSProperties
  label?: string | JSX.Element
}
export const RHFToggleGroup = ({
  name,
  options,
  label,
  hidden,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
}: RHFToggleGroupPropType) => {
  const { values, placeholders, setValue, forceBreakpoint } = useFormContext({
    name,
  })

  const containerClasses = clsx(
    'sc-rhf-toggle-group tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-flex tw-items-center tw-bg-form-field-bg tw-rounded tw-py-1 tw-px-1 tw-relative hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && (
          <div className="tw-text-label-color tw-pr-2 tw-text-label-color">
            {label}
          </div>
        )}
        <ToggleGroup.Root
          className={clsx(
            'tw-inline-flex tw-bg-form-field-bg tw-rounded tw-relative tw-w-full tw-gap-1',
            {
              'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
            },
          )}
          type="single"
          value={values[name] || placeholders[name] || ''}
          onValueChange={(val) => {
            if (val) {
              setValue(name, val)
            }
          }}
        >
          {options.map((option) => (
            <ToggleGroup.Item
              key={option.value}
              className={clsx(
                'tw-flex tw-justify-center tw-items-center tw-overflow-hidden tw-w-full hover:enabled:tw-bg-gray-4 data-[state=on]:tw-bg-white data-[state=on]:tw-shadow-sm tw-rounded-sm tw-py-1 tw-px-2 focus-visible:tw-outline-border-color focus-visible:tw-outline-1 focus-visible:tw-outline-offset-[-1px] focus-visible:tw-outline tw-transition disabled:tw-text-gray-50',
                {
                  'data-[state=on]:!tw-bg-form-field-bg':
                    forceBreakpoint && !name.includes(forceBreakpoint),
                },
              )}
              value={option.value}
              aria-label={option.label}
              onMouseEnter={() => onMouseEnter && onMouseEnter(option.value)}
              onMouseLeave={() => onMouseLeave && onMouseLeave(option.value)}
              disabled={option.disabled}
            >
              {option.tooltip ? (
                <TooltipTrigger
                  tooltip={option.tooltip}
                  contentClassName="tw-max-w-[200px] tw-mr-3"
                >
                  <div>
                    <span className="tw-truncate">{option.label}</span>
                  </div>
                </TooltipTrigger>
              ) : (
                <span className="tw-truncate">{option.label}</span>
              )}
            </ToggleGroup.Item>
          ))}
        </ToggleGroup.Root>
      </Form.Label>
    </Form.Field>
  )
}
