import { InferType, object, string } from 'yup'
import { borderStyleDataSchema } from '../../styles/borderStyleSchema'

export const strokeSnippetSchema = object({
  data: object({
    value: borderStyleDataSchema.required(),
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['STROKE']).required(),
})

export type StrokeSnippet = InferType<typeof strokeSnippetSchema>
