import React from 'react'
import { SliderComponent } from '@sceneio/schemas/lib/types'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { getSliderCssVariables } from '../../tools/cssVariables/getSliderCssVariables'

function SliderContainer({
  children,
  speed,
  loop,
  effect,
  easing,
  itemsPerView,
  activeSlideIndex = 0,
  allowKeyNav,
  autoplay,
  gap,
  boxShadow,
  padding,
  margin,
  border,
  editor,
  preview,
  className,
  style,
}: SliderComponent) {
  return (
    <div
      data-component-id={editor?.id}
      data-plugin-slider={JSON.stringify({
        itemsPerView,
        effect,
        speed,
        autoplay,
        allowKeyNav,
        loop,
        gap,
      })}
      className={clsx(
        'sc-slider swiper',
        {
          'sc-slider--js-loading': !preview,
          'sc-slider--editor-view': preview,
          'sc-slider--continuous-autoplay':
            autoplay?.enable && autoplay?.timeout === 0,
        },
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        className,
      )}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            border,
            gap,
          },
          prefix: 'slider',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'slider',
        }),
        ...getSliderCssVariables({
          responsiveConfig: itemsPerView?.responsiveConfig,
          selectedSlideIndex: activeSlideIndex,
          easing,
        }),
      }}
    >
      {children}
    </div>
  )
}

export const plugins: PluginsType = {
  js: ['slider'],
  css: ['slider'],
}
export default SliderContainer
