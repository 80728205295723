import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ProjectSliceType } from './projectSliceTypes'
import { AppDispatch, RootState } from '../../store'
import { createSlice } from '@reduxjs/toolkit'
import { setSelectedModules } from '../whiteboard/whiteboardSlice'

export const initialState: ProjectSliceType = {
  status: 'idle',
  error: null,
  entity: null,
} as ProjectSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectFetchSuccess: (
      state,
      action: PayloadAction<ProjectSliceType['entity']>,
    ) => {
      if (!action.payload) {
        return
      }
      state.status = 'succeeded'
      state.entity = {
        ...state.entity,
        ...action.payload,
      }
    },
    setAuthLink: (state, action: PayloadAction<string | null | undefined>) => {
      if (!state.entity) {
        return
      }
      state.entity.authLink = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', (state, action) => {
      return initialState
    })
  },
})

export const useProjectDispatch: () => AppDispatch = useDispatch
export const useProjectSelector: TypedUseSelectorHook<RootState> = useSelector

export const { projectFetchSuccess, setAuthLink } = projectSlice.actions

// ---------------
// SELECTORS
// ---------------

export const selectProject = (state: RootState) => {
  return state.project
}

export const selectProjectName = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }

  return state.project.entity.name
}

export const selectProjectData = (state: RootState) => {
  return state.project.entity
}

export const selectProjectDomain = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  const domains = state.project.entity.domains
  return Boolean(domains.length) ? domains[0] : null
}

export const selectProjectId = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }

  return state.project.entity.id
}

export const selectDomainSetupStatus = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.meta.setup?.domain?.status || null
}

export const selectStorageSetupStatus = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.meta.setup?.storage?.status || null
}
export const selectProjectAuthLink = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.authLink
}
export const selectProjectOwner = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.ownedByUser
}

export const selectProjectEditedAt = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.editedAt
}

export default projectSlice.reducer
