import type { SVGProps } from 'react'
const SvgMaxHeight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      fillRule="evenodd"
      d="M13 2a.5.5 0 0 0 0-1H3a.5.5 0 0 0 0 1zm0 13a.5.5 0 0 0 0-1H3a.5.5 0 0 0 0 1zm-1.584-4.777a.5.5 0 0 1-.139.693l-3 2a.5.5 0 0 1-.554 0l-3-2a.5.5 0 1 1 .554-.832L7.5 11.566V4.434L5.277 5.916a.5.5 0 0 1-.554-.832l3-2a.5.5 0 0 1 .554 0l3 2a.5.5 0 1 1-.554.832L8.5 4.434v7.132l2.223-1.482a.5.5 0 0 1 .693.139"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMaxHeight
