import React from 'react'
import { contactFormDefaultConfig } from './contactFormMeta'
import ContactForm from './ContactForm'

const ContactFormPreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : contactFormDefaultConfig

  return <ContactForm {...defaultData} />
}

export default ContactFormPreview
