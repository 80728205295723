import { FontsMap } from '@sceneio/config-fonts/lib/fonts'
import { SNIPPET_TYPE, useFormContext } from '../../../form/context/FormContext'

const getPropertyValue = (suffix: string, values: Record<string, any>) => {
  if (values) {
    const key = Object.keys(values).find((key) => key.includes(suffix))
    if (key) {
      return values[key]
    }
  }
  return '-'
}

const valueToString = (
  snippetType: SNIPPET_TYPE,
  value: any,
  customFonts?: FontsMap,
) => {
  if (snippetType === 'ATOMIC_COLOR') {
    return value
  }
  if (snippetType === 'STROKE') {
    const { default: defaultBpValues } = value || {}
    const borderWidth = getPropertyValue(
      '-border-default-width-default',
      defaultBpValues,
    )
    const borderStyle = getPropertyValue(
      '-border-default-style-default',
      defaultBpValues,
    )
    const borderColor = getPropertyValue(
      '-border-default-color-default',
      defaultBpValues,
    )
    return `${borderWidth} ${borderStyle} ${borderColor}`
  }
  if (snippetType === 'TEXT') {
    const { default: defaultBpValues } = value || {}

    let fontFamily = getPropertyValue(
      '-font-default-family-default',
      defaultBpValues,
    )
    const fontWeight = getPropertyValue(
      '-font-default-weight-default',
      defaultBpValues,
    )
    const fontSize = getPropertyValue(
      '-font-default-size-default',
      defaultBpValues,
    )
    const lineHeight = getPropertyValue(
      '-font-default-line-height-default',
      defaultBpValues,
    )

    const customFontTitlesByFontFamily = Object.values(
      customFonts || {},
    )?.reduce(
      (acc, { fontFamily, title }) => ({
        ...acc,
        [fontFamily]: title,
      }),
      {} as Record<string, string>,
    )

    // check if custom uploaded font
    const customFont = customFontTitlesByFontFamily[fontFamily]
    if (customFont) {
      fontFamily = customFont
    }

    return `${fontFamily} / ${fontWeight} / ${fontSize} / ${lineHeight}`
  }
  if (snippetType === 'BOX_SHADOW') {
    const { default: { default: defaultStateShadows = [] } = {} } = value || {}

    let shadowToStr = ''
    defaultStateShadows.map((shadow: Record<string, any>, idx: number) => {
      if (idx > 0) {
        shadowToStr = shadowToStr + ', '
      }
      const { x, y, blur, spread, color, inset } = shadow
      shadowToStr = `${shadowToStr}${x} ${y} ${blur} ${spread} ${color}${
        inset ? ` inset` : ''
      }`
    })
    return shadowToStr
  }
  if (snippetType === 'TEXT_SHADOW') {
    const { default: { default: defaultStateShadows = [] } = {} } = value || {}

    let shadowToStr = ''
    defaultStateShadows.map((shadow: Record<string, any>, idx: number) => {
      if (idx > 0) {
        shadowToStr = shadowToStr + ', '
      }
      const { x, y, blur, color } = shadow
      shadowToStr = `${shadowToStr}${x} ${y} ${blur} ${color}`
    })
    return shadowToStr
  }
}

export const SnippetValueMap = ({
  snippetType,
  value,
}: {
  snippetType: SNIPPET_TYPE
  value: any
}) => {
  const { customFonts } = useFormContext()

  return (
    <div className="tw-text-label-color tw-flex tw-gap-1 tw-truncate">
      <span className="tw-truncate">
        {valueToString(snippetType, value, customFonts)}
      </span>
    </div>
  )
}
