import { InferType, array, bool, object, number } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { cardComponentSchema } from '../../components/cardComponentSchema'
import { gridComponentSchema } from '../../components/gridComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'

export const featureGrid2BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    tagline: textComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    cards: gridComponentSchema
      .shape({
        items: array().of(
          cardComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
    media: mediaComponentSchema,
  }).noUnknown(),

  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type FeatureGrid2Block = InferType<typeof featureGrid2BlockSchema>
