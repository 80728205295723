import { search } from 'jmespath'

// TODO refactor
export function getComponentConfigPath({ meta, selectedComponentId }) {
  let subComponentMetaPath = ''

  const componentConfigPath = selectedComponentId
    .split('.')
    .reduce((acc, val, index) => {
      const isArrayPath = new RegExp(/\[\d+\]/, 'g').test(val)

      if (isArrayPath) {
        subComponentMetaPath += val.replaceAll(/\[\d+\]/g, '')
      } else {
        subComponentMetaPath += val
      }

      const subComponentMeta = search(meta, subComponentMetaPath)

      if (
        subComponentMeta &&
        (subComponentMeta?.isArrayComponent || subComponentMeta?.isArrayBlock)
      ) {
        acc = acc + (index === 0 ? '' : '.') + val
      } else {
        acc = acc + (index === 0 ? '' : '.') + subComponentMeta?.path
      }
      subComponentMetaPath = subComponentMetaPath + '.components.'
      return acc
    }, '')

  return componentConfigPath
}
