import type { DocumentName } from './types'
import * as documentMap from '../generated/graphqlTypes'
import { data as docsInfo } from '../generated/documentsMap'

export function loadDocumentOrThrow(documentName: DocumentName) {
  const documentNameLowerCased = String(
    documentName,
  ).toLowerCase() as Lowercase<DocumentName>

  const document = documentMap[documentName]

  if (!document) {
    throw Error(`Document "${documentName}" not found.`)
  }

  if (!(documentNameLowerCased in docsInfo)) {
    throw Error(`Document Info "${documentName}" not found.`)
  }

  return { document, info: docsInfo[documentNameLowerCased] }
}
