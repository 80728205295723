import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Button from '../../../components/Button/Button'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import {
  buttonSampleFactory,
  heroCarousel1DefaultConfig,
} from './heroCarousel1Meta'
import { HeroCarousel1Block } from '@sceneio/schemas/lib/types'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'
import SliderVariant1 from '../../../components/Slider/SliderVariant1/SliderVariant1'

function HeroCarousel1({
  id,
  components: { block, slider },
  editor,
  preview,
}: HeroCarousel1Block = heroCarousel1DefaultConfig) {
  const { className, ...restBlockProps } = block

  const heroCarousel1Class = clsx(['sc-hero-carousel-1', className])

  return (
    <Block
      className={heroCarousel1Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <SliderVariant1
        {...slider}
        preview={preview}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'slider',
        }}
        className="sc-hero-carousel-1__slider"
        components={{
          ...slider.components,
          slides: {
            ...slider.components.slides,
            items: slider.components.slides.items.map((slide, idx) => {
              const { background, heading, text, buttons, contentContainer } =
                slide?.components || {}

              return (
                <Container
                  {...slide}
                  key={slide?.id}
                  name="component"
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `slider.slides.items[${idx}]`,
                  }}
                  className="sc-hero-carousel-1__slide"
                >
                  <Media
                    {...background}
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: `slider.slides.items[${idx}].background`,
                    }}
                    className={clsx(
                      'sc-hero-carousel-1__background',
                      {
                        'sc-hero-carousel-1__background--is-video-player':
                          background.format === 'video' &&
                          !background.components.video?.isBackgroundVideo,
                      },
                      background.className,
                    )}
                    cid={resolveComponentId(id, 'background')}
                    preview={preview}
                  />
                  <Container
                    {...contentContainer}
                    name="group"
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: `slider.slides.items[${idx}].contentContainer`,
                    }}
                    flow={{ data: { '--flow-default': 'column' } }}
                  >
                    <Text
                      {...heading}
                      editor={{
                        ...editor?.component,
                        blockId: id,
                        id: `slider.slides.items[${idx}].heading`,
                        wysiwyg: {
                          type: 'HEADING',
                        },
                      }}
                      cid={resolveComponentId(id, 'heading')}
                    />
                    <Text
                      {...text}
                      editor={{
                        ...editor?.component,
                        blockId: id,
                        id: `slider.slides.items[${idx}].text`,
                      }}
                      cid={resolveComponentId(id, 'text')}
                    />
                    <Container
                      className={
                        'sc-hero-carousel-1__buttons bui-w-100 bui-flex-wrap'
                      }
                      editor={{
                        ...editor?.component,
                        blockId: id,
                        id: `slider.slides.items[${idx}].buttons`,
                      }}
                      {...buttons}
                      name="componentArray"
                    >
                      {buttons?.items?.map(
                        (
                          {
                            id: buttonId,
                            childrenWrapProps = {},
                            className: buttonClassname,
                            ...restButtonProps
                          },
                          index,
                        ) => (
                          <Button
                            className={clsx(
                              'sc-hero-carousel-1__button',
                              buttonClassname,
                            )}
                            childrenWrapProps={{
                              ...childrenWrapProps,
                            }}
                            key={buttonId || index}
                            cid={resolveComponentId(id, `button-${index}`)}
                            {...restButtonProps}
                            editor={{
                              ...editor?.component,
                              blockId: id,
                              id: `slider.slides.items[${idx}].buttons.items[${index}]`,
                              wysiwyg: {
                                type: 'ITEMS',
                                ...editorItemsControlsFactory({
                                  itemsArr: buttons.items || [],
                                  itemsId: `buttons.items`,
                                  itemFactory: buttonSampleFactory,
                                  index: index,
                                }),
                              },
                            }}
                          />
                        ),
                      )}
                    </Container>
                  </Container>
                </Container>
              )
            }),
          },
        }}
      />
    </Block>
  )
}

export const heroCarousel1Plugins: PluginsType = {
  js: ['video', 'animation', 'slider'],
  css: ['slider'],
}
export default HeroCarousel1
