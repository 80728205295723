import { ButtonComponent } from '@sceneio/schemas/lib/types'
import { ComponentMeta } from '../../componentCommonTypes'
import { componentDefaultConfigFactory } from '../../helpers'

const arrowButtonDefaultConfig: ButtonComponent = {
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  padding: {
    data: {
      '--padding-default-top': '12px',
      '--padding-default-bottom': '12px',
      '--padding-default-left': '12px',
      '--padding-default-right': '12px',
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#000',
      },
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': '#fff',
      },
      hover: {
        '--background-color-default-hover': '#fff',
      },
      active: {
        '--background-color-default-active': '#fff',
      },
    },
  },
  border: {
    data: {
      default: {
        '--border-default-color-default': '#000',
        '--border-default-width-default': '1px',
        '--border-default-radius-top-left-default': '50%',
        '--border-default-radius-top-right-default': '50%',
        '--border-default-radius-bottom-left-default': '50%',
        '--border-default-radius-bottom-right-default': '50%',
        '--border-default-style-default': 'solid',
        '--border-default-top-style-default': 'solid',
        '--border-default-right-style-default': 'solid',
        '--border-default-bottom-style-default': 'solid',
        '--border-default-left-style-default': 'solid',
      },
    },
    meta: {
      dataType: 'border-style',
    },
  },
}

export const arrowButtonSampleFactory = ({
  type,
}: {
  type: 'left' | 'right'
}): ButtonComponent => ({
  components: {
    icon: {
      meta: {
        dataType: 'icon-component',
      },
      mode: 'only',
      width: {
        data: {
          '--width-default': '24px',
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': '#000',
          },
        },
      },
      responsiveConfig: {
        default: {
          default: {
            provider: 'phosphor',
            name: type === 'left' ? 'ArrowLeft' : 'ArrowRight',
          },
        },
      },
    },
  },
  ...arrowButtonDefaultConfig,
})

export const sliderVariant2ComponentMeta: ComponentMeta<'sliderVariant2'> = {
  defaultConfig: {
    show: true,
    meta: {
      dataType: 'slider-component',
    },
    itemsPerView: {
      responsiveConfig: {
        default: 1,
      },
    },
    speed: 400,
    loop: true,
    effect: 'fade',
    easing: 'ease-out',
    autoplay: {
      enable: true,
      timeout: 2000,
      direction: 'forward',
    },
    components: {
      controlsContainer: {
        meta: {
          dataType: 'container-component',
        },
        margin: {
          data: {
            '--margin-default-top': '1.5rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-end',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        components: {
          navigation: {
            meta: {
              dataType: 'container-component',
            },
            flow: {
              data: {
                '--flow-default': 'row',
              },
            },
            gap: {
              data: {
                '--column-gap-default': '1rem',
              },
            },
            components: {
              buttonPrev: arrowButtonSampleFactory({ type: 'left' }),
              buttonNext: arrowButtonSampleFactory({ type: 'right' }),
            },
          },
          pagination: {
            meta: {
              dataType: 'slider-pagination-component',
            },
            type: 'bullets',
            padding: {
              data: {
                '--padding-default-left': '1rem',
                '--padding-default-right': '1rem',
                '--padding-default-top': '1rem',
                '--padding-default-bottom': '1rem',
              },
            },
            show: true,
          },
        },
      },
      slides: {
        meta: {
          dataType: 'container-component',
        },
        items: [],
      },
    },
  },
}

export const sliderVariant2Defaults =
  componentDefaultConfigFactory<'sliderVariant2'>({
    componentMeta: sliderVariant2ComponentMeta,
  })
