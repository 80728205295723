import { RHFRange, RHFSwitch, RHFTextAreaInput } from '@sceneio/ui-forms'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

type PlacementType = 'top' | 'bottom'

export const blockLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  fieldNames: [...(breakpoint === 'default' ? 'swapOrder' : [])],
  children: (
    <>
      {breakpoint === 'default' && (
        <div className="tw-flex tw-flex-col tw-gap-1">
          <RHFTextAreaInput name="embedHtml" label="Embed HTML code" />
        </div>
      )}
    </>
  ),
  placement: 'bottom' as PlacementType,
})
