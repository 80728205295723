import { Icon } from '@sceneio/ui-icons'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { useFormContext } from '../../form/context/FormContext'
import { GenericSettingsPropType } from './GenericSettings'
import { getFormRelatedValues } from './helpers'
import { stringify } from '@sceneio/tools'
import { InheritedSettingsWrapper } from '../../atoms/InheritedSettingsWrapper/InheritedSettingsWrapper'

export const ResponsiveGenericSettings = ({
  className,
  title = 'Layout',
  formFieldRenderer = () => null,
  additionalFormFieldRenderer = () => null,
}: GenericSettingsPropType) => {
  const { values, unregister, forceBreakpoint, onEditDefaultFrameClick } =
    useFormContext()

  const formRelatedValues = getFormRelatedValues({
    values,
    formFieldRenderer,
    additionalFormFieldRenderer,
  })

  const [isBreakpointActive, setIsBreakpointActive] = useState(false)

  const hasBreakpointValues = Object.keys(formRelatedValues).some((val) =>
    val.includes(`${forceBreakpoint!}`),
  )
  const hasDefaultValues = Object.keys(formRelatedValues).some((val) =>
    val.includes('default'),
  )

  useEffect(() => {
    if (hasBreakpointValues) {
      setIsBreakpointActive(true)
    } else {
      setIsBreakpointActive(false)
    }
  }, [stringify(hasBreakpointValues)])

  return (
    <SegmentWrapper className={clsx('sc-bp-generic-settings', className)}>
      <HeaderSegment
        heading={title}
        customChildren={
          <>
            {hasBreakpointValues && (
              <button
                type="button"
                className="tw-p-1"
                onClick={() => {
                  unregister([
                    ...(formFieldRenderer({ breakpoint: forceBreakpoint! })
                      ?.fieldNames || []),
                    ...(additionalFormFieldRenderer({
                      breakpoint: forceBreakpoint!,
                    })?.fieldNames || []),
                  ])
                }}
              >
                Reset
              </button>
            )}
            {!hasBreakpointValues && (
              <button
                type="button"
                className="tw-p-1"
                onClick={() => {
                  setIsBreakpointActive(!isBreakpointActive)
                }}
              >
                <Icon provider="phosphor" icon="PencilSimple" />
              </button>
            )}
          </>
        }
      />
      {isBreakpointActive && (
        <div className="tw-flex tw-flex-col tw-gap-1">
          {formFieldRenderer({ breakpoint: forceBreakpoint! })?.children}
          <div
            className={clsx({
              'tw-order-first':
                additionalFormFieldRenderer({ breakpoint: forceBreakpoint! })
                  ?.placement === 'top',
            })}
          >
            {
              additionalFormFieldRenderer({ breakpoint: forceBreakpoint! })
                ?.children
            }
          </div>
        </div>
      )}
      <InheritedSettingsWrapper
        onEditClick={() =>
          onEditDefaultFrameClick({ focusedMolecule: 'generic' })
        }
      >
        <div className="tw-flex tw-flex-col tw-gap-1">
          {formFieldRenderer({ breakpoint: 'default' })?.children}
          <div
            className={clsx({
              'tw-order-first':
                additionalFormFieldRenderer({ breakpoint: 'default' })
                  ?.placement === 'top',
            })}
          >
            {additionalFormFieldRenderer({ breakpoint: 'default' })?.children}
          </div>
        </div>
      </InheritedSettingsWrapper>
    </SegmentWrapper>
  )
}
