import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { dividerComponentSchema } from '../../components/dividerComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { socialComponentSchema } from '../../components/socialComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'

const footerMenuSchema = containerComponentSchema
  .shape({
    items: array().of(
      buttonComponentSchema
        .shape({
          id: idSchema.required(),
        })
        .noUnknown(),
    ),
  })
  .noUnknown()

const footerVariant1ColumnSchema = containerComponentSchema
  .shape({
    logo: imageComponentSchema,
    upperText: textComponentSchema,
    bottomText: textComponentSchema,
    menuTitle: textComponentSchema,
    menu: footerMenuSchema,
    social: socialComponentSchema,
  })
  .noUnknown()
  .required()

export const footerVariant1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    column1: footerVariant1ColumnSchema,
    column2: footerVariant1ColumnSchema,
    column3: footerVariant1ColumnSchema,
    column4: footerVariant1ColumnSchema,
    copyright: textComponentSchema,
    secondaryMenu: footerMenuSchema.shape({
      swapOrder: bool(),
    }),
    social: socialComponentSchema.optional(),
    divider: dividerComponentSchema,
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type FooterVariant1ColumnConfig = InferType<
  typeof footerVariant1ColumnSchema
>

export type FooterVariant1Block = InferType<typeof footerVariant1BlockSchema>
