import React from 'react'
import TestimonialVariant1 from './TestimonialVariant1'
import { testimonialVariant1DefaultConfig } from './testimonialVariant1Meta'

const TestimonialVariant1Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : testimonialVariant1DefaultConfig

  return <TestimonialVariant1 {...defaultData} />
}

export default TestimonialVariant1Preview
