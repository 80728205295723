import React from 'react'
import clsx from 'clsx'
import { isEmpty } from 'ramda'
import { getRootLevelCssVariables } from '../../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../../tools/cssVariables/getShadowCssVariables'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { MasonryGridComponent } from '@sceneio/schemas/lib/types'

const TopToBottomMasonryGrid = ({
  columns,
  margin,
  padding,
  boxShadow,
  border,
  gap,
  items = [],
  className,
  columnClassName,
  show = true,
  editor,
  style,
}: MasonryGridComponent) => {
  if (isEmpty(items) || !show) {
    return null
  }

  const gridClass = clsx(
    'sc-masonry-ttb-grid',
    className,
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
  )

  return (
    <div
      data-component-id={editor?.id}
      className={gridClass}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            margin,
            padding,
            border,
            gap,
            columns,
          },
          prefix: 'masonry-grid',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'masonry-grid',
        }),
      }}
    >
      <div className="sc-masonry-ttb-grid__container">
        {items.map((item, idx) => (
          <div
            className={clsx('sc-masonry-ttb-grid__item', columnClassName)}
            key={idx}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default TopToBottomMasonryGrid
