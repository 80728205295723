import { postprocessValueWithUnit } from '../helpers/postprocessValueWithUnit'
import { preprocessValueWithUnit } from '../helpers/preprocessValueWithUnit'
import { getUnit } from '../helpers/getUnit'
import * as Form from '@radix-ui/react-form'
import * as Select from '@radix-ui/react-select'
import clsx from 'clsx'
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Icon } from '@sceneio/ui-icons'
import { debounce } from '@sceneio/tools'
import { UnitType } from '../NumericInput'
import { useFormContext } from '../../../../form/context/FormContext'
import { processValueOnArrowKeyPress } from '../helpers/processValueOnArrowKeyPress'
import { recalcValueOnUnitChange } from '../helpers/recalcValueOnUnitChange'

export type RHFNumericInputBodyPropType = {
  label?: ReactNode | string
  name: string
  onChange?: (arg0: string | number | null) => void
  min?: number
  max?: number
  inputClassName?: string
  style?: CSSProperties
  placeholder?: string
  disabled?: boolean
  hidden?: boolean
  required?: boolean
  step?: number
  units?: UnitType[]
  defaultUnit?: string
  inputValue?: string
  restInputProps?: Record<string, any>
  isControlled?: boolean
  fallbackValue?: string | number
  unitsAsValue?: string[]
  onUnitChange?: (arg0: string | number | null) => void
  allowNegativeValue?: boolean
}

function validateNumericInput(
  input: string,
  { allowNegativeValue = false }: { allowNegativeValue?: boolean },
) {
  if (!allowNegativeValue && input?.startsWith('-')) {
    return false
  }
  const rgx = /^-?[0-9]*\.?[0-9]*$/
  return rgx.test(input)
}

function resolveCurrentValue(
  value: string | number,
  placeholder?: string | number,
) {
  return (isNaN(parseFloat(value?.toString())) ? placeholder : value) || 0
}

export function NumericInputBody({
  label,
  name,
  min,
  max,
  inputClassName,
  placeholder,
  disabled = false,
  hidden = false,
  defaultUnit,
  required,
  units,
  step,
  inputValue,
  fallbackValue = '',
  unitsAsValue = [],
  onChange,
  onUnitChange,
  allowNegativeValue,
}: RHFNumericInputBodyPropType) {
  const { setValue, forceBreakpoint } = useFormContext({ name })
  const [isDirty, setIsDirty] = useState(false)
  // due to possible floating values
  const [tempValue, setTempValue] = useState(
    preprocessValueWithUnit({
      value: inputValue ?? fallbackValue,
      unitsAsValue,
    }),
  )

  useEffect(() => {
    // isDirty state is used to prevent a double setTempValue call (causes the value to be overwritten during fast typing)
    if (!isDirty) {
      setTempValue(
        preprocessValueWithUnit({
          value: inputValue ?? fallbackValue,
          unitsAsValue,
        }),
      )
    }
  }, [inputValue])

  let unit =
    getUnit(inputValue ?? placeholder ?? fallbackValue, units) ??
    defaultUnit ??
    units?.[0]

  const handleOnChange = useCallback(
    debounce(({ val, unit }) => {
      const isValidValue =
        validateNumericInput(val, { allowNegativeValue }) ||
        unitsAsValue.includes(unit!)

      let outputValue = postprocessValueWithUnit({
        value: val,
        unit: unit,
      })

      if (!isValidValue) {
        return
      }

      if (onChange) {
        if (val === '') {
          onChange(fallbackValue)
          return
        }
        onChange(outputValue)
      }
      setIsDirty(true)
    }, 250),
    [onChange],
  ) as (value: any) => void

  const handleBlur = () => {
    const preprocessedInputValue = preprocessValueWithUnit({
      value: inputValue,
      unitsAsValue,
    })
    if (tempValue !== preprocessedInputValue) {
      setTempValue(preprocessedInputValue)
    }
    setIsDirty(false)
  }

  const handleUnitOnChange = (value: string | number) => {
    if (onUnitChange) {
      onUnitChange(value)
    } else {
      setValue(name, value)
    }
  }

  return (
    <>
      <Form.Control
        tabIndex={1}
        disabled={disabled}
        placeholder={
          preprocessValueWithUnit({
            value: placeholder,
            unitsAsValue,
          }).toString() || 'N/A'
        }
        className={clsx(
          'tw-bg-transparent focus:tw-outline-none tw-flex-1 tw-min-w-0 tw-w-[1px] tw-text-start',
          {
            'tw-pr-1': !units?.length || unitsAsValue.includes(unit!),
            'tw-opacity-50 tw-cursor-not-allowed': disabled,
          },
          inputClassName,
        )}
        step={step}
        min={min}
        max={max}
        required={required}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          e.stopPropagation()

          if (e.key === 'Enter') {
            e.preventDefault()
          }

          if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return
          if (unitsAsValue.includes(unit!)) return

          const inputValue = resolveCurrentValue(
            tempValue,
            preprocessValueWithUnit({
              value: placeholder,
              unitsAsValue,
            }),
          )
          const val = processValueOnArrowKeyPress(e, inputValue, unit)
          setTempValue(val)
        }}
        onKeyUp={(e) => {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault()
            handleOnChange({ val: tempValue.toString(), unit })
          }
        }}
        value={tempValue}
        hidden={hidden}
        onChange={(e) => {
          const val = e.target.value
          const parsedValue = preprocessValueWithUnit({
            value: e.target.value,
            unitsAsValue,
          })
          const parsedUnit = getUnit(e.target.value)

          // due to possible floating values
          setTempValue(e.target.value)
          const stringTempValue = val?.toString() || ''
          if (stringTempValue.endsWith('.')) {
            return
          }

          if (unitsAsValue.includes(unit!)) {
            unit = defaultUnit || units?.[0]
          }
          // accept an input value that contains a valid unit
          if (units?.includes(parsedUnit as UnitType)) {
            handleOnChange({ val: parsedValue.toString(), unit: parsedUnit })
            setTimeout(() => {
              setTempValue(parsedValue.toString())
            }, 250)
            unit = parsedUnit
          } else {
            handleOnChange({ val, unit })
          }
        }}
        onFocus={(e) => e.target.select()}
      />
      {units?.length && (
        <div
          className={clsx(
            'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap tw-text-label-color tw-pl-2 tw-pr-1',
            { 'tw-col-span-2': !label },
          )}
        >
          {units?.length === 1 && <span>{units[0]}</span>}
          {units && units?.length > 1 && (
            <Select.Root
              disabled={disabled}
              aria-label="Select unit"
              value={unit}
              onValueChange={(value) => {
                const selectedUnit = value
                const currentValue = preprocessValueWithUnit({
                  value: resolveCurrentValue(tempValue, placeholder),
                  unitsAsValue,
                })
                if (unitsAsValue.includes(selectedUnit)) {
                  handleUnitOnChange(selectedUnit)
                } else if (selectedUnit) {
                  if (selectedUnit === '-') {
                    handleUnitOnChange(currentValue)
                  } else {
                    handleUnitOnChange(
                      `${
                        currentValue === unit
                          ? 100
                          : recalcValueOnUnitChange({
                              prevUnit: unit!,
                              nextUnit: selectedUnit,
                              value: currentValue as number,
                            })
                      }${selectedUnit}`,
                    )
                  }
                  unit = selectedUnit
                }
              }}
            >
              <Select.Trigger
                className={clsx(
                  'tw-inline-flex tw-items-center tw-justify-center focus:tw-outline-none',
                  { 'tw-opacity-50 tw-cursor-not-allowed': disabled },
                )}
              >
                {!unitsAsValue.includes(unit!) && <Select.Value />}
                {unitsAsValue.includes(unit!) &&
                  (!forceBreakpoint ||
                    (forceBreakpoint && name.includes(forceBreakpoint))) && (
                    <Select.Icon className="tw-mx-1">
                      <Icon provider="phosphor" icon="CaretDown" />
                    </Select.Icon>
                  )}
              </Select.Trigger>
              <Select.Content
                position="popper"
                side="bottom"
                collisionBoundary={[
                  document.querySelector('.edit-style-content'),
                ]}
                style={{
                  minWidth: 'var(--radix-select-trigger-width)',
                  maxHeight: 'var(--radix-select-content-available-height)',
                }}
                className="tw-overflow-hidden tw-bg-white tw-text-form-color tw-text-xs tw-rounded tw-border tw-border-border-color tw-p-1 tw-z-10"
              >
                <Select.Viewport>
                  {units.map((unit) => (
                    <Select.Item
                      key={unit}
                      value={unit}
                      className="tw-select-none tw-flex tw-justify-between tw-items-center tw-p-2 hover:tw-outline-none data-[highlighted]:tw-bg-form-field-bg focus-visible:tw-outline-none data-[state=checked]:tw-bg-form-field-bg tw-rounded"
                    >
                      <Select.ItemText>{unit}</Select.ItemText>
                      <Select.ItemIndicator className="tw-pl-1">
                        <Icon provider="phosphor" icon="Check" />
                      </Select.ItemIndicator>
                    </Select.Item>
                  ))}
                </Select.Viewport>
              </Select.Content>
            </Select.Root>
          )}
        </div>
      )}
    </>
  )
}
