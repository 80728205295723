import { InferType, object, string } from 'yup'
import { textShadowResponsiveConfigSchema } from '../../styles/textShadowStyleSchema'

export const textShadowSnippetSchema = object({
  data: object({
    value: textShadowResponsiveConfigSchema,
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['TEXT_SHADOW']).required(),
})

export type TextShadowSnippet = InferType<typeof textShadowSnippetSchema>
