import { search } from 'jmespath'

export function getFlatObjectKeyFromJMESPath({
  data = {},
  jmespath,
}: {
  data: Record<string, any>
  jmespath: string
}): string {
  return (
    jmespath
      .split('.')
      .reduce((key, item, ix) => {
        const prevKey = `${key}${ix === 0 ? '' : '.'}`
        const [, field, id] =
          item.match(/^(\w+)\[\?id=='(.*?)'\]\x20?\|\x20?\[0]/) || []

        if (field && id) {
          const keyIndex = (search(data, key ? `${key}.${field}` : field) || [])
            .map((item: Record<string, any>) => item.id)
            .indexOf(id)

          if (keyIndex === -1) {
            // console.log({ message: 'error', jmespath })

            throw Error(
              `[getFlatObjectKeyFromJMESPath] Jmespath is not valid: ${jmespath} `,
            )
          }

          return `${prevKey}${field}[${keyIndex}]`
        }

        return `${prevKey}${item}`
      }, '')
      // change flatten array notation .0 -> [0]
      .replaceAll(/\.(\d+)/g, '[$1]')
  )
}
