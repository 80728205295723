import type { PageState, ActionWithPayload } from '../types'
import { PAGE, USER } from '../constatnts'

export const pageInitialState: PageState = {
  title: '',
  breadcrumbs: [],
  showBreadcrumb: true,
  useBodyCardsWrapper: false,
  alerts: [],
  showAlerts: false,
}
export default function projectReducer(
  state: PageState = pageInitialState,
  { type, payload }: ActionWithPayload<PageState>,
): PageState {
  if (type === PAGE.SET_META) {
    return {
      ...state,
      ...payload,
    }
  }

  if (type === USER.LOGOUT) {
    return pageInitialState
  }

  return state
}
