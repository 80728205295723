import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { GraphqlThunkData } from '../types'
import {
  DocumentName,
  GetVarsByDocumentName,
  GqlResultDataByDocumentName,
} from '@sceneio/graphql-queries/dist/client/types'

export const createGraphqlAsyncThunk = createAsyncThunk.withTypes<{
  rejectValue: { code: string; message: string }
  state: RootState
  pendingMeta: { pendingData: any }
}>()

export function createGraphqlAsyncThunkByDocument<
  T extends DocumentName,
  S extends keyof NonNullable<GqlResultDataByDocumentName<T>>,
  V extends GraphqlThunkData<any> = GraphqlThunkData<GetVarsByDocumentName<T>>,
>() {
  return createGraphqlAsyncThunk<
    NonNullable<GqlResultDataByDocumentName<T>>[S],
    V
  >
}
