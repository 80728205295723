import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSticker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.625 2H6.375C5.21506 2.00124 4.10298 2.46258 3.28278 3.28278C2.46258 4.10298 2.00124 5.21506 2 6.375V13.625C2.00124 14.7849 2.46258 15.897 3.28278 16.7172C4.10298 17.5374 5.21506 17.9988 6.375 18H11.125C11.1922 17.9999 11.2589 17.9891 11.3227 17.968C13.3719 17.2844 17.2844 13.3719 17.968 11.3227C17.9891 11.2589 17.9999 11.1922 18 11.125V6.375C17.9988 5.21506 17.5374 4.10298 16.7172 3.28278C15.897 2.46258 14.7849 2.00124 13.625 2ZM3.25 13.625V6.375C3.25 5.5462 3.57924 4.75134 4.16529 4.16529C4.75134 3.57924 5.5462 3.25 6.375 3.25H13.625C14.4538 3.25 15.2487 3.57924 15.8347 4.16529C16.4208 4.75134 16.75 5.5462 16.75 6.375V10.5H14.875C13.7151 10.5012 12.603 10.9626 11.7828 11.7828C10.9626 12.603 10.5012 13.7151 10.5 14.875V16.75H6.375C5.5462 16.75 4.75134 16.4208 4.16529 15.8347C3.57924 15.2487 3.25 14.4538 3.25 13.625ZM11.75 16.3703V14.875C11.75 14.0462 12.0792 13.2513 12.6653 12.6653C13.2513 12.0792 14.0462 11.75 14.875 11.75H16.3703C15.4219 13.2734 13.2734 15.4219 11.75 16.3703Z"
      fill="#252523"
    />
  </svg>
)
export default SvgSticker
