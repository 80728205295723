import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { subscriptionFormDefaultConfig } from './subscriptionFormMeta'
import Form from '../../../components/Form/Form'
import { SubscriptionFormBlock } from '@sceneio/schemas/lib/types'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import Text from '../../../components/Text/Text'

function SubscriptionForm({
  id,
  components: { block, tagline, heading, subheading, form },
  editor,
  preview,
}: SubscriptionFormBlock = subscriptionFormDefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const subscriptionFormClass = clsx('sc-subscription-form', className)

  return (
    <Block
      className={subscriptionFormClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <Text
        {...tagline}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'tagline',
        }}
        cid={resolveComponentId(id, 'tagline')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...subheading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'subheading',
        }}
        cid={resolveComponentId(id, 'subheading')}
      />
      <Form
        {...form}
        editor={{ ...editor?.component, blockId: id, id: 'form' }}
        preview={preview}
        cid={resolveComponentId(id, 'form')}
      />
    </Block>
  )
}
export const subscriptionFormPlugins: PluginsType = {
  js: ['form'],
  css: [],
}

export default SubscriptionForm
