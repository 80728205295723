import { ButtonBaseComponent } from '@sceneio/schemas/lib/types'
import clsx from 'clsx'
import React from 'react'
import { PluginsType } from '../../scripts/plugins/pluginTypes'

function ButtonBase({
  children,
  className: classNameProp,
  as: component = 'button',
  disabled = false,
  tabIndex = 0,
  type = 'button',
  link,
  dataAttributes = {},
  onClick,
  onMouseDown,
  'data-bs-toggle': dataToggle,
  style,
  id,
  ariaAttributes = {},
}: ButtonBaseComponent) {
  const className = clsx(
    'sc-button-base',
    {
      'sc-button-base--disabled': disabled,
    },
    classNameProp,
  )

  if (component === 'button' && link?.href) {
    component = 'a'
  }

  let resolvedTarget
  let resolvedHref

  if (component === 'a') {
    resolvedTarget = link?.type === 'anchor' ? '_self' : link?.target
    resolvedHref = link?.type === 'anchor' ? `#${link?.href}` : link?.href
  }

  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    ...dataAttributes,
    ...ariaAttributes,
  }

  if (component === 'button') {
    buttonProps.type =
      type as React.ButtonHTMLAttributes<HTMLButtonElement>['type']
    buttonProps.disabled = disabled
  } else {
    if (component !== 'a' || !link?.href) {
      buttonProps.role = 'button'
    }

    buttonProps['aria-disabled'] = disabled
  }

  const componentInstance = React.createElement(
    component,
    {
      className,
      tabIndex: disabled ? -1 : tabIndex,
      onClick,
      onMouseDown,
      'data-bs-toggle': dataToggle,
      id,
      rel: component === 'a' ? 'noopener noreferrer' : null,
      style,
      ...buttonProps,
      ...(Boolean(link?.href) && { href: resolvedHref }),
      target: resolvedTarget,
    },
    children,
  )

  return componentInstance
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default ButtonBase
