import { InferType, lazy } from 'yup'
import { atomicColorSnippetSchema } from './schemas/atomicColorSnippetSchema'
import { boxShadowSnippetSchema } from './schemas/boxShadowSnippetSchema'
import { strokeSnippetSchema } from './schemas/strokeSnippetSchema'
import { textShadowSnippetSchema } from './schemas/textShadowSnippetSchema'
import { textSnippetSchema } from './schemas/textSnippetSchema'
import { buttonSnippetSchema } from './schemas/buttonSnippetSchema'

export const snippetSchema = lazy((value) => {
  const snippetType = value?.type

  switch (snippetType) {
    case 'ATOMIC_COLOR':
      return atomicColorSnippetSchema
    case 'BOX_SHADOW':
      return boxShadowSnippetSchema
    case 'TEXT_SHADOW':
      return textShadowSnippetSchema
    case 'BUTTON':
      return buttonSnippetSchema
    case 'STROKE':
      return strokeSnippetSchema
    case 'TEXT':
      return textSnippetSchema
    default:
      throw new Error('Invalid snippet type')
  }
})

export type Snippet = InferType<typeof snippetSchema>
