import React, { CSSProperties, ReactNode } from 'react'
import * as Form from '@radix-ui/react-form'
import clsx from 'clsx'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { RangeBody } from './components/RangeBody'
import { useFormContext } from '../../form/context/FormContext'

export type RHFRangePropType = {
  label?: ReactNode | string
  name: string
  'aria-label'?: string
  className?: string
  inputClassName?: string
  style?: CSSProperties
  disabled?: boolean
  min?: number
  max?: number
  step?: number
  hidden?: boolean
  transparent?: boolean
  onChange?: (arg0: number) => void
  value?: number
  showValue?: boolean
}

export const RHFRange = ({
  label,
  name,
  className,
  inputClassName,
  disabled = false,
  style,
  hidden = false,
  min,
  max,
  step,
  transparent = false,
  onChange,
  value,
  showValue,
  ...rest
}: RHFRangePropType) => {
  const { setValue, placeholders, values, error, forceBreakpoint } =
    useFormContext()
  const containerClasses = clsx(
    'sc-rhf-range tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-flex tw-items-center tw-justify-between tw-rounded tw-py-2 tw-gap-3 hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-form-field-bg tw-px-2': !transparent,
            'tw-bg-transparent': transparent,
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && <span className="tw-text-label-color">{label}</span>}
        <RangeBody
          disabled={disabled}
          min={min}
          showValue={showValue}
          label={rest?.['aria-label']}
          max={max}
          step={step}
          inputValue={value ?? values[name] ?? placeholders[name]}
          onChange={(val) => {
            if (onChange) {
              onChange(val)
            } else {
              setValue(name, val)
            }
          }}
          inputClassName={inputClassName}
        />
      </Form.Label>
      {error && <ErrorMessage message={error?.message} />}
    </Form.Field>
  )
}
