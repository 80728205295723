import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { InputFieldComponent } from '@sceneio/schemas/lib/types'
import { stringify } from '@sceneio/tools'
import IconComponent from '../../Icon/Icon'

function TextInput({
  id,
  type,
  components: { icon } = {},
  config: {
    name,
    maskedName,
    placeholder,
    required,
    pattern,
    minLength,
    maxLength,
    defaultValue,
  } = {},
  honeypot,
}: InputFieldComponent) {
  if (honeypot) {
    return (
      //https://dev.to/felipperegazio/how-to-create-a-simple-honeypot-to-protect-your-web-forms-from-spammers--25n8
      <input
        className="oh-no-ho-ne-y"
        autoComplete={'off'}
        type={type}
        name={name}
        placeholder={placeholder}
      />
    )
  }

  return (
    <>
      {icon && <IconComponent {...icon} className="sc-input-field__icon" />}
      <input
        id={id}
        name={maskedName}
        autoComplete={'off'}
        // chrome ignores autocomplete https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-3920283
        role="presentation"
        data-plugin-form-field={stringify({
          name: maskedName,
          fieldType: 'input',
          pattern,
          required,
          minLength,
          maxLength,
          defaultValue,
        })}
        required={required}
        placeholder={placeholder}
        type={'text'}
        className={clsx('sc-input-field__input')}
      />
    </>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default TextInput
