import React from 'react'
import { WysiwygTextEditor } from './WysiwygTextEditor'
import { WysiwygItemsEditor } from './WysiwygItemsEditor'
import { WysiwygHeadingEditor } from './WysiwygHeadingEditor'

export type WysiwygTextComponentEditor = WysiwygTextEditor &
  WysiwygItemsEditor & {
    type?: 'TEXT' | 'ITEMS' | 'HEADING' | 'RAW'
  }

export function WysiwygTextComponentEditor({
  type = 'TEXT',
  ...props
}: WysiwygTextComponentEditor) {
  if (type === 'TEXT') {
    return <WysiwygTextEditor {...props} />
  }

  if (type === 'HEADING') {
    return <WysiwygHeadingEditor {...props} />
  }

  if (type === 'ITEMS') {
    return <WysiwygItemsEditor {...props} />
  }

  return <div>Unsupported Editor Type</div>
}
