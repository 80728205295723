import { InferType } from 'yup'
import {
  FooterVariant1ColumnConfig,
  footerVariant1BlockSchema,
} from './footerVariant1BlockSchema'

export const footerVariant2BlockSchema = footerVariant1BlockSchema

export type FooterVariant2ColumnConfig = FooterVariant1ColumnConfig
export type FooterVariant2Block = InferType<typeof footerVariant2BlockSchema>
