import { InferType, array, bool, number, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'

export const splitHeroBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    logo: imageComponentSchema,
    subheading: textComponentSchema,
    text: textComponentSchema,
    heading: textComponentSchema,
    media: mediaComponentSchema,
    author: textComponentSchema,
    mediaContainer: containerComponentSchema,
    contentContainer: containerComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type SplitHeroBlock = InferType<typeof splitHeroBlockSchema>
