import { BreakpointEnum } from '../../../Image/constants/breakpoints'
import { resolveBreakpointOverlaps } from './resolveBreakpointOverlaps'
import clsx from 'clsx'

// Resolves image classes for a particular breakpoint
// parameters to override available breakpoints are optional

type ResolveBreakpointGridClassesArgsType = {
  breakpoint: BreakpointEnum
  selectedBreakpoints?: BreakpointEnum[]
  breakpoints?: BreakpointEnum[]
  elementClassName: string
}
export function resolveBreakpointGridClasses({
  breakpoint,
  selectedBreakpoints,
  breakpoints,
  elementClassName,
}: ResolveBreakpointGridClassesArgsType) {
  // Resolves the overlaps between breakpoints
  const breakpointOverlaps = resolveBreakpointOverlaps(
    selectedBreakpoints,
    breakpoints,
  )

  const overlaps = breakpointOverlaps[breakpoint]

  if (!overlaps) {
    return ''
  }

  return overlaps.reduce(
    (classesAcc, classesValue) =>
      clsx(classesAcc, `${elementClassName}--show-${classesValue}`),
    `${elementClassName}--hide`,
  )
}
