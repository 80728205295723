export type ServerVars = {
  srvTs: string
  srvCid: string
  srvVersion: string
}

export function runServerValidator(url: string) {
  return fetch(url + '/graphql?query=%7B__typename%7D', { method: 'GET' }).then(
    (res) => {
      return {
        srvTs: res.headers.get('x-srv-ts') || '',
        srvCid: res.headers.get('x-srv-cid') || '',
        srvVersion: res.headers.get('x-srv-version') || '',
      }
    },
  )
}
