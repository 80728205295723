import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Media from '../../../components/Media/Media'
import Text from '../../../components/Text/Text'
import Image from '../../../components/Image/Image'
import Button from '../../../components/Button/Button'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { Container } from '../../../components/Container/Container'
import {
  buttonSampleFactory,
  heroCarousel2DefaultConfig,
} from './heroCarousel2Meta'
import { HeroCarousel2Block } from '@sceneio/schemas/lib/types'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'
import SliderVariant3 from '../../../components/Slider/SliderVariant3/SliderVariant3'
import Grid from '../../../components/Grid/Grid'

function HeroCarousel2({
  id,
  components: {
    block,
    heading,
    text,
    logoImages,
    slider,
    buttons,
    contentContainer,
  },
  editor,
  preview,
}: HeroCarousel2Block = heroCarousel2DefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block

  const contentColumns = columnRatio!
  const sliderColumns = 12 - contentColumns

  const heroCarousel2Class = clsx(['sc-hero-carousel-2', className])

  const sliderColumnClasses = clsx(
    'sc-hero-carousel-2__slider-column bui-d-flex',
    {
      [`bui-col-md-${sliderColumns}`]: sliderColumns,
    },
  )

  const contentColumnClasses = clsx(`sc-hero-carousel-2__content-column`, {
    [`bui-col-md-${contentColumns}`]: contentColumns,
    [`bui-col`]: contentColumns === 0,
    'bui-order-md-last': swapOrder,
  })

  const showButtons = Boolean(buttons?.items?.length) && buttons?.show

  return (
    <Block
      className={heroCarousel2Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={contentColumnClasses}>
          <Container
            {...contentContainer}
            name="group"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'contentContainer',
            }}
            flow={{ data: { '--flow-default': 'column' } }}
            className="bui-h-100"
          >
            <Text
              {...heading}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'heading',
              }}
              cid={resolveComponentId(id, 'heading')}
            />
            <Text
              {...text}
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'text',
              }}
              cid={resolveComponentId(id, 'text')}
            />
            <Grid
              {...logoImages}
              cssType="flex"
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'logoImages',
              }}
              items={logoImages?.items?.map(
                ({ id: imageId, ...restImageProps }, index) => (
                  <Image
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: `logoImages.items[${index}]`,
                    }}
                    key={imageId}
                    {...restImageProps}
                    isAnimated={true}
                  />
                ),
              )}
            />
            <Container
              className="bui-flex-wrap bui-w-100"
              editor={{
                ...editor?.component,
                blockId: id,
                id: 'buttons',
              }}
              {...buttons}
              name="componentArray"
              show={showButtons}
            >
              {buttons?.items?.map(
                (
                  {
                    id: buttonId,
                    childrenWrapProps = {},
                    className: buttonClassname,
                    ...restButtonProps
                  },
                  index,
                ) => (
                  <Button
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: `buttons.items[${index}]`,
                      wysiwyg: {
                        type: 'ITEMS',
                        ...editorItemsControlsFactory({
                          itemsArr: buttons.items || [],
                          itemsId: `buttons.items`,
                          itemFactory: buttonSampleFactory,
                          index: index,
                        }),
                      },
                    }}
                    className={clsx(
                      'sc-hero-carousel-2__button',
                      buttonClassname,
                    )}
                    childrenWrapProps={{
                      ...childrenWrapProps,
                    }}
                    key={buttonId}
                    cid={resolveComponentId(id, `button-${index}`)}
                    {...restButtonProps}
                  />
                ),
              )}
            </Container>
          </Container>
        </div>
        <div className={sliderColumnClasses}>
          <SliderVariant3
            {...slider}
            preview={preview}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'slider',
            }}
            components={{
              ...slider.components,
              slides: {
                ...slider.components.slides,
                items: slider.components.slides.items.map((media, idx) => (
                  <Media
                    {...media}
                    key={media?.id}
                    editor={{
                      ...editor?.component,
                      blockId: id,
                      id: `slider.slides.items[${idx}]`,
                    }}
                    className="sc-hero-carousel-2__media"
                    cid={resolveComponentId(id, 'media')}
                    preview={preview}
                  />
                )),
              },
            }}
          />
        </div>
      </div>
    </Block>
  )
}

export const heroCarousel2Plugins: PluginsType = {
  js: ['video', 'animation', 'slider'],
  css: ['slider'],
}
export default HeroCarousel2
