import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { masonryGallery2DefaultConfig } from './masonryGallery2Meta'
import Text from '../../../components/Text/Text'
import type { MasonryGallery2Block } from '@sceneio/schemas/lib/types'
import GalleryItem from '../../../components/GalleryItem/GalleryItem'
import { removeNil } from '@sceneio/tools'
import { lexicalToPlainText } from '@sceneio/wysiwyg-utils/lib/lexicalToPlainText'
import TopToBottomMasonryGrid from '../../../components/MasonryGrid/TopToBottomMasonryGrid/TopToBottomMasonryGrid'

function MasonryGallery2({
  id,
  components: { block, heading, text, galleryItems },
  editor,
}: MasonryGallery2Block = masonryGallery2DefaultConfig) {
  const { items } = galleryItems || {}
  const { className, enableLightbox = true, ...restBlockProps } = block || {}

  const galleryClass = clsx('sc-masonry-gallery', className)

  return (
    <Block
      className={galleryClass}
      id={id}
      editor={editor}
      dataAttributes={{ 'data-plugin-image-lightbox': enableLightbox }}
      {...restBlockProps}
    >
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <TopToBottomMasonryGrid
        {...galleryItems}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'galleryItems',
        }}
        items={items?.map((item, index) => (
          <GalleryItem
            {...item}
            key={item.id}
            editor={{
              ...editor?.component,
              blockId: id,
              id: `galleryItems.items[${index}]`,
            }}
            components={{
              ...item.components,
              image: {
                ...item.components?.image,
                dataAttributes: {
                  ...(enableLightbox &&
                    !item.components?.image?.link && {
                      'data-plugin-image-lightbox-image': JSON.stringify(
                        removeNil({
                          heading: item.components.heading?.show
                            ? lexicalToPlainText(
                                item.components.heading?.children!,
                              )
                            : undefined,
                          description: item.components.description?.show
                            ? lexicalToPlainText(
                                item.components.description?.children!,
                              )
                            : undefined,
                        }),
                      ),
                    }),
                },
              },
            }}
            cid={resolveComponentId(id, `galleryItems.items[${index}]`)}
            className="sc-masonry-gallery__item"
          />
        ))}
      />
    </Block>
  )
}

export const masonryGallery2Plugins = {
  js: ['animation', 'imageLightbox'],
  css: ['imageLightbox'],
}
export default MasonryGallery2
