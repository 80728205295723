import { useSelector } from '@sceneio/content-store/lib/redux'
import { RootState } from '../../store/storeInstance'

/*
  SELECTORS
*/
export function useAclStore() {
  return useSelector((state: RootState) => state.acl)
}
export function useUserStore() {
  return useSelector((state: RootState) => state.user)
}
export function useUIStore() {
  return useSelector((state: RootState) => state.ui)
}
export function useSystemStore() {
  return useSelector((state: RootState) => state.system)
}
export function usePageStore() {
  return useSelector((state: RootState) => state.page)
}
