import { InferType, bool, object, string } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'

export const embedBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        embedHtml: string(),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type EmbedBlock = InferType<typeof embedBlockSchema>
