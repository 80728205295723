import { ReferencesType, ReferenceItemType } from '../referencesTypes'
import { ReferencesObjectType } from './transformReferencesFromArrayToObject'

export function transformReferencesFromObjectToArray(
  references: ReferencesObjectType = {},
): ReferencesType {
  const output = Object.keys(references).map((path) => {
    const pathFrom = path

    const [typeTo, , idTo, pathTo, resolveMethod] = references[path].split(':')

    return {
      pathFrom,
      typeTo: typeTo.toUpperCase(),
      idTo,
      pathTo,
      resolveMethod,
    } as ReferenceItemType
  })
  return output
}
