import { createContext, createRef, ReactNode, RefObject } from 'react'

type CallbackType = (data: {
  children?: Record<string, any>
  event?: React.MouseEvent<HTMLButtonElement>
  mentionedUsers?: Record<string, any>[]
  mode?: 'create' | 'edit'
}) => void

export type WysiwygCommentContextType = {
  onSubmit: CallbackType
  onChange: CallbackType
  onDiscard?: () => void
  anchorRef: RefObject<HTMLElement>
  userMentionsData?: { id: string; name: string; email: string }[]
  currentUserId: string
}

type WysiwygCommentProviderType = WysiwygCommentContextType & {
  children: ReactNode
}

export const WysiwygCommentContext = createContext<WysiwygCommentContextType>({
  onSubmit: () => {},
  onChange: () => {},
  onDiscard: () => {},
  anchorRef: createRef<HTMLElement>(),
  currentUserId: '',
})

export function WysiwygCommentContextProvider({
  children,
  onSubmit,
  onChange,
  onDiscard,
  anchorRef,
  currentUserId,
  userMentionsData,
}: WysiwygCommentProviderType) {
  return (
    <WysiwygCommentContext.Provider
      value={{
        onSubmit,
        onChange,
        onDiscard,
        anchorRef,
        userMentionsData,
        currentUserId,
      }}
    >
      {children}
    </WysiwygCommentContext.Provider>
  )
}
