import type { SVGProps } from 'react'
const SvgShadowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13 7.036a5 5 0 1 1-10 0 5 5 0 0 1 10 0m-1 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      d="M13.549 11.343a.5.5 0 1 0-.79-.613A6.01 6.01 0 0 1 8 13.06a6.01 6.01 0 0 1-4.755-2.324.5.5 0 1 0-.788.615A7.01 7.01 0 0 0 8 14.06a7.01 7.01 0 0 0 5.549-2.718"
    />
  </svg>
)
export default SvgShadowIcon
