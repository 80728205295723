import React from 'react'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import Text from '../Text/Text'
import Input from '../Input/Input'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import clsx from 'clsx'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import {
  FormFieldComponent,
  InputFieldComponent,
} from '@sceneio/schemas/lib/types'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { hpMaskFormField } from './helpers'

const FORM_FIELD_MAP_BY_TYPE: Partial<
  Record<
    InputFieldComponent['type'],
    { component: React.FC<InputFieldComponent>; pattern: string }
  >
> = {
  text: { component: Input, pattern: '' },
  email: {
    component: Input,
    pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
  },
  tel: { component: Input, pattern: '^[0-9+]+$' },
  textarea: { component: Input, pattern: '' },
  // password: { component: Input, pattern: '.*' },
  // number: { component: Input, pattern: '^[0-9]+$' },
  // url: {
  //   component: Input,
  //   pattern: "^(http(s)?://)+[w-._~:/?#[]@!$&'()*+,;=.]+$",
  // },
}

function FormField({
  components: {
    label,
    field,
    invalidPatternError,
    requiredValueError,
    minLengthError,
    maxLengthError,
  },
  margin,
  padding,
  boxShadow,
  gap,
  border,
  show = true,
  editor,
  style,
  className,
  preview,
  cid,
}: FormFieldComponent) {
  if (!show) {
    return null
  }

  const { type } = field

  const FormFieldComponent = FORM_FIELD_MAP_BY_TYPE[type]?.component
  const maskedFieldName = hpMaskFormField(field?.config?.name)

  return (
    <div
      data-component-id={editor?.id}
      className={clsx(
        'sc-form-field',
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        {
          'sc-form-field--preview': preview,
        },
        className,
      )}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            margin,
            padding,
            gap,
            border,
          },
          prefix: 'form-field',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'form-field',
        }),
      }}
    >
      {label?.show && (
        <label
          htmlFor={`${cid}-${maskedFieldName}`}
          onClick={(e) => (preview ? e.preventDefault() : null)}
        >
          <Text
            {...label}
            editor={{
              ...editor,
              id: `${editor?.id}.label`,
            }}
            cid={resolveComponentId(cid, 'label')}
          />
        </label>
      )}
      {FormFieldComponent && (
        <FormFieldComponent
          {...field}
          id={`${cid}-${maskedFieldName}`}
          editor={{
            ...editor,
            id: `${editor?.id}.field`,
          }}
          config={{
            ...field.config,
            maskedName: maskedFieldName,
            pattern: FORM_FIELD_MAP_BY_TYPE[type]?.pattern,
          }}
        />
      )}
      <Text
        dataAttributes={{
          'data-plugin-form-field-required-message': maskedFieldName,
        }}
        {...requiredValueError}
        editor={{
          ...editor,
          id: `${editor?.id}.requiredValueError`,
        }}
        className="sc-form-field__required-message"
        show={preview ? requiredValueError.show : true}
        cid={resolveComponentId(cid, 'requiredValueError')}
      />
      <Text
        dataAttributes={{
          'data-plugin-form-field-invalid-message': maskedFieldName,
        }}
        {...invalidPatternError}
        editor={{
          ...editor,
          id: `${editor?.id}.invalidPatternError`,
        }}
        className="sc-form-field__invalid-message"
        show={preview ? invalidPatternError.show : true}
        cid={resolveComponentId(cid, 'invalidPatternError')}
      />
      <Text
        dataAttributes={{
          'data-plugin-form-field-min-message': maskedFieldName,
        }}
        {...minLengthError}
        editor={{
          ...editor,
          id: `${editor?.id}.minLengthError`,
        }}
        className="sc-form-field__min-length-message"
        show={preview ? minLengthError.show : true}
        cid={resolveComponentId(cid, 'minLengthError')}
      />
      <Text
        dataAttributes={{
          'data-plugin-form-field-max-message': maskedFieldName,
        }}
        {...maxLengthError}
        editor={{
          ...editor,
          id: `${editor?.id}.maxLengthError`,
        }}
        className="sc-form-field__max-length-message"
        show={preview ? maxLengthError.show : true}
        cid={resolveComponentId(cid, 'maxLengthError')}
      />
      <label className="oh-no-ho-ne-y">
        {FormFieldComponent && (
          <FormFieldComponent {...field} honeypot={true} />
        )}
      </label>
    </div>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default FormField
