import {
  AVAILABLE_BREAKPOINTS,
  BreakpointType,
} from '../../atoms/BreakpointDropdown/BreakpointDropdown'
import { GenericSettingsPropType } from './GenericSettings'

export const getFormRelatedValues = ({
  values,
  formFieldRenderer = () => null,
  additionalFormFieldRenderer = () => null,
}: {
  values: Record<string, any>
  formFieldRenderer: GenericSettingsPropType['formFieldRenderer']
  additionalFormFieldRenderer?: GenericSettingsPropType['additionalFormFieldRenderer']
}) => {
  var fieldNames: string[] = []
  const availableBreakpoints: (BreakpointType | 'default')[] = [
    ...AVAILABLE_BREAKPOINTS,
    'default',
  ]

  availableBreakpoints.forEach((bp) => {
    fieldNames = [
      ...fieldNames,
      ...(formFieldRenderer({ breakpoint: bp })?.fieldNames || []),
      ...(additionalFormFieldRenderer({ breakpoint: bp })?.fieldNames || []),
    ]
  })
  return Object.entries(values).reduce((acc, [key, val]) => {
    if (fieldNames.includes(key)) {
      return {
        ...acc,
        [key]: val,
      }
    }
    return acc
  }, {})
}
