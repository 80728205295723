import { InferType, object } from 'yup'
import { spacingSchema } from './common/spacingSchema'

export const paddingStyleSchema = object({
  data: object({
    // default
    '--padding-default-bottom': spacingSchema,
    '--padding-default-top': spacingSchema,
    '--padding-default-left': spacingSchema,
    '--padding-default-right': spacingSchema,
    // xs
    '--padding-xs-bottom': spacingSchema,
    '--padding-xs-top': spacingSchema,
    '--padding-xs-left': spacingSchema,
    '--padding-xs-right': spacingSchema,
    // md
    '--padding-md-bottom': spacingSchema,
    '--padding-md-top': spacingSchema,
    '--padding-md-left': spacingSchema,
    '--padding-md-right': spacingSchema,
    // lg
    '--padding-lg-bottom': spacingSchema,
    '--padding-lg-top': spacingSchema,
    '--padding-lg-left': spacingSchema,
    '--padding-lg-right': spacingSchema,
  }).noUnknown(),
}).noUnknown()

export type PaddingStyle = InferType<typeof paddingStyleSchema>
