import { InferType, object, string } from 'yup'
import { fontTextStyleSchema } from './fontStyleSchema'
import { cssTextDecorationStyleSchema } from './common/cssTextDecorationStyleSchema'
import { cssTextDecorationLineSchema } from './common/cssTextDecorationLineSchema'
import { cssFontWeightSchema } from './common/cssFontWeightSchema'

export const linkStyleSchema = object({
  data: object({
    default: object({
      '--link-default-color-default': string(),
      '--link-default-weight-default': cssFontWeightSchema,
      '--link-default-style-default': fontTextStyleSchema,
      '--link-default-background-color-default': string(),
      '--link-default-text-decoration-color-default': string(),
      '--link-default-text-decoration-style-default':
        cssTextDecorationStyleSchema,
      '--link-default-text-decoration-line-default':
        cssTextDecorationLineSchema,
      '--link-xs-color-default': string(),
      '--link-xs-weight-default': cssFontWeightSchema,
      '--link-xs-style-default': fontTextStyleSchema,
      '--link-xs-background-color-default': string(),
      '--link-xs-text-decoration-color-default': string(),
      '--link-xs-text-decoration-style-default': cssTextDecorationStyleSchema,
      '--link-xs-text-decoration-line-default': cssTextDecorationLineSchema,
      '--link-md-color-default': string(),
      '--link-md-weight-default': cssFontWeightSchema,
      '--link-md-style-default': fontTextStyleSchema,
      '--link-md-background-color-default': string(),
      '--link-md-text-decoration-color-default': string(),
      '--link-md-text-decoration-style-default': cssTextDecorationStyleSchema,
      '--link-md-text-decoration-line-default': cssTextDecorationLineSchema,
      '--link-lg-color-default': string(),
      '--link-lg-weight-default': cssFontWeightSchema,
      '--link-lg-style-default': fontTextStyleSchema,
      '--link-lg-background-color-default': string(),
      '--link-lg-text-decoration-color-default': string(),
      '--link-lg-text-decoration-style-default': cssTextDecorationStyleSchema,
      '--link-lg-text-decoration-line-default': cssTextDecorationLineSchema,
    })
      .noUnknown()
      .optional(),
    hover: object({
      '--link-default-color-hover': string(),
      '--link-default-weight-hover': cssFontWeightSchema,
      '--link-default-style-hover': fontTextStyleSchema,
      '--link-default-background-color-hover': string(),
      '--link-default-text-decoration-color-hover': string(),
      '--link-default-text-decoration-style-hover':
        cssTextDecorationStyleSchema,
      '--link-default-text-decoration-line-hover': cssTextDecorationLineSchema,
      '--link-xs-color-hover': string(),
      '--link-xs-weight-hover': cssFontWeightSchema,
      '--link-xs-style-hover': fontTextStyleSchema,
      '--link-xs-background-color-hover': string(),
      '--link-xs-text-decoration-color-hover': string(),
      '--link-xs-text-decoration-style-hover': cssTextDecorationStyleSchema,
      '--link-xs-text-decoration-line-hover': cssTextDecorationLineSchema,
      '--link-md-color-hover': string(),
      '--link-md-weight-hover': cssFontWeightSchema,
      '--link-md-style-hover': fontTextStyleSchema,
      '--link-md-background-color-hover': string(),
      '--link-md-text-decoration-color-hover': string(),
      '--link-md-text-decoration-style-hover': cssTextDecorationStyleSchema,
      '--link-md-text-decoration-line-hover': cssTextDecorationLineSchema,
      '--link-lg-color-hover': string(),
      '--link-lg-weight-hover': cssFontWeightSchema,
      '--link-lg-style-hover': fontTextStyleSchema,
      '--link-lg-background-color-hover': string(),
      '--link-lg-text-decoration-color-hover': string(),
      '--link-lg-text-decoration-style-hover': cssTextDecorationStyleSchema,
      '--link-lg-text-decoration-line-hover': cssTextDecorationLineSchema,
    })
      .noUnknown()
      .optional(),
    active: object({
      '--link-default-color-active': string(),
      '--link-default-weight-active': cssFontWeightSchema,
      '--link-default-style-active': fontTextStyleSchema,
      '--link-default-background-color-active': string(),
      '--link-default-text-decoration-color-active': string(),
      '--link-default-text-decoration-style-active':
        cssTextDecorationStyleSchema,
      '--link-default-text-decoration-line-active': cssTextDecorationLineSchema,
      '--link-xs-color-active': string(),
      '--link-xs-weight-active': cssFontWeightSchema,
      '--link-xs-style-active': fontTextStyleSchema,
      '--link-xs-background-color-active': string(),
      '--link-xs-text-decoration-color-active': string(),
      '--link-xs-text-decoration-style-active': cssTextDecorationStyleSchema,
      '--link-xs-text-decoration-line-active': cssTextDecorationLineSchema,
      '--link-md-color-active': string(),
      '--link-md-weight-active': cssFontWeightSchema,
      '--link-md-style-active': fontTextStyleSchema,
      '--link-md-background-color-active': string(),
      '--link-md-text-decoration-color-active': string(),
      '--link-md-text-decoration-style-active': cssTextDecorationStyleSchema,
      '--link-md-text-decoration-line-active': cssTextDecorationLineSchema,
      '--link-lg-color-active': string(),
      '--link-lg-weight-active': cssFontWeightSchema,
      '--link-lg-style-active': fontTextStyleSchema,
      '--link-lg-background-color-active': string(),
      '--link-lg-text-decoration-color-active': string(),
      '--link-lg-text-decoration-style-active': cssTextDecorationStyleSchema,
      '--link-lg-text-decoration-line-active': cssTextDecorationLineSchema,
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown()

export type LinkStyle = InferType<typeof linkStyleSchema>
