import { InferType, object } from 'yup'
import { cssAlignmentSchema } from './common/cssAlignmentSchema'

export const horizontalAlignmentStyleSchema = object({
  data: object({
    '--horizontal-alignment-default': cssAlignmentSchema,
    '--horizontal-alignment-xs': cssAlignmentSchema,
    '--horizontal-alignment-md': cssAlignmentSchema,
    '--horizontal-alignment-lg': cssAlignmentSchema,
  }).noUnknown(),
}).noUnknown()

export type HorizontalAlignmentStyle = InferType<
  typeof horizontalAlignmentStyleSchema
>
