import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

if (dayjs) {
  dayjs.extend(utc)
}

type NormalizeDataOptions = {
  useUTC?: boolean
}

export function normalizeDate(
  date?: string,
  options: NormalizeDataOptions = {},
): string {
  const { useUTC } = options
  if (!date) {
    return '-'
  }
  let dayjsData = dayjs(date)

  if (useUTC) {
    dayjsData = dayjsData.utc()
  }
  return dayjsData.format('MMM D, YYYY HH:mm') // display
}
