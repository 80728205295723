import React from 'react'
import Block from '../../../components/Block/Block'
import clsx from 'clsx'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { Container } from '../../../components/Container/Container'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Text from '../../../components/Text/Text'
import { FAQ3Block } from '@sceneio/schemas/lib/types'
import Button from '../../../components/Button/Button'
import { buttonSampleFactory, fAQ3DefaultConfig } from './fAQ3Meta'
import Grid from '../../../components/Grid/Grid'
import Card from '../../../components/Card/Card'
import { editorItemsControlsFactory } from '../../../tools/editor/editor'

function FAQ3({
  id,
  components: {
    block,
    topHeading,
    topText,
    cards,
    bottomHeading,
    bottomText,
    buttons,
  },
  editor,
  preview,
}: FAQ3Block = fAQ3DefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const fAQClass = clsx('sc-faq-3', className)

  return (
    <Block className={fAQClass} id={id} editor={editor} {...restBlockProps}>
      <Text
        {...topHeading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'topHeading',
        }}
        cid={resolveComponentId(id, 'topHeading')}
      />
      <Text
        {...topText}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'topText',
        }}
        cid={resolveComponentId(id, 'topText')}
      />
      <Grid
        {...cards}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'cards',
        }}
        items={cards?.items?.map((item, index) => (
          <Card
            key={item.id}
            editor={{
              ...editor?.component,
              blockId: id,
              id: `cards.items[${index}]`,
            }}
            cid={resolveComponentId(id, `cards.items[${index}]`)}
            preview={preview}
            {...item}
          />
        ))}
      />

      <Text
        {...bottomHeading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'bottomHeading',
        }}
        cid={resolveComponentId(id, 'bottomHeading')}
      />
      <Text
        {...bottomText}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'bottomText',
        }}
        cid={resolveComponentId(id, 'bottomText')}
      />
      <Container
        {...buttons}
        className="bui-flex-wrap bui-w-100"
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'buttons',
        }}
        name="componentArray"
      >
        {buttons?.items?.map(
          (
            { id: buttonId, className: buttonClassname, ...restButtonProps },
            index,
          ) => (
            <Button
              editor={{
                ...editor?.component,
                blockId: id,
                id: `buttons.items[${index}]`,
                wysiwyg: {
                  type: 'ITEMS',
                  ...editorItemsControlsFactory({
                    itemsArr: buttons.items || [],
                    itemsId: `buttons.items`,
                    itemFactory: buttonSampleFactory,
                    index: index,
                  }),
                },
              }}
              className={clsx('sc-faq-3__button', buttonClassname)}
              key={buttonId}
              cid={resolveComponentId(id, `button-${index}`)}
              {...restButtonProps}
            />
          ),
        )}
      </Container>
    </Block>
  )
}

export const fAQ3Plugins: PluginsType = {
  js: ['animation'],
  css: [],
}

export default FAQ3
