import type { SVGProps } from 'react'
const SvgRadiusTr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      fillRule="evenodd"
      d="M6 3.5a7.5 7.5 0 0 1 7.5 7.5v3a.5.5 0 0 0 1 0v-3A8.5 8.5 0 0 0 6 2.5H3a.5.5 0 0 0 0 1z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgRadiusTr
