import {
  $applyNodeReplacement,
  EditorConfig,
  ElementNode,
  LexicalNode,
  ParagraphNode,
  SerializedElementNode,
  SerializedParagraphNode,
} from 'lexical'

const type = 'array-item'

export class ArrayItemNode extends ParagraphNode {
  static getType() {
    return type
  }

  static clone(node: ArrayItemNode) {
    return new ArrayItemNode(node.__key)
  }

  static importJSON(serializedNode: SerializedElementNode): ParagraphNode {
    const node = $createArrayItemNode()
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }

  createDOM(config: EditorConfig) {
    return document.createElement('span')
  }

  exportJSON(): SerializedElementNode {
    return {
      ...super.exportJSON(),
      type: type,
      version: 1,
    }
  }
}

export function $createArrayItemNode(): ArrayItemNode {
  return $applyNodeReplacement(new ArrayItemNode())
}
