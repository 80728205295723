import clsx from 'clsx'
import { CSSProperties, ReactNode } from 'react'
import * as Form from '@radix-ui/react-form'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { NumericInputBody } from './components/NumericInputBody'
import { ClearButton } from '../../ClearButton/ClearButton'
import { useFormContext } from '../../../form/context/FormContext'

export type UnitType =
  | 'px'
  | 'rem'
  | 'em'
  | '-'
  | '%'
  | 'vw'
  | 'vh'
  | 'auto'
  | 'full width'

export type RHFNumericInputPropType = {
  label?: ReactNode | string
  name: string
  onChange?: (arg0: string | number | null) => void
  onUnitChange?: (arg0: string | number | null) => void
  min?: number
  max?: number
  className?: string
  inputClassName?: string
  style?: CSSProperties
  disabled?: boolean
  hidden?: boolean
  required?: boolean
  step?: number
  pattern?: RegExp
  unit?: UnitType
  units?: UnitType[]
  defaultUnit?: string
  value?: string
  isClearable?: boolean
  fallbackValue?: string | number
  unitsAsValue?: string[]
  placeholder?: string
  allowNegativeValue?: boolean
}

export const RHFNumericInput = ({
  label,
  name,
  min,
  max,
  className,
  inputClassName,
  disabled = false,
  style,
  hidden = false,
  defaultUnit,
  required,
  units,
  step,
  onChange,
  onUnitChange,
  value,
  fallbackValue,
  isClearable = false,
  allowNegativeValue = false,
  unitsAsValue = [],
  placeholder,
}: RHFNumericInputPropType) => {
  const { setValue, placeholders, values, error, unregister, forceBreakpoint } =
    useFormContext({
      name,
    })
  const containerClasses = clsx(
    'sc-rhf-numeric-input tw-container tw-text-xs',
    {
      'tw-hidden': hidden,
    },
    className,
  )

  return (
    <Form.Field className={containerClasses} style={style} name={name}>
      <Form.Label
        className={clsx(
          'tw-bg-form-field-bg tw-flex tw-rounded tw-pl-2 tw-pr-1 tw-py-2 tw-relative hover:tw-outline hover:tw-outline-1 hover:tw-outline-offset-[-1px] hover:tw-outline-border-color focus-within:tw-outline-border-color focus-within:tw-outline-1 focus-within:tw-outline-offset-[-1px] focus-within:tw-outline',
          {
            'tw-bg-white': forceBreakpoint && !name.includes(forceBreakpoint),
          },
        )}
      >
        {label && (
          <span className={clsx('tw-text-label-color tw-pr-2', {})}>
            {label}
          </span>
        )}

        <NumericInputBody
          label={label}
          name={name}
          min={min}
          max={max}
          inputClassName={inputClassName}
          placeholder={placeholder ?? placeholders[name]}
          disabled={disabled}
          hidden={hidden}
          defaultUnit={defaultUnit}
          required={required}
          units={units}
          step={step}
          allowNegativeValue={allowNegativeValue}
          inputValue={value ?? values[name]}
          fallbackValue={fallbackValue}
          unitsAsValue={unitsAsValue}
          onUnitChange={onUnitChange}
          onChange={(val) => {
            if (!val) {
              unregister([name])
              return
            }
            if (onChange) {
              onChange(val)
            } else {
              setValue(name, val)
            }
          }}
        />
        {isClearable && values[name]?.toString() ? (
          <ClearButton name={name} />
        ) : null}
      </Form.Label>
      {error && <ErrorMessage message={error.message} />}
    </Form.Field>
  )
}
