import { InferType, object } from 'yup'
import { spacingSchema } from './common/spacingSchema'

export const marginStyleSchema = object({
  data: object({
    // default
    '--margin-default-bottom': spacingSchema,
    '--margin-default-top': spacingSchema,
    '--margin-default-left': spacingSchema,
    '--margin-default-right': spacingSchema,
    // xs
    '--margin-xs-bottom': spacingSchema,
    '--margin-xs-top': spacingSchema,
    '--margin-xs-left': spacingSchema,
    '--margin-xs-right': spacingSchema,
    // md
    '--margin-md-bottom': spacingSchema,
    '--margin-md-top': spacingSchema,
    '--margin-md-left': spacingSchema,
    '--margin-md-right': spacingSchema,
    // lg
    '--margin-lg-bottom': spacingSchema,
    '--margin-lg-top': spacingSchema,
    '--margin-lg-left': spacingSchema,
    '--margin-lg-right': spacingSchema,
  }).noUnknown(),
}).noUnknown()

export type MarginStyle = InferType<typeof marginStyleSchema>
