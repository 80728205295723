import { BreakpointEnum } from './breakpoints'
import { resolveBreakpointOverlaps } from './resolveBreakpointOverlaps'
import clsx from 'clsx'

// Resolves image classes for a default breakpoint
// parameters to override available breakpoints are optional

type ResolveDefaultClassesArgsType = {
  breakpointsArr: BreakpointEnum[]
  selectedBreakpoints?: BreakpointEnum[]
  breakpoints?: BreakpointEnum[]
  elementClassName?: string
}
export const resolveDefaultClasses = ({
  breakpointsArr,
  selectedBreakpoints,
  breakpoints,
  elementClassName = 'sc-image__wrapper',
}: ResolveDefaultClassesArgsType) => {
  return breakpointsArr.reduce<string>((acc, breakpoint) => {
    const breakpointOverlaps = resolveBreakpointOverlaps(
      selectedBreakpoints,
      breakpoints,
    )
    if (breakpoint === 'default') {
      return acc
    }

    const overlaps = breakpointOverlaps[breakpoint]

    if (!overlaps) {
      return acc
    }

    const classes = overlaps.reduce(
      (classesAcc, classesVal) =>
        clsx(classesAcc, `${elementClassName}--hide-${classesVal}`),
      '',
    )

    return clsx(acc, classes)
  }, '')
}
