import type { SVGProps } from 'react'
const SvgAddBlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.75 3.125c0-.259.21-.469.469-.469H15.78a.469.469 0 1 1 0 .938H4.22a.47.47 0 0 1-.469-.469M10 7.656c.259 0 .469.21.469.469v1.406h1.406a.469.469 0 1 1 0 .938h-1.406v1.406a.469.469 0 0 1-.938 0v-1.406H8.125a.469.469 0 0 1 0-.938h1.406V8.125c0-.259.21-.469.469-.469"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.375 5a.625.625 0 0 0-.625.625v8.75c0 .345.28.625.625.625h11.25c.345 0 .625-.28.625-.625v-8.75A.625.625 0 0 0 15.625 5zm.313.938v8.125h10.625V5.937z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M4.219 16.406a.469.469 0 0 0 0 .938H15.78a.469.469 0 0 0 0-.938z"
    />
  </svg>
)
export default SvgAddBlockIcon
