import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { heroWithForm2DefaultConfig } from './heroWithForm2Meta'
import HeroWithForm1 from '../HeroWithForm1/HeroWithForm1'
import { HeroWithForm2Block } from '@sceneio/schemas/lib/types'

function HeroWithForm2(props: HeroWithForm2Block = heroWithForm2DefaultConfig) {
  return <HeroWithForm1 {...props} />
}

export const heroWithForm2Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default HeroWithForm2
