import React from 'react'
import clsx from 'clsx'
import type { ImageType } from './imageType'
import { resolveDefaultImageClasses } from './helpers/resolveDefaultImageClasses'
import { BreakpointEnum } from './constants/breakpoints'
import { resolveBreakpointImageClasses } from './helpers/resolveBreakpointImageClasses'
import { removeUndefinedValues } from '@sceneio/tools'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { imageDefaults } from './imageMeta'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { ImageComponent } from '@sceneio/schemas/lib/types'
import NativeLink from '../NativeLink/NativeLink'

const IMAGE_DEFAULTS = imageDefaults.config

const Image = ({
  responsiveConfig,
  className,
  padding,
  margin,
  boxShadow,
  border,
  style,
  link,
  dataAttributes = IMAGE_DEFAULTS.dataAttributes,
  isAnimated = IMAGE_DEFAULTS.isAnimated,
  editor, // TODO editor types
  show = IMAGE_DEFAULTS.show,
  // TODO move cover to transformation options
  cover,
  transformationOptions = {},
}: ImageComponent) => {
  if (!responsiveConfig || !Object.keys(responsiveConfig).length || !show) {
    return null
  }

  const computedTransformationOptions: ImageComponent['transformationOptions'] =
    removeUndefinedValues({
      ...(cover && {
        cover: 'WxH',
      }),
      ...transformationOptions,
    })

  const imageTransformationStrings = Object.keys(
    computedTransformationOptions,
  ).map(
    (key) =>
      `${key}=${
        computedTransformationOptions[
          key as keyof ImageComponent['transformationOptions']
        ]
      }`,
  )

  // TODO twicpics HOTFIX, without specified exact width twicpics cannot resolve right dimension during processing and goes to infinite calculation loop
  const previewImageTransformationStrings = imageTransformationStrings?.filter(
    (transformation) => transformation !== 'cover=WxH',
  )

  return (
    <>
      {Object.entries(responsiveConfig).map(([key, imageBreakpoint]) => {
        if (!imageBreakpoint.src) {
          return null
        }

        return (
          <div
            data-component-id={editor?.id}
            key={key}
            className={clsx(
              'sc-image__wrapper',
              {
                'sc-image__wrapper--cover': cover,
                [resolveBreakpointImageClasses({
                  breakpoint: key as BreakpointEnum,
                })]: key !== 'default',
                [resolveDefaultImageClasses({
                  breakpointsArr: (
                    Object.keys(responsiveConfig) as BreakpointEnum[]
                  ).filter((bp) => responsiveConfig[bp]?.src),
                })]: key === 'default',
              },
              editor?.resolveClassName?.({
                blockId: editor.blockId,
                componentId: editor.id,
              }),
              className,
            )}
            style={{
              ...style,
              ...getRootLevelCssVariables({
                cssVariables: {
                  padding,
                  margin,
                  border,
                },
                prefix: 'image',
              }),
              ...getShadowCssVariables({
                shadow: boxShadow,
                shadowType: 'box',
                prefix: 'image',
              }),
            }}
          >
            <NativeLink {...link}>
              <img
                src={`${imageBreakpoint.src}?twic=v1/output=preview${
                  previewImageTransformationStrings.length
                    ? `/${previewImageTransformationStrings.join('/')}`
                    : ''
                }`}
                data-twic-src={twicPicsSourceUrlResolver(imageBreakpoint.src)}
                {...(imageTransformationStrings.length && {
                  'data-twic-transform': imageTransformationStrings.join('/'),
                })}
                alt={imageBreakpoint.alt}
                className={clsx('sc-image sc-image--fluid', {
                  'sc-image--cover': cover,
                })}
                // srcSet={imageBreakpoint.srcSet?.join(', ')}
                // sizes={imageBreakpoint.sizes}
                width={imageBreakpoint.width}
                height={imageBreakpoint.height}
                data-plugin-animation={isAnimated ? true : null}
                {...dataAttributes}
              />
              {responsiveConfig.default?.src && (
                <noscript>
                  <img
                    className="sc-image sc-image--fluid"
                    src={responsiveConfig.default.src}
                  />
                </noscript>
              )}
            </NativeLink>
          </div>
        )
      })}
    </>
  )
}

export const plugins = {
  js: ['animation'],
  css: [],
}
export default Image
