import React from 'react'
import clsx from 'clsx'
import { Container } from '../../../../components/Container/Container'
import Button from '../../../../components/Button/Button'
import resolveComponentId from '../../../../tools/helpers/resolveComponentId'
import { editorItemsControlsFactory } from '../../../../tools/editor/editor'
import { buttonSampleFactory } from '../headerVariant1Meta'
import { HeaderVariant1Block } from '@sceneio/schemas/lib/types'

export const NavButtons = ({
  blockId,
  buttons,
  showMenu,
  showButtons,
  editor,
  className,
}: {
  blockId?: string
  buttons: HeaderVariant1Block['components']['buttons']
  showMenu: boolean
  showButtons: boolean
  editor: HeaderVariant1Block['editor']
  className?: string
}) => {
  return (
    <Container
      className={clsx(
        'sc-header__buttons',
        {
          'bui-flex-grow-1': !showMenu,
        },
        className,
      )}
      editor={{
        ...editor?.component,
        blockId,
        id: 'buttons',
      }}
      name={'componentArray'}
      {...buttons}
      show={showButtons}
    >
      {buttons?.items?.map(
        (
          {
            id: buttonId,
            childrenWrapProps = {},
            className: buttonClassName,
            ...restButtonProps
          },
          index,
        ) => (
          <Button
            isAnimated={false}
            className={clsx('sc-header__button', buttonClassName)}
            childrenWrapProps={{
              ...childrenWrapProps,
            }}
            key={buttonId || index}
            cid={resolveComponentId(blockId, `button-${index}`)}
            {...restButtonProps}
            editor={{
              ...editor?.component,
              blockId,
              id: `buttons.items[${index}]`,
              wysiwyg: {
                type: 'ITEMS',
                ...editorItemsControlsFactory({
                  itemsArr: buttons.items || [],
                  itemsId: `buttons.items`,
                  itemFactory: buttonSampleFactory,
                  index: index,
                }),
              },
            }}
          />
        ),
      )}
    </Container>
  )
}
