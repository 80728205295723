import { AppDispatch } from '../store'
import { createCid } from './createCid'

/**
 * This function is used to add a cid to the data object before dispatching the thunk
 */
export function createAppendCidThunk<ThunkType extends (...args: any[]) => any>(
  thunkFn: ThunkType,
) {
  return (data: Parameters<ThunkType>[0], cid: string = createCid()) =>
    (dispatch: AppDispatch) => {
      dispatch(
        thunkFn({ ...data, queryVariables: { ...data.queryVariables, cid } }),
      )
    }
}
