import { ReferencesType } from '@sceneio/referencing-tools/lib/referencesTypes'
import { SnippetType } from './snippetsTypes'
import type {
  BlockType,
  ContentType,
} from '../../../libs/content/store/lib/features/content/contentSliceTypes'
import { getReferencePath } from '@sceneio/referencing-tools'

export const preprocessSnippetFromEntity = ({
  snippet,
  entity,
  snippets,
  snippetReferenceBasePath,
}: {
  snippet: SnippetType & { snippetTargetPath: string }
  entity: BlockType | SnippetType | ContentType
  snippets: SnippetType[]
  snippetReferenceBasePath: 'data.value' | 'meta'
}) => {
  const { references: entityReferences = [] } = entity
  const { snippetTargetPath, ...restSnippet } = snippet

  const snippetTargetReferencePath = getReferencePath({
    configPath: snippetTargetPath,
    data: entity,
  })

  const processedReferences = entityReferences.reduce(
    (acc, val) => {
      if (
        val.typeTo === 'SNIPPET' &&
        val.pathFrom.startsWith(snippetTargetReferencePath)
      ) {
        const snippet = snippets.find((snip) => snip.id === val.idTo)

        const referencePath = val.pathFrom.replace(
          `${snippetTargetReferencePath}.`,
          '',
        )
        if (!snippet) {
          return acc
        }
        acc = {
          ...acc,
          ['snippetReferences']: [
            ...(acc?.['snippetReferences'] || []),
            {
              ...val,
              idTo: snippet.id,
              pathFrom: `${snippetReferenceBasePath}.${referencePath}`,
            },
          ],
        }
        return acc
      }
      acc = {
        ...acc,
        ['entityReferencesToKeep']: [
          ...(acc?.['entityReferencesToKeep'] || []),
          val,
        ],
      }
      return acc
    },
    {
      entityReferencesToKeep: [],
      snippetReferences: [],
    } as {
      entityReferencesToKeep?: ReferencesType
      snippetReferences?: ReferencesType
    },
  )
  return {
    entity: {
      ...entity,
      references: processedReferences?.entityReferencesToKeep,
    },
    snippet: {
      ...restSnippet,
      references: processedReferences.snippetReferences,
    },
  }
}
