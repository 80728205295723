export const ALIGNMENT_NATIVE_OPTIONS = {
  horizontal: [
    { value: 'left', label: 'Align left', icon: 'TextAlignLeft' },
    { value: 'center', label: 'Align center', icon: 'TextAlignCenter' },
    { value: 'right', label: 'Align right', icon: 'TextAlignRight' },
    { value: 'justify', label: 'Justify', icon: 'TextAlignJustify' },
  ],
  vertical: [
    { value: 'top', label: 'Align top', icon: 'AlignTopSimple' },
    {
      value: 'middle',
      label: 'Align middle',
      icon: 'AlignCenterVerticalSimple',
    },
    { value: 'bottom', label: 'Align bottom', icon: 'AlignBottomSimple' },
  ],
}

export const ALIGNMENT_FLEX_OPTIONS = {
  horizontal: [
    { value: 'flex-start', label: 'Align left', icon: 'AlignLeft' },
    { value: 'center', label: 'Align center', icon: 'AlignCenterHorizontal' },
    { value: 'flex-end', label: 'Align right', icon: 'AlignRight' },
  ],
  vertical: [
    { value: 'flex-start', label: 'Align top', icon: 'AlignTop' },
    { value: 'center', label: 'Align center', icon: 'AlignCenterVertical' },
    { value: 'flex-end', label: 'Align bottom', icon: 'AlignBottom' },
  ],
}
