import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { Container } from '../Container/Container'
import Image from '../Image/Image'
import Text from '../Text/Text'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { TimelineItemComponent } from '@sceneio/schemas/lib/types'

function TimelineItem({
  style,
  className,
  show,
  cid,
  editor,
  swapOrder,
  padding,
  margin,
  lineThickness,
  lineColor,
  components: { heading, text, image, contentContainer },
}: TimelineItemComponent) {
  if (!show) {
    return null
  }

  return (
    <div
      data-component-id={editor?.id}
      className={clsx(
        'sc-timeline-item',
        { 'sc-timeline-item--swap-order': swapOrder },
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        className,
      )}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            lineThickness,
            lineColor,
            padding,
            margin,
          },
          prefix: 'timeline-item',
        }),
      }}
    >
      <div className="sc-timeline__image-wrapper">
        <Image
          {...image}
          isAnimated={true}
          transformationOptions={{ cover: '40x40' }}
          className={clsx('sc-timeline__image', image?.className)}
          editor={{
            ...editor,
            id: `${editor?.id}.image`,
          }}
        />
      </div>
      <div className="sc-timeline__content-wrapper">
        <Container
          {...contentContainer}
          name="group"
          editor={{
            ...editor,
            id: `${editor?.id}.contentContainer`,
          }}
          flow={{ data: { '--flow-default': 'column' } }}
        >
          <Text
            {...heading}
            editor={{
              ...editor,
              id: `${editor?.id}.heading`,
            }}
            cid={resolveComponentId(cid, 'heading')}
          />
          <Text
            {...text}
            editor={{
              ...editor,
              id: `${editor?.id}.text`,
            }}
            cid={resolveComponentId(cid, 'text')}
          />
        </Container>
      </div>
    </div>
  )
}

export default TimelineItem

export const plugins: PluginsType = {
  js: ['animation'],
  css: [],
}
