import type { SVGProps } from 'react'
const SvgRadiusBl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      fillRule="evenodd"
      d="M11 13.5A7.5 7.5 0 0 1 3.5 6V3a.5.5 0 0 0-1 0v3a8.5 8.5 0 0 0 8.5 8.5h3a.5.5 0 0 0 0-1z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgRadiusBl
