import React from 'react'
import HeroWithForm1 from './HeroWithForm1'
import { heroWithForm1DefaultConfig } from './heroWithForm1Meta'

const HeroWithForm1Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroWithForm1DefaultConfig

  return <HeroWithForm1 {...defaultData} />
}

export default HeroWithForm1Preview
