import { SliderComponent } from '@sceneio/schemas/lib/types'

export const getActiveSlideIndexInEditor = ({
  editor,
  slidesLength,
}: {
  editor: SliderComponent['editor']
  slidesLength: number
}) => {
  if (!editor || !editor?.id || !editor?.blockId) {
    return 0
  }

  const persistentComponentSelection =
    editor.persistentComponentSelection as Record<string, any>

  if (!persistentComponentSelection?.[editor.blockId]) {
    return 0
  }

  const selectedComponents = persistentComponentSelection[
    editor.blockId
  ] as Record<string, string>

  const selectedId = selectedComponents?.[editor.id]

  if (!selectedId) {
    return 0
  }
  const slideItemPattern = /(slides)\.items\[(\d+)\]/
  const selectedSlideIndex = selectedId.match(slideItemPattern)?.[2]
  if (
    !selectedSlideIndex ||
    (selectedSlideIndex && Number(selectedSlideIndex) >= slidesLength)
  ) {
    return 0
  }
  return Number(selectedSlideIndex)
}

export const getActivePaginationBulletInEditor = ({
  activeSlideIndex,
  itemsPerView,
  slidesLength,
}: {
  activeSlideIndex: number
  itemsPerView: number
  slidesLength: number
}) => {
  const slideGroups = []
  for (let i = 0; i <= slidesLength - itemsPerView; i++) {
    const group = Array.from({ length: itemsPerView }, (_, j) => i + j)
    slideGroups.push(group)
  }

  if (activeSlideIndex < 0 || activeSlideIndex >= slidesLength) {
    return -1
  }

  for (let i = 0; i < slideGroups.length; i++) {
    if (slideGroups[i].includes(activeSlideIndex)) {
      return i
    }
  }

  return -1
}
