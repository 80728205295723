import React from 'react'
import type { MediaBlock } from '@sceneio/schemas/lib/types'
import Block from '../../components/Block/Block'
import Media from '../../components/Media/Media'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { mediaDefaultConfig } from './mediaMeta'

function MediaBlock({
  id,
  components: { block, media },
  editor,
  preview,
}: MediaBlock = mediaDefaultConfig) {
  const { className, ...restBlockProps } = block || {}

  return (
    <Block className={className} id={id} editor={editor} {...restBlockProps}>
      <Media
        {...media}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'media',
        }}
        components={{
          ...media.components,
          ...('video' in media.components
            ? {
                ...media.components.video,
                usePlayer: true,
              }
            : undefined),
          image: {
            ...('image' in media.components && {
              ...media.components.image,
              isAnimated: true,
            }),
          },
        }}
        cid={resolveComponentId(id, 'media')}
        preview={preview}
      />
    </Block>
  )
}

export const mediaPlugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default MediaBlock
