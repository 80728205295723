import React from 'react'
import { HeaderVariant1Block } from '@sceneio/schemas/lib/types'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Button from '../../../components/Button/Button'
import Image from '../../../components/Image/Image'
import { PluginsType } from '../../../scripts/externalPluginsTypes'
import { headerVariant1DefaultConfig } from './headerVariant1Meta'
import { Navbar } from './components/Navbar'
import { NavButtons } from './components/NavButtons'
import { TopBanner } from './components/TopBanner'

export function HeaderVariant1({
  id,
  components: { block, menu, buttons, logo, toggler, topBanner },
  preview,
  editor,
}: HeaderVariant1Block = headerVariant1DefaultConfig) {
  const headerClassName = clsx(
    'sc-header sc-header--v1',
    {
      'sc-header--block-view': preview,
    },
    block?.className,
  )

  const showbuttons = Boolean(buttons?.items?.length && buttons?.show)
  const showMenu = Boolean(menu?.items?.length && menu?.show)

  const menuDisplayTarget = menu?.displayTarget || {}
  const btnsDisplayTarget = buttons?.displayTarget || {}

  return (
    <Block
      as="header"
      id={id}
      dataAttributes={{
        'data-plugin-header': true,
      }}
      editor={editor}
      beforeContainerEl={
        <TopBanner editor={editor} config={topBanner} blockId={id} />
      }
      {...block}
      className={headerClassName}
    >
      <nav
        className={clsx(
          'sc-header__main-navbar sc-header__main-navbar--expand-lg navbar',
          {
            'sc-header__main-navbar--xs-collapsible':
              btnsDisplayTarget['xs'] === 'mobile' ||
              menuDisplayTarget['xs'] === 'mobile',
            'sc-header__main-navbar--md-collapsible':
              btnsDisplayTarget['md'] === 'mobile' ||
              menuDisplayTarget['md'] === 'mobile',
          },
        )}
      >
        {logo?.show && (
          <div className="sc-header__container bui-d-flex">
            <a href={(preview ? null : '/') as any}>
              <Image
                {...logo}
                className={clsx('sc-header__logo', logo.className)}
                isAnimated={false}
                editor={{
                  ...editor?.component,
                  blockId: id,
                  id: 'logo',
                }}
              />
            </a>
          </div>
        )}
        <Navbar
          blockId={id}
          menu={menu}
          showMenu={showMenu}
          preview={preview}
          editor={editor}
          className={clsx('bui-d-lg-none', {
            'bui-d-flex': menuDisplayTarget['xs'] === 'desktop',
            'bui-d-md-flex': menuDisplayTarget['md'] === 'desktop',
            'bui-d-none': menuDisplayTarget['xs'] === 'mobile',
            'bui-d-md-none': menuDisplayTarget['md'] === 'mobile',
          })}
        />
        <div
          data-plugin-header-menu-collapse
          className={clsx(
            'navbar-collapse collapse sc-header__menu-collapse bui-d-lg-flex',
            {
              'bui-d-none':
                menuDisplayTarget['xs'] === 'desktop' &&
                btnsDisplayTarget['xs'] === 'desktop',
              'bui-d-md-none':
                menuDisplayTarget['md'] === 'desktop' &&
                btnsDisplayTarget['md'] === 'desktop',
            },
          )}
          id={`sc-header-${id}__menu-collapse`}
        >
          <div className="sc-header__menu-container bui-w-100">
            <Navbar
              blockId={id}
              menu={menu}
              showMenu={showMenu}
              preview={preview}
              editor={editor}
              className={clsx('bui-d-lg-flex', {
                'bui-d-flex': menuDisplayTarget['xs'] === 'mobile',
                'bui-d-md-flex': menuDisplayTarget['md'] === 'mobile',
                'bui-d-none': menuDisplayTarget['xs'] === 'desktop',
                'bui-d-md-none': menuDisplayTarget['md'] === 'desktop',
              })}
            />
            <NavButtons
              blockId={id}
              buttons={buttons}
              showMenu={showMenu}
              showButtons={showbuttons}
              editor={editor}
              className={clsx('bui-d-lg-flex', {
                'bui-d-flex': btnsDisplayTarget['xs'] === 'mobile',
                'bui-d-md-flex': btnsDisplayTarget['md'] === 'mobile',
                'bui-d-none': btnsDisplayTarget['xs'] === 'desktop',
                'bui-d-md-none': btnsDisplayTarget['md'] === 'desktop',
              })}
            />
          </div>
        </div>
        <NavButtons
          blockId={id}
          buttons={buttons}
          showMenu={showMenu}
          showButtons={showbuttons}
          editor={editor}
          className={clsx('bui-d-lg-none', {
            'bui-d-flex': btnsDisplayTarget['xs'] === 'desktop',
            'bui-d-md-flex': btnsDisplayTarget['md'] === 'desktop',
            'bui-d-none': btnsDisplayTarget['xs'] === 'mobile',
            'bui-d-md-none': btnsDisplayTarget['md'] === 'mobile',
          })}
        />
        <div
          className={clsx(
            'bui-d-lg-none bui-d-flex bui-align-items-center sc-header__container bui-ms-auto',
            {
              'bui-d-none':
                btnsDisplayTarget['xs'] === 'desktop' &&
                menuDisplayTarget['xs'] === 'desktop',
              'bui-d-md-none':
                btnsDisplayTarget['md'] === 'desktop' &&
                menuDisplayTarget['md'] === 'desktop',
            },
          )}
        >
          <div
            className={clsx(
              'bui-d-flex',
              editor?.component.resolveClassName?.({
                blockId: id,
                componentId: 'toggler',
              }),
            )}
            data-component-id="toggler"
          >
            <Button
              type="button"
              dataAttributes={{
                'data-bs-toggle': 'collapse',
                'data-plugin-header-toggler': true,
                'data-bs-target': `#sc-header-${id}__menu-collapse`,
              }}
              ariaAttributes={{
                'aria-controls': `sc-header-${id}__menu-collapse`,
                'aria-expanded': false,
                'aria-label': 'Toggle navigation',
              }}
              isAnimated={false}
              {...toggler}
            />
          </div>
        </div>
      </nav>
    </Block>
  )
}

export const headerVariant1Plugins: PluginsType = {
  js: ['header', 'drawer', 'portalItems', 'social', 'eshop'],
  css: [],
}
