import React from 'react'
import clsx from 'clsx'
import Block from '../../components/Block/Block'
import TextComponent from '../../components/Text/Text'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { textDefaultConfig } from './textMeta'
import { TextBlock } from '@sceneio/schemas/lib/types'

function Text({
  id,
  components: { block, content },
  editor,
}: TextBlock = textDefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const textClass = clsx(`sc-text-block`, className)

  return (
    <Block id={id} editor={editor} className={textClass} {...restBlockProps}>
      {content?.items?.map(({ id: contentId, ...restTextProps }, index) => (
        <TextComponent
          key={contentId || index}
          cid={resolveComponentId(id, `content-${index}`)}
          {...restTextProps}
          editor={{
            ...editor?.component,
            blockId: id,
            id: `content.items[${index}]`,
          }}
        />
      ))}
    </Block>
  )
}

export const textPlugins: PluginsType = {
  js: ['animation'],
  css: [],
}
export default Text
