import { slugify } from '@sceneio/tools'
import { useFormContext, SNIPPET_TYPE } from '../../form/context/FormContext'

export const isSnippetSlugAvailable = (value = '') => {
  const { snippets = [] } = useFormContext({})
  return !snippets.some(
    (snippet) => slugify(snippet?.name || '') === slugify(value),
  )
}

export const SNIPPET_TYPE_TO_DISPLAY_VALUE_MAP: Record<SNIPPET_TYPE, string> = {
  ATOMIC_COLOR: 'Color',
  BOX_SHADOW: 'Box shadow',
  TEXT_SHADOW: 'Text shadow',
  BUTTON: 'Button',
  TEXT: 'Text',
  STROKE: 'Stroke',
}
