import { BreakpointType } from '../../atoms/BreakpointDropdown/BreakpointDropdown'

export const resolveActiveBackgrounds = ({
  values,
  valuesPath,
  breakpoint,
}: {
  values: Record<string, any>
  valuesPath: string
  breakpoint: BreakpointType | 'default'
}) => {
  const activeBackgrounds = []
  if (values[`${valuesPath}.responsiveConfig.${breakpoint}.color`]) {
    activeBackgrounds.push('color')
  }
  if (values[`${valuesPath}.responsiveConfig.${breakpoint}.overlay`]) {
    activeBackgrounds.push('overlay')
  }
  if (values[`${valuesPath}.responsiveConfig.${breakpoint}.image.src`]) {
    activeBackgrounds.push('image')
  }
  return activeBackgrounds
}
