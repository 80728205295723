import React from 'react'
import { HeaderVariant1Block } from '@sceneio/schemas/lib/types'
import { Container } from '../../../../components/Container/Container'
import { NavList } from './NavList'
import clsx from 'clsx'

export const Navbar = ({
  editor,
  blockId,
  menu,
  showMenu,
  preview,
  className,
}: {
  editor: HeaderVariant1Block['editor']
  blockId?: string
  menu: HeaderVariant1Block['components']['menu']
  showMenu: boolean
  preview?: boolean
  className?: string
}) => {
  return (
    <Container
      as="ul"
      className={clsx('navbar-nav sc-header__menu-nav', className)}
      name="componentArray"
      editor={{
        ...editor?.component,
        blockId,
        id: 'menu',
      }}
      {...menu}
    >
      {showMenu && (
        <NavList
          items={menu?.items}
          linkClassName="tm-header__menu-link"
          preview={preview}
          resolveEditor={(index) => ({
            ...editor?.component,
            blockId,
            id: `menu.items[${index}]`,
            itemsId: 'menu.items',
          })}
        />
      )}
    </Container>
  )
}
