import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { testimonialVariant1DefaultConfig } from './testimonialVariant1Meta'
import Grid from '../../../components/Grid/Grid'
import { TestimonialCard } from './components/TestimonialCard'
import type { TestimonialVariant1Block } from '@sceneio/schemas/lib/types'

function TestimonialVariant1({
  id,
  components: { block, heading, text, testimonials },
  editor,
}: TestimonialVariant1Block = testimonialVariant1DefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const testimonialVariant1Class = clsx('sc-testimonial-variant-1', className)

  return (
    <Block
      className={testimonialVariant1Class}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Grid
        {...testimonials}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'testimonials',
        }}
        items={testimonials?.items?.map((item, index) => (
          <TestimonialCard
            key={item.id}
            index={index}
            config={item}
            blockId={id}
            editor={editor}
          />
        ))}
      />
    </Block>
  )
}

export const testimonialVariant1Plugins: PluginsType = {
  js: ['animation'],
  css: [],
}
export default TestimonialVariant1
