import React from 'react'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { Container } from '../Container/Container'
import { TimelineComponent } from '@sceneio/schemas/lib/types'

function Timeline({ children, ...timelineProps }: TimelineComponent) {
  return (
    <Container
      {...timelineProps}
      flow={{ data: { '--flow-default': 'column' } }}
      name="componentArray"
      className="sc-timeline"
    >
      {children}
    </Container>
  )
}

export default Timeline

export const plugins: PluginsType = {
  js: ['animation'],
  css: [],
}
