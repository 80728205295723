import React from 'react'
import { Hero5Block } from '@sceneio/schemas/lib/types'
import { hero5DefaultConfig } from './hero5Meta'
import Hero5 from './Hero5'

const Hero5Preview = (config: Hero5Block) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : hero5DefaultConfig

  return <Hero5 {...defaultData} />
}

export default Hero5Preview
