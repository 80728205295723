import { InferType, object } from 'yup'
import { cssDimensionSchema } from './common/cssDimensionSchema'

export const gapStyleSchema = object({
  data: object({
    '--row-gap-default': cssDimensionSchema,
    '--column-gap-default': cssDimensionSchema,
    '--row-gap-xs': cssDimensionSchema,
    '--column-gap-xs': cssDimensionSchema,
    '--row-gap-md': cssDimensionSchema,
    '--column-gap-md': cssDimensionSchema,
    '--row-gap-lg': cssDimensionSchema,
    '--column-gap-lg': cssDimensionSchema,
  }).noUnknown(),
}).noUnknown()

export type GapStyle = InferType<typeof gapStyleSchema>
