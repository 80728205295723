import { clsx } from 'clsx'
import * as Form from '@radix-ui/react-form'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from '@sceneio/tools'

type ColorInputPropType = {
  color: string
  isGradient: boolean
  valueToHex: () => string
  restInputProps?: Record<string, any>
  inputClassName?: string
  hidden?: boolean
  placeholder?: string
  onChange?: (arg0: string) => void
  disabled?: boolean
}

function validateColorInput(s: string) {
  const colorRgx =
    /(?:#|0x)(?:[a-fA-F0-9]{3}|[a-fA-F0-9]{6})$\b|(?:rgb|hsl)a?\([^\)]*\)$/

  return colorRgx.test(s)
}

export function ColorInput({
  color,
  isGradient,
  valueToHex,
  restInputProps,
  inputClassName,
  hidden,
  placeholder = 'Color',
  onChange,
  disabled,
}: ColorInputPropType) {
  const [internalValue, setInternalValue] = useState(color || '')

  useEffect(() => {
    setInternalValue(color)
  }, [color])

  const handleOnChange = useCallback(
    debounce((output) => {
      if (!onChange) return
      const isValidColor = validateColorInput(output)
      if (!isValidColor) {
        const isValidHexColor = validateColorInput(
          `#${output.replace('#', '')}`,
        )
        if (isValidHexColor) {
          setInternalValue(`#${output.replace('#', '')}`)
        }
      } else {
        setInternalValue(output)
      }
    }, 250),
    [onChange],
  ) as (value: string) => void

  const handleValidColorChange = () => {
    const isValidColor = validateColorInput(internalValue)
    if (!isValidColor) {
      setInternalValue(color)
    } else if (onChange && color !== internalValue) {
      onChange(internalValue)
    }
  }

  return (
    <div
      className={clsx('tw-flex tw-flex-1', {
        'tw-w-auto': !isGradient,
      })}
    >
      <Form.Control
        {...restInputProps}
        tabIndex={1}
        className={clsx(
          'tw-truncate tw-bg-transparent focus:tw-outline-none tw-px-1 tw-mr-2 tw-flex-1',
          {
            'sc-rhf-color-picker__hex-input': !isGradient,
          },
          inputClassName,
        )}
        hidden={hidden}
        placeholder={isGradient ? placeholder : valueToHex()}
        value={
          internalValue === color && !isGradient ? valueToHex() : internalValue
        }
        onChange={(e) => {
          const value = e.target.value
          const output = value

          setInternalValue(output)
          handleOnChange(output)
        }}
        onBlur={handleValidColorChange}
        onKeyDownCapture={(e) => {
          if (e.key === 'Enter' || e.key === 'Tab') {
            handleValidColorChange()
          }
        }}
        disabled={disabled}
        onFocus={(e) => e.target.select()}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      />
    </div>
  )
}
