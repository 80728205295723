import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { parallaxHeroDefaultConfig } from './parallaxHeroMeta'
import { ParallaxHeroBlock } from '@sceneio/schemas/lib/types'
import Banner from '../Banner/Banner'

function ParallaxHero(props: ParallaxHeroBlock = parallaxHeroDefaultConfig) {
  return <Banner {...props} />
}

export const parallaxHeroPlugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default ParallaxHero
