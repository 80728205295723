import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

if (dayjs) {
  dayjs.extend(relativeTime)
}

export function getTimeFromNow(time?: string): string {
  if (!time) {
    return ''
  }
  return dayjs(time).fromNow() // display
}
