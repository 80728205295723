import React from 'react'
import { heroCarousel1DefaultConfig } from './heroCarousel1Meta'
import { HeroCarousel1Block } from '@sceneio/schemas/lib/types'
import HeroCarousel1 from './HeroCarousel1'

const HeroCarousel1Preview = (config: HeroCarousel1Block) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : heroCarousel1DefaultConfig

  return <HeroCarousel1 {...defaultData} />
}

export default HeroCarousel1Preview
