import type { SVGProps } from 'react'
const SvgReusableBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.728 17.142a1 1 0 0 1 0-1.414l9-9a1 1 0 0 1 1.414 0l9 9a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-1.414 0zm2.121-.707 7.586 7.586 7.586-7.586-7.586-7.586z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgReusableBlock
