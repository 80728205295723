import type { SVGProps } from 'react'
const SvgDropdownIndicatorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="dropdown-indicator-icon_svg__svg"
    viewBox="0 0 8 7"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m3.646 5.354-3-3 .708-.708L4 4.293l2.646-2.647.708.708-3 3L4 5.707z"
    />
  </svg>
)
export default SvgDropdownIndicatorIcon
