export function normalizeGraphqlUrl(url: unknown) {
  if (typeof url !== 'string') {
    throw Error('Url must be a string!')
  }

  if (!url.startsWith('http')) {
    throw Error('URL must start with http prefix!')
  }

  if (url.endsWith('/graphql')) return url

  return `${url.trim().replace(/\/+$/, '')}/graphql`
}
