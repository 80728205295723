import React, { useEffect, useRef, useState } from 'react'
import * as Toolbar from '@radix-ui/react-toolbar'
import { Icon } from '@sceneio/ui-icons'
import {
  BLUR_COMMAND,
  COMMAND_PRIORITY_LOW,
  FOCUS_COMMAND,
  LexicalEditor,
} from 'lexical'
import { autoUpdate, computePosition } from '@floating-ui/dom'
import { mergeRegister } from '@lexical/utils'
import { FloatingItemsToolbar } from './FloatingItemsToolbarPlugin'

export function ItemsToolbar({
  editor,
  onAdd,
  onRemove,
  disableAdd = false,
  disableRemove = false,
  containerRef,
  enableAI,
}: FloatingItemsToolbar & {
  editor: LexicalEditor
}) {
  const [showToolbar, setShowToolbar] = useState(false)
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!showToolbar) {
      return
    }

    const containerElem = containerRef?.current
    const popupCharStylesEditorElem = popupCharStylesEditorRef.current

    if (!containerElem || !popupCharStylesEditorElem) {
      return
    }

    const cleanup = autoUpdate(
      containerElem,
      popupCharStylesEditorElem,
      () => {
        computePosition(containerElem, popupCharStylesEditorElem, {
          placement: 'top',
        }).then((pos) => {
          popupCharStylesEditorElem.style.opacity = '1'
          popupCharStylesEditorElem.style.transform = `translate(${pos.x}px, ${
            pos.y - 10
          }px)`
        })
      },
      { animationFrame: true },
    )

    return () => cleanup()
  }, [showToolbar, containerRef.current, popupCharStylesEditorRef.current])

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setShowToolbar(true)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setShowToolbar(false)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor])

  if (!showToolbar) {
    return null
  }

  return (
    <Toolbar.Root
      ref={popupCharStylesEditorRef}
      className="wysiwyg-toolbar-root"
      aria-label="Items options"
      onMouseDown={(e) => e.preventDefault()} // Prevents the wysiwyg from blurring on click
    >
      {editor.isEditable() && (
        <>
          <Toolbar.ToggleGroup
            className="wysiwyg-toolbar-toggle-group"
            type="multiple"
            aria-label="Item options"
          >
            <Toolbar.ToggleItem
              className="wysiwyg-toolbar-toggle-item"
              value="add"
              data-state={'off'}
              aria-label="Add Item"
              onMouseDown={() => {
                onAdd()
              }}
              disabled={disableAdd}
            >
              <Icon size={24} provider="phosphor" icon="Plus" />
            </Toolbar.ToggleItem>
            <Toolbar.ToggleItem
              className="wysiwyg-toolbar-toggle-item"
              value="remove"
              data-state={'off'}
              aria-label="Remove Item"
              onMouseDown={() => {
                onRemove()
              }}
              disabled={disableRemove}
            >
              <Icon size={24} provider="phosphor" icon="Trash" />
            </Toolbar.ToggleItem>
            {enableAI && (
              <Toolbar.ToggleItem
                className="wysiwyg-toolbar-toggle-item"
                value=""
                aria-label="Muse AI"
                data-state={'off'}
                onMouseDown={() =>
                  document
                    .querySelector('.react-chatbot-kit-chat-container')
                    ?.classList.add('expanded')
                }
              >
                <Icon
                  size={24}
                  style={{ padding: '0.15rem' }}
                  provider="scene"
                  icon="sparkle"
                />
              </Toolbar.ToggleItem>
            )}
          </Toolbar.ToggleGroup>
        </>
      )}
    </Toolbar.Root>
  )
}
