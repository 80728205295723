// TODO temp until BE implemetation

const ALREADY_SUPPORTED_RESOURCES_BY_BACKEND = [
  'project:users:manage',
  'comment:edit',
  'contentModule:edit',
  'content:edit',
  'content:preview',
] as const

// TODO more granular acl resources to discuss and for further BE implementation
const PLANNED_RESOURCES_BY_BACKEND = [
  'project:edit',
  'project:users:manage:viewer',
  'project:users:manage:editor',
] as const

const UI_ACL_RESOURCES = [
  // UI
  'ui:page:export',
  'ui:ai',
  'ui:layoutManager',
  'ui:page:config',
  'ui:page:settings',
  'ui:site:settings',
  'ui:siteMap',
  'ui:page:history',
  'ui:asset:manager',
  'ui:font:manager',
] as const

const CONTENT_EDITOR_ROLE_RESOURCES = [
  ...ALREADY_SUPPORTED_RESOURCES_BY_BACKEND,
  'project:edit',
  'project:users:manage:viewer',
  'project:users:manage:editor',
  'workspace:leave',
  ...UI_ACL_RESOURCES,
] as const

export const VIEWER_ROLE_RESOURCES = [
  'ui:layoutManager',
  'content:preview',
  'comment:edit',
  'project:users:manage',
  'project:users:manage:viewer',
  'workspace:leave',
] as const

const OWNER_ROLE_RESOURCES = [
  'workspace:edit',
  'project:archive',
  'project:restore',
  'project:delete',
  ...ALREADY_SUPPORTED_RESOURCES_BY_BACKEND,
  ...PLANNED_RESOURCES_BY_BACKEND,
  ...UI_ACL_RESOURCES,
] as const

const ALL_ALLOWED_RESOURCES = [
  'workspace:leave',
  ...OWNER_ROLE_RESOURCES,
] as const

export type AclResourceType = (typeof ALL_ALLOWED_RESOURCES)[number]
export type AclResourcesType = AclResourceType[]

export const ALLOWED_ACL_RESOURCES_BY_ROLE = {
  'id:viewer': VIEWER_ROLE_RESOURCES,
  'id:content:editor': CONTENT_EDITOR_ROLE_RESOURCES,
  'id:owner': OWNER_ROLE_RESOURCES,
  'id:developer': ALL_ALLOWED_RESOURCES,
  'id:sys:admin': ALL_ALLOWED_RESOURCES,
  'id:root': ALL_ALLOWED_RESOURCES,
  'id:admin': CONTENT_EDITOR_ROLE_RESOURCES,
}
