import { InferType, array, bool, object } from 'yup'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { masonryGridComponentSchema } from '../../components/masonryGridComponentSchema'
import { galleryItemComponentSchema } from '../../components/galleryItemComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'

export const masonryGalleryBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        enableLightbox: bool(),
      })
      .noUnknown(),
    heading: textComponentSchema,
    text: textComponentSchema,
    galleryItems: masonryGridComponentSchema
      .shape({
        items: array().of(
          galleryItemComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type MasonryGalleryBlock = InferType<typeof masonryGalleryBlockSchema>
