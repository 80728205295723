import { InferType, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { formComponentSchema } from '../../components/formComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'

export const contactFormBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    tagline: textComponentSchema,
    heading: textComponentSchema,
    subheading: textComponentSchema,
    form: formComponentSchema,
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type ContactFormBlock = InferType<typeof contactFormBlockSchema>
