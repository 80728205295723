import { InferType, object } from 'yup'
import { cssFlexAlignmentSchema } from './common/cssFlexAlignmentSchema'

export const verticalAlignmentStyleSchema = object({
  data: object({
    '--vertical-alignment-default': cssFlexAlignmentSchema,
    '--vertical-alignment-xs': cssFlexAlignmentSchema,
    '--vertical-alignment-md': cssFlexAlignmentSchema,
    '--vertical-alignment-lg': cssFlexAlignmentSchema,
  }).noUnknown(),
}).noUnknown()

export type VerticalAlignmentStyle = InferType<
  typeof verticalAlignmentStyleSchema
>
