import { useEffect, useRef, useState } from 'react'
import { isSnippetSlugAvailable } from '../SnippetsDropdown/snippetHelpers'
import { debounce } from '@sceneio/tools'
import clsx from 'clsx'
import { useFormContext, SNIPPET_TYPE } from '../../form/context/FormContext'

export const SnippetNameInput = ({
  snippetName,
  snippetType,
  snippetId,
  editSnippetMode,
  hasOverrides,
  className,
}: {
  snippetType: SNIPPET_TYPE
  snippetName: string
  snippetId: string
  editSnippetMode: boolean
  hasOverrides: boolean
  className?: string
}) => {
  const { onChangeSnippetName } = useFormContext({
    snippetsType: snippetType,
  })
  const [newSnippetName, setNewSnippetName] = useState('')
  const isSaveEnabled =
    isSnippetSlugAvailable(newSnippetName) && newSnippetName.length >= 3
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    ref.current.value = snippetName
  }, [snippetName])

  const handleNameChange = debounce((name: string) => {
    setNewSnippetName(name)
  }, 250) as (name: string) => void

  return (
    <div className="tw-relative tw-flex tw-flex-1 tw-min-w-0">
      <input
        ref={ref}
        type="text"
        className={clsx('tw-truncate tw-bg-transparent tw-flex-1', className)}
        style={{ WebkitTextFillColor: '#252523' }} // Safari issue
        defaultValue={snippetName}
        onChange={(e) => {
          handleNameChange(e.target.value)
        }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        onKeyDownCapture={(e) => {
          if (e.key === 'Enter') {
            const target = e.target as HTMLInputElement
            if (isSaveEnabled) {
              target.blur()
            }
          }
        }}
        onFocus={(e) => e.target.select()}
        onBlur={(e) => {
          const target = e.target as HTMLInputElement
          if (isSaveEnabled) {
            onChangeSnippetName({ id: snippetId, name: target.value })
          } else if (ref.current) {
            ref.current.value = snippetName
          }
          setNewSnippetName('')
        }}
        disabled={!editSnippetMode && !hasOverrides}
      />
      <div
        className={clsx(
          "tw-flex tw-flex-wrap tw-gap-1 tw-transition-all tw-opacity-1 tw-w-max tw-max-w-full tw-absolute tw-p-2 tw-bottom-full tw-left-[50%] tw-transform tw-translate-x-[-50%] tw-rounded-md tw-bg-[#252523] tw-text-white after:content-[' '] after:tw-absolute after:tw-top-full after:tw-left-1/2 after:-tw-ml-[5px] after:tw-border after:tw-border-[5px] after:tw-border-l-transparent after:tw-border-r-transparent after:tw-border-b-transparent after:tw-border-t-[#252523]",
          {
            'tw-opacity-0 tw-transition-none': !newSnippetName || isSaveEnabled,
          },
        )}
      >
        {newSnippetName && newSnippetName.length < 3 && (
          <>Name should contain at least 3 characters</>
        )}
        {newSnippetName && newSnippetName.length >= 3 && (
          <>
            Style
            <span className="tw-truncate tw-font-semibold">
              {newSnippetName}
            </span>
            already exists
          </>
        )}
      </div>
    </div>
  )
}
