import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect, useRef } from 'react'
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical'
import { mergeRegister } from '@lexical/utils'

export function OnEditorStateChangePlugin({
  serialisedEditorState,
}: {
  serialisedEditorState: string
}) {
  const [editor] = useLexicalComposerContext()
  const amFocused = useRef<boolean>(false)

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          amFocused.current = false
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          amFocused.current = true
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [])

  useEffect(() => {
    // to fix an issue where external (ws) updates issued by me would update my state and thus re-rendering (blurring) my wysiwyg
    if (amFocused.current) {
      return
    }
    const serialisedCurrentEditorState = JSON.stringify(
      editor.getEditorState().toJSON(),
    )
    if (
      serialisedEditorState &&
      serialisedCurrentEditorState !== serialisedEditorState
    ) {
      const parsedExternalEditorState = editor.parseEditorState(
        serialisedEditorState,
      )

      if (parsedExternalEditorState) {
        editor.setEditorState(parsedExternalEditorState)
      }
    }
  }, [serialisedEditorState, amFocused])
  return null
}
