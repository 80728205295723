import { Icon } from '@sceneio/ui-icons'
import { SNIPPET_TYPE } from '../../../form/context/FormContext'

export const SnippetTypeToIcon = ({
  snippetType,
  value,
}: {
  snippetType: SNIPPET_TYPE
  value?: any
}) => {
  if (snippetType === 'ATOMIC_COLOR' && value) {
    return (
      <div className="tw-inline-block tw-relative tw-shrink-0 tw-w-4 tw-h-4 tw-mr-1 tw-rounded tw-border tw-border-border-color tw-shrink-0">
        <div
          className="sc-rhf-color-picker__color tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-z-[2] tw-rounded-[3px]"
          style={{
            background: value,
          }}
        />
        <div
          className="sc-rhf-color-picker__checkers tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-z-[1] tw-rounded"
          style={{
            background:
              "url('data:image/svg+xml;utf8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%200H3V3H0V0Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M3%200H6V3H3V0Z%22%20fill%3D%22white%22/%3E%3Cpath%20d%3D%22M3%203H6V6H3V3Z%22%20fill%3D%22%23E1E1E1%22/%3E%3Cpath%20d%3D%22M0%203H3V6H0V3Z%22%20fill%3D%22white%22/%3E%3C/svg%3E%0A')",
          }}
        />
      </div>
    )
  }
  if (snippetType === 'TEXT') {
    return <Icon provider="phosphor" icon="TextT" className="tw-shrink-0" />
  }
  if (snippetType === 'BOX_SHADOW' || snippetType === 'TEXT_SHADOW') {
    return (
      <Icon
        provider="scene"
        icon="shadow-icon"
        className="tw-shrink-0"
        size="1rem"
      />
    )
  }
  if (snippetType == 'STROKE') {
    return (
      <Icon
        provider="scene"
        icon="sides-blrt"
        className="tw-shrink-0"
        size="1rem"
      />
    )
  }
  return null
}
