import { isValidElement } from 'react'
import { curry, mergeWith, union } from 'ramda'
import { isArrayLike } from './isArrayLike'
import { isObject } from './isObject'

const deepMergeObjects = curry((a, b): object | object[] => {
  if (isValidElement(a)) {
    return a
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return isArrayLike(a) && isArrayLike(b)
    ? union(a, b)
    : mergeWith(deepMerge, a, b)
})
export const deepMerge = curry(
  (
    a,
    b, // eslint-disable-next-line no-nested-ternary
  ): object =>
    b == null ? a : isObject(a) && isObject(b) ? deepMergeObjects(a, b) : b,
)
