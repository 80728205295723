import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { heroWithForm4DefaultConfig } from './heroWithForm4Meta'
import { HeroWithForm4Block } from '@sceneio/schemas/lib/types'
import HeroWithForm3 from '../HeroWithForm3/HeroWithForm3'

function HeroWithForm4(props: HeroWithForm4Block = heroWithForm4DefaultConfig) {
  return <HeroWithForm3 {...props} />
}

export const heroWithForm4Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default HeroWithForm4
