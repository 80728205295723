import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Text from '../../../components/Text/Text'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import Grid from '../../../components/Grid/Grid'
import Card from '../../../components/Card/Card'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { featureGridDefaultConfig } from './featureGridMeta'
import type { FeatureGridBlock } from '@sceneio/schemas/lib/types'

function FeatureGrid({
  id,
  components: { block, tagline, heading, text, cards },
  editor,
  preview,
}: FeatureGridBlock = featureGridDefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const featureGridClass = clsx('sc-feature-grid', className)

  return (
    <Block
      className={featureGridClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <Text
        {...tagline}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'tagline',
        }}
        cid={resolveComponentId(id, 'tagline')}
      />
      <Text
        {...heading}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'heading',
        }}
        cid={resolveComponentId(id, 'heading')}
      />
      <Text
        {...text}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'text',
        }}
        cid={resolveComponentId(id, 'text')}
      />
      <Grid
        {...cards}
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'cards',
        }}
        items={cards?.items?.map((item, index) => (
          <Card
            key={item.id}
            editor={{
              ...editor?.component,
              blockId: id,
              id: `cards.items[${index}]`,
            }}
            cid={resolveComponentId(id, `cards.items[${index}]`)}
            preview={preview}
            {...item}
          />
        ))}
      />
    </Block>
  )
}
export const featureGridPlugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}

export default FeatureGrid
