import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../../tools/cssVariables/getRootLevelCssVariables'
import clsx from 'clsx'
import { SliderComponent } from '@sceneio/schemas/lib/components/sliderComponentSchema'
import { resolveBreakpointImageClasses } from '../../Image/helpers/resolveBreakpointImageClasses'
import { BreakpointEnum } from '../../../helpers/breakpoints'
import { resolveDefaultImageClasses } from '../../Image/helpers/resolveDefaultImageClasses'
import { SliderPaginationComponent } from '@sceneio/schemas/lib/types'
import { getActivePaginationBulletInEditor } from '../helpers'
import { getBackgroundCssVariables } from '../../../tools/cssVariables/getBackgroundCssVariables'
import { getShadowCssVariables } from '../../../tools/cssVariables/getShadowCssVariables'

function Pagination({
  type = 'bullets',
  itemsPerView,
  slidesLength,
  activeSlideIndex,
  loop,
  autoplay,
  padding,
  margin,
  color,
  background,
  boxShadow,
  gap,
  border,
  width,
  height,
  editor,
  className,
  style,
  show,
  preview,
}: SliderPaginationComponent & {
  itemsPerView: SliderComponent['itemsPerView']
  slidesLength: number
  activeSlideIndex: number
  loop?: boolean
  autoplay?: boolean
}) {
  if (!show) {
    return null
  }

  const { responsiveConfig } = itemsPerView

  return (
    <div
      data-component-id={editor?.id}
      className={clsx(
        'sc-slider__pagination',
        {
          'sc-slider__pagination--bullets': type === 'bullets',
          'sc-slider__pagination--bars': type === 'bars',
          'sc-slider__pagination--autoplay-on': autoplay && !preview,
        },
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        className,
      )}
      style={{
        ...style,
        ...getBackgroundCssVariables({
          background: background || {},
          prefix: `pagination`,
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: `pagination`,
        }),
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            color,
            width,
            height,
            gap,
            border,
          },
          prefix: 'pagination',
        }),
      }}
    >
      {preview && (
        // for editor purposes only, the swiper script handles the pagination itself
        <>
          {Object.entries(responsiveConfig).map(([bp, value]) => {
            const paginationCount = loop
              ? slidesLength
              : slidesLength - value + 1

            return (
              <div
                className={clsx('sc-slider__pagination-wrapper', {
                  [resolveBreakpointImageClasses({
                    breakpoint: bp as BreakpointEnum,
                    elementClassName: 'sc-slider__pagination-wrapper',
                  })]: bp !== 'default',
                  [resolveDefaultImageClasses({
                    elementClassName: 'sc-slider__pagination-wrapper',
                    breakpointsArr: Object.keys(
                      responsiveConfig,
                    ) as BreakpointEnum[],
                  })]: bp === 'default',
                })}
                key={bp}
              >
                {[...Array(paginationCount)].map((_, index) => (
                  <div
                    key={index}
                    className={clsx('sc-slider__pagination-bullet', {
                      'sc-slider__pagination-bullet--active':
                        getActivePaginationBulletInEditor({
                          activeSlideIndex,
                          itemsPerView: value,
                          slidesLength,
                        }) === index,
                    })}
                  >
                    <div className=".sc-slider__pagination-bullet--progress" />
                  </div>
                ))}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export const plugins: PluginsType = {
  js: ['slider'],
  css: ['slider'],
}

export default Pagination
