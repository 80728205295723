import type { Icons } from '@sceneio/ui-icons'

export const phosphorIconsMap: Icons['phosphor'][] = [
  'Check',
  'CheckCircle',
  'XCircle',
  'Warning',
  'Plus',
  'Minus',
  'PlusCircle',
  'CheckSquare',
  'ThumbsUp',
  'PencilSimple',
  'Trash',
  'ArrowRight',
  'ArrowsCounterClockwise',
  'Confetti',
  'CaretDown',
  'CaretUp',
  'Star',
  'Heart',
  'MagicWand',
  'RocketLaunch',
  'Rocket',
  'Fire',
  'Lightning',
  'MagnifyingGlass',
  'GearSix',
  'PaperPlaneTilt',
  'ShareFat',
  'Envelope',
  'At',
  'EnvelopeSimple',
  'Phone',
  'ChatsCircle',
  'ChatCircle',
  'Car',
  'AirplaneTilt',
  'ShieldCheck',
  'SealCheck',
  'Lock',
  'Key',
  'FingerprintSimple',
  'Detective',
  'FolderOpen',
  'Folder',
  'Copy',
  'PushPin',
  'MusicNotesSimple',
  'Play',
  'ImagesSquare',
  'Camera',
  'Microphone',
  'FilmStrip',
  'CloudArrowUp',
  'CloudArrowDown',
  'Lightbulb',
  'Package',
  'CalendarBlank',
  'CalendarPlus',
  'Alarm',
  'Timer',
  'Clock',
  'ClockClockwise',
  'ClockCountdown',
  'ClockCounterClockwise',
  'ShoppingCartSimple',
  'Basket',
  'Tote',
  'ShoppingBag',
  'BellRinging',
  'GlobeHemisphereWest',
  'GlobeHemisphereEast',
  'GlobeSimple',
  'NavigationArrow',
  'Compass',
  'TwitterLogo',
  'InstagramLogo',
  'LinkedinLogo',
  'FacebookLogo',
  'TiktokLogo',
  'YoutubeLogo',
  'RedditLogo',
  'GithubLogo',
  'SlackLogo',
  'DiscordLogo',
  'AndroidLogo',
  'GooglePlayLogo',
  'AppleLogo',
  'AppStoreLogo',
  'StackOverflowLogo',
  'SpotifyLogo',
  'SoundcloudLogo',
  'AmazonLogo',
  'PaypalLogo',
  'Bank',
  'ChartLine',
  'ChartBar',
  'ChartPie',
  'ChartDonut',
  'MathOperations',
  'House',
  'GraduationCap',
  'PiggyBank',
  'BookmarkSimple',
  'Flag',
  'Recycle',
  'Pill',
  'HandHeart',
  'FirstAid',
  'List',
]

export const sceneIconsMap: Icons['scene'][] = ['threads', 'x']

export const mergedIconsMap = [...phosphorIconsMap, ...sceneIconsMap]

export const suggestedIconsMap: Icons['phosphor'][] = [
  'Plus',
  'Check',
  'Envelope',
  'PaperPlaneTilt',
  'ArrowRight',
  'ShoppingCartSimple',
  'BellRinging',
  'GearSix',
  'XCircle',
  'Fire',
  'TwitterLogo',
  'InstagramLogo',
  'LinkedinLogo',
  'FacebookLogo',
  'TiktokLogo',
  'YoutubeLogo',
  'RedditLogo',
  'GithubLogo',
  'SlackLogo',
  'DiscordLogo',
]
