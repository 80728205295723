import { StateType } from '../../../atoms/StateTabs/StateTabs'
import { RHFShadowSettingsPropType } from '../ShadowSettings'
import { BreakpointShadowFields } from './BreakpointShadowFields'

type StateShadowFieldPropType = {
  state: StateType
  valuesPath: string
  hidden?: boolean
  shadowType: RHFShadowSettingsPropType['shadowType']
}

export function StateShadowFields({
  state,
  valuesPath,
  shadowType,
}: StateShadowFieldPropType) {
  return (
    <BreakpointShadowFields
      breakpoint="default"
      state={state}
      valuesPath={valuesPath}
      shadowType={shadowType}
    />
  )
}
