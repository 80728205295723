import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowsMaximize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5312 0.398602L10.6525 2.51992C10.7932 2.66057 10.8722 2.85134 10.8722 3.05025C10.8722 3.24917 10.7932 3.43993 10.6525 3.58058C10.5119 3.72124 10.3211 3.80025 10.1222 3.80025C9.92328 3.80025 9.73251 3.72123 9.59186 3.58058L8.75217 2.74089L8.75261 6.23356C8.75261 6.43282 8.67346 6.62391 8.53256 6.76481C8.39166 6.9057 8.20057 6.98486 8.00131 6.98486C7.80205 6.98486 7.61096 6.90571 7.47006 6.76481C7.32916 6.62391 7.25001 6.43282 7.25001 6.23356L7.24957 2.74089L6.40988 3.58058C6.26923 3.72123 6.07846 3.80025 5.87955 3.80025C5.68064 3.80025 5.48987 3.72123 5.34922 3.58058C5.20857 3.43993 5.12955 3.24916 5.12955 3.05025C5.12955 2.85134 5.20857 2.66057 5.34922 2.51992L7.47054 0.398602C7.61119 0.25795 7.80196 0.178933 8.00087 0.178933C8.19978 0.178932 8.39055 0.25795 8.5312 0.398602ZM7.25045 9.76777L7.24957 13.2591L6.40988 12.4194C6.26923 12.2788 6.07846 12.1997 5.87955 12.1997C5.68064 12.1997 5.48987 12.2788 5.34922 12.4194C5.20857 12.5601 5.12955 12.7508 5.12955 12.9497C5.12955 13.1487 5.20857 13.3394 5.34922 13.4801L7.47054 15.6014C7.61119 15.7421 7.80196 15.8211 8.00087 15.8211C8.19978 15.8211 8.39055 15.7421 8.5312 15.6014L10.6525 13.4801C10.7932 13.3394 10.8722 13.1487 10.8722 12.9497C10.8722 12.7508 10.7932 12.5601 10.6525 12.4194C10.5119 12.2788 10.3211 12.1997 10.1222 12.1997C9.92328 12.1997 9.73251 12.2788 9.59186 12.4194L8.75217 13.2591L8.75261 9.76644C8.75261 9.56718 8.67346 9.37609 8.53256 9.23519C8.39166 9.0943 8.20057 9.01514 8.00131 9.01514C7.80205 9.01514 7.61096 9.09429 7.47006 9.23519C7.32916 9.37609 7.25001 9.56718 7.25001 9.76644L7.25045 9.76777Z"
      fill="#252523"
    />
  </svg>
)
export default SvgArrowsMaximize
