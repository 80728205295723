import { ButtonComponent } from '@sceneio/schemas/lib/types'
import { ComponentMeta } from '../../componentCommonTypes'
import { componentDefaultConfigFactory } from '../../helpers'

const arrowButtonDefaultConfig: ButtonComponent = {
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  color: {
    data: {
      default: {
        '--color-default-default': '#fff',
      },
      hover: {
        '--color-default-hover': '#fff',
      },
      active: {
        '--color-default-active': '#fff',
      },
    },
  },
  backgroundColor: {
    data: {
      default: {
        '--background-color-default-default': 'rgba(0, 0, 0, 0.12)',
      },
      hover: {
        '--background-color-default-hover': 'rgba(0, 0, 0, 0.12)',
      },
      active: {
        '--background-color-default-active': 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
  border: {
    meta: {
      dataType: 'border-style',
    },
    data: {
      default: {
        '--border-default-color-default': 'transparent',
        '--border-default-width-default': '1px',
        '--border-default-radius-top-left-default': '50%',
        '--border-default-radius-top-right-default': '50%',
        '--border-default-radius-bottom-left-default': '50%',
        '--border-default-radius-bottom-right-default': '50%',
        '--border-default-style-default': 'solid',
        '--border-default-top-style-default': 'solid',
        '--border-default-right-style-default': 'solid',
        '--border-default-bottom-style-default': 'solid',
        '--border-default-left-style-default': 'solid',
      },
      hover: {
        '--border-default-color-hover': 'transparent',
      },
      active: {
        '--border-default-color-active': 'transparent',
      },
    },
  },
  padding: {
    data: {
      '--padding-default-top': '16px',
      '--padding-default-bottom': '16px',
      '--padding-default-left': '16px',
      '--padding-default-right': '16px',
    },
  },
}

export const arrowButtonSampleFactory = ({
  type,
}: {
  type: 'left' | 'right'
}): ButtonComponent => ({
  components: {
    icon: {
      meta: {
        dataType: 'icon-component',
      },
      mode: 'only',
      width: {
        data: {
          '--width-default': '24px',
        },
      },
      color: {
        data: {
          default: {
            '--color-default-default': '#fff',
          },
        },
      },
      responsiveConfig: {
        default: {
          default: {
            provider: 'phosphor',
            name: type === 'left' ? 'ArrowLeft' : 'ArrowRight',
          },
        },
      },
    },
  },
  margin: {
    data:
      type === 'left'
        ? { '--margin-default-left': '1.5rem' }
        : { '--margin-default-right': '1.5rem' },
  },
  ...arrowButtonDefaultConfig,
})

export const sliderVariant3ComponentMeta: ComponentMeta<'sliderVariant3'> = {
  defaultConfig: {
    show: true,
    meta: {
      dataType: 'slider-component',
    },
    itemsPerView: {
      responsiveConfig: {
        default: 1,
      },
    },
    speed: 400,
    loop: true,
    effect: 'fade',
    easing: 'ease-out',
    autoplay: {
      enable: true,
      timeout: 2000,
      direction: 'forward',
    },
    components: {
      slides: {
        meta: {
          dataType: 'container-component',
        },
        items: [],
      },
      buttonPrev: arrowButtonSampleFactory({ type: 'left' }),
      buttonNext: arrowButtonSampleFactory({ type: 'right' }),
      pagination: {
        meta: {
          dataType: 'slider-pagination-component',
        },
        type: 'bars',
        margin: {
          data: {
            '--margin-default-left': '1rem',
            '--margin-default-right': '1rem',
            '--margin-default-bottom': '1.5rem',
          },
        },
        background: {
          responsiveConfig: {
            default: {
              color: '#fff',
            },
          },
        },
        border: {
          meta: {
            dataType: 'border-style',
          },
          data: {
            default: {
              '--border-default-radius-bottom-left-default': '32px',
              '--border-default-radius-bottom-right-default': '32px',
              '--border-default-radius-top-left-default': '32px',
              '--border-default-radius-top-right-default': '32px',
            },
          },
        },
        padding: {
          data: {
            '--padding-default-left': '1rem',
            '--padding-default-right': '1rem',
            '--padding-default-top': '1rem',
            '--padding-default-bottom': '1rem',
          },
        },
        show: true,
      },
    },
  },
}

export const sliderVariant3Defaults =
  componentDefaultConfigFactory<'sliderVariant3'>({
    componentMeta: sliderVariant3ComponentMeta,
  })
