import { createHeadlessEditor } from '@lexical/headless'
import wysiwygConfig from '@sceneio/config-wysiwyg'
import { $generateNodesFromDOM } from '@lexical/html'
import { $getRoot, $insertNodes } from 'lexical'
import { isJsonString } from './tools/isJsonString'

export function htmlToLexical(serialisedEditorState: string): Promise<string> {
  return new Promise((resolve) => {
    const isJson = isJsonString(serialisedEditorState)

    // no need to convert if compatible editorState
    if (isJson) {
      return resolve(serialisedEditorState)
    }

    // is not compatible editor state and we need to convert it
    const editor = createHeadlessEditor({ ...wysiwygConfig })
    editor.update(
      () => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser()
        const dom = parser.parseFromString(serialisedEditorState, 'text/html')

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom)

        // Select the root
        $getRoot().select()

        // Insert them at a selection.
        $insertNodes(nodes)
      },
      {
        onUpdate: () => {
          resolve(JSON.stringify(editor.getEditorState().toJSON()))
        },
      },
    )
  })
}
