import React from 'react'
import { SliderVariant3Component } from '@sceneio/schemas/lib/types'
import clsx from 'clsx'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Button from '../../Button/Button'
import Pagination from '../components/Pagination'
import { getActiveSlideIndexInEditor } from '../helpers'
import SliderContainer from '../SliderContainer'

function SliderVariant3({
  components: { slides, buttonNext, buttonPrev, pagination },
  itemsPerView,
  loop,
  autoplay,
  className,
  editor,
  preview,
  show,
  style,
  ...props
}: SliderVariant3Component) {
  if (!show || !slides.items.length) {
    return null
  }

  return (
    <SliderContainer
      {...props}
      itemsPerView={itemsPerView}
      activeSlideIndex={getActiveSlideIndexInEditor({
        editor,
        slidesLength: slides.items.length,
      })}
      loop={loop}
      autoplay={autoplay}
      editor={editor}
      preview={preview}
      className={clsx('sc-slider-variant-3', className)}
    >
      <div className="sc-slider__wrapper swiper-wrapper">
        {slides.items.map((slide, idx) => (
          <div key={idx} className="sc-slider__slide swiper-slide">
            {slide}
          </div>
        ))}
      </div>

      <Pagination
        {...pagination}
        editor={{
          ...editor,
          id: `${editor?.id}.pagination`,
        }}
        itemsPerView={itemsPerView}
        slidesLength={slides.items.length}
        activeSlideIndex={getActiveSlideIndexInEditor({
          editor,
          slidesLength: slides.items.length,
        })}
        loop={loop}
        preview={preview}
        autoplay={autoplay.enable}
      />
      <div className="sc-slider__navigation">
        <div className="sc-slider__navigation-btn sc-slider__button--prev">
          <Button
            {...buttonPrev}
            editor={{
              ...editor,
              id: `${editor?.id}.buttonPrev`,
              wysiwyg: {
                type: 'RAW',
              },
            }}
          />
        </div>
        <div className="sc-slider__navigation-btn sc-slider__button--next">
          <Button
            {...buttonNext}
            editor={{
              ...editor,
              id: `${editor?.id}.buttonNext`,
              wysiwyg: {
                type: 'RAW',
              },
            }}
          />
        </div>
      </div>
    </SliderContainer>
  )
}

export const plugins: PluginsType = {
  js: ['slider'],
  css: ['slider'],
}
export default SliderVariant3
