import React from 'react'
import Timeline from './Timeline'
import { timelineDefaultConfig } from './timelineMeta'

const TimelinePreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : timelineDefaultConfig
  return <Timeline {...defaultData} />
}

export default TimelinePreview
