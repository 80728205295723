import React from 'react'
import clsx from 'clsx'
import Block from '../../../components/Block/Block'
import Image from '../../../components/Image/Image'
import Text from '../../../components/Text/Text'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import resolveComponentId from '../../../tools/helpers/resolveComponentId'
import { logoStripDefaultConfig } from './logoStripMeta'
import Grid from '../../../components/Grid/Grid'
import type { LogoStripBlock } from '@sceneio/schemas/lib/types'

function LogoStrip({
  id,
  components: { block, heading, logoImages },
  editor,
}: LogoStripBlock = logoStripDefaultConfig) {
  const {
    className,
    swapOrder,
    columnRatio = 4,
    ...restBlockProps
  } = block || {}

  const textColumns = columnRatio
  const logoImagesColumns = 12 - textColumns

  const logoImagesColumnClasses = clsx('sc-logo-strip__logo-images-column', {
    [`bui-col-md-${logoImagesColumns}`]: logoImagesColumns,
    'bui-order-md-first': swapOrder,
  })

  const textColumnClasses = clsx(`sc-logo-strip__text-column`, {
    [`bui-col-md-${textColumns}`]: textColumns,
    [`bui-col`]: textColumns === 0,
  })
  const logoStripClass = clsx(['sc-logo-strip', className])

  return (
    <Block
      id={id}
      editor={editor}
      className={logoStripClass}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={textColumnClasses}>
          <Text
            {...heading}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'heading',
            }}
            cid={resolveComponentId(id, 'heading')}
          />
        </div>
        <div className={logoImagesColumnClasses}>
          <Grid
            {...logoImages}
            cssType="flex"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'logoImages',
            }}
            items={logoImages?.items?.map(
              ({ id: imageId, ...restImageProps }, index) => (
                <Image
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `logoImages.items[${index}]`,
                  }}
                  key={imageId}
                  cid={resolveComponentId(id, `image-${index}`)}
                  {...restImageProps}
                  isAnimated={true}
                />
              ),
            )}
          />
        </div>
      </div>
    </Block>
  )
}

export const logoStripPlugins: PluginsType = {
  js: ['animation'],
  css: [],
}
export default LogoStrip
