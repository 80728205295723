import React from 'react'
import Banner from './Banner'
import { BannerBlock } from '@sceneio/schemas/lib/types'
import { bannerDefaultConfig } from './bannerMeta'

const BannerPreview = (config: BannerBlock) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : bannerDefaultConfig

  return <Banner {...defaultData} />
}

export default BannerPreview
