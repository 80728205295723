import React from 'react'
import LogoStrip, { logoStripPlugins } from '../LogoStrip/LogoStrip'
import { LogoStrip2Block } from '@sceneio/schemas/lib/types'
import { logoStrip2DefaultConfig } from './logoStrip2Meta'

function LogoStrip2(props: LogoStrip2Block = logoStrip2DefaultConfig) {
  return <LogoStrip {...props} />
}

export default LogoStrip2
export const logoStrip2Plugins = logoStripPlugins
