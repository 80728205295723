import { InferType, number, object } from 'yup'

export const columnsStyleSchema = object({
  data: object({
    '--columns-default': number().min(1).max(12),
    '--columns-xs': number().min(1).max(12),
    '--columns-md': number().min(1).max(12),
    '--columns-lg': number().min(1).max(12),
  }).noUnknown(),
}).noUnknown()

export type ColumnsStyle = InferType<typeof columnsStyleSchema>
