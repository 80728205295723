import { InferType, bool, object, number } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'
import { formComponentSchema } from '../../components/formComponentSchema'

export const featureWithFormBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    tagline: textComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    bottomText: textComponentSchema,
    form: formComponentSchema,
    media: mediaComponentSchema,
  }).noUnknown(),

  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type FeatureWithFormBlock = InferType<typeof featureWithFormBlockSchema>
