import { InferType, object } from 'yup'
import { cssFlexAlignmentSchema } from './common/cssFlexAlignmentSchema'

export const horizontalAlignmentFlexStyleSchema = object({
  data: object({
    '--horizontal-alignment-default': cssFlexAlignmentSchema,
    '--horizontal-alignment-xs': cssFlexAlignmentSchema,
    '--horizontal-alignment-md': cssFlexAlignmentSchema,
    '--horizontal-alignment-lg': cssFlexAlignmentSchema,
  }).noUnknown(),
}).noUnknown()

export type HorizontalAlignmentFlexStyle = InferType<
  typeof horizontalAlignmentFlexStyleSchema
>
