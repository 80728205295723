import { RHFToggleGroup } from '@sceneio/ui-forms'
import { BreakpointType } from '@sceneio/ui-forms/lib/components/atoms/BreakpointDropdown/BreakpointDropdown'

export const buttonContainerLayoutAdditionalFormFieldRenderer = ({
  breakpoint,
}: {
  breakpoint: BreakpointType | 'default'
}) => ({
  placement: 'top',
  fieldNames: [`flow.data.--flow-${breakpoint}`],
  children: (
    <RHFToggleGroup
      name={`flow.data.--flow-${breakpoint}`}
      options={[
        { value: 'row', label: 'Horizontal' },
        { value: 'column', label: 'Vertical' },
      ]}
    />
  ),
})
