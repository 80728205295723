import { createSlice } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './../../store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { ServerSliceType } from './serverSliceTypes'

export * from './serverSliceSelectors'

// ---------------
// Initial State
// ---------------
export const initialState: ServerSliceType = {
  token: '',
  url: '',
} as ServerSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Reducer
// ---------------

export const serverSlice = createSlice({
  name: 'server',
  initialState: () => initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', () => {
      return initialState
    })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useServerDispatch: () => AppDispatch = useDispatch
export const useServerSelector: TypedUseSelectorHook<RootState> = useSelector

export default serverSlice.reducer
