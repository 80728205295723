import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from './../../store'
import { CommentType, CommentsSliceType } from './commentsSliceTypes'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { createAppendCidThunk } from '../../helpers/createAppendCidThunk'
import { createCid } from '../../helpers/createCid'
import { createGraphqlAsyncThunkByDocument } from '../../helpers/createGraphqlAsyncThunk'
import { fetchContent } from '../content/contentSlice'
import { ContentType } from '../content/contentSliceTypes'
import { updateWhiteboardContentEntitiesMeta } from '../whiteboard/whiteboardSlice'
import { WhiteboardContentEntitiesMetaUpdateResType } from '../whiteboard/whiteboardSliceTypes'

export * from './commentsSliceSelectors'

// ---------------
// Initial State
// ---------------
export const initialState: CommentsSliceType = {
  entities: [],
  status: 'idle',
  error: null,
} as CommentsSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const fetchComments = createGraphqlAsyncThunkByDocument<
  'CommentsQueryDocument',
  'comments'
>()('comments/fetchData', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'CommentsQueryDocument',
    selector: 'comments',
    variables: queryVariables,
    thunkAPI,
  })
})

export const createComment = createGraphqlAsyncThunkByDocument<
  'CommentCreateMutationDocument',
  'createComment'
>()(
  'comment/create',
  async ({ queryVariables }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'CommentCreateMutationDocument',
      selector: 'createComment',
      variables: queryVariables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
  {
    getPendingMeta: (_, api) => {
      const state = api.getState()

      return {
        pendingData: {
          createdByUser: state.user,
        },
      }
    },
  },
)

export const deleteComment = createGraphqlAsyncThunkByDocument<
  'DeleteCommentMutationDocument',
  'deleteComment'
>()('comment/delete', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'DeleteCommentMutationDocument',
    selector: 'deleteComment',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

export const updateComment = createGraphqlAsyncThunkByDocument<
  'CommentUpdateMutationDocument',
  'updateComment'
>()('comment/update', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'CommentUpdateMutationDocument',
    selector: 'updateComment',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

export const replyToComment = createGraphqlAsyncThunkByDocument<
  'CommentReplyToMutationDocument',
  'replyToComment'
>()(
  'comment/reply',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'CommentReplyToMutationDocument',
      selector: 'replyToComment',
      variables: queryVariables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }

      return res
    })
  },
  {
    getPendingMeta: (base, api) => {
      const state = api.getState()

      return {
        pendingData: {
          createdByUser: state.user,
          parentId: base.arg.queryVariables.id,
        },
      }
    },
  },
)

export const createCommentWithCid =
  createAppendCidThunk<typeof createComment>(createComment)

export const replyToCommentWithCid =
  createAppendCidThunk<typeof replyToComment>(replyToComment)
// ---------------
// Reducer
// ---------------

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetCommentsState: () => {
      return initialState
    },
    createTemporaryComment: (
      state,
      action: PayloadAction<{
        position: {
          x: number
          y: number
        }
      }>,
    ) => {
      const existingTemporaryCommentIndex = state.entities.findIndex(
        // @ts-ignore
        ({ state }) => state === 'TEMPORARY',
      )

      if (existingTemporaryCommentIndex === -1) {
        state.entities = [
          // @ts-ignore
          ...state.entities,
          // @ts-ignore
          {
            id: createCid(),
            cid: createCid(),
            meta: {
              readByUserIds: [],
              whiteboard: action.payload,
            },
            data: {},
            contentId: '',
            state: 'TEMPORARY',
            createdAt: '',
            createdByUser: {
              id: '',
              name: '',
              email: '',
            },
          },
        ]
      }
    },
    deleteTemporaryComment: (state) => {
      state.entities = state.entities.filter(
        // @ts-ignore
        (comment) => comment.state !== 'TEMPORARY',
      )
    },
    wsUpdateWhiteboardContentCommentsEntity: (
      state,
      action: PayloadAction<WhiteboardContentEntitiesMetaUpdateResType>,
    ) => {
      if (!state.entities) {
        return
      }

      const updatedWhiteboardContentEntities = action.payload
      const updatedWhiteboardContentCommentEntities =
        updatedWhiteboardContentEntities.filter(
          ({ entity }) => entity === 'COMMENT',
        )

      updatedWhiteboardContentCommentEntities.forEach((comment) => {
        const existingCommentIndex = state.entities.findIndex(
          (existingComment) => existingComment.id === comment.id,
        )

        if (existingCommentIndex > -1) {
          const existingComment = state.entities[existingCommentIndex]

          state.entities[existingCommentIndex] = {
            ...existingComment,
            meta: {
              ...existingComment.meta,
              whiteboard: {
                ...existingComment.meta.whiteboard,
                ...comment.payload,
              },
            },
          }
        }
      })
    },
    wsCreateComment: (state, action: PayloadAction<CommentType>) => {
      const actionPayload = action.payload
      const existingCommmentIndex = state.entities.findIndex(
        ({ cid }) => cid === actionPayload.cid,
      )

      if (existingCommmentIndex > -1) {
        // the same comment already exists
        const existingComment = state.entities[existingCommmentIndex]
        const isPending = existingComment.cid === existingComment.id
        if (isPending) {
          state.entities[existingCommmentIndex] = actionPayload
        }
      } else {
        state.entities = [...state.entities, actionPayload]
      }
    },
    wsUpdateComment: (state, action: PayloadAction<CommentType>) => {
      const actionPayload = action.payload

      const existingCommmentIndex = state.entities.findIndex(
        ({ cid }) => cid === actionPayload.cid,
      )

      if (existingCommmentIndex > -1) {
        state.entities[existingCommmentIndex] = actionPayload
      }
    },
    wsResolveComment: (state, action: PayloadAction<CommentType>) => {
      const actionPayload = action.payload

      const existingCommmentIndex = state.entities.findIndex(
        ({ cid }) => cid === actionPayload.cid,
      )

      if (existingCommmentIndex > -1) {
        state.entities[existingCommmentIndex] = actionPayload
      }
    },
    wsReplyToComment: (state, action: PayloadAction<CommentType>) => {
      const actionPayload = action.payload

      const existingCommmentIndex = state.entities.findIndex(
        ({ cid }) => cid === actionPayload.cid,
      )

      if (existingCommmentIndex > -1) {
        // the same comment already exists
        const existingComment = state.entities[existingCommmentIndex]
        const isPending = existingComment.cid === existingComment.id
        if (isPending) {
          state.entities[existingCommmentIndex] = actionPayload
        }
      } else {
        state.entities = [...state.entities, actionPayload]
      }
    },
    wsDeleteComments: (state, action: PayloadAction<CommentType[]>) => {
      const deletedComments = action.payload

      deletedComments.forEach((comment) => {
        const commentId = comment.cid
        state.entities = state.entities.filter(
          (comment) => comment.cid !== commentId,
        )
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(fetchComments.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payload = action.payload as CommentType[]
        state.entities = payload || []
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.code || null
      })
      //TODO add pending/reject states
      .addCase(updateWhiteboardContentEntitiesMeta.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const updatedWhiteboardContentEntities =
            action.meta.arg.queryVariables.updateInput
          const updatedWhiteboardContentCommentEntities =
            updatedWhiteboardContentEntities.filter(
              ({ entity }) => entity === 'COMMENT',
            )

          updatedWhiteboardContentCommentEntities.forEach((comment) => {
            const existingCommentIndex = state.entities.findIndex(
              (existingComment) => existingComment.id === comment.id,
            )

            if (existingCommentIndex > -1) {
              const existingComment = state.entities[existingCommentIndex]

              state.entities[existingCommentIndex] = {
                ...existingComment,
                meta: {
                  ...existingComment.meta,
                  whiteboard: {
                    ...existingComment.meta.whiteboard,
                    ...comment.payload,
                  },
                },
              }
            }
          })
        }
      })
      .addCase(createComment.pending, (state, action) => {
        // we create a pending comment on a 'pending' state to have instant response for the user
        // once the request is fulfilled the comment is replaced by the data from the api response
        // if failed, the comment is removed
        const { contentId, cid, ...commentData } =
          action.meta.arg.queryVariables
        const pendingAdditionalData = action.meta?.pendingData

        // We don't want to add pending comment if there is no way to identify it in the future
        if (!cid) {
          return
        }

        state.entities = [
          ...state.entities,
          {
            ...commentData,
            id: cid,
            cid,
            ...pendingAdditionalData,
          },
        ]
      })
      .addCase(createComment.fulfilled, (state, action) => {
        // replace pending comment data with api data
        const { cid } = action.payload
        const pendingCommentIndex = state.entities.findIndex(
          (comment) => comment.cid === cid,
        )

        state.entities[pendingCommentIndex] = action.payload
      })
      .addCase(deleteComment.pending, (state, action) => {
        const { id } = action.meta.arg.queryVariables

        state.entities = state.entities.filter((comment) => comment.id !== id)
      })
      .addCase(updateComment.pending, (state, action) => {
        const { id, resolved, ...commentData } = action.meta.arg.queryVariables
        const commentIndex = state.entities.findIndex(
          (comment) => comment.id === id,
        )

        const comment = state.entities[commentIndex]

        state.entities[commentIndex] = {
          ...comment,
          state: resolved ? 'RESOLVED' : comment.state,
          ...commentData,
        }
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        const { id, ...commentData } = action.payload
        const commentIndex = state.entities.findIndex(
          (comment) => comment.id === id,
        )

        state.entities[commentIndex] = {
          ...state.entities[commentIndex],
          ...commentData,
        }
      })
      .addCase(replyToComment.pending, (state, action) => {
        // we create a pending comment on a 'pending' state to have instant response for the user
        // once the request is fulfilled the comment is replaced by the data from the api response
        // if failed, the comment is removed
        const { id, ...commentData } = action.meta.arg.queryVariables
        const pendingAdditionalData = action.meta?.pendingData

        state.entities = [
          ...state.entities,
          {
            ...commentData,
            ...pendingAdditionalData,
          },
        ]
      })
      .addCase(replyToComment.fulfilled, (state, action) => {
        // replace pending comment data with api data
        const { cid } = action.payload
        const pendingCommentIndex = state.entities.findIndex(
          (comment) => comment.cid === cid,
        )

        state.entities[pendingCommentIndex] = action.payload
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useCommentsDispatch: () => AppDispatch = useDispatch
export const useCommentsSelector: TypedUseSelectorHook<RootState> = useSelector

// Action creators are generated for each case reducer function
export const {
  wsCreateComment,
  wsReplyToComment,
  resetCommentsState,
  wsResolveComment,
  wsUpdateComment,
  wsUpdateWhiteboardContentCommentsEntity,
  wsDeleteComments,
  createTemporaryComment,
  deleteTemporaryComment,
} = commentsSlice.actions

export default commentsSlice.reducer
