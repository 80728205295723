import { InferType, object, string } from 'yup'
import { fontStyleDataSchema } from '../../styles/fontStyleSchema'

export const textSnippetSchema = object({
  data: object({
    value: fontStyleDataSchema.required(),
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['TEXT']).required(),
})

export type TextSnippet = InferType<typeof textSnippetSchema>
