import type { SVGProps } from 'react'
const SvgGridIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="grid-icon_svg__svg"
    viewBox="0 0 12 12"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 1H1v3h3zM0 0v5h5V0zm11 1H8v3h3zM7 0v5h5V0zM4 8H1v3h3zM0 7v5h5V7zm11 1H8v3h3zM7 7v5h5V7z"
    />
  </svg>
)
export default SvgGridIcon
