import React from 'react'
import VideoHero from './VideoHero'
import { VideoHeroBlock } from '@sceneio/schemas/lib/types'
import { videoHeroDefaultConfig } from './videoHeroMeta'

const VideoHeroPreview = (config: VideoHeroBlock) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : videoHeroDefaultConfig

  return <VideoHero {...defaultData} />
}

export default VideoHeroPreview
