/*
  Middleware which resolve twic pics image source absolute url independent on provided domain during twic pics script initialisation
*/
export function twicPicsSourceUrlResolver(src?: string) {
  if (!src) {
    return undefined
  }
  const parsedSourceUrl = new URL(src, 'file:')
  const pathname = parsedSourceUrl.pathname
  const search = parsedSourceUrl.search

  return `image:${pathname}${search}`
}
