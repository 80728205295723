import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const selectFontStyles = (state: RootState) => {
  const fontStyles = state.assets.fontStyles.entities
  const sortedFontStyles = [...fontStyles].sort((a, b) => {
    const titleA = a?.meta?.fontTitle
    const titleB = b?.meta?.fontTitle
    if (titleA < titleB) {
      return -1
    } else if (titleA > titleB) {
      return 1
    }
    return 0
  })

  return sortedFontStyles
}

export const selectFontStyleById = createSelector(
  [selectFontStyles, (_, fontStyleId) => fontStyleId],
  (fontStyles, fontStyleId) => {
    const fontStyle = fontStyles.find(
      (fontStyle) => fontStyle.id === fontStyleId,
    )

    return fontStyle
  },
)
