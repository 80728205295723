import { unflatten } from 'flat'
import {
  AVAILABLE_BREAKPOINTS,
  BreakpointType,
} from '../atoms/BreakpointDropdown/BreakpointDropdown'
import { StateType } from '../atoms/StateTabs/StateTabs'
import { search } from 'jmespath'

export const resolveActiveBreakpoints = (
  values: Record<string, any> = {},
  activeBreakpoints: BreakpointType[] = [],
) => {
  const newActiveBreakpoints: BreakpointType[] = activeBreakpoints
  for (const bp of AVAILABLE_BREAKPOINTS) {
    for (const value of Object.keys(values)) {
      const regex = new RegExp(`-${bp}-|-${bp}$|\\.${bp}`)

      if (regex.test(value) && !newActiveBreakpoints.includes(bp)) {
        newActiveBreakpoints.push(bp)
      }
    }
  }
  return newActiveBreakpoints
}

export const getActiveStates = (stateOptions: {
  inheritedState?: StateType
  allowHover?: boolean
  allowActive?: boolean
}) => {
  const { inheritedState, allowActive, allowHover } = stateOptions
  const states = [inheritedState || 'default']
  if (allowHover) {
    states.push('hover')
  }
  if (allowActive) {
    states.push('active')
  }
  return states
}

export const getMergedValuesWithPlaceholders = ({
  placeholders,
  values = {},
  valuesPath,
}: {
  placeholders: Record<string, any>
  values: Record<string, any>
  valuesPath?: string
}) => {
  const hasValues =
    Object.keys(search(unflatten(values), valuesPath || '@') || {}).length > 0

  const result = { ...placeholders, ...(hasValues ? values : {}) }

  if (!valuesPath) {
    return unflatten(result)
  }
  return search(unflatten(result), valuesPath)
}
