import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import Banner from '../../Hero/Banner/Banner'
import { ctaBanner1DefaultConfig } from './CTABanner1Meta'
import { CTABanner1Block } from '@sceneio/schemas/lib/types'

function CTABanner1(props: CTABanner1Block = ctaBanner1DefaultConfig) {
  return <Banner {...props} />
}

export const ctaBanner1Plugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default CTABanner1
