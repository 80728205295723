import React from 'react'
import { Icon } from '@sceneio/ui-icons'
import { useFormContext } from '../../form/context/FormContext'

export const ClearButton = ({
  name,
  onClick,
}: {
  name: string
  onClick?: () => void
}) => {
  const { unregister } = useFormContext({ name })
  return (
    <button
      type="button"
      onClick={() => {
        unregister([name])
        if (onClick) {
          onClick()
        }
      }}
      className="tw-absolute -tw-right-0.5 -tw-top-0.5 tw-text-black tw-bg-border-color tw-rounded-full tw-p-px"
    >
      <Icon provider="phosphor" icon="X" size={'0.5rem'} />
    </button>
  )
}
