import React from 'react'
import FAQ2 from './FAQ2'
import { fAQ2DefaultConfig } from './fAQ2Meta'

const FAQ2Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : fAQ2DefaultConfig

  return <FAQ2 {...defaultData} />
}

export default FAQ2Preview
