import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Icon } from '@sceneio/ui-icons'

export const AVAILABLE_BREAKPOINTS: BreakpointType[] = ['xs', 'md'] // 'lg' also available if needed
export const BREAKPOINT_DEVICE_MAP = {
  xs: 'Mobile',
  md: 'Tablet',
  lg: 'Desktop',
}
export type BreakpointType = 'xs' | 'md' | 'lg'

type RHFBreakpointDropdownPropType = {
  onSelect: (breakpoint: 'lg' | 'md' | 'xs') => void
  disabledBreakpoints?: string[]
}

export const RHFBreakpointDropdown = ({
  onSelect,
  disabledBreakpoints = [],
}: RHFBreakpointDropdownPropType) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="tw-p-1 tw-rounded-sm focus-visible:tw-outline-none data-[state=open]:tw-bg-gray-100">
        <Icon provider="phosphor" icon="Devices" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="tw-overflow-hidden tw-bg-white tw-text-form-color tw-text-xs tw-rounded tw-border tw-border-border-color tw-p-1 tw-min-w-[130px] tw-mt-0.5 tw-z-10"
        align="end"
      >
        {AVAILABLE_BREAKPOINTS.map((bp) => (
          <DropdownMenu.Item
            key={bp}
            className="tw-p-2 hover:tw-outline-none hover:tw-bg-form-field-bg aria-disabled:tw-opacity-50 tw-rounded"
            onSelect={() => {
              if (onSelect) {
                onSelect(bp as BreakpointType)
              }
            }}
            disabled={disabledBreakpoints.includes(bp)}
          >
            {BREAKPOINT_DEVICE_MAP[bp as BreakpointType]}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
