import { createGraphqlAsyncThunk } from './createGraphqlAsyncThunk'
import { ThunkRejectType, thunkReject } from './thunkReject'
import { graphqlClientFactory } from '@sceneio/graphql-queries/src'
import type {
  DocumentName,
  GetVarsByDocumentName,
  GqlResultDataByDocumentName,
} from '@sceneio/graphql-queries/dist/client/types'

type ThunkPayloadCreatorType<T> = Parameters<
  typeof createGraphqlAsyncThunk<T>
>[1]
export type ThunkApiType<T> = Parameters<ThunkPayloadCreatorType<T>>[1]

type AsyncThunkFetcherType<
  T extends DocumentName,
  S extends keyof NonNullable<GqlResultDataByDocumentName<T>>,
  V extends GetVarsByDocumentName<T>,
> = {
  query: T
  selector: S
  variables: V
  thunkAPI: ThunkApiType<GqlResultDataByDocumentName<T>>
  rejectQueries?: ThunkRejectType['rejectQueries']
  validateDataCallback?: (
    data: NonNullable<GqlResultDataByDocumentName<T>>[S],
  ) =>
    | {
        isValid: true
      }
    | { isValid: false; error: string }
}

export type AsyncThunkFetcherReturnType<
  T extends DocumentName,
  S extends keyof NonNullable<GqlResultDataByDocumentName<T>>,
> = Promise<
  | NonNullable<GqlResultDataByDocumentName<T>>[S]
  | ReturnType<ThunkApiType<never>['rejectWithValue']>
>

export async function asyncThunkFetcher<
  T extends DocumentName,
  S extends keyof NonNullable<GqlResultDataByDocumentName<T>>,
  V extends GetVarsByDocumentName<T>,
>(args: AsyncThunkFetcherType<T, S, V>): AsyncThunkFetcherReturnType<T, S> {
  const {
    query,
    selector,
    variables,
    thunkAPI,
    validateDataCallback,
    rejectQueries = [],
  } = args
  const { graphql } = graphqlClientFactory()

  const myWorkspaceId = thunkAPI.getState().user.myWorkspaceId
  const selectedWorkspaceId = thunkAPI.getState().editor.workspaceId
  const projectId = window.location.pathname.match(
    /project\/([a-z0-9]+)\//,
  )?.[1]

  try {
    const { data, error } = await graphql(query, variables, {
      authContext: {
        projectId,
        workspaceId: selectedWorkspaceId || myWorkspaceId,
      },
    })

    if (error) {
      console.error(`[asyncThunkFetcher] Server error: ${error.message}`)
      return thunkReject({
        code: 'server_error',
        message: 'Server Error',
        thunkAPI,
        rejectQueries,
      })
    }

    if (!data) {
      console.error(`[asyncThunkFetcher] No data returned`)
      return thunkReject({
        code: 'no_data',
        message: 'No data returned',
        thunkAPI,
        rejectQueries,
      })
    }

    const selectedData = data[selector]

    if (!selectedData) {
      return thunkReject({
        code: 'no_data_selection',
        message: 'Selected data not found',
        thunkAPI,
        rejectQueries,
      })
    }

    // Data Validation
    if (validateDataCallback) {
      const validation = validateDataCallback(selectedData)

      if (!validation.isValid) {
        console.error(
          '[asyncThunkFetcher] Validation error: ',
          validation.error,
        )
        return thunkReject({
          code: 'validation_error',
          message: validation.error,
          thunkAPI,
          rejectQueries,
        })
      }
    }

    return selectedData
  } catch (e) {
    if (e instanceof Error) {
      console.error(`[asyncThunkFetcher] Client error: ${e.message}`)
      return thunkReject({
        code: 'client_error',
        message: 'Client Error',
        thunkAPI,
        rejectQueries,
      })
    } else {
      return thunkReject({
        code: 'unexpected_error',
        message: '',
        thunkAPI,
        rejectQueries,
      })
    }
  }
}
