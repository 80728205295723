import type { SVGProps } from 'react'
const SvgSceneLogoInvert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 500 500"
    {...props}
  >
    <rect width={500} height={500} fill="#F5F6F3" rx={250} />
    <path
      fill="#5E56F9"
      fillRule="evenodd"
      d="M366.288 249.999h.047V133.632H251.902v-.048h-64.694c-32.149 0-58.208 26.059-58.208 58.208s26.059 58.207 58.208 58.207h64.694v-95.083c10.008 54.1 57.399 95.068 114.386 95.068zM312.784 250h-60.888v103.664C245.567 295.377 196.21 250 136.241 250h-2.585v116.415h2.538v-.047h115.702v.047h60.888c32.149 0 58.207-26.058 58.207-58.207S344.933 250 312.784 250"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSceneLogoInvert
