import React from 'react'
import CTABanner1 from './CTABanner1'
import { ctaBanner1DefaultConfig } from './CTABanner1Meta'

const CTABanner1Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : ctaBanner1DefaultConfig

  return <CTABanner1 {...defaultData} />
}

export default CTABanner1Preview
