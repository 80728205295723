import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { FooterVariant2Block } from '@sceneio/schemas/lib/types'
import {
  FooterVariant1,
  footerVariant1Plugins,
} from '../FooterVariant1/FooterVariant1'
import { footerVariant2DefaultConfig } from './footerVariant2Meta'

function FooterVariant2(
  props: FooterVariant2Block = footerVariant2DefaultConfig,
) {
  return <FooterVariant1 {...props} />
}

export const footerVariant2Plugins: PluginsType = footerVariant1Plugins

export default FooterVariant2
