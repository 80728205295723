import { BlockType, ResponsiveSpace } from '../../types/blockType'

type GenerateBlockMarginAndPaddingClsxClassesArgsType = {
  blockPadding?: BlockType['blockPadding']
  blockMargin?: BlockType['blockMargin']
  options?: {
    limitToOrientation?: ('left' | 'right' | 'bottom' | 'top')[]
  }
}

// typecast Object.keys
const keys = Object.keys as <T>(o: T) => Extract<keyof T, string | number>[]

function checkForAxisLimitation({
  orientation,
  limitToOrientation,
}: {
  orientation: keyof ResponsiveSpace
  limitToOrientation?: (keyof ResponsiveSpace)[]
}): boolean {
  if (limitToOrientation && limitToOrientation.length > 0) {
    return limitToOrientation.includes(orientation)
  }

  return true
}

export function generateBlockMarginAndPaddingClsxClasses({
  blockPadding = {},
  blockMargin = {},
  options,
}: GenerateBlockMarginAndPaddingClsxClassesArgsType): Record<string, boolean> {
  let classes = {}
  const { limitToOrientation } = options || {}

  keys(blockPadding).forEach((breakpoint) => {
    keys(blockPadding[breakpoint]).forEach((orientation) => {
      const shouldResolve = checkForAxisLimitation({
        orientation,
        limitToOrientation,
      })

      if (!shouldResolve) {
        return classes
      }

      const value = blockPadding?.[breakpoint]?.[orientation]
      classes = {
        ...classes,
        [`sc-block--padding-${breakpoint}-${orientation}-${value}`]:
          typeof value === 'number',
      }
    })
  })
  keys(blockMargin || {}).forEach((breakpoint) => {
    keys(blockMargin[breakpoint]).forEach((orientation) => {
      const value = blockMargin?.[breakpoint]?.[orientation]

      const shouldResolve = checkForAxisLimitation({
        orientation,
        limitToOrientation,
      })

      if (!shouldResolve) {
        return classes
      }

      classes = {
        ...classes,
        [`sc-block--margin-${breakpoint}-${orientation}-${value}`]:
          typeof value === 'number',
      }
    })
  })

  return classes
}

export function generateContainerClsxClasses(
  container: BlockType['container'],
) {
  return {
    container: container === 'container',
    'container-fluid': container === 'container-fluid',
    'container-10': container === 'container-10',
    'container-20': container === 'container-20',
    'container-30': container === 'container-30',
    'container-40': container === 'container-40',
    'container-50': container === 'container-50',
    'container-60': container === 'container-60',
    'container-70': container === 'container-70',
    'container-80': container === 'container-80',
    'container-90': container === 'container-90',
    'container-grid': container === 'container-grid',
  }
}

type PostionType = 'relative' | 'static' | 'fixed' | 'sticky'
type ResponsivePositionType = {
  default: PostionType
  xs?: PostionType
  sm?: PostionType
  md?: PostionType
  lg?: PostionType
  xl?: PostionType
}

export function generatePositionClasses({
  position,
}: {
  position: ResponsivePositionType
}) {
  if (!position) {
    return {}
  }
  return Object.entries(position).reduce((acc, [breakpoint, value]) => {
    if (value) {
      return {
        ...acc,
        [`sc-block--position-${breakpoint}-${value}`]: true,
      }
    }
    return acc
  }, {})
}
