import {
  ButtonComponent,
  HeaderVariant3Block,
} from '@sceneio/schemas/lib/types'
import { mergeDeepRight, randomString } from '@sceneio/tools'
import {
  DEFAULT_ICON_BUTTON_CSS_VARIABLES,
  DEFAULT_LINK_BUTTON_CSS_VARIABLES,
} from '../../../components/Button/constants/globalButtonCssVariables'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import {
  menuContainerDisplayAdditionalFormFieldRenderer,
  menuContainerLayoutAdditionalFormFieldRenderer,
} from '../HeaderVariant1/form/additionalFormFieldRenderers'
import { textContentSampleFactory } from '../HeaderVariant1/headerVariant1Meta'

export const buttonSampleFactory = (): ButtonComponent => ({
  id: randomString(8),
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  children: textToLexical('Button', 'array-item'),
  link: {
    type: 'internal',
    href: '#',
    target: '_self',
  },
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
})

export const navLinkSampleFactory = ({
  disableNestedItems,
}: { disableNestedItems?: boolean } = {}): ButtonComponent =>
  mergeDeepRight(DEFAULT_LINK_BUTTON_CSS_VARIABLES, {
    id: randomString(8),
    meta: {
      dataType: 'button-component',
    },
    children: textToLexical('Menu item', 'array-item'),
    link: {
      type: 'internal',
      href: '#',
      target: '_self',
    },
    ...(!disableNestedItems && {
      items: [],
    }),
    font: {
      meta: {
        dataType: 'font-style',
      },
      data: {
        default: {
          '--font-default-size-default': '0.85rem',
          '--font-xs-size-default': '1.3rem',
        },
      },
    },
    padding: {
      data: {
        '--padding-default-right': '0.5rem',
        '--padding-default-left': '0.5rem',
        '--padding-default-top': '0.5rem',
        '--padding-default-bottom': '0.5rem',
      },
    },
  })

export const headerVariant3ComponentMeta: BlockComponentMeta<HeaderVariant3Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        minHeight: {
          data: {
            '--min-height-default': '5rem',
          },
        },
        className: 'sc-header--vertical',
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
            rowGap: '1rem',
            columnGap: '1.5rem',
          },
        },
        padding: {
          data: {
            '--padding-default-left': '1rem',
            '--padding-default-right': '1rem',
            '--padding-default-bottom': '1.5rem',
            '--padding-default-top': '1.5rem',
            '--padding-xs-bottom': '0rem',
            '--padding-md-bottom': '0rem',
            '--padding-xs-top': '0rem',
            '--padding-md-top': '0rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        background: {
          responsiveConfig: {
            default: {
              color: '#fff',
            },
          },
        },
        direction: 'horizontal',
      },
      type: 'blockContainer',
    },
    topBanner: {
      path: 'components.topBanner',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Text',
          type: 'text',
          disableToggleShow: true,
          sampleFactory: textContentSampleFactory,
        },
      },
      inlineEditors: [
        {
          editorType: 'inlineText',
          editorPath: 'components.content.items',
          editorOptions: {
            isItemArray: true,
            include: ['table', 'list'],
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        show: false,
        items: [textContentSampleFactory()],
        padding: {
          data: {
            '--padding-default-left': '1rem',
            '--padding-default-right': '1rem',
            '--padding-default-top': '1.5rem',
            '--padding-default-bottom': '1.5rem',
          },
        },
        gap: {
          data: {
            '--row-gap-default': '1rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'center',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        flow: {
          data: {
            '--flow-default': 'column',
          },
        },
        background: {
          responsiveConfig: {
            default: {
              color: '#F4F4F4',
            },
          },
        },
      },
      configEditor: {
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
      },
      name: 'Sticky banner',
      type: 'container',
    },
    logo: {
      path: 'components.logo',
      name: 'Logo',
      defaultConfig: {
        meta: {
          dataType: 'image-component',
        },
        show: true,
        responsiveConfig: {
          default: {
            src: 'https://assets.scene.io/global/placeholders/logo-ipsum-horizontal.svg',
            alt: '',
            width: 150,
          },
        },
      },
      type: 'image',
    },
    menu: {
      path: 'components.menu',
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Link',
          type: 'button',
          disableToggleShow: true,
          sampleFactory: navLinkSampleFactory,
          components: {
            items: {
              isArrayComponent: true,
              allowEmptyArray: true,
              path: 'items',
              name: 'Link',
              type: 'button',
              disableToggleShow: true,
              sampleFactory: () =>
                navLinkSampleFactory({ disableNestedItems: true }),
            },
          },
        },
      },
      inlineEditors: [
        {
          editorType: 'inlineNavLink',
          editorPath: 'components.menu.items',
          editorOptions: {
            sampleFactory: navLinkSampleFactory,
          },
        },
      ],
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        items: [
          {
            ...navLinkSampleFactory(),
            children: textToLexical('The most important', 'array-item'),
          },
          {
            ...navLinkSampleFactory(),
            children: textToLexical('Navigationally useful', 'array-item'),
          },
          {
            ...navLinkSampleFactory(),
            children: textToLexical('Limited in number', 'array-item'),
          },
          {
            ...navLinkSampleFactory(),
            children: textToLexical('Accessible', 'array-item'),
          },
        ],
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-start',
          },
        },
        gap: {
          data: {
            '--row-gap-default': '1rem',
            '--column-gap-default': '0.75rem',
          },
        },
        padding: {
          data: {
            '--padding-md-left': '1.5rem',
            '--padding-md-right': '1.5rem',
            '--padding-md-bottom': '1.5rem',
            '--padding-md-top': '1.5rem',
            '--padding-xs-left': '1.5rem',
            '--padding-xs-right': '1.5rem',
            '--padding-xs-bottom': '1.5rem',
            '--padding-xs-top': '1.5rem',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-xs': 'column',
            '--flow-md': 'column',
          },
        },
        displayTarget: {
          xs: 'mobile',
          md: 'mobile',
          lg: 'desktop',
        },
        show: true,
      },
      configEditor: {
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
        additionalFormFields: {
          layout: {
            formFieldRenderer: menuContainerLayoutAdditionalFormFieldRenderer,
          },
          display: {
            formFieldRenderer: menuContainerDisplayAdditionalFormFieldRenderer,
          },
        },
      },
      name: 'Menu',
      type: 'container',
    },
    buttons: {
      path: 'components.buttons',
      inlineEditors: [
        {
          editorType: 'buttonEditor',
          editorPath: 'components.buttons.items',
          editorOptions: {
            isItemArray: true,
            sampleFactory: buttonSampleFactory,
          },
        },
      ],
      components: {
        items: {
          isArrayComponent: true,
          path: 'items',
          name: 'Button',
          type: 'button',
          disableToggleShow: true,
          sampleFactory: buttonSampleFactory,
        },
      },
      defaultConfig: {
        meta: {
          dataType: 'container-component',
        },
        items: [
          {
            ...buttonSampleFactory(),
            children: textToLexical('Call-to-Action', 'array-item'),
          },
        ],
        show: false,
        padding: {
          data: {
            '--padding-md-left': '1.5rem',
            '--padding-md-right': '1.5rem',
            '--padding-md-bottom': '1.5rem',
            '--padding-md-top': '1.5rem',
            '--padding-xs-left': '1.5rem',
            '--padding-xs-right': '1.5rem',
            '--padding-xs-bottom': '1.5rem',
            '--padding-xs-top': '1.5rem',
          },
        },
        gap: {
          data: {
            '--row-gap-default': '1rem',
            '--column-gap-default': '0.75rem',
          },
        },
        horizontalAlignment: {
          data: {
            '--horizontal-alignment-default': 'flex-end',
            '--horizontal-alignment-xs': 'center',
            '--horizontal-alignment-md': 'center',
          },
        },
        verticalAlignment: {
          data: {
            '--vertical-alignment-default': 'center',
          },
        },
        flow: {
          data: {
            '--flow-default': 'row',
            '--flow-md': 'column',
            '--flow-xs': 'column',
          },
        },
        displayTarget: {
          xs: 'mobile',
          md: 'mobile',
          lg: 'desktop',
        },
      },
      configEditor: {
        additionalFormFields: {
          layout: {
            formFieldRenderer: menuContainerLayoutAdditionalFormFieldRenderer,
          },
          display: {
            formFieldRenderer: menuContainerDisplayAdditionalFormFieldRenderer,
          },
        },
        formProps: {
          name: 'componentArray',
          registerValues:
            containerComponentFormRegisterValuesFactory('componentArray'),
        },
      },
      name: 'Buttons',
      type: 'container',
    },
    toggler: {
      path: 'components.toggler',
      name: 'Mobile menu action',
      defaultConfig: mergeDeepRight(DEFAULT_ICON_BUTTON_CSS_VARIABLES, {
        show: true,
        meta: {
          dataType: 'button-component',
        },
        components: {
          icon: {
            meta: {
              dataType: 'icon-component',
            },
            mode: 'only',
            width: {
              data: {
                '--width-default': '24px',
              },
            },
            color: {
              data: {
                default: {
                  '--color-default-default': '#000',
                },
              },
            },
            responsiveConfig: {
              default: {
                default: {
                  name: 'List',
                  provider: 'phosphor',
                },
                active: {
                  name: 'X',
                  provider: 'phosphor',
                },
              },
            },
          },
        },
      }),
      type: 'button',
    },
  }

export const headerVariant3BlockMeta: BlockMeta<HeaderVariant3Block> = {}

export const headerVariant3DefaultConfig =
  blockDefaultConfigFactory<HeaderVariant3Block>({
    componentMeta: headerVariant3ComponentMeta,
    blockMeta: headerVariant3BlockMeta,
    version: 1,
  })
