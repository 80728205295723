import {
  ALL_BREAKPOINTS,
  AVAILABLE_BREAKPOINTS,
  BreakpointEnum,
} from '../constants/breakpoints'

// Resolves breakpoint overals.
// We may use only use a subset of breakpoints for example: default, xs, md and lg, however we also support sm and xl
// so we need the previouse breakpoints to cover these (eg. sm covered by xs and xl covered by lg)
export function resolveBreakpointOverlaps(
  selectedBreakpoints: BreakpointEnum[] = AVAILABLE_BREAKPOINTS,
  breakpoints: BreakpointEnum[] = ALL_BREAKPOINTS,
) {
  return selectedBreakpoints.reduce<{ [key in BreakpointEnum]?: string[] }>(
    (acc, val, key) => {
      // skip default
      if (val === 'default') {
        return acc
      }

      // Find the index of the current breakpoint in the 'breakpoints' array
      const bpIndex = breakpoints.findIndex((value) => value === val)

      // Find the index of the next breakpoint in the 'breakpoints' array
      const nextBpIndex = breakpoints.findIndex(
        (value) => value === selectedBreakpoints[key + 1],
      )

      // Extract the subset of breakpoints between the current and next breakpoint
      const arrBetween = breakpoints.slice(
        bpIndex,
        nextBpIndex >= 0 ? nextBpIndex : breakpoints.length,
      )

      return { ...acc, [val]: arrBetween }
    },
    {},
  )
}
