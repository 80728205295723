import React from 'react'
import clsx from 'clsx'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { InputFieldComponent } from '@sceneio/schemas/lib/types'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import TextArea from './components/TextArea'
import TextInput from './components/TextInput'

function Input({
  id,
  type,
  components: { icon } = {},
  config: {
    name,
    maskedName,
    placeholder,
    required,
    pattern,
    minLength,
    maxLength,
    rows,
    cols,
    defaultValue,
  } = {},
  honeypot,
  margin,
  padding,
  boxShadow,
  border,
  color,
  backgroundColor,
  placeholderColor,
  font,
  show = true,
  editor,
  style,
  className,
}: InputFieldComponent) {
  if (!show) {
    return null
  }

  const Children = type === 'textarea' ? TextArea : TextInput

  return (
    <div
      data-component-id={editor?.id}
      className={clsx(
        'sc-input-field',
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        className,
      )}
      data-plugin-animation={true}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            margin,
            padding,
            border,
            color,
            backgroundColor,
            font,
          },
          prefix: 'input',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'input',
        }),
        ...getRootLevelCssVariables({
          cssVariables: {
            placeholderColor,
          },
          prefix: 'placeholder',
        }),
      }}
      onClick={() => document.getElementById(id!)?.focus()}
    >
      <Children
        id={id}
        type={type}
        components={{ icon }}
        config={{
          name,
          maskedName,
          placeholder,
          required,
          pattern,
          minLength,
          maxLength,
          defaultValue,
          rows,
          cols,
        }}
        honeypot={honeypot}
      />
    </div>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}

export default Input
