import { InferType, object, string } from 'yup'

const flowSchema = string().oneOf(['row', 'column'])
export const flowStyleSchema = object({
  data: object({
    // default
    '--flow-default': flowSchema,
    // xs
    '--flow-xs': flowSchema,
    // md
    '--flow-md': flowSchema,
    // lg
    '--flow-lg': flowSchema,
  }).noUnknown(),
}).noUnknown()

export type FlowStyle = InferType<typeof flowStyleSchema>
