import clsx from 'clsx'

export const SegmentWrapper = ({
  children,
  className,
}: {
  children: any
  className?: string
}) => {
  return (
    <div
      className={clsx(
        'sc-segment-wrapper tw-border-b tw-border-border-color tw-py-3 tw-px-2 tw-flex tw-flex-col tw-gap-2',
        className,
      )}
    >
      {children}
    </div>
  )
}
