import { InferType, object } from 'yup'
import { cssDimensionSchema } from './common/cssDimensionSchema'

export const widthStyleSchema = object({
  data: object({
    '--width-default': cssDimensionSchema,
    '--width-xs': cssDimensionSchema,
    '--width-md': cssDimensionSchema,
    '--width-lg': cssDimensionSchema,
  }).noUnknown(),
}).noUnknown()

export type WidthStyle = InferType<typeof widthStyleSchema>
