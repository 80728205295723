/**
 * Checks if a given string is a valid JSON string.
 *
 * TODO stringified array will also return tru
 *
 * @param {string} str - The string to be checked.
 * @return {boolean} - Returns true if the string is a valid JSON string, otherwise false.
 */
export function isJsonString(str: string) {
  try {
    const o = JSON.parse(str)

    if (!o || typeof o !== 'object') {
      throw new Error('Invalid Object')
    }
  } catch (e) {
    return false
  }
  return true
}
