import clsx from 'clsx'
import React from 'react'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { resolveBreakpointImageClasses } from '../Image/helpers/resolveBreakpointImageClasses'
import { resolveDefaultImageClasses } from '../Image/helpers/resolveDefaultImageClasses'
import { BreakpointEnum } from '../Image/constants/breakpoints'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { ContainerComponent } from '@sceneio/schemas/lib/types'
import { twicPicsSourceUrlResolver } from '@sceneio/middleware/lib/twicPicsSourceUrlResolver'
import { getContainerFlowClasses } from './containerHelpers'

export const AVAILABLE_CONTAINER_NAMES = [
  'group',
  'componentArray',
  'component',
]

export function Container({
  name = 'group',
  className,
  children,
  as = 'div',
  padding,
  margin,
  border,
  boxShadow,
  background,
  horizontalAlignment,
  verticalAlignment,
  gap,
  show = true,
  style = {},
  flow,
  editor,
}: ContainerComponent) {
  if (!show) {
    return null
  }

  const {
    fit,
    parallax,
    responsiveConfig: bgResponsiveConfig,
  } = background || {}

  const Component = as as keyof JSX.IntrinsicElements

  return (
    <Component
      data-component-id={editor?.id}
      className={clsx(
        'sc-container',
        className,
        editor?.resolveClassName?.({
          blockId: editor.blockId,
          componentId: editor.id,
        }),
        {
          [`sc-container--${name}`]: name,
        },
        getContainerFlowClasses({ flow }),
      )}
      style={{
        ...style,
        ...getBackgroundCssVariables({
          background: background || {},
          prefix: `container-${name}`,
        }),
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            border,
            horizontalAlignment,
            verticalAlignment,
            background,
            gap,
            flow,
          },
          prefix: `container-${name}`,
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: `container-${name}`,
        }),
      }}
    >
      {bgResponsiveConfig && Object.keys(bgResponsiveConfig).length && (
        <>
          <div className="sc-container__bg-overlay" />
          {Object.entries(bgResponsiveConfig).map(([bp, config]) => {
            const { image } = config || {}
            return (
              <React.Fragment key={bp}>
                {image?.src && (
                  <div
                    style={{
                      backgroundImage: `url(${image.src}?twic=v1/output=preview)`,
                    }}
                    data-twic-background={`url(${twicPicsSourceUrlResolver(
                      image.src,
                    )}${editor ? `?cover=${fit === 'cover'}` : ''})`}
                    className={clsx('sc-container__bg-image', {
                      'sc-container__bg-image--parallax': parallax,
                      'sc-container__bg-image--cover': fit === 'cover',
                      [resolveBreakpointImageClasses({
                        breakpoint: bp as BreakpointEnum,
                        elementClassName: 'sc-container__bg-image',
                      })]: bp !== 'default',
                      [resolveDefaultImageClasses({
                        breakpointsArr: (
                          Object.keys(bgResponsiveConfig) as BreakpointEnum[]
                        ).filter((bp) => bgResponsiveConfig[bp]),
                        elementClassName: 'sc-container__bg-image',
                      })]: bp === 'default',
                    })}
                  />
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
      {children}
    </Component>
  )
}

export const plugins: PluginsType = {
  js: [],
  css: [],
}
