import React from 'react'
import Buttons from './Buttons'
import { buttonsDefaultConfig } from './buttonsMeta'

const ButtonsPreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : buttonsDefaultConfig
  return <Buttons {...defaultData} />
}

export default ButtonsPreview
