import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useUserStore } from '../hooks/store/storeHooks'

export function useGoogleAnalytics() {
  const location = useLocation()
  const { isLoggedIn, id } = useUserStore()
  const gaMeasurementId = import.meta.env.VITE_GA_MEASUREMENT_ID
  const isProd = import.meta.env.PROD
  useEffect(() => {
    if (gaMeasurementId && isProd) {
      ReactGA.initialize(gaMeasurementId, {
        // gaOptions: {
        //   debug_mode: true,
        // },
        gtagOptions: {
          send_page_view: false,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      ReactGA.set({ userId: id })
    }
  }, [isLoggedIn])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
    })
  }, [location.pathname])
}
