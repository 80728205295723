import React from 'react'
import FeatureGrid4 from './FeatureGrid4'
import { featureGrid4DefaultConfig } from './featureGrid4Meta'

const FeatureGrid4Preview = (config: any) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : featureGrid4DefaultConfig

  return <FeatureGrid4 {...defaultData} />
}

export default FeatureGrid4Preview
