import { InferType, string } from 'yup'

export const cssAlignmentSchema = string().oneOf([
  'start',
  'end',
  'left',
  'right',
  'center',
  'justify',
  'justify-all',
])

export type CssAlignment = InferType<typeof cssAlignmentSchema>
