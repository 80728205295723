import type { UIState, ActionWithPayload } from '../types'
import { UI, USER } from '../constatnts'

const initialState: UIState = {
  isHeaderFixed: false,
  isLoading: false,
}
export default function uiReducer(
  state: UIState = initialState,
  {
    type,
  }: /* , payload */

  ActionWithPayload<UIState>,
): UIState {
  /** HEADER */
  if (type === UI.SWITCH_HEADER) {
    return {
      ...state,
      isHeaderFixed: !state.isHeaderFixed,
    }
  }

  /** LOADING */
  if (type === UI.SET_LOADING_START) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (type === UI.SET_LOADING_STOP) {
    return {
      ...state,
      isLoading: false,
    }
  }

  if (type === USER.LOGOUT) {
    return initialState
  }

  return state
}
