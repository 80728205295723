import { InferType, string } from 'yup'

export const cssPositionSchema = string().oneOf([
  'relative',
  'static',
  'fixed',
  'sticky',
])

export type CssPosition = InferType<typeof cssPositionSchema>
