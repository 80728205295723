import React from 'react'
import clsx from 'clsx'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { Container } from '../Container/Container'
import Text from '../Text/Text'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { AccordionItemComponent } from '@sceneio/schemas/lib/types'
import Button from '../Button/Button'
import { WebIcon } from '../WebIcon/WebIcon'

function AccordionItem({
  padding,
  margin,
  boxShadow,
  border,
  style,
  editor,
  cid,
  show,
  className,
  swapIconSide,
  components: { headerContainer, bodyContainer, heading, text, icon },
}: AccordionItemComponent) {
  if (!show) {
    return null
  }

  const accordionItemClasses = clsx(
    'sc-accordion-item',
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  return (
    <div
      data-component-id={editor?.id}
      data-plugin-accordion-item={true}
      className={accordionItemClasses}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            padding,
            margin,
            border,
          },
          prefix: 'accordion-item',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'accordion-item',
        }),
      }}
    >
      <div data-plugin-accordion-header className="sc-accordion-item__header">
        <Container
          {...headerContainer}
          name="group"
          editor={{
            ...editor,
            id: `${editor?.id}.headerContainer`,
          }}
          flow={{ data: { '--flow-default': 'row' } }}
          className="bui-justify-content-between"
        >
          <Text
            {...heading}
            className={clsx({ 'bui-order-1': swapIconSide })}
            editor={{
              ...editor,
              id: `${editor?.id}.heading`,
            }}
            cid={resolveComponentId(cid, 'heading')}
          />
          <Button
            {...icon}
            className={clsx('bui-flex-shrink0', {
              'bui-order-0': swapIconSide,
            })}
            editor={{
              ...editor,
              id: `${editor?.id}.icon`,
              wysiwyg: {
                type: 'RAW',
              },
            }}
          >
            <span data-plugin-accordion-collapse-icon>
              <WebIcon className="sc-accordion-item__icon--collapse" icon="X" />
            </span>
            <span data-plugin-accordion-expand-icon>
              <WebIcon
                className="sc-accordion-item__icon--expand"
                icon="Plus"
              />
            </span>
          </Button>
        </Container>
      </div>
      <div data-plugin-accordion-body className="sc-accordion-item__body">
        <Container
          {...bodyContainer}
          name="group"
          editor={{
            ...editor,
            id: `${editor?.id}.bodyContainer`,
          }}
          flow={{ data: { '--flow-default': 'column' } }}
        >
          <Text
            {...text}
            editor={{
              ...editor,
              id: `${editor?.id}.text`,
            }}
            cid={resolveComponentId(cid, 'text')}
          />
        </Container>
      </div>
    </div>
  )
}

export const plugins: PluginsType = {
  js: ['accordion', 'animation'],
  css: [],
}
export default AccordionItem
