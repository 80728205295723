import { InferType, object, string } from 'yup'

export const atomicColorSnippetSchema = object({
  data: object({
    value: string().required(),
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['ATOMIC_COLOR']).required(),
})

export type AtomicColorSnippet = InferType<typeof atomicColorSnippetSchema>
