import React from 'react'
import ParallaxHero from './ParallaxHero'
import { ParallaxHeroBlock } from '@sceneio/schemas/lib/types'
import { parallaxHeroDefaultConfig } from './parallaxHeroMeta'

const ParallaxHeroPreview = (config: ParallaxHeroBlock) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : parallaxHeroDefaultConfig

  return (
    <>
      <ParallaxHero {...defaultData} />
      <ParallaxHero {...defaultData} />
      <ParallaxHero {...defaultData} />
      <ParallaxHero {...defaultData} />
    </>
  )
}

export default ParallaxHeroPreview
