import { BreakpointEnum } from './breakpoints'
import { resolveBreakpointOverlaps } from './resolveBreakpointOverlaps'
import clsx from 'clsx'

// Resolves image classes for a particular breakpoint
// parameters to override available breakpoints are optional

type ResolveBreakpointClassesArgsType = {
  breakpoint: BreakpointEnum
  selectedBreakpoints?: BreakpointEnum[]
  breakpoints?: BreakpointEnum[]
  elementClassName?: string
}
export function resolveBreakpointClasses({
  breakpoint,
  selectedBreakpoints,
  breakpoints,
  elementClassName = 'sc-image__wrapper',
}: ResolveBreakpointClassesArgsType) {
  // Resolves the overlaps between breakpoints
  const breakpointOverlaps = resolveBreakpointOverlaps(
    selectedBreakpoints,
    breakpoints,
  )

  const overlaps = breakpointOverlaps[breakpoint]

  if (!overlaps) {
    return ''
  }

  return overlaps.reduce(
    (classesAcc, classesValue) =>
      clsx(classesAcc, `${elementClassName}--show-${classesValue}`),
    `${elementClassName}--hide`,
  )
}
