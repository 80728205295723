import * as Tabs from '@radix-ui/react-tabs'
import clsx from 'clsx'

export type StateType = 'default' | 'hover' | 'active'

export type RHFStateTabsPropType = {
  defaultStateTabContent: JSX.Element
  hoverStateTabContent: JSX.Element
  activeStateTabContent: JSX.Element
  defaultStateLabel?: string
  hoverStateLabel?: string
  activeStateLabel?: string
  disabledTabs?: string[]
  onTabClick?: (arg0: string) => void
  className?: string
}

export const RHFStateTabs = ({
  defaultStateTabContent,
  hoverStateTabContent,
  activeStateTabContent,
  defaultStateLabel = 'Default',
  hoverStateLabel = 'Hover',
  activeStateLabel = 'Active',
  disabledTabs = [],
  onTabClick,
  className,
}: RHFStateTabsPropType) => {
  const tabButtonClasses = clsx(
    'tw-px-3 tw-py-1 data-[state=active]:tw-bg-white data-[state=active]:tw-shadow-sm data-[state=active]:tw-font-semibold data-[disabled]:tw-opacity-50 tw-rounded-sm',
  )

  return (
    <Tabs.Root
      className="sc-rhf-state-tabs tw-text-xs"
      defaultValue="default"
      onValueChange={(val) => {
        if (onTabClick) {
          onTabClick(val)
        }
      }}
    >
      <Tabs.List
        className={clsx(
          'tw-bg-form-field-bg tw-py-1 tw-px-2 tw-grid tw-grid-cols-3 tw-rounded tw-mb-1',
          className,
        )}
      >
        <Tabs.Trigger
          value="default"
          disabled={disabledTabs.includes('default')}
          className={tabButtonClasses}
        >
          {defaultStateLabel}
        </Tabs.Trigger>
        <Tabs.Trigger
          value="hover"
          disabled={disabledTabs.includes('hover')}
          className={tabButtonClasses}
        >
          {hoverStateLabel}
        </Tabs.Trigger>
        <Tabs.Trigger
          value="active"
          disabled={disabledTabs.includes('active')}
          className={tabButtonClasses}
        >
          {activeStateLabel}
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="default">{defaultStateTabContent}</Tabs.Content>
      <Tabs.Content value="hover">{hoverStateTabContent}</Tabs.Content>
      <Tabs.Content value="active">{activeStateTabContent}</Tabs.Content>
    </Tabs.Root>
  )
}
