import type { SVGProps } from 'react'
import * as React from 'react'

const SvgLockCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.625"
      y="0.625"
      width="38.75"
      height="38.75"
      rx="19.375"
      fill="url(#paint0_linear_7454_51651)"
    />
    <rect
      x="0.625"
      y="0.625"
      width="38.75"
      height="38.75"
      rx="19.375"
      stroke="url(#paint1_linear_7454_51651)"
      strokeWidth="1.25"
    />
    <path
      d="M26.252 15.9384H24.0645V14.3759C24.0645 13.2984 23.6364 12.2651 22.8746 11.5032C22.1127 10.7414 21.0794 10.3134 20.002 10.3134C18.9245 10.3134 17.8912 10.7414 17.1293 11.5032C16.3675 12.2651 15.9395 13.2984 15.9395 14.3759V15.9384H13.752C13.3376 15.9384 12.9401 16.103 12.6471 16.396C12.3541 16.689 12.1895 17.0865 12.1895 17.5009V26.2509C12.1895 26.6653 12.3541 27.0627 12.6471 27.3557C12.9401 27.6487 13.3376 27.8134 13.752 27.8134H26.252C26.6664 27.8134 27.0638 27.6487 27.3568 27.3557C27.6498 27.0627 27.8145 26.6653 27.8145 26.2509V17.5009C27.8145 17.0865 27.6498 16.689 27.3568 16.396C27.0638 16.103 26.6664 15.9384 26.252 15.9384ZM17.8145 14.3759C17.8145 13.7957 18.0449 13.2393 18.4552 12.8291C18.8654 12.4188 19.4218 12.1884 20.002 12.1884C20.5821 12.1884 21.1385 12.4188 21.5487 12.8291C21.959 13.2393 22.1895 13.7957 22.1895 14.3759V15.9384H17.8145V14.3759ZM25.9395 25.9384H14.0645V17.8134H25.9395V25.9384Z"
      fill="#5E56F9"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7454_51651"
        x1="20"
        y1="0"
        x2="20"
        y2="47.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0EFFE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7454_51651"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4F3FF" />
        <stop offset="1" stopColor="#DBD8FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgLockCircleIcon
