import { getRootLevelCssVariables } from './getRootLevelCssVariables'

import { fontsConfig } from '@sceneio/config-fonts'
import type { FontType } from '@sceneio/config-fonts/lib/fonts'

const FONTS_MAP_BY_FONT_FAMILY: Record<string, FontType> = Object.entries(
  fontsConfig,
).reduce((acc, [_, fontDefinition]) => {
  return {
    ...acc,
    [fontDefinition.fontFamily]: fontDefinition,
  }
}, {})

export function getFontSmoothingCssVariables({
  font,
  prefix,
}: {
  font: Record<string, any>
  prefix: string
}) {
  const fontCssVariables = getRootLevelCssVariables({
    cssVariables: { font },
    prefix: prefix,
  }) as Record<string, any>

  const fontFamiliesPerBreakpoint = Object.entries(fontCssVariables).reduce(
    (acc, [cssVariable, val]) => {
      const isFontFamily = cssVariable.includes('family')

      if (isFontFamily) {
        const breakpoint = cssVariable
          .match(/-default-|-xs-|-sm-|-md-|-lg-|-xl-/)?.[0]
          ?.replaceAll('-', '') as string

        if (breakpoint) {
          return {
            ...acc,
            [breakpoint]: val.split(',')[0],
          }
        }
      }
      return acc
    },
    {} as Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', string>,
  )

  const fontSmoothingCssVariables = Object.entries(
    fontFamiliesPerBreakpoint,
  ).reduce((acc, [breakpoint, fontFamily]) => {
    const isFontAntialiased =
      FONTS_MAP_BY_FONT_FAMILY[fontFamily]?.config?.fontAntialiased
    if (isFontAntialiased) {
      return {
        ...acc,
        [`--${prefix}-webkit-${breakpoint}-font-smoothing`]: 'antialiased',
        [`--${prefix}-moz-osx-${breakpoint}-font-smoothing`]: 'greyscale',
      }
    }

    return acc
  }, {} as Record<string, string>)

  return fontSmoothingCssVariables
}
