import type { SVGProps } from 'react'
const SvgMinHeight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      d="M11.277 4.416a.5.5 0 0 0-.554-.832L8.5 5.066V1.5a.5.5 0 0 0-1 0v3.566L5.277 3.584a.5.5 0 0 0-.554.832l3 2a.5.5 0 0 0 .554 0zM2.5 8a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5M4.584 12.277a.5.5 0 0 1 .139-.693l3-2a.5.5 0 0 1 .554 0l3 2a.5.5 0 0 1-.554.832L8.5 10.934V14.5a.5.5 0 0 1-1 0v-3.566l-2.223 1.482a.5.5 0 0 1-.693-.139"
    />
  </svg>
)
export default SvgMinHeight
