import type { SVGProps } from 'react'
const SvgSceneToolbarLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#5E56F9"
      fillRule="evenodd"
      d="M15.689 8h.003V.003H8.126V0H3.848C1.723 0 0 1.79 0 4s1.723 4 3.848 4h4.278V1.466c.662 3.718 3.795 6.533 7.563 6.533zM12.152 8H8.126v7.124C7.707 11.118 4.444 8 .479 8H.31v8h.167v-.003h7.65V16h4.026C14.277 16 16 14.21 16 12s-1.723-4-3.848-4"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSceneToolbarLogo
