export function processValueOnArrowKeyPress(
  e: React.KeyboardEvent<HTMLInputElement>,
  inputValue?: number | string,
  unit: string = '',
) {
  let val = !isNaN(Number(inputValue)) ? Number(inputValue) : 0

  if (e.key === 'ArrowUp') {
    e.preventDefault()
    if (e.shiftKey) {
      const incrementValue = ['rem', 'em'].includes(unit) ? 1 : 10
      val = val + incrementValue
    } else if (e.altKey) {
      val = val + 0.1
    } else {
      const incrementValue = ['rem', 'em'].includes(unit) ? 0.1 : 1
      val = val + incrementValue
    }
  }
  if (e.key === 'ArrowDown') {
    e.preventDefault()
    if (e.shiftKey) {
      const decrementValue = ['rem', 'em'].includes(unit) ? 1 : 10
      val = val - decrementValue
    } else if (e.altKey) {
      val = val - 0.1
    } else {
      const decrementValue = ['rem', 'em'].includes(unit) ? 0.1 : 1
      val = val - decrementValue
    }
  }
  const isDecimal = val % 1 !== 0
  if (isDecimal) {
    val = parseFloat(val.toFixed(1))
  }
  return val
}
