import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { FooterVariant3Block } from '@sceneio/schemas/lib/types'
import {
  FooterVariant1,
  footerVariant1Plugins,
} from '../FooterVariant1/FooterVariant1'
import { footerVariant3DefaultConfig } from './footerVariant3Meta'

function FooterVariant3(
  props: FooterVariant3Block = footerVariant3DefaultConfig,
) {
  return <FooterVariant1 {...props} />
}

export const footerVariant3Plugins: PluginsType = footerVariant1Plugins

export default FooterVariant3
