import React from 'react'
import clsx from 'clsx'
import Block from '../../components/Block/Block'
import Text from '../../components/Text/Text'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { Container } from '../../components/Container/Container'
import Button from '../../components/Button/Button'
import TimelineComponent from '../../components/Timeline/Timeline'
import TimelineItem from '../../components/TimelineItem/TimelineItem'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { TimelineBlock } from '@sceneio/schemas/lib/types'
import { buttonSampleFactory, timelineDefaultConfig } from './timelineMeta'
import { editorItemsControlsFactory } from '../../tools/editor/editor'

function Timeline({
  id,
  components: { block, tagline, heading, text, buttons, timeline },
  editor,
}: TimelineBlock = timelineDefaultConfig) {
  const { className, swapOrder, columnRatio, ...restBlockProps } = block || {}
  const timelineClass = clsx('sc-timeline-block', className)

  const contentColumns = columnRatio!
  const timelineColumns = 12 - contentColumns

  const timelineColumnClasses = clsx('sc-timeline-block__timeline-column', {
    [`bui-col-md-${timelineColumns}`]: timelineColumns,
    'bui-order-md-first': swapOrder,
  })

  const contentColumnClasses = clsx(`sc-timeline-block__content-column`, {
    [`bui-col-md-${contentColumns}`]: contentColumns,
    [`bui-col`]: contentColumns === 0,
  })

  return (
    <Block
      className={timelineClass}
      id={id}
      editor={editor}
      {...restBlockProps}
    >
      <div className="bui-row">
        <div className={contentColumnClasses}>
          <Text
            {...tagline}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'tagline',
            }}
            cid={resolveComponentId(id, 'tagline')}
          />
          <Text
            {...heading}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'heading',
            }}
            cid={resolveComponentId(id, 'heading')}
          />
          <Text
            {...text}
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'text',
            }}
            cid={resolveComponentId(id, 'text')}
          />
          <Container
            className="bui-flex-wrap bui-w-100"
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'buttons',
            }}
            {...buttons}
            name="componentArray"
          >
            {buttons?.items?.map(
              (
                {
                  id: buttonId,
                  className: buttonClassname,
                  ...restButtonProps
                },
                index,
              ) => (
                <Button
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `buttons.items[${index}]`,
                    wysiwyg: {
                      type: 'ITEMS',
                      ...editorItemsControlsFactory({
                        itemsArr: buttons.items || [],
                        itemsId: `buttons.items`,
                        itemFactory: buttonSampleFactory,
                        index: index,
                      }),
                    },
                  }}
                  className={clsx('sc-timeline-block__button', buttonClassname)}
                  key={buttonId}
                  cid={resolveComponentId(id, `button-${index}`)}
                  {...restButtonProps}
                />
              ),
            )}
          </Container>
        </div>
        <div className={timelineColumnClasses}>
          <TimelineComponent
            editor={{
              ...editor?.component,
              blockId: id,
              id: 'timeline',
            }}
            {...timeline}
            name="componentArray"
          >
            {timeline?.items?.map(
              (
                {
                  id: timelineId,
                  className: timelineClassname,
                  ...restAccordionProps
                },
                index,
              ) => (
                <TimelineItem
                  editor={{
                    ...editor?.component,
                    blockId: id,
                    id: `timeline.items[${index}]`,
                  }}
                  className={clsx(
                    'sc-timeline-block__timeline-item',
                    timelineClassname,
                  )}
                  key={timelineId}
                  cid={resolveComponentId(id, `timeline-item-${index}`)}
                  {...restAccordionProps}
                />
              ),
            )}
          </TimelineComponent>
        </div>
      </div>
    </Block>
  )
}

export const timelinePlugins: PluginsType = {
  js: ['animation'],
  css: [],
}
export default Timeline
