import { SnippetType } from '../snippetsTypes'
import { unwrapSnippetEntityReferences } from './unwrapSnippetEntityReferences'

const FILTERS = {
  onlyWithHashDifference: (snippets: SnippetType[]): SnippetType[] => {
    return snippets.filter(({ issued, hash }) => hash !== issued.hash)
  },
}

export function getSnippetDependencyByRefs({
  snippetId,
  snippets,
  filters,
}: {
  snippetId: string
  snippets: SnippetType[]
  filters?: ['onlyWithHashDifference']
}): string[] {
  const snippet = snippets.find((snip) => snip.id === snippetId)

  const unwrappedSnippetReferences = unwrapSnippetEntityReferences({
    entity: snippet,
    snippets,
  })

  const dependantSnippetIds = unwrappedSnippetReferences.map(({ idTo }) => idTo)

  let filteredDependantSnippets = snippets.filter(({ id }) =>
    dependantSnippetIds.includes(id),
  )

  if (filters) {
    filters.forEach((filter) => {
      const filterExists = Object.keys(FILTERS).includes(filter)
      if (filterExists) {
        filteredDependantSnippets = FILTERS[filter](filteredDependantSnippets)
      }
    })
  }

  return filteredDependantSnippets.map(({ id }) => id)
}
