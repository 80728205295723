import React from 'react'
import type { Hero4Block } from '@sceneio/schemas/lib/types'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { hero4DefaultConfig } from './hero4Meta'
import Banner from '../Banner/Banner'

function Hero4(props: Hero4Block = hero4DefaultConfig) {
  return <Banner {...props} />
}
export const hero4Plugins: PluginsType = {
  js: ['video', 'animation', 'social'],
  css: [],
}
export default Hero4
