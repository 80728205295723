import React from 'react'
import { ButtonComponent } from '@sceneio/schemas/lib/types'
import { stringify } from '@sceneio/tools'
import clsx from 'clsx'
import Button from '../../../../components/Button/Button'
import { editorItemsControlsFactory } from '../../../../tools/editor/editor'
import { navLinkSampleFactory } from '../headerVariant1Meta'

export function NavList({
  items = [],
  linkClassName,
  listItemClassName,
  submenuLinkClassName,
  submenuListItemClassName,
  preview,
  resolveEditor,
}: {
  linkClassName?: string
  listItemClassName?: string
  submenuLinkClassName?: string
  submenuListItemClassName?: string
  preview?: boolean
  items?: (ButtonComponent & {
    items?: ButtonComponent[]
  })[]
  resolveEditor: (index: number) => void
}) {
  return (
    <>
      {items.map((navItem, index) => {
        const hasSubmenu = navItem.items && navItem.items.length > 0

        const resolvedEditor: any = resolveEditor(index)

        let editorForceShowSubmenuDropdown = false

        if (resolvedEditor?.selectedComponentId) {
          editorForceShowSubmenuDropdown =
            resolvedEditor?.selectedComponentId?.startsWith(
              `${resolvedEditor?.id}.items`,
            )
        }
        return (
          <li
            key={navItem.id || index}
            className={clsx(
              'nav-item sc-header__nav-item',
              {
                dropdown: hasSubmenu,
              },
              listItemClassName,
            )}
          >
            <Button
              {...navItem}
              isAnimated={false}
              className={clsx(
                'nav-link sc-header__nav-link',
                {
                  'bui-d-flex bui-align-items-center': preview,
                  // active: preview && index === 0,
                },
                {
                  'dropdown-toggle sc-header__dropdown-toggle': hasSubmenu,
                },
                linkClassName,
              )}
              dataAttributes={{
                'data-plugin-link-highlighter': stringify({
                  url: navItem?.link?.href,
                }),
              }}
              {...(hasSubmenu && {
                dataAttributes: {
                  'data-bs-toggle': 'dropdown',
                  'aria-expanded': false,
                },
                id: 'dropdownMenuLink',
                link: {
                  href: '#',
                },
                role: 'button',
              })}
              editor={{
                ...resolvedEditor,
                wysiwyg: {
                  type: 'ITEMS',
                  itemsArr: items,
                  ...editorItemsControlsFactory({
                    itemsArr: items,
                    itemsId: resolvedEditor.itemsId,
                    itemFactory: () =>
                      navLinkSampleFactory({ disableNestedItems: true }),
                    index,
                  }),
                },
              }}
            >
              {navItem.children}
            </Button>
            {hasSubmenu && (
              <ul
                className={clsx(
                  'dropdown-menu sc-header__dropdown-menu bui-m-0 bui-p-0 bui-rounded-0',
                  {
                    show: editorForceShowSubmenuDropdown,
                  },
                )}
                aria-labelledby="dropdownMenuLink"
              >
                {navItem?.items?.map((submenuItem, index) => {
                  return (
                    <li
                      key={submenuItem.id}
                      className={clsx('nav-item', submenuListItemClassName)}
                    >
                      <Button
                        {...submenuItem}
                        isAnimated={false}
                        className={clsx(
                          'nav-link sc-header__nav-link bui-w-100 bui-justify-content-lg-start',
                          submenuLinkClassName || linkClassName,
                        )}
                        dataAttributes={{
                          'data-plugin-link-highlighter': stringify({
                            url: submenuItem?.link?.href,
                          }),
                        }}
                        editor={{
                          ...resolvedEditor,
                          id: `${resolvedEditor?.id}.items[${index}]`,
                          wysiwyg: {
                            type: 'ITEMS',
                            ...editorItemsControlsFactory({
                              itemsArr: navItem.items,
                              itemsId: `${resolvedEditor?.id}.items`,
                              itemFactory: () =>
                                navLinkSampleFactory({
                                  disableNestedItems: true,
                                }),
                              index,
                            }),
                          },
                        }}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
          </li>
        )
      })}
    </>
  )
}
