import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { ALLOWED_ACL_RESOURCES_BY_ROLE } from '../../../../../acl/lib/config/allowedAclResourcesByRole'
import type { AclResourcesType } from '../../../../../acl/lib/config/allowedAclResourcesByRole'

export * from './aclSliceSelectors'

// ---------------
// Initial State
// ---------------
export type AclSliceType = {
  aclRoleId?: string
  aclRolePermissions: AclResourcesType
}
export const initialState: AclSliceType = {
  aclRoleId: undefined,
  aclRolePermissions: [],
}
// ---------------
// Reducer
// ---------------

export const aclSlice = createSlice({
  name: 'acl',
  initialState: () => initialState,
  reducers: {
    setAclRolePersmissionsByAclRoleId: (
      state,
      action: PayloadAction<string>,
    ) => {
      const aclRoleId = action.payload
      state.aclRoleId = aclRoleId

      state.aclRolePermissions = ALLOWED_ACL_RESOURCES_BY_ROLE[
        (aclRoleId || 'id:viewer') as keyof typeof ALLOWED_ACL_RESOURCES_BY_ROLE
      ] as unknown as AclResourcesType
    },
  },
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', () => {
      return initialState
    })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAclDispatch: () => AppDispatch = useDispatch
export const useAclSelector: TypedUseSelectorHook<RootState> = useSelector

export const { setAclRolePersmissionsByAclRoleId } = aclSlice.actions

export default aclSlice.reducer
