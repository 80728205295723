import { GraphQLClient } from 'graphql-hooks'
import { createClient as createGraphqlWsClient } from 'graphql-ws'
import { Ws } from './types'

let clientInstance = new Map()

export function wsClientInstance(arg: Parameters<typeof wsClientFactory>[0]) {
  const key = JSON.stringify(arg)

  if (!clientInstance.has(key)) {
    clientInstance.clear()
    clientInstance.set(key, wsClientFactory(arg))
  }

  return clientInstance.get(key)
}

export type WsClientFactoryConfig = Omit<
  Parameters<typeof createGraphqlWsClient>[0],
  'url' | 'connectionParams'
>

function wsClientFactory(arg: {
  backendUrl: Ws.BackendUrl
  token: Ws.Token
  config?: WsClientFactoryConfig
}) {
  const { backendUrl, token, config } = arg || {}

  if (!backendUrl) {
    throw Error(`[ws:error]: Invalid backendUrl given "${backendUrl}"`)
  }

  if (!backendUrl.startsWith('http')) {
    throw Error(`[ws:error]: BackendUrl must start with "http"`)
  }

  const wsBackendUrl = backendUrl
    .replace(/^http/, 'ws')
    .replace(/\/\/(localhost|0.0.0.0)/, '//127.0.0.1')!

  const cfg = config || {}

  // defaults
  const {
    retryAttempts = 100,
    shouldRetry = ({ code }: any) => {
      if (code === 4477) {
        console.log(
          '%c[ws] Connection closed by server',
          'font-size: 13px; font-weight: bold;',
        )

        return false
      }

      return true
    },
    ...nextOptions
  } = cfg || {}

  // default event callbacks
  const {
    connecting = () => {
      console.log('[ws] connecting ...')
    },
    connected = () => {
      console.log('[ws] connected ✅')
    },
    closed = () => {
      console.log('[ws] closed 👀')
    },
    error = (...args: any[]) => {
      console.error('[ws:error] something is wrong ❌', JSON.stringify(args))
    },
    ...nextOnProps
  } = cfg.on || {}

  return new GraphQLClient({
    url: `${backendUrl}/graphql`,
    subscriptionClient: () =>
      createGraphqlWsClient({
        url: `${wsBackendUrl}/graphql`,
        connectionParams: {
          token: `Bearer ${token}`,
        },
        retryAttempts,
        shouldRetry,
        ...nextOptions,

        on: {
          connecting,
          connected,
          closed,
          error,
          ...nextOnProps,
        },
      }),
  })
}
