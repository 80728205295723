export const computeContainerMaxWidth = (
  containerMaxWidthValue,
  rootContainerMaxWidthValue,
) => {
  // Apply the regex to the value
  const match = containerMaxWidthValue?.match(/(\d+)(.*)/)

  if (match) {
    // is percetange
    const isPercentageValue = match?.[2] === '%'

    if (isPercentageValue) {
      let percentualContainerMaxWidthValue = Number(match?.[1]) / 100

      return `${Math.floor(
        parseInt(rootContainerMaxWidthValue, 10) *
          percentualContainerMaxWidthValue,
      )}px`
    } else {
      return containerMaxWidthValue
    }
  }
  return rootContainerMaxWidthValue
}
