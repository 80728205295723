import flatten, { unflatten } from 'flat'

export function removeUndefinedValues<T extends Record<string, any>>(
  inputObj: T,
): Partial<T> {
  if (inputObj === undefined) {
    return {}
  }

  if (
    typeof inputObj !== 'object' ||
    Object.prototype.toString.call(inputObj) !== '[object Object]'
  ) {
    throw Error(`Unexpected type of object: "${typeof inputObj}"`)
  }

  const obj = inputObj || {}

  if (Object.keys(obj).length === 0) return {}

  const flat = flatten(obj) as Record<string, any>

  Object.keys(flat).forEach((objKey) => {
    if (flat[objKey] === undefined) {
      delete flat[objKey]
    }
  })

  return unflatten(flat)
}
