import type { SVGProps } from 'react'
const SvgMinWidth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#252523"
      d="M8 2.5a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5M3.723 4.584a.5.5 0 0 1 .693.139l2 3a.5.5 0 0 1 0 .554l-2 3a.5.5 0 1 1-.832-.554L5.066 8.5H1.5a.5.5 0 0 1 0-1h3.566L3.584 5.277a.5.5 0 0 1 .139-.693M11.584 11.277a.5.5 0 0 0 .832-.554L10.934 8.5H14.5a.5.5 0 0 0 0-1h-3.566l1.482-2.223a.5.5 0 1 0-.832-.554l-2 3a.5.5 0 0 0 0 .554z"
    />
  </svg>
)
export default SvgMinWidth
