/* eslint-disable no-prototype-builtins */
import { is } from 'ramda'
import { isArray } from './isArray'

export const isArrayLike = (x: any): boolean => {
  if (isArray(x)) {
    return true
  }

  if (!x) {
    return false
  }

  if (typeof x !== 'object') {
    return false
  }

  if (is(String, x)) {
    return false
  }

  if (x.nodeType === 1) {
    return !!x.length
  }

  if (x.length === 0) {
    return true
  }

  if (x.length > 0) {
    return x.hasOwnProperty(0) && x.hasOwnProperty(x.length - 1)
  }

  return false
}
