import { ReferencesType } from '../referencesTypes'
import { buildReferenceDefinition } from './buildReferenceDefinition'
import { EntityType, referenceEntityFactory } from './referenceEntityFactory'

export type ReferencesObjectType = Record<string, string>

export function transformReferencesFromArrayToObject(
  references: ReferencesType = [],
): ReferencesObjectType {
  return references.reduce(
    (acc, { typeTo, idTo, pathFrom, pathTo, resolveMethod = 'REPLACE' }) => {
      const source = buildReferenceDefinition(
        referenceEntityFactory({
          value: idTo,
          entity: typeTo.toLowerCase() as EntityType,
          pathTo,
          resolveMethod,
        }),
      )
      acc[pathFrom] = source
      return acc
    },
    {} as Record<string, any>,
  )
}
