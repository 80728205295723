import { combineReducers } from 'redux'
import userReducer from './user/userReducer'
import uiReducer from './ui/uiReducer'
import systemReducer from './system/systemReducer'
import pageReducer from './page/pageReducer'

export default <R>(additionalReduces: R) =>
  combineReducers({
    user: userReducer,
    ui: uiReducer,
    system: systemReducer,
    page: pageReducer,
    ...additionalReduces,
  })
