import { createHeadlessEditor } from '@lexical/headless'
import wysiwygConfig from '@sceneio/config-wysiwyg'
import { $getRoot } from 'lexical'

export function lexicalToPlainText(editorState: string) {
  try {
    if (!editorState) {
      return ''
    }
    const editor = createHeadlessEditor({ ...wysiwygConfig })
    const parsedEditorState = editor.parseEditorState(editorState)
    return parsedEditorState.read(() => $getRoot().getTextContent())
  } catch (e) {
    return editorState
  }
}
