import { array, object, InferType, bool, number } from 'yup'
import { idSchema } from '../../shared/idSchema'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { gridComponentSchema } from '../../components/gridComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { version } from '../../shared/versionSchema'

export const logoStripBlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema
      .shape({
        swapOrder: bool(),
        columnRatio: number().min(1).max(12),
      })
      .noUnknown(),
    heading: textComponentSchema,
    logoImages: gridComponentSchema
      .shape({
        items: array().of(
          imageComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type LogoStripBlock = InferType<typeof logoStripBlockSchema>
