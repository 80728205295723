import { object, InferType, mixed, string } from 'yup'

export const blockEditorSchema = object({
  component: object({
    resolveClassName:
      mixed<(data: { blockId?: string; componentId?: string }) => any>(),
    selectedComponentId: string(),
    onClick: mixed<(data: { componentId?: string }) => void>(),
  }).noUnknown(),
})
  .noUnknown()
  .optional()

export type BlockEditor = InferType<typeof blockEditorSchema>
