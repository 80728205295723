import React from 'react'
import { subscriptionFormDefaultConfig } from './subscriptionFormMeta'
import SubscriptionForm from './SubscriptionForm'

const SubscriptionFormPreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : subscriptionFormDefaultConfig

  return <SubscriptionForm {...defaultData} />
}

export default SubscriptionFormPreview
