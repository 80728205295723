import * as React from 'react'
import type { SVGProps } from 'react'
const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#252523"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 14H2V9M9 2h5v5"
    />
  </svg>
)
export default SvgExpand
