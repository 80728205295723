import { ClientContext } from 'graphql-hooks'
import { createElement } from 'react'
import { Ws } from './types'
import { wsClientInstance } from './wsClient'

export function WsProvider({
  children,
  token,
  backendUrl,
  config,
}: Ws.ProviderOptions) {
  const client = wsClientInstance({
    backendUrl,
    token,
    config,
  })

  return createElement(ClientContext.Provider, { value: client }, children)
}
