import { ReactNode } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useCanShowPlaceholder } from './useCanShowPlaceholder'
import clsx from 'clsx'

type FixPlaceholderPluginType = {
  children: ReactNode
}

export function FixPlaceholderPlugin({ children }: FixPlaceholderPluginType) {
  const [editor] = useLexicalComposerContext()
  const showPlaceholder = useCanShowPlaceholder(editor)

  return (
    <div
      className={clsx('position-relative', {
        'wysiwyg-fix-placeholder--active': showPlaceholder,
      })}
    >
      {children}
    </div>
  )
}
