import { computeContainerMaxWidth } from './computeContainerMaxWidth'

{
  /* block container form currently supports only xs,md,lg settings,
so other breakpoints have to inherit these settings */
}
export const getFallbackBreakpoint = (bp) => {
  switch (bp) {
    case 'sm':
      return 'xs'
    case 'xl':
      return 'lg'
    case 'xxl':
      return 'lg'
    default:
      return bp
  }
}

export const generateResponsiveContainerStyles = ({
  containerResponsiveConfig,
  rootContainerMaxWidth,
}) => {
  let outputStyles = {}
  Object.entries(rootContainerMaxWidth).forEach(([breakpoint, maxWidth]) => {
    const resolvedBreakpoint = getFallbackBreakpoint(breakpoint)
    outputStyles = {
      ...outputStyles,
      [`--block-container-row-gap-${breakpoint}`]:
        containerResponsiveConfig?.[resolvedBreakpoint]?.rowGap ||
        containerResponsiveConfig?.default?.rowGap ||
        '0rem',
      [`--block-container-column-gap-${breakpoint}`]:
        containerResponsiveConfig?.[resolvedBreakpoint]?.columnGap ||
        containerResponsiveConfig?.default?.columnGap ||
        '0rem',
    }

    const isContainerDisabled =
      containerResponsiveConfig?.[resolvedBreakpoint]?.maxWidth === 'screen' ||
      !containerResponsiveConfig?.default?.maxWidth ||
      containerResponsiveConfig?.default?.maxWidth === 'screen'

    if (isContainerDisabled) {
      return
    }

    const computedContainerMaxWidth = computeContainerMaxWidth(
      containerResponsiveConfig?.[resolvedBreakpoint]?.maxWidth ||
        containerResponsiveConfig?.default?.maxWidth,
      maxWidth,
    )

    if (computedContainerMaxWidth) {
      outputStyles = {
        ...outputStyles,
        [`--block-container-${breakpoint}-max-width`]: computeContainerMaxWidth(
          containerResponsiveConfig?.[resolvedBreakpoint]?.maxWidth ||
            containerResponsiveConfig?.default?.maxWidth,
          maxWidth,
        ),
      }
    }
  })
  return outputStyles
}
