import type { SVGProps } from 'react'
const SvgReusableBlockDetatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.142 6.728 2.444 2.444-1.414 1.414-1.737-1.737-7.585 7.586 1.736 1.737-1.414 1.414-2.444-2.444a1 1 0 0 1 0-1.414l9-9a1 1 0 0 1 1.414 0m-1.414 19.414-2.314-2.314 1.415-1.414 1.606 1.607 7.586-7.586-1.607-1.607 1.415-1.414 2.313 2.314a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-1.414 0m8.299-15.793a1 1 0 0 0-1.414-1.414L9.027 22.521a1 1 0 0 0 1.414 1.414z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgReusableBlockDetatch
