import { ROOT_CONTAINER_MAX_WIDTH_DEFAULTS } from '../../../config/container'
import { getFallbackBreakpoint } from './generateResponsiveContainerStyles'

export function generateDisabledContainerClasses({
  containerResponsiveConfig,
}) {
  return Object.keys(ROOT_CONTAINER_MAX_WIDTH_DEFAULTS).reduce(
    (acc, breakpoint) => {
      const resolvedBreakpoint = getFallbackBreakpoint(breakpoint)
      const resolvedMaxWidth =
        containerResponsiveConfig?.[resolvedBreakpoint]?.maxWidth ||
        containerResponsiveConfig?.default?.maxWidth ||
        'screen'

      return {
        ...acc,
        [`sc-block-container-responsive--disable-${breakpoint}`]:
          resolvedMaxWidth === 'screen',
      }
    },
    {},
  )
}
