import { useEffect, useState } from 'react'
import { useFormContext } from '../../form/context/FormContext'
import clsx from 'clsx'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { Icon } from '@sceneio/ui-icons'
import { BreakpointSpacingFields } from './components/BreakpointSpacingFields'
import { RHFSpacingSettingsPropType } from './SpacingSettings'
import { stringify } from '@sceneio/tools'
import { InheritedSettingsWrapper } from '../../atoms/InheritedSettingsWrapper/InheritedSettingsWrapper'

export const ResponsiveSpacingSettings = ({
  basePath,
  title = 'Spacing',
  type,
  className,
}: RHFSpacingSettingsPropType) => {
  const {
    values,
    unregister,
    register,
    registerValues,
    forceBreakpoint,
    onEditDefaultFrameClick,
  } = useFormContext({
    name: basePath,
  })

  const [isBreakpointActive, setIsBreakpointActive] = useState(false)

  const valuesPath = `${basePath ? basePath + '.' : ''}data`
  const hasBreakpointValues = Object.keys(values).some(
    (val) =>
      val.startsWith(`${valuesPath}`) && val.includes(`-${forceBreakpoint!}-`),
  )
  const hasDefaultValues = Object.keys(values).some(
    (val) => val.startsWith(`${valuesPath}`) && val.includes('-default-'),
  )

  useEffect(() => {
    if (hasBreakpointValues) {
      setIsBreakpointActive(true)
    } else {
      setIsBreakpointActive(false)
    }
  }, [stringify(hasBreakpointValues)])

  return (
    <SegmentWrapper className={clsx('sc-rhf-bp-spacing-settings', className)}>
      <HeaderSegment
        heading={title}
        addButton={{
          show:
            !hasDefaultValues && !hasBreakpointValues && !isBreakpointActive,
          onClick: () => {
            register(registerValues)
            setIsBreakpointActive(true)
          },
        }}
        customChildren={
          <>
            {hasBreakpointValues && (
              <button
                type="button"
                className="tw-p-1"
                onClick={() => {
                  unregister([`${valuesPath}.--${type}-${forceBreakpoint}`])
                }}
              >
                Reset
              </button>
            )}
            {hasDefaultValues && !hasBreakpointValues && (
              <button
                type="button"
                className="tw-p-1"
                onClick={() => {
                  setIsBreakpointActive(!isBreakpointActive)
                }}
              >
                <Icon provider="phosphor" icon="PencilSimple" />
              </button>
            )}
          </>
        }
      />
      {isBreakpointActive && (
        <BreakpointSpacingFields
          breakpoint={forceBreakpoint!}
          type={type}
          valuesPath={valuesPath}
        />
      )}
      {(hasDefaultValues || isBreakpointActive) && (
        <InheritedSettingsWrapper
          onEditClick={() => onEditDefaultFrameClick({ focusedMolecule: type })}
        >
          <BreakpointSpacingFields
            breakpoint={'default'}
            type={type}
            valuesPath={valuesPath}
          />
        </InheritedSettingsWrapper>
      )}
    </SegmentWrapper>
  )
}
