import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { FeatureGrid5Block } from '@sceneio/schemas/lib/types'
import FeatureGrid2, { featureGrid2Plugins } from '../FeatureGrid2/FeatureGrid2'
import { featureGrid5DefaultConfig } from './featureGrid5Meta'

function FeatureGrid5(props: FeatureGrid5Block = featureGrid5DefaultConfig) {
  return <FeatureGrid2 {...props} />
}

export const featureGrid5Plugins: PluginsType = featureGrid2Plugins

export default FeatureGrid5
