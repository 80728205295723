import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowsMinimize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.74922 0.92884L8.75054 4.42062L9.59023 3.58093C9.73089 3.44028 9.92165 3.36126 10.1206 3.36126C10.3195 3.36126 10.5102 3.44028 10.6509 3.58093C10.7915 3.72158 10.8706 3.91235 10.8706 4.11126C10.8706 4.31017 10.7915 4.50094 10.6509 4.64159L8.52957 6.76291C8.38892 6.90356 8.19816 6.98258 7.99924 6.98258C7.80033 6.98258 7.60957 6.90356 7.46891 6.76291L5.34759 4.64159C5.20694 4.50094 5.12792 4.31017 5.12792 4.11126C5.12792 3.91235 5.20694 3.72158 5.34759 3.58093C5.48825 3.44028 5.67901 3.36126 5.87792 3.36126C6.07684 3.36126 6.2676 3.44028 6.40825 3.58093L7.24794 4.42062L7.2475 0.927956C7.2475 0.728699 7.32666 0.537602 7.46755 0.396707C7.60845 0.25581 7.79954 0.176655 7.9988 0.176655C8.19806 0.176655 8.38916 0.25581 8.53005 0.396706C8.67095 0.537602 8.7501 0.728699 8.7501 0.927956L8.74922 0.92884ZM7.46891 9.23779L5.34759 11.3591C5.20694 11.4998 5.12792 11.6905 5.12792 11.8894C5.12792 12.0883 5.20694 12.2791 5.34759 12.4198C5.48825 12.5604 5.67901 12.6394 5.87792 12.6394C6.07684 12.6394 6.2676 12.5604 6.40825 12.4198L7.24794 11.5801L7.24883 15.0714C7.24883 15.2707 7.32798 15.4618 7.46888 15.6027C7.60977 15.7436 7.80087 15.8227 8.00013 15.8227C8.19938 15.8227 8.39048 15.7436 8.53138 15.6027C8.67227 15.4618 8.75143 15.2707 8.75143 15.0714L8.75054 11.5801L9.59023 12.4198C9.73089 12.5604 9.92165 12.6394 10.1206 12.6394C10.3195 12.6394 10.5102 12.5604 10.6509 12.4198C10.7915 12.2791 10.8706 12.0883 10.8706 11.8894C10.8706 11.6905 10.7915 11.4998 10.6509 11.3591L8.52957 9.23779C8.38892 9.09713 8.19816 9.01812 7.99924 9.01812C7.80033 9.01812 7.60957 9.09713 7.46891 9.23779Z"
      fill="#252523"
    />
  </svg>
)
export default SvgArrowsMinimize
