import {
  ReferenceItemType,
  ReferencesType,
} from '@sceneio/referencing-tools/lib/referencesTypes'
import { SnippetType } from '../snippetsTypes'

const MAX_DEPTH_LEVEL = 10

export const unwrapSnippetEntityReferences = ({
  entity,
  depthLevel = 0,
  snippets,
  pathFrom,
  unwrappedReferences = [],
}: {
  entity: { references?: ReferencesType; [key: string]: any }
  depthLevel?: number
  snippets: SnippetType[]
  pathFrom?: string
  unwrappedReferences?: ReferencesType
}): ReferencesType => {
  if (depthLevel > MAX_DEPTH_LEVEL) {
    throw Error('[unwrapEntityReferences]: Max depth level reached')
  }

  if (!entity.references) {
    return []
  }

  return entity.references.reduce((acc, reference) => {
    // skip if irrelevant reference
    if (reference.typeTo !== 'SNIPPET') {
      return acc
    }

    const snippet = snippets.find((snip) => snip.id === reference.idTo)

    const resolvedPathFrom = pathFrom
      ? `${pathFrom}.${reference.pathFrom.substring(
          reference.pathTo.length + 1,
        )}`
      : reference.pathFrom

    const resolvedReference = {
      ...reference,
      pathFrom: resolvedPathFrom,
    }

    const resolvedReferences = snippet?.references?.length
      ? unwrapSnippetEntityReferences({
          entity: snippet,
          snippets,
          pathFrom: resolvedPathFrom,
          depthLevel: depthLevel + 1,
          unwrappedReferences: [resolvedReference],
        })
      : [resolvedReference]

    return [...acc, ...resolvedReferences]
  }, unwrappedReferences)
}
