import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'

import { HeaderVariant2Block } from '@sceneio/schemas/lib/types'
import {
  HeaderVariant1,
  headerVariant1Plugins,
} from '../HeaderVariant1/HeaderVariant1'
import { headerVariant2DefaultConfig } from './headerVariant2Meta'

function HeaderVariant2(
  props: HeaderVariant2Block = headerVariant2DefaultConfig,
) {
  return <HeaderVariant1 {...props} />
}

export const headerVariant2Plugins: PluginsType = headerVariant1Plugins

export default HeaderVariant2
