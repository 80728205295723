import type { SVGProps } from 'react'
const SvgGalleryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    style={{
      height: 'inherit',
      width: 'inherit',
      maxHeight: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      verticalAlign: 'top',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    }}
    viewBox="0 0 51.6 52.3"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M9.8 48.4c-3 0-5.6-2.2-5.9-5.2L0 10.3C-.4 7 2 4 5.2 3.7L37.1 0c3.3-.4 6.3 2 6.6 5.2L47.5 38c.2 1.6-.3 3.1-1.2 4.4-1 1.3-2.4 2.1-4 2.2l-31.9 3.7c-.2 0-.4.1-.6.1M37.8 2h-.5L5.4 5.7C3.3 6 1.8 7.9 2 10.1l3.8 32.8C6 45 8 46.6 10.2 46.3l31.9-3.7c1-.1 1.9-.6 2.6-1.5.6-.8.9-1.9.8-2.9L41.7 5.4C41.5 3.5 39.8 2 37.8 2" />
    <path d="m4.22 37.505 41.725-4.801.228 1.987-41.724 4.8z" />
    <path d="M46.1 52.3H14.8c-3 0-5.5-2.5-5.5-5.5h2c0 2 1.5 3.5 3.5 3.5h31.3c2 0 3.5-1.5 3.5-3.5v-32c0-2-1.5-3.5-3.5-3.5h-2.7v-2h2.7c3 0 5.5 2.5 5.5 5.5v32c0 3.1-2.4 5.5-5.5 5.5" />
    <path d="M45.6 41.3h4.9v2h-4.9z" />
  </svg>
)
export default SvgGalleryIcon
