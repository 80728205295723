import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSceneLogoGray = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#F5F6F3"
      fillRule="evenodd"
      d="M37.26 19h.008V.008h-17.97V0H9.14C4.092 0 0 4.253 0 9.5S4.092 19 9.14 19H19.3V3.482c1.571 8.83 9.013 15.515 17.961 15.515zM28.86 19h-9.561v16.919C18.305 26.406 10.555 19 1.139 19H.731v19h.399v-.008h18.168V38h9.56C33.91 38 38 33.747 38 28.5S33.908 19 28.86 19"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSceneLogoGray
