import React from 'react'
import CTABanner2 from './CTABanner2'
import { ctaBanner2DefaultConfig } from './CTABanner2Meta'

const CTABanner2Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : ctaBanner2DefaultConfig

  return <CTABanner2 {...defaultData} />
}

export default CTABanner2Preview
