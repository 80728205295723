import React from 'react'
import { AccordionComponent } from '@sceneio/schemas/lib/types'
import { Container } from '../Container/Container'
import { PluginsType } from '../../scripts/plugins/pluginTypes'

function Accordion({ children, ...accordionProps }: AccordionComponent) {
  return (
    <div data-plugin-accordion>
      <Container
        {...accordionProps}
        flow={{ data: { '--flow-default': 'column' } }}
        name="componentArray"
        className="sc-accordion"
      >
        {children}
      </Container>
    </div>
  )
}

export default Accordion

export const plugins: PluginsType = {
  js: ['accordion', 'animation'],
  css: [],
}
