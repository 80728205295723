import React from 'react'
import clsx from 'clsx'
import Image from '../Image/Image'
import Video from '../Video/Video'
import NativeLink from '../NativeLink/NativeLink'
import { MediaWithLabels } from './components/MediaWithLabels'
import {
  UsedResponsiveBreakpoints,
  getOverlayCssVariables,
} from './mediaHelpers'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import { MediaComponent } from '@sceneio/schemas/lib/types'

function Media({
  format = 'image',
  components: { image, video, credits, caption },
  overlay,
  border,
  boxShadow,
  padding,
  margin,
  editor,
  className,
  animation,
  cover,
  style,
  link,
  show = true,
  cid,
  preview,
}: MediaComponent) {
  if (!format || !show) {
    return null
  }

  const mediaClass = clsx(
    'sc-media',
    {
      [`sc-media--${format}`]: format,
      'sc-media--cover': cover,
    },
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  const imageClass = (className?: string) =>
    clsx(
      {
        [`sc-animation--${animation}`]: animation,
      },
      className,
    )

  let usedResponsiveBreakpoints = Object.keys(overlay?.responsiveConfig || {})

  if (format === 'image') {
    usedResponsiveBreakpoints = [
      ...usedResponsiveBreakpoints,
      ...Object.keys(image?.responsiveConfig || {}),
    ]
  }

  if (format === 'video') {
    usedResponsiveBreakpoints = [
      ...usedResponsiveBreakpoints,
      ...Object.keys(video?.responsiveConfig || {}),
    ]
  }

  // uniq values
  usedResponsiveBreakpoints = [
    ...new Set(usedResponsiveBreakpoints),
  ] as UsedResponsiveBreakpoints

  const mediaStyle = {
    ...style,
    ...getOverlayCssVariables({
      overlay,
      usedResponsiveBreakpoints,
      type: 'media',
    }),
    ...getRootLevelCssVariables({
      cssVariables: {
        padding,
        margin,
        border,
      },
      prefix: 'media',
    }),
    ...getShadowCssVariables({
      shadow: boxShadow,
      shadowType: 'box',
      prefix: 'media',
    }),
  }

  // Video format
  if (format === 'video') {
    return (
      <div
        data-component-id={editor?.id}
        className={mediaClass}
        style={mediaStyle}
      >
        <MediaWithLabels
          credits={{
            ...credits,
            editor: {
              id: `${editor?.id}.credits`,
            },
          }}
          caption={{
            ...caption,
            editor: {
              id: `${editor?.id?.replace('media', 'caption')}`,
            },
          }}
          cid={cid}
        >
          <NativeLink
            {...link}
            href={video?.isBackgroundVideo ? link?.href : undefined}
          >
            <Video {...video} cover={cover} preview={preview} />
          </NativeLink>
        </MediaWithLabels>
      </div>
    )
  }

  // Image format
  if (format === 'image' || format === 'rawImage') {
    if (format === 'rawImage') {
      return (
        <Image
          {...image}
          editor={editor}
          className={imageClass(image?.className)}
          cover={cover}
        />
      )
    }

    return (
      <div
        data-component-id={editor?.id}
        className={mediaClass}
        style={mediaStyle}
      >
        <MediaWithLabels
          credits={{
            ...credits,
            editor: {
              id: `${editor?.id}.credits`,
            },
          }}
          caption={{
            ...caption,
            editor: {
              id: `${editor?.id?.replace('media', 'caption')}`,
            },
          }}
          cid={cid}
        >
          <NativeLink {...link}>
            <Image
              {...image}
              className={imageClass(image?.className)}
              cover={cover}
            />
          </NativeLink>
        </MediaWithLabels>
      </div>
    )
  }

  return null
}

export const plugins = {
  js: ['video', 'animation'],
  css: [],
}
export default Media
