import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Icon } from '@sceneio/ui-icons'

type HeaderDropdownPropType = {
  icon?: string
  options: string[]
  onSelect: (type: string) => void
  disabledOptions?: string[]
}

export const HeaderDropdown = ({
  icon = 'Plus',
  options,
  onSelect,
  disabledOptions,
}: HeaderDropdownPropType) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="tw-p-1 focus-visible:tw-outline-none">
        <Icon provider="phosphor" icon={icon as any} size="1rem" />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className="tw-overflow-hidden tw-bg-white tw-text-form-color tw-text-xs tw-rounded tw-border tw-border-border-color tw-p-1 tw-min-w-[130px] tw-mt-0.5 tw-z-10"
        align="end"
      >
        {options.map((option) => (
          <DropdownMenu.Item
            key={option}
            className="tw-p-2 hover:tw-outline-none hover:tw-bg-form-field-bg aria-disabled:tw-opacity-50 tw-rounded"
            onSelect={() => onSelect(option)}
            disabled={disabledOptions?.includes(option)}
          >
            <span className="tw-capitalize">{option}</span>
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
