import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { HeaderVariant3Block } from '@sceneio/schemas/lib/types'
import {
  HeaderVariant1,
  headerVariant1Plugins,
} from '../HeaderVariant1/HeaderVariant1'
import { headerVariant3DefaultConfig } from './headerVariant3Meta'

function HeaderVariant3(
  props: HeaderVariant3Block = headerVariant3DefaultConfig,
) {
  return <HeaderVariant1 {...props} />
}

export const headerVariant3Plugins: PluginsType = headerVariant1Plugins

export default HeaderVariant3
