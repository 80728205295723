import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import {
  COMMAND_PRIORITY_CRITICAL,
  INSERT_LINE_BREAK_COMMAND,
  KEY_ENTER_COMMAND,
} from 'lexical'

export function LineBreakOnEnterPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.registerCommand<KeyboardEvent | null>(
      KEY_ENTER_COMMAND,
      (event) => {
        if (event !== null) {
          event.preventDefault()
          return editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false)
        }
        return true
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor])

  return null
}
