export type PostprocessValueType = {
  value?: string | number
  unit?: string
}

export function postprocessValueWithUnit({
  value,
  unit = '',
}: PostprocessValueType) {
  if (typeof value === 'undefined' || value === '') {
    return null
  }

  if (typeof value === 'number') {
    if (!unit || unit === '-') {
      return value
    }
  }

  if (typeof value === 'string') {
    if (Number.isNaN(Number(value))) {
      return null
    }
    if (!unit || unit === '-') {
      return parseFloat(value)
    }
  }

  return parseFloat(value.toString())
    .toString()
    .concat(unit === '-' ? '' : unit)
}
