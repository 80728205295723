import React from 'react'
import clsx from 'clsx'
import { getRootLevelCssVariables } from '../../tools/cssVariables/getRootLevelCssVariables'
import { getShadowCssVariables } from '../../tools/cssVariables/getShadowCssVariables'
import Image from '../Image/Image'
import Text from '../Text/Text'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { GalleryItemComponent } from '@sceneio/schemas/lib/types'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { getBackgroundCssVariables } from '../../tools/cssVariables/getBackgroundCssVariables'
import NativeLink from '../NativeLink/NativeLink'

function GalleryItem({
  editor,
  style,
  className,
  horizontalAlignment,
  gap,
  padding,
  margin,
  border,
  background,
  boxShadow,
  height,
  cid,
  components: { image, heading, description },
}: GalleryItemComponent) {
  const galleryItemClasses = clsx(
    'sc-gallery-item',
    editor?.resolveClassName?.({
      blockId: editor.blockId,
      componentId: editor.id,
    }),
    className,
  )

  return (
    <div
      data-component-id={editor?.id}
      className={galleryItemClasses}
      style={{
        ...style,
        ...getRootLevelCssVariables({
          cssVariables: {
            horizontalAlignment,
            gap,
            padding,
            margin,
            border,
            height,
          },
          prefix: 'gallery-item',
        }),
        ...getShadowCssVariables({
          shadow: boxShadow,
          shadowType: 'box',
          prefix: 'gallery-item',
        }),
        ...getBackgroundCssVariables({
          background: background || {},
          prefix: `gallery-item`,
        }),
      }}
    >
      <Image
        {...image}
        isAnimated={true}
        cover
        className={clsx('sc-gallery-item__image-wrapper', image?.className)}
        editor={{
          ...editor,
          id: `${editor?.id}.image`,
        }}
      />
      <NativeLink {...image.link}>
        <Text
          {...heading}
          editor={{
            ...editor,
            id: `${editor?.id}.heading`,
          }}
          cid={resolveComponentId(cid, 'heading')}
        />
      </NativeLink>
      <NativeLink {...image.link}>
        <Text
          {...description}
          editor={{
            ...editor,
            id: `${editor?.id}.description`,
          }}
          cid={resolveComponentId(cid, 'description')}
        />
      </NativeLink>
    </div>
  )
}

export default GalleryItem

export const plugins: PluginsType = {
  js: ['animation'],
  css: [],
}
