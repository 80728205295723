import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { CommentType, NormalisedCommentType } from './commentsSliceTypes'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { stringToColor } from '@sceneio/tools'
import { memoize } from 'proxy-memoize'
dayjs.extend(relativeTime)

export const selectAllComments = (state: RootState) => {
  return state.comments.entities
}

export const selectAllCommentsWithoutResolved = memoize((state: RootState) => {
  return state.comments.entities.filter(
    (comment) => comment.state !== 'RESOLVED',
  )
})

export const selectCommentIds = memoize((state: RootState) => {
  const allCommentsWithoutResolved = selectAllCommentsWithoutResolved(
    state,
  ) as CommentType[]
  return allCommentsWithoutResolved.map((comment) => comment.id)
})

function normalizeComment(comment: CommentType): NormalisedCommentType {
  const { data, message } = comment
  const content =
    typeof data === 'object' && Object.keys(data).length > 0
      ? JSON.stringify(comment.data)
      : message || ''

  return {
    id: comment?.id,
    cid: comment.cid,
    createdAt: comment?.createdAt,
    meta: {
      ...comment.meta,
      whiteboard: {
        position:
          comment?.meta?.whiteboard?.position || comment?.preferences?.position,
      },
    },
    data: {
      content: content,
      authorName: comment?.createdByUser?.name,
      authorId: comment?.createdByUser?.id,
      authorLetter:
        (comment?.createdByUser?.name || '')?.charAt(0)?.toUpperCase() ?? '',
      authorEmail: comment?.createdByUser?.email,
      authorColor: stringToColor(comment?.createdByUser?.email),
      replies: [],
      preferences: comment?.preferences,
    },
  }
}

export const selectNormalisedComments = createSelector(
  [selectAllCommentsWithoutResolved],
  (comments) => {
    let output: NormalisedCommentType[] = []

    type CommentsReduceAccType = {
      parentComments: NormalisedCommentType[]
      repliesByParentCommentId: {
        [
          parentId: NonNullable<CommentType['parentId']>
        ]: NormalisedCommentType[]
      }
    }

    // Optimalised reduce fn that reduces comments into:
    // 1. normalized parentComments
    // 2. normalized comments grouped by parent id
    const { parentComments, repliesByParentCommentId } = comments.reduce(
      (acc, val) => {
        if (val.parentId) {
          // if comment has parentId, group by parentId
          return {
            ...acc,
            repliesByParentCommentId: {
              ...acc.repliesByParentCommentId,
              [val.parentId]: [
                ...(acc.repliesByParentCommentId[val.parentId] || []),
                normalizeComment(val),
              ],
            },
          }
        } else {
          // otherwise it is parent comment and push it onto parentComments arr
          return {
            ...acc,
            parentComments: [...acc.parentComments, normalizeComment(val)],
          }
        }
      },
      {
        parentComments: [],
        repliesByParentCommentId: {},
      } as CommentsReduceAccType,
    )

    for (const comment of parentComments) {
      const replies = repliesByParentCommentId[comment.id] || []
      const sortedReplies = replies.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      )
      output.push({
        ...comment,
        data: {
          ...comment.data,
          replies: sortedReplies,
        },
      })
    }

    return output
  },
)

export const selectUnreadCommentsByUserId = createSelector(
  [selectNormalisedComments, (state, userId) => userId],
  (allComments, userId) => {
    const currentDate = new Date()
    const oneMonthAgo = new Date().setMonth(currentDate.getMonth() - 1)

    // select all unread comments not older than one month
    return allComments.filter(
      (comment) =>
        comment.createdAt &&
        new Date(comment.createdAt).getTime() > oneMonthAgo &&
        !comment.meta.readByUserIds?.includes(userId),
    )
  },
)

export const selectTemporaryComment = (state: RootState) => {
  return state.comments.entities.find(
    (comment) => comment.state === 'TEMPORARY',
  )
}

export const selectNormalisedCommentById = createSelector(
  [selectNormalisedComments, (state, commentId) => commentId],
  (allComments, commentId) => {
    const comment = allComments.find((comment) => comment.id === commentId)
    return comment
  },
)
