import React from 'react'
import Hero4 from './Hero4'
import { Hero4Block } from '@sceneio/schemas/lib/types'
import { hero4DefaultConfig } from './hero4Meta'

const Hero4Preview = (config: Hero4Block) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : hero4DefaultConfig

  return <Hero4 {...defaultData} />
}

export default Hero4Preview
