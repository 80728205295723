import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { imageComponentSchema } from '../../components/imageComponentSchema'
import { mediaComponentSchema } from '../../components/mediaComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'

export const hero5BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    logo: imageComponentSchema,
    subheading: textComponentSchema,
    text: textComponentSchema,
    heading: textComponentSchema,
    author: textComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
    media: mediaComponentSchema,
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type Hero5Block = InferType<typeof hero5BlockSchema>
