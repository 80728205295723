import clsx from 'clsx'
import { HeaderSegment } from '../../atoms/HeaderSegment/HeaderSegment'
import { SegmentWrapper } from '../../atoms/SegmentWrapper/SegmentWrapper'
import { useFormContext } from '../../form/context/FormContext'
import { RHFSpacingSettingsPropType } from './SpacingSettings'
import { BreakpointSpacingFields } from './components/BreakpointSpacingFields'

export const DefaultSpacingSettings = ({
  basePath,
  type,
  title = 'Spacing',
  className,
}: RHFSpacingSettingsPropType) => {
  const { values, unregister, register, registerValues } = useFormContext({
    name: basePath,
  })

  const valuesPath = `${basePath ? basePath + '.' : ''}data`

  const hasExistingValues = Object.keys(values).some((val) =>
    val.startsWith(`${valuesPath}`),
  )

  return (
    <SegmentWrapper className={clsx('sc-rhf-spacing-settings', className)}>
      <HeaderSegment
        heading={title}
        addButton={{
          show: !hasExistingValues,
          onClick: () => register(registerValues),
        }}
        removeButton={{
          show: hasExistingValues,
          onClick: () => unregister([`${valuesPath}`]),
        }}
      />
      {hasExistingValues && (
        <BreakpointSpacingFields
          breakpoint={'default'}
          type={type}
          valuesPath={valuesPath}
        />
      )}
    </SegmentWrapper>
  )
}
