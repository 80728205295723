import type { SystemState, ActionWithPayload } from '../types'
import { SYSTEM, USER } from '../constatnts'

const initialState: SystemState = {
  websiteSupportedLanguagesLoading: true,
  websiteSupportedLanguagesError: false,
  websiteSupportedLanguages: [],
  cmsSupportedLanguages: ['en'],
}
export default function uiReducer(
  state: SystemState = initialState,
  { type, payload }: ActionWithPayload<any>,
): SystemState {
  if (type === SYSTEM.WEBSITE_LANGUAGES_FETCH_SUCCESS) {
    return {
      ...state,
      websiteSupportedLanguagesError: false,
      websiteSupportedLanguagesLoading: false,
      websiteSupportedLanguages: payload,
    }
  }

  if (type === SYSTEM.WEBSITE_LANGUAGES_FETCH_FAIL) {
    return {
      ...state,
      websiteSupportedLanguagesError: true,
      websiteSupportedLanguagesLoading: false,
    }
  }

  if (type === USER.LOGOUT) {
    return initialState
  }

  return state
}
