import React from 'react'
import FAQ1 from './FAQ1'
import { fAQ1DefaultConfig } from './fAQ1Meta'

const FAQ1Preview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : fAQ1DefaultConfig

  return <FAQ1 {...defaultData} />
}

export default FAQ1Preview
