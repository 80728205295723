import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { HeadingNode } from '@lexical/rich-text'
import { ArrayItemNode } from '@sceneio/wysiwyg-nodes/lib/ArrayItemNode'
import { MentionNode } from '@sceneio/wysiwyg-nodes/lib/MentionNode'

export default {
  theme: {
    paragraph: 'sc-text__content',
    heading: {
      h1: 'sc-text__content',
      h2: 'sc-text__content',
      h3: 'sc-text__content',
      h4: 'sc-text__content',
      h5: 'sc-text__content',
      h6: 'sc-text__content',
    },
    list: {
      ol: 'sc-text__content',
      ul: 'sc-text__content',
    },
    text: {
      strikethrough: 'sc-text__strikethrough',
      underline: 'sc-text__underline',
      underlineStrikethrough: 'sc-text__underlineStrikethrough',
    },
    link: 'sc-link',
  },
  nodes: [
    HeadingNode,
    ListItemNode,
    ListNode,
    ArrayItemNode,
    LinkNode,
    AutoLinkNode,
    MentionNode,
  ],
}
