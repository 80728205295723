import React from 'react'
import Media from './Media'
import { MediaType } from '../../types/mediaType'
import { mediaDefaultConfig } from './mediaMeta'

const MediaPreview = (config: MediaType) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : mediaDefaultConfig
  return <Media {...defaultData} />
}

export default MediaPreview
