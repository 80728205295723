import { InferType, object, reach, string } from 'yup'
import { boxShadowResponsiveConfigSchema } from '../../styles/boxShadowStyleSchema'

export const boxShadowSnippetSchema = object({
  data: object({
    value: boxShadowResponsiveConfigSchema,
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['BOX_SHADOW']).required(),
})

export type BoxShadowSnippet = InferType<typeof boxShadowSnippetSchema>
