export function recalcValueOnUnitChange({
  prevUnit,
  nextUnit,
  value,
}: {
  prevUnit: string
  nextUnit: string
  value: number
}) {
  if (prevUnit === nextUnit) {
    return value
  }

  if (prevUnit === 'full width' && nextUnit === '%') {
    return 100
  }

  if (value === 0) {
    return value
  }

  if (prevUnit === 'px' && (nextUnit === 'rem' || nextUnit === 'em')) {
    return Math.round((value / 16) * 100) / 100
  }

  if ((prevUnit === 'rem' || prevUnit === 'em') && nextUnit === 'px') {
    return Math.round(value * 16 * 100) / 100
  }

  if ((prevUnit === 'rem' || prevUnit === 'em') && nextUnit === '%') {
    return Math.round(value * 100 * 100) / 100
  }

  if (prevUnit === 'px' && nextUnit === '%') {
    return Math.round((100 / (16 / value)) * 100) / 100
  }

  if (prevUnit === '%' && nextUnit === 'px') {
    return Math.round((16 / (100 / value)) * 100) / 100
  }

  if (prevUnit === '%' && (nextUnit === 'rem' || nextUnit === 'em')) {
    return Math.round((value / 100) * 1000) / 1000
  }

  return value
}
