import { InferType, bool, number, object, string } from 'yup'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { styleSchema } from '../shared/styleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'

export const commonSliderSchema = object({
  meta: object({
    dataType: string().oneOf(['slider-component']).required(),
  })
    .noUnknown()
    .required(),
  itemsPerView: object({
    responsiveConfig: object({
      default: number(),
      xs: number(),
      md: number(),
      lg: number(),
    }).noUnknown(),
  }).noUnknown(),
  activeSlideIndex: number(),
  allowKeyNav: bool(),
  loop: bool(),
  effect: string().oneOf(['fade', 'slide']),
  easing: string().oneOf(['ease-in', 'ease-out', 'ease-in-out']),
  autoplay: object({
    timeout: number(),
    enable: bool(),
    direction: string().oneOf(['forward', 'backward']),
  }).noUnknown(),
  speed: number(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  preview: bool(),
  cid: string(),
})

export const sliderComponentSchema = commonSliderSchema
  .shape({
    children: reactNodeSchema,
  })
  .noUnknown()

export type SliderComponent = InferType<typeof sliderComponentSchema>

export type SliderComponentStyles = Pick<
  SliderComponent,
  'margin' | 'padding' | 'boxShadow' | 'border' | 'gap'
>
