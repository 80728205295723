import { addBlock, deleteBlock } from '../features/content/contentSlice'
import { DeleteContentBlockQueryVarsType } from '../features/content/contentSliceTypes'
import { StoreType } from '../store'
import { LocalHistoryCommand } from './LocalHistoryCommand'

export class DeleteBlockCommand extends LocalHistoryCommand {
  thunkData: DeleteContentBlockQueryVarsType
  constructor(thunkData: DeleteContentBlockQueryVarsType) {
    super()
    this.thunkData = thunkData
    this.type = 'DeleteBlockCommand'
  }

  execute() {
    return deleteBlock(this.thunkData)
  }

  preExecute(getState: StoreType['getState']) {
    const state = getState()

    if (state.content.status !== 'succeeded') {
      return
    }

    const block = state.content.entity.contentBlocks.find(
      ({ id }) => this.thunkData.queryVariables.id === id,
    )

    if (block) {
      this.thunkData = {
        queryVariables: { cid: block.cid, id: block.id },
        thunkOptions: this.thunkData.thunkOptions,
      }
      this.pastState = block
    }
  }

  undo() {
    return addBlock({
      queryVariables: { ...this.pastState, position: this.pastState.order },
    })
  }
}
