import { flatten, unflatten } from 'flat'
import { randomString } from './randomString'

export function nestedIdsRandomizer(data: Record<string, any> = {}) {
  const flattenData = flatten(data) as Record<string, any>
  const flattenDataWithRandomNestedIds = Object.entries(flattenData).reduce(
    (acc, [key, value]) => {
      if (key.endsWith('id')) {
        return {
          ...acc,
          [key]: randomString(8),
        }
      }
      return {
        ...acc,
        [key]: value,
      }
    },
    {},
  )

  return unflatten(flattenDataWithRandomNestedIds)
}
