import { InferType, object } from 'yup'
import { cssDimensionSchema } from './common/cssDimensionSchema'

export const heightStyleSchema = object({
  data: object({
    '--height-default': cssDimensionSchema,
    '--height-xs': cssDimensionSchema,
    '--height-md': cssDimensionSchema,
    '--height-lg': cssDimensionSchema,
  }).noUnknown(),
}).noUnknown()

export type HeightStyle = InferType<typeof heightStyleSchema>
