export default function resolveComponentId(
  cid: any,
  name: string,
): string | undefined {
  if (!cid) {
    return undefined
  }

  return `${cid}-${name}`
}
