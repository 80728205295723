import React from 'react'
import { PluginsType } from '../../../scripts/plugins/pluginTypes'
import { videoHeroDefaultConfig } from './videoHeroMeta'
import { VideoHeroBlock } from '@sceneio/schemas/lib/types'
import Banner from '../Banner/Banner'

function VideoHero(props: VideoHeroBlock = videoHeroDefaultConfig) {
  return <Banner {...props} />
}

export const videoHeroPlugins: PluginsType = {
  js: ['video', 'animation'],
  css: [],
}
export default VideoHero
