import React from 'react'
import FeatureWithForm from './FeatureWithForm'
import { featureWithFormDefaultConfig } from './featureWithFormMeta'

const FeatureWithFormPreview = (config: any) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : featureWithFormDefaultConfig

  return <FeatureWithForm {...defaultData} />
}

export default FeatureWithFormPreview
