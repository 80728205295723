import {
  ButtonComponent,
  HeroCarousel1Block,
  SliderVariant1Component,
} from '@sceneio/schemas/lib/types'
import { randomString } from '@sceneio/tools'
import { textToLexical } from '@sceneio/wysiwyg-utils'
import { DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES } from '../../../components/Button/constants/globalButtonCssVariables'
import { mediaDefaults } from '../../../components/Media/mediaMeta'
import { DEFAULT_HEADINGS_CSS_VARIABLES } from '../../../components/Text/constants/globalTextCssVariables'
import { BlockComponentMeta, BlockMeta } from '../../blockCommonTypes'
import { blockDefaultConfigFactory } from '../../helpers'
import { buttonContainerLayoutAdditionalFormFieldRenderer } from '../SplitHero/form/additionalFormFieldRenderers'
import { sliderVariant1Defaults } from '../../../components/Slider/SliderVariant1/sliderVariant1Meta'
import { containerComponentFormRegisterValuesFactory } from '../../../components/Container/form/ContainerComponentForm'

const MEDIA_DEFAULTS = mediaDefaults.config
const SLIDER_VARIANT_1_DEFAULTS = sliderVariant1Defaults.config

export const buttonSampleFactory = (data = {}) => ({
  id: randomString(8),
  meta: {
    dataType: 'button-component',
  },
  font: {
    meta: {
      dataType: 'font-style',
    },
  },
  children: textToLexical('Button', 'array-item'),
  width: {
    data: {
      '--width-default': 'auto',
      '--width-xs': '100%',
    },
  },
  ...data,
})

export const slideSampleFactory = ({
  imageSrc,
  heading,
}: {
  imageSrc?: string
  heading?: string
} = {}): SliderVariant1Component['components']['slides']['items'][0] & {
  id: string
} => ({
  id: randomString(),
  horizontalAlignment: {
    data: {
      '--horizontal-alignment-default': 'flex-start',
      '--horizontal-alignment-xs': 'center',
    },
  },
  verticalAlignment: {
    data: {
      '--vertical-alignment-default': 'flex-end',
      '--vertical-alignment-xs': 'center',
    },
  },
  flow: {
    data: {
      '--flow-default': 'column',
    },
  },
  meta: {
    dataType: 'container-component',
  },
  components: {
    background: {
      ...MEDIA_DEFAULTS,
      cover: true,
      components: {
        ...MEDIA_DEFAULTS.components,
        image: {
          ...MEDIA_DEFAULTS.components.image,
          responsiveConfig: {
            default: {
              src:
                imageSrc ||
                MEDIA_DEFAULTS.components.image?.responsiveConfig?.default.src,
            },
          },
        },
        video: {
          ...MEDIA_DEFAULTS.components.video,
          isBackgroundVideo: true,
        },
      },
    },
    contentContainer: {
      meta: {
        dataType: 'container-component',
      },
      gap: {
        data: {
          '--row-gap-default': '1.5rem',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-xs': 'center',
        },
      },
      padding: {
        data: {
          '--padding-default-bottom': '5rem',
          '--padding-default-top': '5rem',
          '--padding-default-left': '5rem',
          '--padding-default-right': '5rem',
          '--padding-xs-left': '2rem',
          '--padding-xs-right': '2rem',
        },
      },
    },
    heading: {
      meta: {
        dataType: 'text-component',
      },
      children: textToLexical(heading || 'Top level heading', 'h1'),
      as: 'h1',
      show: true,
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-xs': 'center',
        },
      },
      font: {
        ...DEFAULT_HEADINGS_CSS_VARIABLES['h1'].font,
        data: {
          default: {
            '--font-default-size-default': '4rem',
            '--font-md-size-default': '2.5rem',
            '--font-xs-size-default': '2.25rem',
            '--font-default-weight-default': 'bold',
            '--font-default-line-height-default': 1.2,
          },
        },
      },
    },
    text: {
      meta: {
        dataType: 'text-component',
      },
      font: {
        meta: {
          dataType: 'font-style',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-xs': 'center',
        },
      },
      children: textToLexical(
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. \nSuspendisse varius enim in eros elementum tristique.',
      ),
      as: 'p',
      show: true,
    },
    buttons: {
      meta: {
        dataType: 'container-component',
      },
      items: [
        buttonSampleFactory({
          children: textToLexical('CTA Button', 'array-item'),
          ...DEFAULT_PRIMARY_BUTTON_CSS_VARIABLES,
        }),
        buttonSampleFactory(),
      ] as ButtonComponent[],
      gap: {
        data: {
          [`--row-gap-default`]: '1rem',
          [`--column-gap-default`]: '0.75rem',
        },
      },
      flow: {
        data: {
          '--flow-default': 'row',
          '--flow-xs': 'column',
        },
      },
      horizontalAlignment: {
        data: {
          '--horizontal-alignment-default': 'flex-start',
        },
      },
      padding: {
        data: {
          '--padding-default-top': '1rem',
        },
      },
    },
  },
})

export const heroCarousel1ComponentMeta: BlockComponentMeta<HeroCarousel1Block> =
  {
    block: {
      path: 'components.block',
      name: 'Block',
      type: 'block',
      defaultConfig: {
        meta: {
          dataType: 'block-component',
        },
        minHeight: {
          data: {
            '--min-height-default': '100vh',
          },
        },
      },
    },
    blockContainer: {
      path: 'components.block.blockContainer',
      name: 'Container',
      disableToggleShow: true,
      defaultConfig: {
        meta: {
          dataType: 'block-container-component',
        },
        responsiveConfig: {
          default: {
            maxWidth: 'screen',
          },
        },
      },
      type: 'blockContainer',
    },
    slider: {
      path: 'components.slider',
      name: 'Slider',
      type: 'sliderVariant1',
      components: {
        slides: {
          type: 'container',
          name: 'Slides',
          path: 'components.slides',
          configEditor: {
            disable: true,
          },
          disableToggleShow: true,
          persistentSelectionId: 'slider',
          components: {
            items: {
              isArrayComponent: true,
              path: 'items',
              name: 'Slide',
              type: 'container',
              disableToggleShow: true,
              sampleFactory: slideSampleFactory,
              components: {
                background: {
                  type: 'media',
                  name: 'Background',
                  path: 'components.background',
                  components: {
                    credits: {
                      path: 'components.credits',
                      inlineEditors: [
                        {
                          editorType: 'inlineText',
                          editorPath: 'components.media.components.credits',
                          editorOptions: {
                            isNestedArrayItem: true,
                          },
                        },
                      ],
                      disableEditing: true,
                      name: 'Credits',
                      type: 'text',
                    },
                  },
                },
                contentContainer: {
                  path: 'components.contentContainer',
                  name: 'Content container',
                  type: 'container',
                  configEditor: {
                    formProps: {
                      name: 'group',
                      registerValues:
                        containerComponentFormRegisterValuesFactory('group'),
                    },
                  },
                },
                heading: {
                  type: 'text',
                  name: 'Heading',
                  path: 'components.heading',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.heading',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                },
                text: {
                  type: 'text',
                  name: 'Text',
                  path: 'components.text',
                  inlineEditors: [
                    {
                      editorType: 'inlineText',
                      editorPath: 'components.text',
                      editorOptions: {
                        isNestedArrayItem: true,
                      },
                    },
                  ],
                },
                buttons: {
                  type: 'container',
                  name: 'Buttons',
                  path: 'components.buttons',
                  components: {
                    items: {
                      isArrayComponent: true,
                      path: 'items',
                      name: 'Button',
                      type: 'button',
                      disableToggleShow: true,
                      sampleFactory: () => buttonSampleFactory(),
                    },
                  },
                  inlineEditors: [
                    {
                      editorType: 'buttonEditor',
                      editorPath: 'components.buttons.items',
                      editorOptions: {
                        isNestedArrayItem: true,
                        isItemArray: true,
                        sampleFactory: () => buttonSampleFactory(),
                      },
                    },
                  ],
                  configEditor: {
                    additionalFormFields: {
                      layout: {
                        formFieldRenderer:
                          buttonContainerLayoutAdditionalFormFieldRenderer,
                      },
                    },
                    formProps: {
                      name: 'componentArray',
                      registerValues:
                        containerComponentFormRegisterValuesFactory(
                          'componentArray',
                        ),
                    },
                  },
                },
              },
              configEditor: {
                formProps: {
                  registerValues:
                    containerComponentFormRegisterValuesFactory('component'),
                },
              },
            },
          },
        },
        controlsContainer: {
          type: 'container',
          name: 'Controls container',
          path: 'components.controlsContainer',
          components: {
            navigation: {
              type: 'container',
              name: 'Navigation',
              path: 'components.navigation',
              components: {
                buttonPrev: {
                  path: 'components.buttonPrev',
                  name: 'Button prev',
                  type: 'button',
                },
                buttonNext: {
                  path: 'components.buttonNext',
                  name: 'Button next',
                  type: 'button',
                },
              },
              configEditor: {
                formProps: {
                  registerValues:
                    containerComponentFormRegisterValuesFactory('group'),
                },
              },
            },
            pagination: {
              type: 'sliderPagination',
              name: 'Pagination',
              path: 'components.pagination',
            },
          },
          configEditor: {
            formProps: {
              registerValues:
                containerComponentFormRegisterValuesFactory('component'),
            },
          },
        },
      },
      defaultConfig: {
        ...SLIDER_VARIANT_1_DEFAULTS,
        components: {
          ...SLIDER_VARIANT_1_DEFAULTS.components,
          slides: {
            ...SLIDER_VARIANT_1_DEFAULTS.components.slides,
            items: [
              slideSampleFactory(),
              slideSampleFactory({ heading: 'Top level heading 2' }),
              slideSampleFactory({ heading: 'Top level heading 3' }),
            ],
          },
        },
      },
      configEditor: {
        formProps: {
          excludedSettings: ['itemsPerView', 'gap'],
        },
      },
    },
  }

export const heroCarousel1BlockMeta: BlockMeta<HeroCarousel1Block> = {}

export const heroCarousel1DefaultConfig =
  blockDefaultConfigFactory<HeroCarousel1Block>({
    componentMeta: heroCarousel1ComponentMeta,
    blockMeta: heroCarousel1BlockMeta,
    version: 1,
  })
