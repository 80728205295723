import clsx from 'clsx'

export const ErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return
  }
  return (
    <div className="tw-absolute tw-right-2 tw-top-3">
      <div className="tw-relative tw-bg-danger tw-rounded-full tw-w-2 tw-h-2">
        <div
          className={clsx(
            'tw-transition-all tw-opacity-1 tw-absolute tw-p-2 tw-bottom-[110%] tw-right-0 tw-w-max tw-max-w-[200px] tw-text-xs tw-rounded-md tw-bg-[rgba(37,37,35,0.92)] tw-text-white',
          )}
        >
          {message}
        </div>
      </div>
    </div>
  )
}
