import type { SVGProps } from 'react'
const SvgExclamationMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="2"
    height="10"
    viewBox="0 0 2 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.333334 0.999674V5.66634C0.333334 5.84315 0.403572 6.01272 0.528596 6.13774C0.65362 6.26277 0.823189 6.33301 1 6.33301C1.17681 6.33301 1.34638 6.26277 1.47141 6.13774C1.59643 6.01272 1.66667 5.84315 1.66667 5.66634V0.999674C1.66667 0.822863 1.59643 0.653294 1.47141 0.52827C1.34638 0.403246 1.17681 0.333008 1 0.333008C0.823189 0.333008 0.65362 0.403246 0.528596 0.52827C0.403572 0.653294 0.333334 0.822863 0.333334 0.999674Z"
      fill="currentColor"
    />
    <path
      d="M1.83147 9.22191C1.94135 9.05746 2 8.86412 2 8.66634C2 8.40112 1.89464 8.14677 1.70711 7.95923C1.51957 7.7717 1.26522 7.66634 1 7.66634C0.802219 7.66634 0.60888 7.72499 0.44443 7.83487C0.279981 7.94475 0.151809 8.10093 0.0761209 8.28366C0.000433286 8.46638 -0.01937 8.66745 0.0192152 8.86143C0.0578004 9.05541 0.153041 9.23359 0.292894 9.37345C0.432746 9.5133 0.610929 9.60854 0.80491 9.64712C0.998891 9.68571 1.19996 9.66591 1.38268 9.59022C1.56541 9.51453 1.72159 9.38636 1.83147 9.22191Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgExclamationMark
