import { StateType } from '../../../atoms/StateTabs/StateTabs'
import { BreakpointBorderFields } from './BreakpointBorderFields'

type StateBorderFieldsPropType = {
  state: StateType
  valuesPath: string
  hidden?: boolean
}

export function StateBorderFields({
  state,
  valuesPath,
}: StateBorderFieldsPropType) {
  return (
    <BreakpointBorderFields
      breakpoint="default"
      state={state}
      valuesPath={valuesPath}
    />
  )
}
