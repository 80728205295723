import React from 'react'
import SplitHero from './SplitHero'
import { splitHeroDefaultConfig } from './splitHeroMeta'

const SplitHeroPreview = (config) => {
  const defaultData =
    Object.keys(config).length !== 0 ? config : splitHeroDefaultConfig

  return <SplitHero {...defaultData} />
}

export default SplitHeroPreview
