import React from 'react'
import clsx from 'clsx'
import Block from '../../components/Block/Block'
import resolveComponentId from '../../tools/helpers/resolveComponentId'
import { PluginsType } from '../../scripts/plugins/pluginTypes'
import { ButtonsBlock } from '@sceneio/schemas/lib/types'
import { buttonSampleFactory, buttonsDefaultConfig } from './buttonsMeta'
import { Container } from '../../components/Container/Container'
import { editorItemsControlsFactory } from '../../tools/editor/editor'
import Button from '../../components/Button/Button'

function Buttons({
  id,
  components: { block, buttons },
  editor,
}: ButtonsBlock = buttonsDefaultConfig) {
  const { className, ...restBlockProps } = block || {}
  const buttonsClass = clsx(`sc-buttons-block`, className)

  return (
    <Block id={id} editor={editor} className={buttonsClass} {...restBlockProps}>
      <Container
        {...buttons}
        className="bui-flex-wrap bui-w-100"
        editor={{
          ...editor?.component,
          blockId: id,
          id: 'buttons',
        }}
        name="componentArray"
      >
        {buttons?.items?.map(
          (
            { id: buttonId, className: buttonClassname, ...restButtonProps },
            index,
          ) => (
            <Button
              editor={{
                ...editor?.component,
                blockId: id,
                id: `buttons.items[${index}]`,
                wysiwyg: {
                  type: 'ITEMS',
                  ...editorItemsControlsFactory({
                    itemsArr: buttons.items || [],
                    itemsId: `buttons.items`,
                    itemFactory: buttonSampleFactory,
                    index: index,
                  }),
                },
              }}
              className={clsx('sc-buttons__button', buttonClassname)}
              key={buttonId}
              cid={resolveComponentId(id, `button-${index}`)}
              {...restButtonProps}
            />
          ),
        )}
      </Container>
    </Block>
  )
}

export const buttonsPlugins: PluginsType = {
  js: ['animation'],
  css: [],
}
export default Buttons
