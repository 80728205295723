import { useEffect, useState } from 'react'
import { RHFNumericInput } from '../../../atoms/Input/NumericInput/NumericInput'
import { RHFSwitch } from '../../../atoms/Switch/Switch'
import { Icon } from '@sceneio/ui-icons'
import { BreakpointType } from '../../../atoms/BreakpointDropdown/BreakpointDropdown'
import { StateType } from '../../../atoms/StateTabs/StateTabs'
import { RHFColorPicker } from '../../../atoms/ColorPicker/ColorPicker'
import { RHFShadowSettingsPropType } from '../ShadowSettings'
import { unflatten } from 'flat'
import { search } from 'jmespath'
import { randomString } from '@sceneio/tools'
import { useFormContext } from '../../../form/context/FormContext'

type BreakpointBorderFieldsPropType = {
  valuesPath: string
  breakpoint: BreakpointType | 'default'
  state: StateType
  shadowType: RHFShadowSettingsPropType['shadowType']
}

export function BreakpointShadowFields({
  breakpoint,
  valuesPath,
  state,
  shadowType,
}: BreakpointBorderFieldsPropType) {
  const { registerValues, placeholders, watch, unregister, register } =
    useFormContext({ name: valuesPath })
  const [shadowsCount, setShadowsCount] = useState(1)
  const shadows =
    watch(`${valuesPath}.${breakpoint}.${state}`) ||
    watch(`${valuesPath}.${breakpoint}.default`) ||
    search(unflatten(placeholders), `${valuesPath}.${breakpoint}.${state}`) ||
    []

  useEffect(() => {
    setShadowsCount(shadows.length)
  }, [shadows.length])

  const onAddShadowClick = (idx: number) => {
    const newShadow = search(
      unflatten(registerValues),
      `${valuesPath}.default.default`,
    )[0]
    const shadowsCopy = [...shadows]
    shadowsCopy.splice(idx, 0, { ...newShadow, id: randomString(8) })
    register({ [`${valuesPath}.${breakpoint}.${state}`]: shadowsCopy })
    setShadowsCount(shadowsCount + 1)
  }

  const onRemoveShadowClick = (idx: number) => {
    unregister([`${valuesPath}.${breakpoint}.${state}.${[idx]}`])
    setShadowsCount(shadowsCount - 1)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {Array.from({ length: shadowsCount }, (_, idx) => (
        <div className="tw-flex tw-gap-1" key={idx}>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-min-w-0">
            <RHFColorPicker
              name={`${valuesPath}.${breakpoint}.${state}.${[idx]}.color`}
            />
            <div className="tw-flex tw-flex-row tw-gap-1">
              <RHFNumericInput
                name={`${valuesPath}.${breakpoint}.${state}.${[idx]}.x`}
                label="X"
                units={['px', 'rem', 'em']}
                allowNegativeValue
              />
              <RHFNumericInput
                name={`${valuesPath}.${breakpoint}.${state}.${[idx]}.y`}
                label="Y"
                units={['px', 'rem', 'em']}
                allowNegativeValue
              />
            </div>
            <div className="tw-flex tw-flex-row tw-gap-1">
              <RHFNumericInput
                name={`${valuesPath}.${breakpoint}.${state}.${[idx]}.blur`}
                label="Blur"
                units={['px', 'rem', 'em']}
              />
              {shadowType === 'box' && (
                <RHFNumericInput
                  name={`${valuesPath}.${breakpoint}.${state}.${[idx]}.spread`}
                  label="Spread"
                  units={['px', 'rem', 'em']}
                  allowNegativeValue
                />
              )}
            </div>
            {shadowType === 'box' && (
              <RHFSwitch
                name={`${valuesPath}.${breakpoint}.${state}.${[idx]}.inset`}
                label="Inset"
              />
            )}
          </div>
          <div className="tw-flex tw-flex-col tw-justify-between tw-pr-1">
            {shadowsCount !== 1 && (
              <button
                type="button"
                className="tw-w-6 tw-h-8 tw-flex tw-justify-end tw-items-center tw-pr-1"
                onClick={() => {
                  onRemoveShadowClick(idx)
                }}
              >
                <Icon provider="phosphor" icon="Minus" />
              </button>
            )}
            <button
              type="button"
              className="tw-w-6 tw-h-8 tw-flex tw-justify-end tw-items-center tw-pr-1"
              onClick={() => {
                onAddShadowClick(idx + 1)
              }}
            >
              <Icon provider="phosphor" icon="Plus" />
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}
